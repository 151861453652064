import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import DateRange from "../../../../common/types/date-range";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import usePagination from "../../../../common/hooks/use-pagination";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import routeFinishedListingApiService from "../common/api/route-finished-listing-api.service";
import RouteFinishedListingResponse from "../common/api/route-finished-listing.response";
import RouteFinishedListingViewBasicProps from "../common/types/route-finished-listing-view-basic-props";
import routeFinishedListingByRailyFactory from "./common/route-finished-listing-by-raily.factory";
import routeFinishedListingByRailyRouteQueryParamsService from "./common/route-finished-listing-by-raily-route-query-params.service";
import routeFinishedListingByRailySortHelper from "./common/sort/route-finished-listing-by-raily-sort.helper";
import RouteFinishedListingByRailyFilter, {
  RouteFinishedListingByRailyEndDateFilter,
  RouteFinishedListingByRailyStartDateFilter,
} from "./common/types/route-finished-listing-by-raily-filter";
import RouteFinishedListingByRailyFilterType from "./common/types/route-finished-listing-by-raily-filter-type";
import RouteFinishedListingByRailyListingItem from "./common/types/route-finished-listing-by-raily-item";
import RouteFinishedListingByRailyRouteQueryParams from "./common/types/route-finished-listing-by-raily-route-query-params";
import RouteFinishedListingByRailySortKey from "./common/types/route-finished-listing-by-raily-sort-key";
import RouteFinishedListingByRailySortSelectOption from "./common/types/route-finished-listing-by-raily-sort-select-option";
import RouteFinishedListingByRailyFiltersBadgeListComponent from "./filter/list/route-finished-listing-by-raily-filters-badge-list.component";
import RouteFinishedListingByRailyFiltersSelectComponent from "./filter/select/route-finished-listing-by-raily-listing-filters-select.component";
import RouteFinishedListingByRailyTableComponent from "./table/route-finished-listing-by-raily-table.component";
import routeFinishedListingByRailyRequestFactory from "./common/route-finished-listing-by-raily-request.factory";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";

type RouteFinishedListingByRailyProps = RouteFinishedListingViewBasicProps;

const RouteFinishedListingByRailyComponent: FC<
  RouteFinishedListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<RouteFinishedListingByRailyRouteQueryParams>();

  const translations = routeTranslationsHelper.getFinishedListingTranslations();

  const [filters, setFilters] = useState<RouteFinishedListingByRailyFilter[]>(
    () =>
      routeFinishedListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<RouteFinishedListingByRailySortKey | null>(
      () =>
        routeFinishedListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    RouteFinishedListingByRailyListingItem[]
  >([]);
  const [totalResults, setTotalListingItems] = useState(0);

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      routeFinishedListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      routeFinishedListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      routeFinishedListingByRailyRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: RouteFinishedListingByRailyFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onListingFetchSuccess = (response: RouteFinishedListingResponse) => {
    const listingItems = routeFinishedListingByRailyFactory.createListingItems(
      response.data.data
    );

    setListingItems(listingItems);
    setTotalListingItems(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: RouteFinishedListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = routeFinishedListingByRailyRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    routeFinishedListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const sortSelectOptions: RouteFinishedListingByRailySortSelectOption[] =
    useMemo(() => routeFinishedListingByRailySortHelper.getSelectOptions(), []);

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) => filter.type === RouteFinishedListingByRailyFilterType.START_DATE
  )?.value as RouteFinishedListingByRailyStartDateFilter["value"] | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== RouteFinishedListingByRailyFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByRailyFilterType.START_DATE
    );

    const newFilter: RouteFinishedListingByRailyStartDateFilter = {
      type: RouteFinishedListingByRailyFilterType.START_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteFinishedListingByRailyFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const endDateFilterValue = filters.find(
    (filter) => filter.type === RouteFinishedListingByRailyFilterType.END_DATE
  )?.value as RouteFinishedListingByRailyEndDateFilter["value"] | undefined;

  const onEndDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== RouteFinishedListingByRailyFilterType.END_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) => filter.type === RouteFinishedListingByRailyFilterType.END_DATE
    );

    const newFilter: RouteFinishedListingByRailyEndDateFilter = {
      type: RouteFinishedListingByRailyFilterType.END_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteFinishedListingByRailyFilterType.END_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
  };

  return (
    <div className="route_finished_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <div className="route_finished_listing_tools">
        <div className="d-flex">
          <RouteFinishedListingByRailyFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="route-finished-listing-by-raily-date-range-start"
          />
          <DateRangeInputComponent
            date={endDateFilterValue ?? null}
            onChange={onEndDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByEndDateSelectInputPlaceholder
            }
            idForTesting="route-finished-listing-by-raily-date-range-end"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="route-finished-listing-by-raily-select"
        />
      </div>
      <RouteFinishedListingByRailyFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <RouteFinishedListingByRailyTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="route_finished_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default RouteFinishedListingByRailyComponent;
