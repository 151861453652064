import { FC, useMemo } from "react";
import routeFinishedListingByTaxiTableHelper from "./route-finished-listing-by-taxi-table.helper";
import RouteFinishedListingByTaxiListingItem from "../common/types/route-finished-listing-by-taxi-item";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import RouteFinishedListingByTaxiTableColumn from "../common/types/route-finished-listing-by-taxi-table-column";
import RouteFinishedListingByTaxiTableRow from "../common/types/route-finished-listing-by-taxi-table-row";

type RouteFinishedListingByTaxiTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: RouteFinishedListingByTaxiListingItem[];
};

const RouteFinishedListingByTaxiTableComponent: FC<
  RouteFinishedListingByTaxiTableProps
> = (props) => {
  const columns: RouteFinishedListingByTaxiTableColumn[] =
    routeFinishedListingByTaxiTableHelper.getColumns();

  const rows: RouteFinishedListingByTaxiTableRow[] = useMemo(() => {
    return routeFinishedListingByTaxiTableHelper.getRows(props.listingItems);
  }, [props.listingItems]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default RouteFinishedListingByTaxiTableComponent;
