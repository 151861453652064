import { SearchAddressResponseItem } from "../../../../../common/utils/search-address/search-address.response";
import RoutesWaypointAddressAddFormSearchOption from "./types/routes-waypoint-address-add-form-search-option";
import RoutesWaypointAddressAddFormSearchSelectOption from "./types/routes-waypoint-address-add-form-search-select-option";

const createOptionSubText = (item: SearchAddressResponseItem): string => {
  const cityName =
    item.address.city ?? item.address.town ?? item.address.village;

  return cityName && item.address.road
    ? `${item.address.city}, ${item.address.road}`
    : item.display_name ?? "";
};

const createSearchAddressOption = (
  item: SearchAddressResponseItem
): RoutesWaypointAddressAddFormSearchOption => {
  return {
    latitude: Number(item.lat),
    longitude: Number(item.lon),
  };
};

const createSearchAddressSelectOption = (
  item: SearchAddressResponseItem
): RoutesWaypointAddressAddFormSearchSelectOption => {
  return {
    label: item.display_name,
    value: createSearchAddressOption(item),
    subText: createOptionSubText(item),
  };
};

const createAddressSelectOptions = (
  items: SearchAddressResponseItem[]
): RoutesWaypointAddressAddFormSearchSelectOption[] => {
  return items.map(createSearchAddressSelectOption);
};

const routesWaypointAddressAddFormFactory = {
  createAddressSelectOptions,
};

export default routesWaypointAddressAddFormFactory;
