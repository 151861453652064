import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import CargoOrderSettlementListingByCargoRouteQueryParams from "./common/types/cargo-order-settlement-listing-by-cargo-route-query-params";
import CargoOrderSettlementListingItem from "../common/types/cargo-order-settlement-listing-item";
import CargoOrderSettlementListingFilter, {
  CargoOrderSettlementListingOrderStartDateFilter,
} from "../common/types/cargo-order-settlement-listing-filter";
import CargoOrderSettlementListingSortKey from "../common/types/cargo-order-settlement-listing-sort-key";
import CargoOrderSettlementListingSortSelectOption from "../common/types/cargo-order-settlement-listing-sort-select-option";
import cargoOrderSettlementListingSortHelper from "../common/cargo-order-settlement-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import cargoOrderSettlementListingByCargoRouteQueryParamsService from "./common/cargo-order-settlement-listing-by-cargo-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import CargoSettledOrderListingStatusLegendComponent from "../common/status-legend/cargo-order-settlement-listing-status-legend.component";
import CargoOrderSettlementListingFiltersSelectComponent from "../common/filters/select/cargo-order-settlement-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import CargoOrderSettlementListingFiltersBadgeListComponent from "../common/filters/list/cargo-order-settlement-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import CargoOrderSettlementListingTableComponent from "../common/table/cargo-order-settlement-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import CargoOrderSettlementListingViewBasicProps from "../common/types/cargo-order-settlement-listing-view-basic-props";
import cargoOrderSettlementListingByCargoFactory from "./common/cargo-order-settlement-listing-by-cargo.factory";
import { useAppContext } from "../../../../../context/app.context";
import cargoOrderSettlementListingByCargoApiService from "./common/api/cargo-order-settlement-listing-by-cargo-api.service";
import CargoOrderSettlementListingFilterType from "../common/types/cargo-order-settlement-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import CargoOrderSettlementListingResponse, {
  CargoOrderSettlementListingResponseData,
} from "../common/api/cargo-order-settlement-listing.response";
import cargoOrderSettlementListingRequestFactory from "../common/cargo-order-settlement-listing-request.factory";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import ButtonComponent from "../../../../../common/components/button/button.component";
import notificationService from "../../../../../common/utils/notification/notification.service";
import CargoOrderSettlementListingReportResponse, {
  CargoOrderSettlementListingReportResponseData,
} from "../common/api/cargo-order-settlement-listing-report.response";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import cargoOrderSettlementListingReportRequestFactory from "../common/cargo-order-settlement-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";

type CargoOrderSettlementListingByCargoProps =
  CargoOrderSettlementListingViewBasicProps;

const CargoOrderSettlementListingByCargoComponent: FC<
  CargoOrderSettlementListingByCargoProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { user, selectedAppLanguage } = useAppContext();

  const cargoCompanyUuid: string =
    user?.aspects.cargoOfficer?.cargoCompanyUuid ??
    user?.aspects.dispatcher?.cargoCompanyUuid!;

  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<CargoOrderSettlementListingByCargoRouteQueryParams>();

  const [areListingItemsFetching, setAreListingItemsFetching] = useState(false);

  const [isListingItemsFetchingError, setIsListingItemsFetchingError] =
    useState(false);

  const [listingItems, setListingItems] = useState<
    CargoOrderSettlementListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [filters, setFilters] = useState<CargoOrderSettlementListingFilter[]>(
    () =>
      cargoOrderSettlementListingByCargoRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<CargoOrderSettlementListingSortKey | null>(
      () =>
        cargoOrderSettlementListingByCargoRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: CargoOrderSettlementListingSortSelectOption[] =
    useMemo(
      () => cargoOrderSettlementListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      cargoOrderSettlementListingByCargoRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      cargoOrderSettlementListingByCargoRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    responseData: CargoOrderSettlementListingResponseData
  ) => {
    const listingItems =
      cargoOrderSettlementListingByCargoFactory.createListingItems(
        responseData.data
      );

    setListingItems(listingItems);

    setTotalResults(responseData.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingItemsFetchingError(true);
  };

  const handleListingResponse = (
    response: CargoOrderSettlementListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = (signal: AbortSignal) => {
    setAreListingItemsFetching(true);
    setIsListingItemsFetchingError(false);

    const request = cargoOrderSettlementListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    cargoOrderSettlementListingByCargoApiService
      .fetchListing(cargoCompanyUuid, request, signal)
      .then((data) => {
        if (!signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setAreListingItemsFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchListing(signal);
    return () => {
      abortController.abort();
    };
  }, [page, pageSize, filters, selectedSortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      cargoOrderSettlementListingByCargoRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: CargoOrderSettlementListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
  )?.value as
    | CargoOrderSettlementListingOrderStartDateFilter["value"]
    | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== CargoOrderSettlementListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === CargoOrderSettlementListingFilterType.ORDER_START_DATE
    );

    const newFilter: CargoOrderSettlementListingOrderStartDateFilter = {
      type: CargoOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            CargoOrderSettlementListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: CargoOrderSettlementListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: CargoOrderSettlementListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request =
      cargoOrderSettlementListingReportRequestFactory.createRequest(
        filters,
        selectedSortKey
      );

    cargoOrderSettlementListingByCargoApiService
      .fetchReport(cargoCompanyUuid, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="cargo-order-settlement-listing-by-cargo-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!cargoCompanyUuid;

  return (
    <div className="cargo_order_settlements_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <CargoSettledOrderListingStatusLegendComponent
        cargoCompanyUuid={cargoCompanyUuid}
      />
      <div className="cargo_order_settlements_listing_tools">
        <div className="d-flex">
          <CargoOrderSettlementListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="cargo-order-settlement-listing-by-cargo-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="cargo-order-settlement-listing-by-cargo-sort"
        />
      </div>
      <CargoOrderSettlementListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <CargoOrderSettlementListingTableComponent
          listingItems={listingItems}
          isError={isListingItemsFetchingError}
          isLoading={areListingItemsFetching}
        />
        <div className="cargo_order_settlements_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default CargoOrderSettlementListingByCargoComponent;
