import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";

const getPenaltyTypeTranslations = (): Map<PenaltyType, string> => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiViewBillingsTranslations();
  return new Map([
    [
      PenaltyType.OPTIMAZATION_REFUSED,
      translations.additionalOptions.penaltyType.optimizationRefused,
    ],
    [
      PenaltyType.BEING_LATE,
      translations.additionalOptions.penaltyType.beingLate,
    ],
    [
      PenaltyType.INCOMPATIBILE_CAR,
      translations.additionalOptions.penaltyType.incompatibleCar,
    ],
    [
      PenaltyType.HIGHWAY_NOT_ALLOWED,
      translations.additionalOptions.penaltyType.highwayNotAllowed,
    ],
    [
      PenaltyType.SUBOPTIMAL_RIDE,
      translations.additionalOptions.penaltyType.subOptimalRide,
    ],
    [
      PenaltyType.GUIDELINE_IGNORED,
      translations.additionalOptions.penaltyType.guidelineIgnored,
    ],
    [PenaltyType.OTHER, translations.additionalOptions.penaltyType.other],
  ]);
};

const getBonusTypeTranslations = (): Map<BonusType, string> => {
  const translations =
    billingsTranslationsHelper.getTaxiTaxiViewBillingsTranslations();
  return new Map([
    [
      BonusType.DISTANCE_RATE_CHANGED,
      translations.additionalOptions.bonusType.distanceRateChanged,
    ],
    [
      BonusType.HALTING_RATE_CHANGED,
      translations.additionalOptions.bonusType.haltingRateChanged,
    ],
    [
      BonusType.SHORT_ROUTE,
      translations.additionalOptions.bonusType.shortRoute,
    ],
    [BonusType.OTHER, translations.additionalOptions.bonusType.other],
  ]);
};

const additionalOptionsFormHelper = {
  getPenaltyTypeTranslations,
  getBonusTypeTranslations,
};

export default additionalOptionsFormHelper;
