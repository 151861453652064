import appConfig from "../../../../../app.config";
import authService from "../../../../auth/common/auth.service";
import RouteActiveListingResponse from "./route-active-listing.response";

const fetchListing = async (): Promise<RouteActiveListingResponse> => {
  const url = `${appConfig.baseApiUrl}/plan-entries/active`;

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const routeActiveListingApiService = {
  fetchListing,
};

export default routeActiveListingApiService;
