import paginationService from "../../../../../common/utils/pagination/pagination.service";
import TaxiRouteSettlementListingFilterType from "./types/taxi-route-settlement-listing-filter-type";
import TaxiRouteSettlementListingSortKey from "./types/taxi-route-settlement-listing-sort-key";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingBillingModelFilter,
  TaxiRouteSettlementListingDriverFilter,
  TaxiRouteSettlementListingFleetPartnerFilter,
  TaxiRouteSettlementListingRouteIdFilter,
  TaxiRouteSettlementListingRouteStartDateFilter,
} from "./types/taxi-route-settlement-listing-filter";
import TaxiRouteSettlementListingRequest, {
  TaxiRouteSettlementListingRequestOrder,
} from "./api/taxi-route-settlement-listing.request";

const createRequestOrder = (
  sortKey: TaxiRouteSettlementListingSortKey | null
): TaxiRouteSettlementListingRequest["order"] => {
  const options: {
    sortKey: TaxiRouteSettlementListingSortKey;
    requestOrder: TaxiRouteSettlementListingRequestOrder;
  }[] = [
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.HIGHWAY_CHARGE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.HIGHWAY_CHARGE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DRIVER_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DRIVER_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DRIVER_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DRIVER_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.BILLING_MODEL_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.BILLING_MODEL_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.BILLING_MODEL_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_RATE_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_RATE_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_AMOUNT_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.DISTANCE_AMOUNT_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.TOTAL_AMOUNT_ASC,
      sortKey: TaxiRouteSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      requestOrder: TaxiRouteSettlementListingRequestOrder.TOTAL_AMOUNT_DESC,
      sortKey: TaxiRouteSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiRouteSettlementListingFilter[],
  sortKey: TaxiRouteSettlementListingSortKey | null
): TaxiRouteSettlementListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    limit: pageSize,
    offset,
    order: createRequestOrder(sortKey),
    human_id: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ROUTE_ID
      )?.value as TaxiRouteSettlementListingRouteIdFilter["value"] | undefined
    )?.toString(),
    driver: filters.find(
      (filter) => filter.type === TaxiRouteSettlementListingFilterType.DRIVER
    )?.value as TaxiRouteSettlementListingDriverFilter["value"] | undefined,
    date_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    date_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiRouteSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiRouteSettlementListingRouteStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    billing_model: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.BILLING_MODEL
    )?.value as
      | TaxiRouteSettlementListingBillingModelFilter["value"]
      | undefined,
    fleet_partner: filters.find(
      (filter) =>
        filter.type === TaxiRouteSettlementListingFilterType.FLEET_PARTNER
    )?.value as
      | TaxiRouteSettlementListingFleetPartnerFilter["value"]
      | undefined,
  };
};

const taxiRouteSettlementListingRequestFactory = {
  createRequest,
};

export default taxiRouteSettlementListingRequestFactory;
