import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiUnsettledRouteListingByRailyRouteQueryParams from "./common/types/taxi-unsettled-route-listing-by-raily-route-query-params";
import TaxiUnsettledRouteListingItem from "../common/types/taxi-unsettled-route-listing-item";
import TaxiUnsettledRouteListingFilter, {
  TaxiUnsettledRouteListingRouteStartDateFilter,
} from "../common/types/taxi-unsettled-route-listing-filter";
import TaxiUnsettledRouteListingSortKey from "../common/types/taxi-unsettled-route-listing-sort-key";
import TaxiUnsettledRouteListingSortSelectOption from "../common/types/taxi-unsettled-route-listing-sort-select-option";
import taxiUnsettledRouteListingSortHelper from "../common/taxi-unsettled-route-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiUnsettledRouteListingByRailyRouteQueryParamsService from "./common/taxi-unsettled-route-listing-by-raily-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import TaxiUnsettledRouteListingFiltersSelectComponent from "../common/filters/select/taxi-unsettled-route-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiUnsettledRouteListingFiltersBadgeListComponent from "../common/filters/list/taxi-unsettled-route-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiUnsettledRouteListingTableComponent from "../common/table/taxi-unsettled-route-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiUnsettledRouteListingViewBasicProps from "../common/types/taxi-unsettled-route-listing-view-basic-props";
import taxiUnsettledRouteListingByRailyApiService from "./common/api/taxi-unsettled-route-listing-by-raily-api.service";
import taxiUnsettledRouteListingByRailyFactory from "./common/taxi-unsettled-route-listing-by-raily.factory";
import TaxiUnsettledRouteListingFilterType from "../common/types/taxi-unsettled-route-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption from "./common/types/taxi-unsettled-route-listing-by-raily-taxi-corporation-select-option";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import TaxiUnsettledRouteListingResponse from "../common/api/taxi-unsettled-route-listing.response";
import taxiUnsettledRouteListingRequestFactory from "../common/taxi-unsettled-route-listing-request.factory";
import TaxiUnsettledRouteListingByRailyTaxiCoporationsResponse from "./common/api/taxi-unsettled-route-listing-by-raily-taxi-corporations.response";
import { TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem } from "../../order-settlement-listing/by-raily/common/api/taxi-order-settlement-listing-by-raily-taxi-corporations.response";
import { useAppContext } from "../../../../../context/app.context";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";

type TaxiRouteSettlementListingByRailyProps =
  TaxiUnsettledRouteListingViewBasicProps;

const TaxiUnsettledRouteListingByRailyComponent: FC<
  TaxiRouteSettlementListingByRailyProps
> = () => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiUnsettledRouteListingByRailyRouteQueryParams>();

  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations();

  const [filters, setFilters] = useState<TaxiUnsettledRouteListingFilter[]>(
    () =>
      taxiUnsettledRouteListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiUnsettledRouteListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiUnsettledRouteListingSortKey | null>(
      () =>
        taxiUnsettledRouteListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        taxiUnsettledRouteListingByRailyRouteQueryParamsService.getTaxiCorporationUuid(
          routeQueryParams
        ) ?? null
    );

  const onTaxiCorporationChange = (
    selectedOption: TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isTaxiCorporationFetching, setIsTaxiCorporationFetching] =
    useState(false);
  const [isTaxiCorporationFetchingError, setIsTaxiCorporationFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<TaxiUnsettledRouteListingByRailyTaxiCorporationSelectOption[]>([]);

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const sortSelectOptions: TaxiUnsettledRouteListingSortSelectOption[] =
    useMemo(
      () => taxiUnsettledRouteListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiUnsettledRouteListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiUnsettledRouteListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      taxiUnsettledRouteListingByRailyFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationFetchingError(true);
  };

  const handleTaxiCorporationResponse = (
    response: TaxiUnsettledRouteListingByRailyTaxiCoporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationFetching(true);
    setIsTaxiCorporationFetchingError(false);

    taxiUnsettledRouteListingByRailyApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsTaxiCorporationFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: TaxiUnsettledRouteListingResponse
  ) => {
    const listingItems =
      taxiUnsettledRouteListingByRailyFactory.createListingItems(
        response.data.data
      );

    setListingItems(listingItems);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiUnsettledRouteListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = taxiUnsettledRouteListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    taxiUnsettledRouteListingByRailyApiService
      .fetchListing(selectedTaxiCorporationUuid!, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }

    fetchListing();
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, pageSize, page]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiUnsettledRouteListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedTaxiCorporationUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: TaxiUnsettledRouteListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiUnsettledRouteListingRouteStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiUnsettledRouteListingRouteStartDateFilter = {
      type: TaxiUnsettledRouteListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== TaxiUnsettledRouteListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const isListingContentVisible = !!selectedTaxiCorporationUuid;

  return (
    <div className="taxi_unsettled_route_listing">
      <HeadingComponent title={translations.header.headingText} />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "taxi_unsettled_route_listing_taxi_corporation_select",
          }}
          idForTesting={`taxi-corporation-select`}
          isLoading={isTaxiCorporationFetching}
          isDisabled={isTaxiCorporationFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="taxi_unsettled_route_listing_tools">
            <div className="d-flex">
              <TaxiUnsettledRouteListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="taxi-unsettled-route-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="taxi-unsettled-route-listing-by-raily-sort"
            />
          </div>
          <TaxiUnsettledRouteListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          <CardComponent classNames={{ root: "mt-4" }}>
            <TaxiUnsettledRouteListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="taxi_unsettled_route_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default TaxiUnsettledRouteListingByRailyComponent;
