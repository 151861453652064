enum RouteActiveListingByTaxiSortKey {
  DRIVER_NAME_ASC = "DRIVER_NAME_ASC",
  DRIVER_NAME_DESC = "DRIVER_NAME_DESC",
  ROUTE_ID_ASC = "ROUTE_ID_ASC",
  ROUTE_ID_DESC = "ROUTE_ID_DESC",
  START_DATE_ASC = "START_DATE_ASC",
  START_DATE_DESC = "START_DATE_DESC",
  END_DATE_ASC = "END_DATE_ASC",
  END_DATE_DESC = "END_DATE_DESC",
}

export default RouteActiveListingByTaxiSortKey;
