import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import BonusType from "../types/bonus-type";
import PenaltyType from "../types/penalty-type";

const getPenaltyTypeOptions = (): SelectOption<PenaltyType>[] => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverAddBillingsTranslations();

  return [
    {
      label: translations.additionalOptions.penaltyType.optimizationRefused,
      value: PenaltyType.OPTIMAZATION_REFUSED,
    },
    {
      label: translations.additionalOptions.penaltyType.beingLate,
      value: PenaltyType.BEING_LATE,
    },
    {
      label: translations.additionalOptions.penaltyType.incompatibleCar,
      value: PenaltyType.INCOMPATIBILE_CAR,
    },
    {
      label: translations.additionalOptions.penaltyType.highwayNotAllowed,
      value: PenaltyType.HIGHWAY_NOT_ALLOWED,
    },
    {
      label: translations.additionalOptions.penaltyType.subOptimalRide,
      value: PenaltyType.SUBOPTIMAL_RIDE,
    },
    {
      label: translations.additionalOptions.penaltyType.guidelineIgnored,
      value: PenaltyType.GUIDELINE_IGNORED,
    },
    {
      label: translations.additionalOptions.penaltyType.other,
      value: PenaltyType.OTHER,
    },
  ];
};

const getBonusTypeOptions = (): SelectOption<BonusType>[] => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverAddBillingsTranslations();

  return [
    {
      label: translations.additionalOptions.bonusType.distanceRateChanged,
      value: BonusType.DISTANCE_RATE_CHANGED,
    },
    {
      label: translations.additionalOptions.bonusType.haltingRateChanged,
      value: BonusType.HALTING_RATE_CHANGED,
    },
    {
      label: translations.additionalOptions.bonusType.shortRoute,
      value: BonusType.SHORT_ROUTE,
    },
    {
      label: translations.additionalOptions.bonusType.other,
      value: BonusType.OTHER,
    },
  ];
};

const additionalOptionsFormHelper = {
  getPenaltyTypeOptions,
  getBonusTypeOptions,
};

export default additionalOptionsFormHelper;
