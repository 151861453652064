import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteFinishedListingByRailySortKey from "../types/route-finished-listing-by-raily-sort-key";
import RouteFinishedListingByRailySortSelectOption from "../types/route-finished-listing-by-raily-sort-select-option";

const getSelectOptions = (): RouteFinishedListingByRailySortSelectOption[] => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().sort;

  return [
    {
      label: translations.routeIdAscOptionLabel,
      value: RouteFinishedListingByRailySortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.routeIdDescOptionLabel,
      value: RouteFinishedListingByRailySortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.startDateAscOptionLabel,
      value: RouteFinishedListingByRailySortKey.START_DATE_ASC,
    },
    {
      label: translations.startDateDescOptionLabel,
      value: RouteFinishedListingByRailySortKey.START_DATE_DESC,
    },
  ];
};

const routeFinishedListingByRailySortHelper = {
  getSelectOptions,
};

export default routeFinishedListingByRailySortHelper;
