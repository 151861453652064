import BillingsOrderForwardCargoTaxiResponse, {
  BillingsOrderForwardCargoTaxiResponseItem,
} from "../../../../api/raily/billings/order-forward/cargo-taxi/billings-order-forward-cargo-taxi.response";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsOrderForwardCargoTaxi, {
  BillingsOrderForwardCargoTaxiItem,
} from "./billings-order-forward-cargo-taxi";

const createDataItem = (
  data: BillingsOrderForwardCargoTaxiResponseItem
): BillingsOrderForwardCargoTaxiItem => {
  return {
    billingUuid: data.id,
    cargoOrderUuid: data.cargoOrder.id,
    contractDetails: {
      companyName: data.contract.cargoCompany.displayName,
      distanceRate: data.contract.distanceRate,
      model: data.contract.model as BillingModel,
    },
    baseAmount: data.baseAmount,
    routeDistanceCost: data.discountAmount,
    haltingCost: data.haltingAmount,
    highwayCharge: data.highwayCharge,
    distance: data.distance,
    internalOrderId: data.cargoOrder.humanId,
    discount: data.discount,
    total: data.totalAmount,
    discountAmount: data.discountAmount,
    isDraft: data.draft,
    date: data.date,
    status: data.status as BillingStatus,
  };
};

const createData = (
  responseData: BillingsOrderForwardCargoTaxiResponseItem[]
): BillingsOrderForwardCargoTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderForwardCargoTaxiResponse
): BillingsOrderForwardCargoTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderForwardCargoTaxiFactory = {
  create,
};

export default billingsOrderForwardCargoTaxiFactory;
