import { FC } from "react";
import CargoOrderDetails from "../../../../../common/services/cargo-order/details/cargo-order-details";
import planningTranslationsHelper from "../../../../../languages/planning-translations.helper";
import planningOrderOrderDetailsRouteHelper from "./planning-order-order-details-route.helper";
import planningOrderOrderDetailsRouteItemFactory from "./planning-order-order-route-item.factory";

type PlanningOrderOrderDetailsRouteProps = {
  orderDetails: CargoOrderDetails;
};

const PlanningOrderOrderDetailsRouteComponent: FC<
  PlanningOrderOrderDetailsRouteProps
> = (props) => {
  const translations =
    planningTranslationsHelper.getOrderTranslations().details.routes;

  const Headers = (
    <>
      <span>{translations.placeHeaderLabel}</span>
      <span title={translations.orderedDateHeaderTitle}>
        {translations.orderedDateHeaderLabel}
      </span>
      <span title={translations.estimatedDateHeaderTitle}>
        {translations.estimatedDateHeaderLabel}
      </span>
      <span title={translations.haltingDateHeaderTitle}>
        {translations.haltingDateHeaderLabel}
      </span>
      <span title={translations.distanceHeaderTitle}>
        {translations.distanceHeaderLabel}
      </span>
    </>
  );

  const listingItems = planningOrderOrderDetailsRouteItemFactory.create(
    props.orderDetails.transportingOrders
  );

  return (
    <ul className="planning_order_order_details_route">
      <li className="planning_order_order_details_route__headers">{Headers}</li>
      {listingItems.map((item, index) => (
        <li
          className="planning_order_order_details_route__waypoint"
          key={index}
        >
          <div
            className="planning_order_order_details_route__waypoint-name"
            title={item.address}
          >
            <span className="planning_order_order_details_route__waypoint-number">
              {`${item.stageNo}.`}
            </span>
            <span>{item.address}</span>
          </div>
          <span
            title={planningOrderOrderDetailsRouteHelper.getDateTitle(
              item.orderedDate
            )}
          >
            {planningOrderOrderDetailsRouteHelper.getDateLabel(
              item.orderedDate
            )}
          </span>
          <span
            title={planningOrderOrderDetailsRouteHelper.getDateTitle(
              item.estimatedDate
            )}
          >
            {planningOrderOrderDetailsRouteHelper.getDateLabel(
              item.estimatedDate
            )}
          </span>
          <span>
            {planningOrderOrderDetailsRouteHelper.getHaltingTimeLabel(
              item.orderedStopoverTime
            )}
          </span>
          <span>
            {planningOrderOrderDetailsRouteHelper.getDistanceLabel(
              item.distance
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PlanningOrderOrderDetailsRouteComponent;
