import Joi from "joi";
import RouteFinishedListingByRailyFilter, {
  RouteFinishedListingByRailyDriverFilter,
  RouteFinishedListingByRailyEndDateFilter,
  RouteFinishedListingByRailyPassengersFilter,
  RouteFinishedListingByRailyRouteIntermediateAddressFilter,
  RouteFinishedListingByRailyRouteIdFilter,
  RouteFinishedListingByRailyStartDateFilter,
  RouteFinishedListingByRailyTaxiCorporationFilter,
  RouteFinishedListingByRailyRoutePickupAddressFilter,
  RouteFinishedListingByRailyRouteDestinationAddressFilter,
} from "./types/route-finished-listing-by-raily-filter";
import RouteFinishedListingByRailyFilterType from "./types/route-finished-listing-by-raily-filter-type";
import RouteFinishedListingByRailyRouteQueryFilterParams from "./types/route-finished-listing-by-raily-route-query-filter-params";
import RouteFinishedListingByRailyRouteQueryParams from "./types/route-finished-listing-by-raily-route-query-params";
import RouteFinishedListingByRailySortKey from "./types/route-finished-listing-by-raily-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteFinishedListingByRailyRouteQueryFilterParams
): RouteFinishedListingByRailyFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: RouteFinishedListingByRailyFilter[] = [];

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteFinishedListingByRailyDriverFilter = {
      type: RouteFinishedListingByRailyFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.endDateFrom && routeQueryFilterParams.endDateTo) {
    const endDateFilter: RouteFinishedListingByRailyEndDateFilter = {
      type: RouteFinishedListingByRailyFilterType.END_DATE,
      value: {
        from: new Date(routeQueryFilterParams.endDateFrom),
        to: new Date(routeQueryFilterParams.endDateTo),
      },
    };

    filters.push(endDateFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: RouteFinishedListingByRailyStartDateFilter = {
      type: RouteFinishedListingByRailyFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteFinishedListingByRailyPassengersFilter = {
      type: RouteFinishedListingByRailyFilterType.PASSENGERS,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: RouteFinishedListingByRailyTaxiCorporationFilter =
      {
        type: RouteFinishedListingByRailyFilterType.TAXI_CORPORATION,
        value: routeQueryFilterParams.taxiCorporation,
      };

    filters.push(taxiCorporationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: RouteFinishedListingByRailyRouteIntermediateAddressFilter =
      {
        type: RouteFinishedListingByRailyFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: RouteFinishedListingByRailyRouteDestinationAddressFilter =
      {
        type: RouteFinishedListingByRailyFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: RouteFinishedListingByRailyRoutePickupAddressFilter =
      {
        type: RouteFinishedListingByRailyFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: RouteFinishedListingByRailyRouteIdFilter = {
      type: RouteFinishedListingByRailyFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };

    filters.push(routeIdFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteFinishedListingByRailyRouteQueryParams
): RouteFinishedListingByRailySortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: RouteFinishedListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: RouteFinishedListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: RouteFinishedListingByRailyFilter[],
  sortKey: RouteFinishedListingByRailySortKey | null,
  page: number,
  pageSize: number
): RouteFinishedListingByRailyRouteQueryParams => {
  const routeQueryParams: RouteFinishedListingByRailyRouteQueryParams = {
    driver: filters.find(
      (filter) => filter.type === RouteFinishedListingByRailyFilterType.DRIVER
    )?.value as RouteFinishedListingByRailyDriverFilter["value"] | undefined,
    endDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.END_DATE
      )?.value as RouteFinishedListingByRailyEndDateFilter["value"] | undefined
    )?.from.toJSON(),
    endDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.END_DATE
      )?.value as RouteFinishedListingByRailyEndDateFilter["value"] | undefined
    )?.to.toJSON(),
    passenger: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByRailyFilterType.PASSENGERS
    )?.value as
      | RouteFinishedListingByRailyPassengersFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRoutePickupAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRouteDestinationAddressFilter["value"]
      | undefined,
    routeId: filters.find(
      (filter) => filter.type === RouteFinishedListingByRailyFilterType.ROUTE_ID
    )?.value as RouteFinishedListingByRailyRouteIdFilter["value"] | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.START_DATE
      )?.value as
        | RouteFinishedListingByRailyStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.START_DATE
      )?.value as
        | RouteFinishedListingByRailyStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByRailyFilterType.TAXI_CORPORATION
    )?.value as
      | RouteFinishedListingByRailyTaxiCorporationFilter["value"]
      | undefined,

    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: RouteFinishedListingByRailyRouteQueryFilterParams
) => {
  const filterParams: RouteFinishedListingByRailyRouteQueryFilterParams = {
    driver: routeQueryFilterParams.driver,
    endDateFrom: routeQueryFilterParams.endDateFrom,
    endDateTo: routeQueryFilterParams.endDateTo,
    passenger: routeQueryFilterParams.passenger,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    routeId: routeQueryFilterParams.routeId,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    taxiCorporation: routeQueryFilterParams.taxiCorporation,
  };

  const validationSchema =
    Joi.object<RouteFinishedListingByRailyRouteQueryFilterParams>({
      driver: Joi.string(),
      endDateFrom: Joi.string(),
      endDateTo: Joi.string(),
      passenger: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      routeId: Joi.number(),
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
      taxiCorporation: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: RouteFinishedListingByRailyRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(RouteFinishedListingByRailySortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: RouteFinishedListingByRailyRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: RouteFinishedListingByRailyRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const routeFinishedListingByRailyRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeFinishedListingByRailyRouteQueryParamsService;
