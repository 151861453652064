import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import CargoOrderSettlementListingItem from "../types/cargo-order-settlement-listing-item";
import CargoOrderSettlementListingTableColumn from "../types/cargo-order-settlement-listing-table-column";
import CargoOrderSettlementListingTableRow from "../types/cargo-order-settlement-listing-table-row";
import dateService from "../../../../../../common/utils/date/date.service";
import CargoOrderSettlementListingTableStatusComponent from "./status/cargo-order-settlement-listing-table-status.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";
import { faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import CargoOrderSettlementListingItemBillingType from "../types/cargo-order-settlement-listing-item-billing-type";
import { CargoOrderSettlementListingUserPermissions } from "../user-permissions/cargo-order-settlement-listing-user-permission";

const getColumns = (): CargoOrderSettlementListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .table.headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdTitle,
      title: translations.internalOrderIdTitle,
      colSpan: 10,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 10,
    },
    {
      accessor: "route",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 30,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 10,
    },
    {
      accessor: "taxi",
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      colSpan: 10,
    },
    {
      accessor: "dispatch",
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      colSpan: 10,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceRate",
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceCost",
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      colSpan: 10,
    },
    {
      accessor: "stopoverTime",
      header: translations.stopoverTimeLabel,
      title: translations.stopoverTimeTitle,
      colSpan: 8,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 9,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "discountAmount",
      header: translations.discountAmountLabel,
      title: translations.discountAmountTitle,
      colSpan: 6,
    },
    {
      accessor: "discountSavings",
      header: translations.discountSavingsLabel,
      title: translations.discountSavingsTitle,
      colSpan: 9,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 9,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const checkIsAddressGroup = (
  address: string | string[]
): address is string[] => {
  return Array.isArray(address);
};

const getRouteLabel = (
  addresses: CargoOrderSettlementListingItem["routeAddresses"]
): string => {
  const addressesWithFlattenedGroups = addresses.map((address) => {
    const isAddressGroup = checkIsAddressGroup(address);

    if (!isAddressGroup) {
      return address;
    }

    const connectedAddresses = address.join(" | ");
    return `[ ${connectedAddresses} ]`;
  });

  return addressesWithFlattenedGroups.join(" > ");
};

const getRouteTitle = (
  addresses: CargoOrderSettlementListingItem["routeAddresses"]
): string => {
  return addresses.join(`\r`);
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceRateLabel = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDistanceRateTitle = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getStopoverTimeLabel = (stopoverTime: number): string => {
  return `${String(stopoverTime.toFixed(2))} h`;
};

const getStopoverTimeTitle = (stopoverTime: number): string => {
  return `${String(stopoverTime.toFixed(2))} h`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getDiscountAmountLabel = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getDiscountAmountTitle = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getDiscountCostLabel = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getDiscountCostTitle = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getDistanceCostLabel = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getDistanceCostTitle = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getRow = (
  listingItem: CargoOrderSettlementListingItem,
  userPermissions: CargoOrderSettlementListingUserPermissions
): CargoOrderSettlementListingTableRow => {
  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations()
      .table;

  const routeAddressesLabel = getRouteLabel(listingItem.routeAddresses);
  const routeAddressesTitle = getRouteTitle(listingItem.routeAddresses);

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const distanceRateLabel = getDistanceRateLabel(listingItem.distanceRate);
  const distanceRateTitle = getDistanceRateTitle(listingItem.distanceRate);

  const stoppingTimeLabel = getStopoverTimeLabel(listingItem.stoppingTime);
  const stoppingTimeTitle = getStopoverTimeTitle(listingItem.stoppingTime);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const discountAmountLabel = getDiscountAmountLabel(
    listingItem.discountAmountPercent
  );
  const discountAmountTitle = getDiscountAmountTitle(
    listingItem.discountAmountPercent
  );

  const discountCostLabel = getDiscountCostLabel(listingItem.discountSavings);
  const discountCostTitle = getDiscountCostTitle(listingItem.discountSavings);

  const distanceCostLabel = getDistanceCostLabel(listingItem.distanceCost);
  const distanceCostTitle = getDistanceCostTitle(listingItem.distanceCost);

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const isCargoWithTaxiSettlementEditButtonVisible =
    userPermissions.hasAccessToEditCargoWithTaxiSettlement &&
    listingItem.billingType ===
      CargoOrderSettlementListingItemBillingType.PRIV_CARGO_2_TAXI;

  const isCargoWithTaxiSettlementDetailsButtonVisible =
    userPermissions.hasAccessToDetailsCargoWithTaxiSettlement &&
    listingItem.billingType ===
      CargoOrderSettlementListingItemBillingType.PRIV_CARGO_2_TAXI;

  return {
    id: listingItem.uuid,
    value: {
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      internalOrderId: (
        <div title={listingItem.internalOrderId}>
          {listingItem.internalOrderId}
        </div>
      ),
      route: <div title={routeAddressesTitle}>{routeAddressesLabel}</div>,
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div key={index} title={passenger}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      taxi: (
        <div title={listingItem.taxiCorporationName ?? `Raily`}>
          {listingItem.taxiCorporationName ?? `Raily`}
        </div>
      ),
      externalOrderId: (
        <div title={listingItem.externalOrderId}>
          {listingItem.externalOrderId}
        </div>
      ),
      dispatch: (
        <div title={listingItem.dispatchName}>{listingItem.dispatchName}</div>
      ),
      billingStatus: (
        <CargoOrderSettlementListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
      distanceCost: <div title={distanceCostTitle}>{distanceCostLabel}</div>,
      stopoverTime: <div title={stoppingTimeTitle}>{stoppingTimeLabel}</div>,
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      discountAmount: (
        <div title={discountAmountTitle}>{discountAmountLabel}</div>
      ),
      discountSavings: <div title={discountCostTitle}>{discountCostLabel}</div>,
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiSettlementEditButtonVisible && (
            <TableLinkButtonComponent
              icon={faEdit}
              to={billingRoutesHelper.getCargoWithTaxiEditRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiSettlementEditButtonTitle}
              idForTesting={`cargo-order-settlement-listing-table-item-${listingItem.uuid}-edit-button`}
            />
          )}
          {isCargoWithTaxiSettlementDetailsButtonVisible && (
            <TableLinkButtonComponent
              icon={faCircleInfo}
              to={billingRoutesHelper.getCargoWithTaxiDetailsRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiSettlementDetailsButtonTitle}
              idForTesting={`cargo-order-settlement-listing-table-item-${listingItem.uuid}-details-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: CargoOrderSettlementListingItem[],
  userPermissions: CargoOrderSettlementListingUserPermissions
): CargoOrderSettlementListingTableRow[] => {
  return listingItems.map((listingItem) =>
    getRow(listingItem, userPermissions)
  );
};

const cargoOrderSettlementListingTableHelper = {
  getColumns,
  getRows,
};

export default cargoOrderSettlementListingTableHelper;
