import HttpResponse from "../../../../utils/http/http.response";

type DriverPlanCandidatureAddByOrderIdSuccessResponse = HttpResponse<string>;

export enum DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType {
  DRIVER_HAS_CANDIDATURE = "DRIVER_HAS_CANDIDATURE",
  NO_TAXI_DRIVER_ASSOCIATION = "NO_TAXI_DRIVER_ASSOCIATION",
  NO_VALID_TAXI_DRIVER_CONTRACT = "NO_VALID_TAXI_DRIVER_CONTRACT",
  NO_VALID_TAXI_RAILY_CONTRACT = "NO_VALID_TAXI_RAILY_CONTRACT",
  NO_VALID_CARGO_RAILY_CONTRACT = "NO_VALID_CARGO_RAILY_CONTRACT",
  TARGET_PLAN_ENTRY_NOT_FOUND = "TARGET_PLAN_ENTRY_NOT_FOUND",
  CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME = "CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME",
  CANDIDATE_DOES_NOT_FIT = "CANDIDATE_DOES_NOT_FIT",
  TOO_MANY_CHECKOUT_EVENTS = "TOO_MANY_CHECKOUT_EVENTS",
  CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION = "CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION",
  DELETE_SOLVED_ORDER_TARGET_NOT_FOUND = "DELETE_SOLVED_ORDER_TARGET_NOT_FOUND",
  PLAN_ENTRY_CANDIDATE_COLLISION = "PLAN_ENTRY_CANDIDATE_COLLISION",
  FORBIDDEN_TAXI = "FORBIDDEN_TAXI",
  FORBIDDEN_PLAN_ENTRY = "FORBIDDEN_PLAN_ENTRY",
  PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR = "PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR",
}

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseDriverHasCandidatureError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.DRIVER_HAS_CANDIDATURE;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoTaxiDriverAssociationError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_TAXI_DRIVER_ASSOCIATION;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidTaxiDriverContractError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_VALID_TAXI_DRIVER_CONTRACT;
    driverId: string;
    taxiId: string;
  };

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidTaxiRailyContractError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_VALID_TAXI_RAILY_CONTRACT;
  };

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidCargoRailyContractError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.NO_VALID_CARGO_RAILY_CONTRACT;
    driverId: string;
    taxiId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseTargetPlanEntryNotFoundError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.TARGET_PLAN_ENTRY_NOT_FOUND;
    candidateEntryId: string;
    targetPlanEntryId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateFitsWithNotEnoughTimeError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_NOT_ENOUGH_TIME;
    candidatureId: string;
    candidatureEntryId: string;
    collidingSolvedOrderId: string;
    candidatureSolvedOrderId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseTooManyCheckoutEventsError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.TOO_MANY_CHECKOUT_EVENTS;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateDoesNotFitError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.CANDIDATE_DOES_NOT_FIT;
    candidatureId: string;
    candidatureEntryId: string;
    candidatureSolvedOrderId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidatePlanEntryCollidesError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.CANDIDATE_FITS_WITH_PLAN_ENTRY_COLLISION;
    candidatureId: string;
    candidatureEntryId: string;
    candidaturePlanEntryId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.DELETE_SOLVED_ORDER_TARGET_NOT_FOUND;
    candidatureId: string;
    candidateEntryId: string;
    targetSolvedOrderId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponsePlanEntryCandidateCollisionError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_COLLISION;
    candidatureId: string;
    candidateEntryId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseForbiddenTaxiError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.FORBIDDEN_TAXI;
    providedTaxiId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseForbiddenPlanEntryError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.FORBIDDEN_PLAN_ENTRY;
    providedPlanEntryId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponsePlanEntryCandidateStartTimeExpiredError =
  {
    errorType: DriverPlanCandidatureAddByOrderIdNotAcceptableResponseErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR;
    candidatureId: string;
    candidatureEntryId: string;
  };

type DriverPlanCandidatureAddByOrderIdNotAcceptableResponseError =
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseDriverHasCandidatureError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoTaxiDriverAssociationError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidTaxiDriverContractError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidTaxiRailyContractError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseNoValidCargoRailyContractError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseTargetPlanEntryNotFoundError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateFitsWithNotEnoughTimeError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidateDoesNotFitError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseCandidatePlanEntryCollidesError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseDeleteSolvedOrderTargetNotFoundError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponsePlanEntryCandidateCollisionError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseForbiddenTaxiError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseForbiddenPlanEntryError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponseTooManyCheckoutEventsError
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponsePlanEntryCandidateStartTimeExpiredError;

export type DriverPlanCandidatureAddByOrderIdNotAcceptableResponse =
  HttpResponse<DriverPlanCandidatureAddByOrderIdNotAcceptableResponseError>;

type DriverPlanCandidatureAddByOrderIdResponse =
  | DriverPlanCandidatureAddByOrderIdSuccessResponse
  | DriverPlanCandidatureAddByOrderIdNotAcceptableResponse;

export default DriverPlanCandidatureAddByOrderIdResponse;
