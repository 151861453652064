import userTranslationsHelper from "../../../../../languages/user-translations.helper";

const getCandidatureActivityText = (isCandidatureActive: boolean): string => {
  const translations = userTranslationsHelper.getDriverListingTranslations();

  return isCandidatureActive
    ? translations.activeCandidatureLabel
    : translations.inactiveCandidatureLabel;
};

const driverListingHelper = {
  getCandidatureActivityText,
};

export default driverListingHelper;
