import { FC, useEffect, useMemo, useState } from "react";
import useRouteQueryParams from "../../../common/hooks/use-route-query-params";
import HeadingComponent from "../../../common/components/heading/heading.component";
import Row from "../../../common/components/grid/row";
import Column from "../../../common/components/grid/column";
import { OrderJoinRouteParams } from "../common/routes/types/order-join-route-params";
import orderJoinApiService from "./common/api/order-join-api.service";
import OrderJoinOrderPreviewResponse, {
  OrderJoinOrderDetailsResponseData,
} from "./common/api/order-join-order-details.response";
import ButtonComponent from "../../../common/components/button/button.component";
import OrderJoinOrderDetails from "./common/types/order-join-order-details";
import orderJoinOrderDetailsFactory from "./common/factory/order-join-order-details.factory";
import orderJoinHelper from "./order-join.helper";
import OrderJoinOrderDetailsSolvedRidesRequest from "./common/api/order-join-order-details-solved-rides.request";
import SearchRoadRoutingRequest from "../../../common/utils/search-road-route/search-road-routing.request";
import OrderJoinOrderDetailsRouteResponse from "./common/api/order-join-order-details-route.response";
import orderJoinOrderDetailsMapRouteFactory from "./common/factory/order-join-order-details-map-route.factory";
import MapRoute from "../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse, {
  SearchRoadRoutingResponseRouteLeg,
} from "../../../common/utils/search-road-route/search-road-routing.response";
import { OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint } from "./common/api/order-join-order-details-solved-rides.response";
import MapMarker from "../../../common/components/map/types/map-marker";
import orderJoinOrderDetailsPlannedRouteMapMarkersFactory from "./common/factory/order-join-order-details-planned-route-map-markers.factory";
import { useAppContext } from "../../../context/app.context";
import orderBreadcrumbsHelper from "../common/breadcrumbs/order-breadcrumbs.helper";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import useDocumentTitle from "../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../languages/app-translations.helper";
import notificationService from "../../../common/utils/notification/notification.service";
import { useNavigate } from "react-router-dom";
import orderRoutesHelper from "../common/routes/order-routes.helper";
import OrderJoinRequest from "./common/api/order-join.request";
import OrderJoinResponse from "./common/api/order-join.response";
import orderJoinRouteParamsService, {
  OrderJoinOrderData,
} from "../common/routes/order-join-route-params.service";
import OrderJoinOrderDetailsComponent from "./common/details/order-join-order-details.component";
import OrderJoinPreviewRequest from "./common/api/order-join-preview.request";

type OrderJoinProps = {};

type FetchRouteResponse = {
  mapRoutes: MapRoute[];
  routeLegs: SearchRoadRoutingResponseRouteLeg[];
};

const OrderJoinComponent: FC<OrderJoinProps> = () => {
  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(documentTitleTranslations.orderJoin);

  const navigate = useNavigate();

  const [routeQueryParams] = useRouteQueryParams<OrderJoinRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();
  const [isTargetOrderDetailsFetching, setIsTargetOrderDetailsFetching] =
    useState(false);

  const [isMergeOrdersFetching, setIsMergeOrdersFetching] = useState(false);

  const [isMergedOrderDetailsFetching, setIsMergedOrderDetailsFetching] =
    useState(false);

  const [
    isTargetOrderDetailsFetchingError,
    setIsTargetOrderDetailsFetchingError,
  ] = useState(false);

  const [
    isMergedOrderDetailsFetchingError,
    setIsMergedOrderDetailsFetchingError,
  ] = useState(false);

  const [mergedOrderDetails, setMergedOrderDetails] =
    useState<OrderJoinOrderDetails | null>(null);

  const [sourceOrderDetails, setSourceOrderDetails] =
    useState<OrderJoinOrderDetails | null>(null);

  const [selectedSourceOrderUuid, setSelectedSourceOrderUuid] = useState("");

  const [sourceOrderDetailsResponse, setSourceOrderDetailsResponse] =
    useState<OrderJoinOrderDetailsResponseData | null>(null);

  const [targetOrderDetailsResponse, setTargetOrderDetailsResponse] =
    useState<OrderJoinOrderDetailsResponseData | null>(null);

  const [mergedOrderDetailsResponse, setMergedOrderDetailsResponse] =
    useState<OrderJoinOrderDetailsResponseData | null>(null);

  const [sourceOrderMapRoutes, setSourceOrderMapRoutes] = useState<MapRoute[]>(
    []
  );

  const [targetOrderMapRoutes, setTargetOrderMapRoutes] = useState<MapRoute[]>(
    []
  );

  const [mergedOrderMapRoutes, setMergedOrderMapRoutes] = useState<MapRoute[]>(
    []
  );

  const [targetOrderDetails, setTargetOrderDetails] =
    useState<OrderJoinOrderDetails | null>(null);

  const [sourceOrderSolvedRidesResponse, setSourceOrderSolvedRidesResponse] =
    useState<OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[]>([]);

  const [targetOrderSolvedRidesResponse, setTargetOrderSolvedRidesResponse] =
    useState<OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[]>([]);

  const [mergedOrderSolvedRidesResponse, setMergedOrderSolvedRidesResponse] =
    useState<OrderJoinOrderDetailsResponseCargoOrderSolvedRideWaypoint[]>([]);

  const [isSourceOrderDetailsFetching, setIsSourceOrdersDetailsFetching] =
    useState(false);

  const [
    isSourceOrderDetailsFetchingError,
    setIsSourceOrdersDetailsFetchingError,
  ] = useState(false);

  const [sourceOrdersData, setSourceOrdersOptions] = useState<
    OrderJoinOrderData[]
  >([]);

  useEffect(() => {
    const sourceOrdersData =
      orderJoinRouteParamsService.parse(routeQueryParams).sourceOrdersData;

    setSourceOrdersOptions(sourceOrdersData);

    setSelectedSourceOrderUuid(sourceOrdersData[0].uuid);
  }, [JSON.stringify(routeQueryParams.sourceOrders)]);

  useEffect(() => {
    if (!selectedSourceOrderUuid) {
      return;
    }

    fetchSourceOrderDetails();
  }, [selectedSourceOrderUuid]);

  const handleSourceOrderRouteResponse = (
    response: OrderJoinOrderDetailsRouteResponse
  ) => {
    const mapRoutes = orderJoinOrderDetailsMapRouteFactory.createMapRoutes(
      response.routes
    );

    setSourceOrderMapRoutes(mapRoutes);

    const routeLegs = response.routes.map((route) => route.legs).flat();

    const newSourceOrderDetails =
      orderJoinOrderDetailsFactory.createOrderDetails(
        sourceOrderDetailsResponse!,
        sourceOrderSolvedRidesResponse,
        routeLegs
      );

    setSourceOrderDetails(newSourceOrderDetails);
  };

  const handleMergedOrderRouteResponse = (
    response: OrderJoinOrderDetailsRouteResponse
  ) => {
    const mapRoutes = orderJoinOrderDetailsMapRouteFactory.createMapRoutes(
      response.routes
    );

    setMergedOrderMapRoutes(mapRoutes);

    const routeLegs = response.routes.map((route) => route.legs).flat();

    const newMergedOrderDetails =
      orderJoinOrderDetailsFactory.createOrderDetails(
        mergedOrderDetailsResponse!,
        mergedOrderSolvedRidesResponse,
        routeLegs
      );

    setMergedOrderDetails(newMergedOrderDetails);
  };

  const handleTargetOrderRouteResponse = (
    response: OrderJoinOrderDetailsRouteResponse
  ) => {
    const mapRoutes = orderJoinOrderDetailsMapRouteFactory.createMapRoutes(
      response.routes
    );

    setTargetOrderMapRoutes(mapRoutes);

    const routeLegs = response.routes.map((route) => route.legs).flat();

    const newTargetOrderDetails =
      orderJoinOrderDetailsFactory.createOrderDetails(
        targetOrderDetailsResponse!,
        targetOrderSolvedRidesResponse,
        routeLegs
      );

    setTargetOrderDetails(newTargetOrderDetails);
  };

  const onMergedOrderDetailsFetchSuccess = async (
    response: OrderJoinOrderPreviewResponse
  ) => {
    const isOrderSolved = orderJoinHelper.isOrderSolved(
      response.data.cargo_order.transporting_orders
    );

    if (isOrderSolved) {
      const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
        response.data,
        null,
        []
      );

      setMergedOrderDetails(orderDetails);
    } else {
      const request: OrderJoinOrderDetailsSolvedRidesRequest = {
        raily_ride: response.data.cargo_order.ride,
        node_exclusion_collection: { exclusions: [] },
      };

      orderJoinApiService
        .fetchSolvedRides(request)
        .then((solvedRidesResponse) => {
          const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
            response.data,
            solvedRidesResponse.data.seq,
            []
          );

          setMergedOrderSolvedRidesResponse(solvedRidesResponse.data.seq);
          setMergedOrderDetails(orderDetails);
        });
    }

    setMergedOrderDetailsResponse(response.data);
  };

  const onTargetOrderDetailsFetchSuccess = async (
    response: OrderJoinOrderPreviewResponse
  ) => {
    const isOrderSolved = orderJoinHelper.isOrderSolved(
      response.data.cargo_order.transporting_orders
    );

    if (isOrderSolved) {
      const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
        response.data,
        null,
        []
      );

      setTargetOrderDetails(orderDetails);
    } else {
      const request: OrderJoinOrderDetailsSolvedRidesRequest = {
        raily_ride: response.data.cargo_order.ride,
        node_exclusion_collection: { exclusions: [] },
      };

      orderJoinApiService
        .fetchSolvedRides(request)
        .then((solvedRidesResponse) => {
          const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
            response.data,
            solvedRidesResponse.data.seq,
            []
          );

          setTargetOrderSolvedRidesResponse(solvedRidesResponse.data.seq);
          setTargetOrderDetails(orderDetails);
        });
    }

    setTargetOrderDetailsResponse(response.data);
  };

  const onSourceOrdersDetailsFetchSuccess = (
    response: OrderJoinOrderPreviewResponse
  ) => {
    const isOrderSolved = orderJoinHelper.isOrderSolved(
      response.data.cargo_order.transporting_orders
    );

    if (isOrderSolved) {
      const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
        response.data,
        null,
        []
      );

      setSourceOrderDetails(orderDetails);
    } else {
      const request: OrderJoinOrderDetailsSolvedRidesRequest = {
        raily_ride: response.data.cargo_order.ride,
        node_exclusion_collection: { exclusions: [] },
      };

      orderJoinApiService
        .fetchSolvedRides(request)
        .then((solvedRidesResponse) => {
          const orderDetails = orderJoinOrderDetailsFactory.createOrderDetails(
            response.data,
            solvedRidesResponse.data.seq,
            []
          );

          setSourceOrderSolvedRidesResponse(solvedRidesResponse.data.seq);
          setSourceOrderDetails(orderDetails);
        });
    }

    setSourceOrderDetailsResponse(response.data);
  };

  const onSourceOrdersDetailsFetchFailure = () => {
    setIsSourceOrdersDetailsFetchingError(true);
  };

  const onTargetOrderDetailsFetchFailure = () => {
    setIsTargetOrderDetailsFetchingError(true);
  };

  const onMergedOrderDetailsFetchFailure = () => {
    setIsMergedOrderDetailsFetchingError(true);
  };

  const handleSourceOrdersDetailsResponse = (
    response: OrderJoinOrderPreviewResponse
  ) => {
    if (response.status === 200) {
      onSourceOrdersDetailsFetchSuccess(response);
      return;
    }

    onSourceOrdersDetailsFetchFailure();
  };

  const handleTargetOrderDetailsResponse = (
    response: OrderJoinOrderPreviewResponse
  ) => {
    if (response.status === 200) {
      onTargetOrderDetailsFetchSuccess(response);
      return;
    }

    onTargetOrderDetailsFetchFailure();
  };

  const handleMergedOrderDetailsResponse = (
    response: OrderJoinOrderPreviewResponse
  ) => {
    if (response.status === 200) {
      onMergedOrderDetailsFetchSuccess(response);
      return;
    }

    onMergedOrderDetailsFetchFailure();
  };

  const handleJoinOrdersResponse = (response: OrderJoinResponse) => {
    if (response.status === 201) {
      onOrdersMergeSuccess();
      return;
    }

    onOrdersMergeFailure();
  };

  const onOrdersMergeSuccess = () => {
    const targetOrderUuid = routeQueryParams.targetOrderUuid;
    notificationService.success(translations.successMergeNotificationText);
    navigate(
      orderRoutesHelper.getListingOfActiveOrdersRoute({
        defaultSelectedOrderUuid: targetOrderUuid,
      })
    );
  };

  const onOrdersMergeFailure = () => {
    notificationService.error(translations.failureMergeNotificationText);
  };

  const fetchSourceOrderDetails = () => {
    setIsSourceOrdersDetailsFetchingError(false);
    setIsSourceOrdersDetailsFetching(true);

    return orderJoinApiService
      .fetchOrderDetails(selectedSourceOrderUuid)
      .then(handleSourceOrdersDetailsResponse)
      .catch(onSourceOrdersDetailsFetchFailure)
      .finally(() => setIsSourceOrdersDetailsFetching(false));
  };

  const fetchTargetOrderDetails = () => {
    setIsTargetOrderDetailsFetchingError(false);
    setIsTargetOrderDetailsFetching(true);

    return orderJoinApiService
      .fetchOrderDetails(routeQueryParams.targetOrderUuid)
      .then(handleTargetOrderDetailsResponse)
      .catch(onTargetOrderDetailsFetchFailure)
      .finally(() => setIsTargetOrderDetailsFetching(false));
  };

  const fetchPreview = () => {
    setIsMergedOrderDetailsFetchingError(false);
    setIsMergedOrderDetailsFetching(true);

    const request: OrderJoinPreviewRequest = {
      source_order_ids: sourceOrdersData!.map((order) => order.uuid),
    };

    return orderJoinApiService
      .fetchOrderJoinPreview(routeQueryParams.targetOrderUuid, request)
      .then(handleMergedOrderDetailsResponse)
      .catch(onMergedOrderDetailsFetchFailure)
      .finally(() => setIsMergedOrderDetailsFetching(false));
  };

  useEffect(() => {
    if (sourceOrderDetails) {
      const fetchRouteResult = fetchRoute(
        sourceOrderDetails,
        handleSourceOrderRouteResponse
      );

      setSourceOrderMapRoutes(fetchRouteResult?.mapRoutes ?? []);

      const newSourceOrder = orderJoinOrderDetailsFactory.createOrderDetails(
        sourceOrderDetailsResponse!,
        sourceOrderSolvedRidesResponse,
        fetchRouteResult?.routeLegs ?? []
      );

      setSourceOrderDetails(newSourceOrder);
    }
  }, [sourceOrderDetails?.orderId]);

  useEffect(() => {
    if (mergedOrderDetails) {
      const fetchRouteResult = fetchRoute(
        mergedOrderDetails,
        handleMergedOrderRouteResponse
      );

      setMergedOrderMapRoutes(fetchRouteResult?.mapRoutes ?? []);

      const newSourceOrder = orderJoinOrderDetailsFactory.createOrderDetails(
        mergedOrderDetailsResponse!,
        mergedOrderSolvedRidesResponse,
        fetchRouteResult?.routeLegs ?? []
      );

      setMergedOrderDetails(newSourceOrder);
    }
  }, [mergedOrderDetails?.orderId]);

  useEffect(() => {
    if (targetOrderDetails) {
      const fetchRouteResult = fetchRoute(
        targetOrderDetails,
        handleTargetOrderRouteResponse
      );

      setTargetOrderMapRoutes(fetchRouteResult?.mapRoutes ?? []);

      const newTargetOrder = orderJoinOrderDetailsFactory.createOrderDetails(
        targetOrderDetailsResponse!,
        targetOrderSolvedRidesResponse,
        fetchRouteResult?.routeLegs ?? []
      );

      setTargetOrderDetails(newTargetOrder);
    }
  }, [targetOrderDetails?.orderId]);

  useEffect(() => {
    if (!routeQueryParams.targetOrderUuid) {
      return;
    }

    fetchTargetOrderDetails();
  }, [routeQueryParams.targetOrderUuid]);

  useEffect(() => {
    if (!routeQueryParams.targetOrderUuid || !sourceOrdersData.length) {
      return;
    }

    fetchPreview();
  }, [routeQueryParams.targetOrderUuid, sourceOrdersData]);

  useEffect(() => {
    const breadcrumbs = orderBreadcrumbsHelper.getOrderJoinBreadcrumbs({
      targetOrderUuid: routeQueryParams.targetOrderUuid,
      sourceOrders: routeQueryParams.sourceOrders,
    });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const sourceOrderMapMarkers: MapMarker[] = useMemo(() => {
    if (sourceOrderDetails?.routeWaypoints) {
      return orderJoinOrderDetailsPlannedRouteMapMarkersFactory.createMapMarkers(
        sourceOrderDetails?.routeWaypoints
      );
    }

    return [];
  }, [sourceOrderDetails?.routeWaypoints]);

  const targetOrderMapMarkers: MapMarker[] = useMemo(() => {
    if (targetOrderDetails?.routeWaypoints) {
      return orderJoinOrderDetailsPlannedRouteMapMarkersFactory.createMapMarkers(
        targetOrderDetails?.routeWaypoints
      );
    }

    return [];
  }, [targetOrderDetails?.routeWaypoints]);

  const mergedOrderMapMarkers: MapMarker[] = useMemo(() => {
    if (mergedOrderDetails?.routeWaypoints) {
      return orderJoinOrderDetailsPlannedRouteMapMarkersFactory.createMapMarkers(
        mergedOrderDetails?.routeWaypoints
      );
    }

    return [];
  }, [mergedOrderDetails?.routeWaypoints]);

  const fetchRoute = (
    orderDetails: OrderJoinOrderDetails,
    handleRouteResponse: (response: any) => void
  ): FetchRouteResponse | null => {
    const isRouteSequenceSolved = orderJoinHelper.isRouteSequenceSolved(
      orderDetails.routeWaypoints
    );

    if (isRouteSequenceSolved) {
      fetchRouteForSolvedOrder(orderDetails, handleRouteResponse);

      return null;
    }

    const waypointsCouldBeConnected =
      orderJoinHelper.getWaypointGroupsCouldBeConnected(
        orderDetails.routeWaypoints
      );

    const coordinatesOfWaypointsCouldBeConnected =
      orderJoinHelper.getCoordinatesOfWaypointsCouldBeConnected(
        waypointsCouldBeConnected
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    coordinatesOfWaypointsCouldBeConnected.forEach((coordinates) => {
      const request: SearchRoadRoutingRequest = {
        waypointCoordinates: coordinates,
        excludeHighway: orderDetails.nodeExclusion.excludeInside,
      };

      const fetch = orderJoinApiService.fetchRoute(request);

      fetchPromises.push(fetch);
    });

    let result: FetchRouteResponse | null = null;

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response) => {
        const mapRoute = response.routes[0]
          ? orderJoinOrderDetailsMapRouteFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRoutes: MapRoute[] = mapRouteWaypointGroups.map(
        (routeWaypointGroup) => ({
          waypoints: routeWaypointGroup,
        })
      );
      const responseRouteLegs = responses
        .map((response) => response.routes.map((route) => route.legs).flat())
        .flat();

      result = { mapRoutes: newMapRoutes, routeLegs: responseRouteLegs };
    });

    return result;
  };

  const fetchRouteForSolvedOrder = (
    orderDetails: OrderJoinOrderDetails,
    handleRouteResponse: (response: any) => void
  ) => {
    const request: SearchRoadRoutingRequest = {
      waypointCoordinates: orderDetails.routeWaypoints.map((waypoint) => ({
        latitude: waypoint.place.latitude,
        longitude: waypoint.place.longitude,
      })),
      excludeHighway: orderDetails.nodeExclusion.excludeInside,
    };

    orderJoinApiService.fetchRoute(request).then(handleRouteResponse);
  };

  const translations = orderTranslationsHelper.getOrderJoinTranslations();

  const joinOrders = () => {
    setIsMergeOrdersFetching(true);
    const request: OrderJoinRequest = {
      source_order_ids: sourceOrdersData.map((order) => order.uuid),
    };

    orderJoinApiService
      .joinOrders(routeQueryParams.targetOrderUuid, request)
      .then(handleJoinOrdersResponse)
      .finally(() => setIsMergeOrdersFetching(false));
  };

  const onSubmitButtonClick = () => {
    joinOrders();
  };

  const SourceOrderSelect = (
    <div className="order_join_select_order_button_wrapper">
      {sourceOrdersData.map((order) => (
        <ButtonComponent
          type={order.uuid === selectedSourceOrderUuid ? "success" : undefined}
          classNames={{ root: "mt-1" }}
          onClick={() => {
            setSelectedSourceOrderUuid(order.uuid);
          }}
          key={order.uuid}
        >
          {order.internalOrderId}
        </ButtonComponent>
      ))}
    </div>
  );

  return (
    <div className="order_join">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          <ButtonComponent
            onClick={onSubmitButtonClick}
            type="primary"
            isLoading={isMergeOrdersFetching}
            idForTesting={`order-join-submit-button`}
            title={translations.submitButtonTitle}
            isDisabled={
              isMergedOrderDetailsFetchingError || isMergedOrderDetailsFetching
            }
          >
            {translations.submitButtonText}
          </ButtonComponent>,
        ]}
      />
      <Row>
        <Column xl={4} withPaddings>
          <OrderJoinOrderDetailsComponent
            details={targetOrderDetails}
            headingText={translations.targetOrder.headingText}
            isError={isTargetOrderDetailsFetchingError}
            isLoading={isTargetOrderDetailsFetching}
            mapMarkers={targetOrderMapMarkers}
            mapRoutes={targetOrderMapRoutes}
            errorMessage={
              translations.targetOrder.failureDetailsFetchNotificationText
            }
          />
        </Column>
        <Column xl={4} withPaddings>
          <OrderJoinOrderDetailsComponent
            details={mergedOrderDetails}
            headingText={translations.joinPreview.headingText}
            isError={isMergedOrderDetailsFetchingError}
            isLoading={isMergedOrderDetailsFetching}
            mapMarkers={mergedOrderMapMarkers}
            mapRoutes={mergedOrderMapRoutes}
            errorMessage={translations.joinPreview.failureFetchNotificationText}
          />
        </Column>
        <Column xl={4} withPaddings>
          <OrderJoinOrderDetailsComponent
            details={sourceOrderDetails}
            headingText={translations.sourceOrder.headingText}
            isError={isSourceOrderDetailsFetchingError}
            isLoading={isSourceOrderDetailsFetching}
            mapMarkers={sourceOrderMapMarkers}
            mapRoutes={sourceOrderMapRoutes}
            errorMessage={
              translations.sourceOrder.failureDetailsFetchNotificationText
            }
            selector={SourceOrderSelect}
          />
        </Column>
      </Row>
    </div>
  );
};

export default OrderJoinComponent;
