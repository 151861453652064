import { FC, useMemo } from "react";
import routeFinishedListingByRailyTableHelper from "./route-finished-listing-by-raily-table.helper";
import RouteFinishedListingByRailyListingItem from "../common/types/route-finished-listing-by-raily-item";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import RouteFinishedListingByRailyTableColumn from "../common/types/route-finished-listing-by-raily-table-column";
import RouteFinishedListingByRailyTableRow from "../common/types/route-finished-listing-by-raily-table-row";

type RouteFinishedListingByRailyTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: RouteFinishedListingByRailyListingItem[];
};

const RouteFinishedListingByRailyTableComponent: FC<
  RouteFinishedListingByRailyTableProps
> = (props) => {
  const columns: RouteFinishedListingByRailyTableColumn[] =
    routeFinishedListingByRailyTableHelper.getColumns();

  const rows: RouteFinishedListingByRailyTableRow[] = useMemo(() => {
    return routeFinishedListingByRailyTableHelper.getRows(props.listingItems);
  }, [props.listingItems]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default RouteFinishedListingByRailyTableComponent;
