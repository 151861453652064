import HttpResponse from "../../../../utils/http/http.response";

export enum DriverListResponseItemAddressType {
  STARTING = "STARTING",
  MAILING = "MAILING",
}

export enum DriverListResponseItemDriverCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverListResponseItemDriverLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum DriverListResponseItemDriverCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export type DriverListResponseItemAddress = {
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  id: string;
  displayName: string;
  type: DriverListResponseItemAddressType;
};

export type DriverListResponseItemTaxiAssociationFleetPartner = {
  id: string;
  name: string;
  taxiCorporationId: string;
};

export type DriverListResponseItemTaxiDriverAssociationTaxiCorporation = {
  taxId: string;
  regon: string | null;
  krsNo: string | null;
  companyName: string;
  displayName: string;
  headquartersAddressString: string;
  contractMaintenancePolicy: string;
  notes: string | null;
  id: string | null;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export enum DriverListResponseItemTaxiDriverAssociationContractType {
  B2B = "B2B",
  EMPLOYMENT = "EMPLOYMENT",
  CONTRACT_OF_MANDATE = "CONTRACT_OF_MANDATE",
}

export type DriverListResponseItemTaxiDriverAssociation = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  contractType: DriverListResponseItemTaxiDriverAssociationContractType;
  taxId: string | null;
  regon: string | null;
  krsNo: string | null;
  companyName: string | null;
  fleetPartnerId: string | null;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  fleetPartner: DriverListResponseItemTaxiAssociationFleetPartner | null;
  taxiCorporation: DriverListResponseItemTaxiDriverAssociationTaxiCorporation;
};

export type DriverListResponseItemDriver = {
  addresses: DriverListResponseItemAddress[];
  birthDate: Date;
  birthPlace: string;
  carMake: string | null;
  carModel: string | null;
  carOwnership: DriverListResponseItemDriverCarOwnership;
  carProdYear: number | null;
  carRegNo: string | null;
  carSeatsNo: number | null;
  mobileModel: string | null;
  planApproved: boolean;
  citizenship: DriverListResponseItemDriverCitizenship;
  experience: number;
  idNumber: string;
  languages: DriverListResponseItemDriverLanguage[];
  taxiDriverAssociations: DriverListResponseItemTaxiDriverAssociation[];
};

export type DriverListResponseItem = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  driver: DriverListResponseItemDriver;
  pendingCandidature: boolean;
};

type DriverListResponse = HttpResponse<{
  data: DriverListResponseItem[];
  totalCount: number;
}>;

export default DriverListResponse;
