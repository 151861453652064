import Joi from "joi";
import RouteActiveListingByTaxiFilter, {
  RouteActiveListingByTaxiDriverFilter,
  RouteActiveListingByTaxiEndDateFilter,
  RouteActiveListingByTaxiPassengersFilter,
  RouteActiveListingByTaxiRouteAddressFilter,
  RouteActiveListingByTaxiRouteIdFilter,
  RouteActiveListingByTaxiStartDateFilter,
} from "./types/route-active-listing-by-taxi-filter";
import RouteActiveListingByTaxiFilterType from "./types/route-active-listing-by-taxi-filter-type";
import RouteActiveListingByTaxiRouteQueryFilterParams from "./types/route-active-listing-by-taxi-route-query-filter-params";
import RouteActiveListingByTaxiRouteQueryParams from "./types/route-active-listing-by-taxi-route-query-params";
import RouteActiveListingByTaxiSortKey from "./types/route-active-listing-by-taxi-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteActiveListingByTaxiRouteQueryFilterParams
): RouteActiveListingByTaxiFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: RouteActiveListingByTaxiFilter[] = [];

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteActiveListingByTaxiDriverFilter = {
      type: RouteActiveListingByTaxiFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.endDateFrom && routeQueryFilterParams.endDateTo) {
    const endDateFilter: RouteActiveListingByTaxiEndDateFilter = {
      type: RouteActiveListingByTaxiFilterType.END_DATE,
      value: {
        from: new Date(routeQueryFilterParams.endDateFrom),
        to: new Date(routeQueryFilterParams.endDateTo),
      },
    };

    filters.push(endDateFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: RouteActiveListingByTaxiStartDateFilter = {
      type: RouteActiveListingByTaxiFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteActiveListingByTaxiPassengersFilter = {
      type: RouteActiveListingByTaxiFilterType.PASSENGERS,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: RouteActiveListingByTaxiRouteAddressFilter = {
      type: RouteActiveListingByTaxiFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: RouteActiveListingByTaxiRouteIdFilter = {
      type: RouteActiveListingByTaxiFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };

    filters.push(routeIdFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteActiveListingByTaxiRouteQueryParams
): RouteActiveListingByTaxiSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: RouteActiveListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: RouteActiveListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: RouteActiveListingByTaxiFilter[],
  sortKey: RouteActiveListingByTaxiSortKey | null,
  page: number,
  pageSize: number
): RouteActiveListingByTaxiRouteQueryParams => {
  const routeQueryParams: RouteActiveListingByTaxiRouteQueryParams = {
    driver: filters.find(
      (filter) => filter.type === RouteActiveListingByTaxiFilterType.DRIVER
    )?.value as RouteActiveListingByTaxiDriverFilter["value"] | undefined,
    endDateFrom: (
      filters.find(
        (filter) => filter.type === RouteActiveListingByTaxiFilterType.END_DATE
      )?.value as RouteActiveListingByTaxiEndDateFilter["value"] | undefined
    )?.from.toJSON(),
    endDateTo: (
      filters.find(
        (filter) => filter.type === RouteActiveListingByTaxiFilterType.END_DATE
      )?.value as RouteActiveListingByTaxiEndDateFilter["value"] | undefined
    )?.to.toJSON(),
    passenger: filters.find(
      (filter) => filter.type === RouteActiveListingByTaxiFilterType.PASSENGERS
    )?.value as RouteActiveListingByTaxiPassengersFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === RouteActiveListingByTaxiFilterType.ROUTE_ADDRESS
    )?.value as RouteActiveListingByTaxiRouteAddressFilter["value"] | undefined,
    routeId: filters.find(
      (filter) => filter.type === RouteActiveListingByTaxiFilterType.ROUTE_ID
    )?.value as RouteActiveListingByTaxiRouteIdFilter["value"] | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteActiveListingByTaxiFilterType.START_DATE
      )?.value as RouteActiveListingByTaxiStartDateFilter["value"] | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteActiveListingByTaxiFilterType.START_DATE
      )?.value as RouteActiveListingByTaxiStartDateFilter["value"] | undefined
    )?.to.toJSON(),
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: RouteActiveListingByTaxiRouteQueryFilterParams
) => {
  const filterParams: RouteActiveListingByTaxiRouteQueryFilterParams = {
    driver: routeQueryFilterParams.driver,
    endDateFrom: routeQueryFilterParams.endDateFrom,
    endDateTo: routeQueryFilterParams.endDateTo,
    passenger: routeQueryFilterParams.passenger,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeId: routeQueryFilterParams.routeId,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
  };

  const validationSchema =
    Joi.object<RouteActiveListingByTaxiRouteQueryFilterParams>({
      driver: Joi.string(),
      endDateFrom: Joi.string(),
      endDateTo: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeId: Joi.number(),
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: RouteActiveListingByTaxiRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(RouteActiveListingByTaxiSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: RouteActiveListingByTaxiRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: RouteActiveListingByTaxiRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const routeActiveListingByTaxiRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeActiveListingByTaxiRouteQueryParamsService;
