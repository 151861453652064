export enum OrderAddCargoAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

type OrderAddCargoAddress = {
  uuid: string;
  displayName: string;
  latitude: number;
  longitude: number;
  type: OrderAddCargoAddressType;
};

export default OrderAddCargoAddress;
