import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderDetailsResponseItemStatus {
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  FINISHED = "FINISHED",
}

export enum CargoOrderDetailsResponseItemPublicStatus {
  PUBLIC = "PUBLIC",
  PUBLISHED = "PUBLISHED",
  PRIVATE = "PRIVATE",
}

export enum CargoOrderDetailsResponseItemTransportingOrderDriverCitizenship {
  PL = "PL",
  DE = "DE",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum CargoOrderDetailsResponseItemTransportingOrderDriverCarOwnership {
  OWNER = "OWNER",
  BUSINESS = "BUSINESS",
}

export enum CargoOrderDetailsResponseItemTransportingOrderDriverLanguage {
  PL = "PL",
  EN = "EN",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  SK = "SK",
  CZ = "CZ",
  UA = "UA",
  RU = "RU",
  BY = "BY",
  LT = "LT",
}

export enum CargoOrderDetailsResponseItemPassengerType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

type CargoOrderDetailsResponseItemNodeExclusions = {
  nodeIds: string[];
  excludeApproaching: boolean;
  excludeReturning: boolean;
  excludeInside: boolean;
};

export type CargoOrderDetailsResponseItemDispatcher = {
  dispatchId: string;
  dispatch: CargoOrderDetailsResponseItemDispatch | null;
};

export type CargoOrderDetailsResponseItemDispatch = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  cargoCompany: CargoOrderDetailsResponseItemDispatchCargoCompany;
};

export type CargoOrderDetailsResponseItemDispatchCargoCompany = {
  id: string;
  displayName: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
};

export type CargoOrderDetailsResponseItemTransportingOrder = {
  id: string;
  driver: CargoOrderDetailsResponseItemTransportingOrderDriver | null;
  passengers: CargoOrderDetailsResponseItemTransportingOrderPassenger[];
  solve: CargoOrderDetailsResponseItemDriverSolveWaypoint[];
  driverSolve: CargoOrderDetailsResponseItemDriverSolveWaypoint[] | null;
  createSolvedOrderEntries: CargoOrderDetailsResponseItemSolvedOrderEntries[];
  createPlanEntryEntries: CargoOrderDetailsResponseItemPlanEntryEntries[];
};

export type CargoOrderDetailsResponseItemTransportingOrderDriver = {
  removedBy: string | null;
  removedAt: Date | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  username: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
  email: string;
  driver: CargoOrderDetailsResponseItemTransportingOrderDriverDetails;
};

type CargoOrderDetailsResponseItemTransportingOrderDriverDetails = {
  birthDate: Date;
  birthPlace: string;
  idNumber: string;
  citizenship: CargoOrderDetailsResponseItemTransportingOrderDriverCitizenship[];
  languages: CargoOrderDetailsResponseItemTransportingOrderDriverLanguage[];
  experience: number;
  carOwnership: CargoOrderDetailsResponseItemTransportingOrderDriverCarOwnership[];
  carMake: string | null;
  carModel: string | null;
  carRegNo: string | null;
  carProdYear: number | null;
  carSeatsNo: number | null;
  mobileModel: string | null;
  planApproved: boolean;
  startingAddress: CargoOrderDetailsResponseItemTransportingOrderDriverAddress;
  mailingAddress: CargoOrderDetailsResponseItemTransportingOrderDriverMailingAddress;
};

type CargoOrderDetailsResponseItemTransportingOrderDriverAddress = {
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  displayName: string;
};

type CargoOrderDetailsResponseItemTransportingOrderDriverMailingAddress = {
  lat: number;
  lon: number;
  country: string | null;
  town: string | null;
  zipCode: string | null;
  street: string | null;
  houseNo: string | null;
  apartment: string | null;
  description: string | null;
  displayName: string;
};

export type CargoOrderDetailsResponseItemTransportingOrderPassenger =
  | CargoOrderDetailsResponseItemTransportingOrderInternalPassenger
  | CargoOrderDetailsResponseItemTransportingOrderExternalPassenger;

export type CargoOrderDetailsResponseItemTransportingOrderInternalPassenger = {
  itemType: CargoOrderDetailsResponseItemPassengerType.INTERNAL;
  firstName: string;
  lastName: string;
  dispatch: string | null;
  phonePrefix: string;
  phoneNo: string;
  cargoItemId: string;
  passengerId: string;
};

export type CargoOrderDetailsResponseItemTransportingOrderExternalPassenger = {
  itemType: CargoOrderDetailsResponseItemPassengerType.EXTERNAL;
  firstName: string;
  lastName: string;
  dispatch: string | null;
  phonePrefix: string;
  phoneNo: string;
  cargoItemId: string;
};

export type CargoOrderDetailsResopnseItemSolve =
  | CargoOrderDetailsResponseItemSolveWaypoint
  | CargoOrderDetailsResponseItemSolveWaypoint[];

export type CargoOrderDetailsResponseItemSolveWaypoint = {
  nodeId: string;
  lat: number;
  lon: number;
  requestedTime: Date | null;
  requestedHaltingTime: number;
  displayName: string;
  cargoEnter: string[];
  cargoExit: string[];
  estimatedTime: Date;
  checkoutEvent: CargoOrderDetailsResponseItemSolveCheckoutEvent | null;
  distance: number;
};

export type CargoOrderDetailsResponseItemDriverSolveWaypoint = {
  nodeId: string;
  lat: number;
  lon: number;
  requestedTime: Date | null;
  requestedHaltingTime: number;
  displayName: string;
  cargoEnter: string[];
  cargoExit: string[];
  estimatedTime: Date;
  checkoutEvent: CargoOrderDetailsResponseItemSolveCheckoutEvent | null;
  distance: number;
};

type CargoOrderDetailsResponseItemSolveCheckoutEvent = {
  achievedAt: Date;
  haltingTime: number | null;
};

type CargoOrderDetailsResponseItemSolvedOrderEntries = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  driver: CargoOrderDetailsResponseItemSolvedOrderEntriesDriver;
  candidatureId: string;
};

type CargoOrderDetailsResponseItemPlanEntryEntries = {
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  driver: CargoOrderDetailsResponseItemSolvedOrderEntriesDriver;
  candidatureId: string;
};

export type CargoOrderDetailsResponseItemSolvedOrderEntriesDriver = {
  removedAt: string | null;
  removedBy: string | null;
  id: string;
  modifiedAt: Date | null;
  modifiedBy: string | null;
  createdAt: Date;
  createdBy: string;
  firstName: string;
  lastName: string;
  mobilePrimaryPrefix: string;
  mobilePrimary: string;
  mobileSecondaryPrefix: string | null;
  mobileSecondary: string | null;
};

export type CargoOrderDetailsResponseItem = {
  id: string;
  isPublished: boolean;
  targetTaxiId: string | null;
  isFinished: boolean;
  externalId: string | null;
  humanId: number;
  isCancelled: boolean;
  isCancelRequested: boolean;
  approvingDispatcherId: string | null;
  approvingOperatorId: string | null;
  approvingDealerId: string | null;
  orderType: CargoOrderDetailsResponseItemPublicStatus;
  status: CargoOrderDetailsResponseItemStatus;
  nodeExclusions: CargoOrderDetailsResponseItemNodeExclusions;
  dispatcher: CargoOrderDetailsResponseItemDispatcher;
  transportingOrders: CargoOrderDetailsResponseItemTransportingOrder[];
};

type CargoOrderDetailsResponse = HttpResponse<CargoOrderDetailsResponseItem>;

export default CargoOrderDetailsResponse;
