import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type PassengerAddUserPermissionOption =
  | "hasAccessToCargoView"
  | "hasAccessToRailyView";

export type PassengerAddUserPermissionsDefinition =
  UserPermissionsDefinition<PassengerAddUserPermissionOption>;

export type PassengerAddUserPermissions =
  UserPermissions<PassengerAddUserPermissionOption>;

const passengerAddUserPermissionDefinition: PassengerAddUserPermissionsDefinition =
  {
    hasAccessToCargoView: [UserRole.CARGO_OFFICER, UserRole.DISPATCHER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default passengerAddUserPermissionDefinition;
