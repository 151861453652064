import { FC, useMemo } from "react";
import routeActiveListingByRailyTableHelper from "./route-active-listing-by-raily-table.helper";
import RouteActiveListingByRailyListingItem from "../common/types/route-active-listing-by-raily-item";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import RouteActiveListingByRailyTableColumn from "../common/types/route-active-listing-by-raily-table-column";
import RouteActiveListingByRailyTableRow from "../common/types/route-active-listing-by-raily-table-row";

type RouteActiveListingByRailyTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: RouteActiveListingByRailyListingItem[];
};

const RouteActiveListingByRailyTableComponent: FC<
  RouteActiveListingByRailyTableProps
> = (props) => {
  const columns: RouteActiveListingByRailyTableColumn[] =
    routeActiveListingByRailyTableHelper.getColumns();

  const rows: RouteActiveListingByRailyTableRow[] = useMemo(() => {
    return routeActiveListingByRailyTableHelper.getRows(props.listingItems);
  }, [props.listingItems]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default RouteActiveListingByRailyTableComponent;
