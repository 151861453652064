export enum RouteFinishedListingRequestOrder {
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  HUMAN_ID_ASC = "HUMAN_ID_ASC",
  HUMAN_ID_DESC = "HUMAN_ID_DESC",
}

type RouteFinishedListingRequest = {
  order?: RouteFinishedListingRequestOrder;
  limit: number;
  offset?: number;
  start_time_since?: string;
  start_time_to?: string;
  end_time_since?: string;
  end_time_to?: string;
  human_id?: number;
  plan_entry_nodes?: string;
  starting_node_label?: string;
  ending_node_label?: string;
  involved_cargo_labels?: string;
  driver_name?: string;
  taxi_name?: string;
};

export default RouteFinishedListingRequest;
