import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import taxiOrderSettlementListingHelper from "../../taxi-order-settlement-listing.helper";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingInternalOrderIdFilter,
  TaxiOrderSettlementListingBillingStatusFilter,
  TaxiOrderSettlementListingOrderStartDateFilter,
  TaxiOrderSettlementListingBillingModelFilter,
  TaxiOrderSettlementListingBillingTypeFilter,
  TaxiOrderSettlementListingCargoCompanyFilter,
  TaxiOrderSettlementListingDestinationTaxiFilter,
} from "../../types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingFilterType from "../../types/taxi-order-settlement-listing-filter-type";
import dateService from "../../../../../../../common/utils/date/date.service";
import TaxiOrderSettlementListingItemBillingType from "../../types/taxi-order-settlement-listing-item-billing-type";
import BillingModel from "../../../../../common/types/billing-model";
import TaxiOrderSettlementListingItemBillingStatus from "../../types/taxi-order-settlement-listing-item-billing-status";

const getStartDateBadgeOption = (
  filter: TaxiOrderSettlementListingOrderStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiOrderSettlementListingOrderStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.orderStartDateFilterLabel
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.orderStartDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiOrderSettlementListingFilterType.ORDER_START_DATE,
      value: filter.value,
    },
  };
};

const getBillingStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiOrderSettlementListingBillingStatusFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters;

  return Object.values(TaxiOrderSettlementListingItemBillingStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.billingStatusFilterLabel.replace(
            "#{option}",
            taxiOrderSettlementListingHelper.getBillingStatusText(
              statusFilterValue
            )
          ),
          title: translations.billingStatusFilterTitle.replace(
            "#{option}",
            taxiOrderSettlementListingHelper.getBillingStatusText(
              statusFilterValue
            )
          ),
        },
        filter: {
          type: TaxiOrderSettlementListingFilterType.BILLING_STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBillingTypeBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiOrderSettlementListingBillingTypeFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters;

  return Object.values(TaxiOrderSettlementListingItemBillingType).map(
    (typeFilterValue) => {
      return {
        badge: {
          text: translations.billingTypeFilterLabel.replace(
            "#{option}",
            taxiOrderSettlementListingHelper.getBillingTypeText(typeFilterValue)
          ),
          title: translations.billingTypeFilterTitle.replace(
            "#{option}",
            taxiOrderSettlementListingHelper.getBillingTypeText(typeFilterValue)
          ),
        },
        filter: {
          type: TaxiOrderSettlementListingFilterType.BILLING_TYPE,
          value: typeFilterValue,
        },
      };
    }
  );
};

const getBillingModelBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiOrderSettlementListingBillingModelFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters;

  return Object.values(BillingModel).map((modelFilterValue) => {
    return {
      badge: {
        text: translations.billingModelFilterLabel.replace(
          "#{option}",
          taxiOrderSettlementListingHelper.getBillingModelText(modelFilterValue)
        ),
        title: translations.billingModelFilterTitle.replace(
          "#{option}",
          taxiOrderSettlementListingHelper.getBillingModelText(modelFilterValue)
        ),
      },
      filter: {
        type: TaxiOrderSettlementListingFilterType.BILLING_MODEL,
        value: modelFilterValue,
      },
    };
  });
};

const getBadgeOptions = (filter: TaxiOrderSettlementListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiOrderSettlementListingFilter;
  }[] = [
    {
      badge: {
        text: translations.internalOrderIdFilterLabel.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as TaxiOrderSettlementListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.cargoCompanyFilterLabel.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingCargoCompanyFilter["value"]
          )
        ),
        title: translations.cargoCompanyFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingCargoCompanyFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiOrderSettlementListingFilterType.CARGO_COMPANY,
        value:
          filter.value as TaxiOrderSettlementListingCargoCompanyFilter["value"],
      },
    },
    {
      badge: {
        text: translations.destinationTaxiFilterLabel.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingDestinationTaxiFilter["value"]
          )
        ),
        title: translations.destinationTaxiFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiOrderSettlementListingDestinationTaxiFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiOrderSettlementListingFilterType.DESTINATION_TAXI,
        value:
          filter.value as TaxiOrderSettlementListingDestinationTaxiFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as TaxiOrderSettlementListingOrderStartDateFilter
    ),
    ...getBillingStatusBadgeOptions(),
    ...getBillingTypeBadgeOptions(),
    ...getBillingModelBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: TaxiOrderSettlementListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiOrderSettlementListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiOrderSettlementListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiOrderSettlementListingFiltersBadgeListHelper;
