import {
  CargoOrderDetailsTransportingOrder,
  CargoOrderDetailsTransportingOrderWaypoint,
  CargoOrderDetailsTransportingOrderWaypointItem,
} from "../../../../common/services/cargo-order/details/cargo-order-details";
import { SearchRoadRoutingResponseRouteGeometryCoordinate } from "../../../../common/utils/search-road-route/search-road-routing.response";
import SearchRoadRoutingRequest from "../../../../common/utils/search-road-route/search-road-routing.request";
import PlanningOrderOrderMapWaypoints from "./types/planning-order-order-map";
import mapMarkerIconFactory from "../../../../common/components/map/marker/map-marker-icon.factory";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";

const createMapRoutes = (
  coordinates: SearchRoadRoutingResponseRouteGeometryCoordinate[]
): MapRoute => {
  return {
    waypoints: coordinates.map((coordinate) => {
      return {
        latitude: coordinate[1],
        longitude: coordinate[0],
      };
    }),
  };
};

const createMapMarker = (item: PlanningOrderOrderMapWaypoints): MapMarker => {
  const markerIcon = mapMarkerIconFactory.createIcon({
    className: "map_marker standard",
    content: item.stageNo,
  });

  return {
    coordinate: {
      latitude: item.latitude,
      longitude: item.longitude,
    },
    icon: markerIcon,
    title: item.displayName,
    tooltip: item.displayName,
  };
};

const createMapMarkers = (
  items: PlanningOrderOrderMapWaypoints[]
): MapMarker[] => items.map((item) => createMapMarker(item));

const createWaypoint = (
  item: PlanningOrderOrderMapWaypoints,
  stageNo: number
): PlanningOrderOrderMapWaypoints => {
  return {
    latitude: item.latitude,
    longitude: item.longitude,
    displayName: item.displayName,
    stageNo: stageNo,
  };
};

const createWaypointsForDriver = (
  waypoints: CargoOrderDetailsTransportingOrderWaypointItem[],
  stageNo: number
): PlanningOrderOrderMapWaypoints[] => {
  return waypoints.map((solvedWaypoint) => {
    stageNo++;
    return createWaypoint(solvedWaypoint, stageNo);
  });
};

const createWaypointsForNoDriver = (
  waypoints: CargoOrderDetailsTransportingOrderWaypoint[],
  stageNo: number
): PlanningOrderOrderMapWaypoints[] => {
  return waypoints
    .map((waypointItem) => {
      if (Array.isArray(waypointItem)) {
        stageNo++;
        return waypointItem.map((waypoint) =>
          createWaypoint(waypoint, stageNo)
        );
      }
      stageNo++;
      return createWaypoint(waypointItem, stageNo);
    })
    .flat();
};

const createWaypoints = (
  transportingOrders: CargoOrderDetailsTransportingOrder[]
): PlanningOrderOrderMapWaypoints[] => {
  const stageNo = 0;

  return transportingOrders
    .map((transportingOrder) => {
      if (transportingOrder.driver) {
        return createWaypointsForDriver(
          transportingOrder.solvedWaypoints,
          stageNo
        );
      }
      return createWaypointsForNoDriver(transportingOrder.waypoints, stageNo);
    })
    .flat();
};

const createWaypointsCoordinates = (
  waypoints: PlanningOrderOrderMapWaypoints[],
  isHighwayAllowed: boolean | undefined
): SearchRoadRoutingRequest => {
  const result: SearchRoadRoutingRequest = {
    excludeHighway: isHighwayAllowed,
    waypointCoordinates: [],
  };

  waypoints.forEach((item: PlanningOrderOrderMapWaypoints) => {
    result.waypointCoordinates = [
      ...result.waypointCoordinates,
      { latitude: item.latitude, longitude: item.longitude },
    ];
  });

  return result;
};

const planningOrderOrderMapFactory = {
  createMapMarkers,
  createMapRoutes,
  createWaypoints,
  createWaypointsCoordinates,
};

export default planningOrderOrderMapFactory;
