import {
  OrderRouteEditSeqGroupNode,
  OrderRouteEditSeqNode,
} from "../api/order-route-edit-order-details.response";
import OrderRouteEditPassenger, {
  OrderRouteEditInternalPassenger,
  OrderRouteEditPassengerType,
} from "../types/order-route-edit-passenger";
import OrderRouteEditRouteItem, {
  OrderRouteEditRouteWaypoint,
  OrderRouteEditRouteWaypointGroup,
} from "../types/order-route-edit-route-waypoint";

const checkIsRouteItemAWaypoint = (
  routeItem: OrderRouteEditRouteItem
): routeItem is OrderRouteEditRouteWaypoint => {
  if ((routeItem as OrderRouteEditRouteWaypoint).date !== undefined) {
    return true;
  }

  return false;
};

const checkIsRouteItemWaypointGroup = (
  routeItem: OrderRouteEditRouteItem
): routeItem is OrderRouteEditRouteWaypointGroup => {
  if ((routeItem as OrderRouteEditRouteWaypointGroup).waypoints) {
    return true;
  }

  return false;
};

const checkIsSingleNode = (
  node: OrderRouteEditSeqNode | OrderRouteEditSeqGroupNode
): node is OrderRouteEditSeqNode => {
  return (node as OrderRouteEditSeqNode).seq === undefined;
};

const checkIsInternalPassenger = (
  passenger: OrderRouteEditPassenger
): passenger is OrderRouteEditInternalPassenger => {
  return passenger.type === OrderRouteEditPassengerType.INTERNAL;
};

const orderRouteEditHelper = {
  checkIsRouteItemAWaypoint,
  checkIsRouteItemWaypointGroup,
  checkIsSingleNode,
  checkIsInternalPassenger,
};

export default orderRouteEditHelper;
