import isEqual from "lodash/isEqual";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteFinishedListingByTaxiFilter from "../types/route-finished-listing-by-taxi-filter";
import RouteFinishedListingByTaxiFilterSelectOption, {
  RouteFinishedListingByTaxiRouteIdFilterSelectOption,
} from "../types/route-finished-listing-by-taxi-filter-select-option";
import RouteFinishedListingByTaxiFilterType from "../types/route-finished-listing-by-taxi-filter-type";

const getSearchQueryOptions = (
  query: string
): RouteFinishedListingByTaxiFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    routeTranslationsHelper.getFinishedListingTranslations().filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByDriverQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengersQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengersTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIntermediateAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIntermediateAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRoutePickupAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRoutePickupAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteDestinationAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteDestinationAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIdQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByDriverQueryLabel(query),
      value: {
        type: RouteFinishedListingByTaxiFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByPassengersQueryLabel(query),
      value: {
        type: RouteFinishedListingByTaxiFilterType.PASSENGERS,
        value: query,
      },
    },
    {
      label: getSearchByRouteIntermediateAddressQueryLabel(query),
      value: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRoutePickupAddressQueryLabel(query),
      value: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_PICKUP_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByRouteDestinationAddressQueryLabel(query),
      value: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_DESTINATION_ADDRESS,
        value: query,
      },
    },
  ];

  const options: RouteFinishedListingByTaxiFilterSelectOption[] = [
    ...stringOptions,
  ] as RouteFinishedListingByTaxiFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: RouteFinishedListingByTaxiRouteIdFilterSelectOption =
      {
        label: getSearchByRouteIdQueryLabel(query),
        value: {
          type: RouteFinishedListingByTaxiFilterType.ROUTE_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSelectOptions = (
  query: string,
  selectedFilters: RouteFinishedListingByTaxiFilter[]
): RouteFinishedListingByTaxiFilterSelectOption[] => {
  const allFilterOptions = getSearchQueryOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const routeFinishedListingByTaxiFilterHelper = {
  getSelectOptions,
};

export default routeFinishedListingByTaxiFilterHelper;
