import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderFinishedListingTableColumn from "../common/types/order-finished-listing-table-column";
import {
  faCancel,
  faCar,
  faMessage,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";

const getColumns = (): OrderFinishedListingTableColumn[] => {
  const translations =
    orderTranslationsHelper.getFinishedListingTranslations().table.headers;

  const tableColumns: OrderFinishedListingTableColumn[] = [
    {
      header: "",
      title: "",
      accessor: "status",
      colSpan: 2,
    },
    {
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      accessor: "date",
      colSpan: 10,
    },
    {
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      accessor: "cargoCompanyOrderId",
      colSpan: 5,
    },
    {
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      accessor: "cargoCompanyExternalOrderId",
      colSpan: 7,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faMessage}
          className="order_finished_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.newSharedPostTitle,
      accessor: "newSharedPost",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faPencil}
          className="order_finished_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.cargoOrderEditTitle,
      accessor: "cargoOrderEdit",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faCancel}
          className="order_finished_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.cargoOrderCancelTitle,
      accessor: "cargoOrderCancel",
      colSpan: 2,
    },
    {
      header: (
        <FontAwesomeIcon
          icon={faCar}
          className="order_finished_listing_table_label"
          size={"sm"}
        />
      ),
      title: translations.slkStatusTitle,
      accessor: "slkStatus",
      colSpan: 2,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 22,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      accessor: "passenger",
      colSpan: 8,
    },
    {
      header: translations.dispatchLabel,
      title: translations.dispatchTitle,
      accessor: "dispatch",
      colSpan: 8,
    },
    {
      header: translations.clientLabel,
      title: translations.clientTitle,
      accessor: "client",
      colSpan: 8,
    },
    {
      header: translations.taxiLabel,
      title: translations.taxiTitle,
      accessor: "taxi",
      colSpan: 8,
    },
    {
      header: translations.driverLabel,
      title: translations.driverTitle,
      accessor: "driver",
      colSpan: 10,
    },
    {
      header: translations.publicStatusLabel,
      title: translations.publicStatusTitle,
      accessor: "publicStatus",
      colSpan: 8,
    },
  ];

  return tableColumns;
};

const orderFinishedListingTableHelper = {
  getColumns,
};

export default orderFinishedListingTableHelper;
