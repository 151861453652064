import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: false,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: false,
    [MessengerChannel.SHARED]: true,
    [MessengerChannel.TAXI]: true,
  };
};

const billingsTaxiTaxiHelper = { getMessengerChannelAvailability };

export default billingsTaxiTaxiHelper;
