import { FC, useMemo, useState } from "react";
import RouteFinishedListingByTaxiFilter from "../../common/types/route-finished-listing-by-taxi-filter";
import routeFinishedListingByTaxiFilterHelper from "../../common/filter/route-finished-listing-by-taxi-filter.helper";
import RouteFinishedListingByTaxiFilterSelectOption from "../../common/types/route-finished-listing-by-taxi-filter-select-option";
import ListingFilterSelectComponent from "../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../context/app.context";

type RouteFinishedListingByTaxiFiltersSelectProps = {
  filters: RouteFinishedListingByTaxiFilter[];
  onAddNewFilter: (filter: RouteFinishedListingByTaxiFilter) => void;
};

const RouteFinishedListingByTaxiFiltersSelectComponent: FC<
  RouteFinishedListingByTaxiFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return routeFinishedListingByTaxiFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: RouteFinishedListingByTaxiFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as RouteFinishedListingByTaxiFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="route-finished-listing-by-taxi-listing-filter"
    />
  );
};

export default RouteFinishedListingByTaxiFiltersSelectComponent;
