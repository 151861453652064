import isEqual from "lodash/isEqual";
import TaxiRouteSettlementListingFilterSelectOption, {
  TaxiRouteSettlementListingBillingModelFilterSelectOption,
  TaxiRouteSettlementListingRouteIdFilterSelectOption,
} from "./types/taxi-route-settlement-listing-filter-select-option";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiRouteSettlementListingFilterType from "./types/taxi-route-settlement-listing-filter-type";
import TaxiRouteSettlementListingFilter, {
  TaxiRouteSettlementListingRouteStartDateFilter,
} from "./types/taxi-route-settlement-listing-filter";
import BillingModel from "../../../common/types/billing-model";

const getSearchQueryOptions = (
  query: string
): TaxiRouteSettlementListingFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByRouteIdLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByDriverLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByFleetPartnerLabel = (query: string) => {
    return searchFilterTranslations.searchByFleetPartnerTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByDriverLabel(query),
      value: {
        type: TaxiRouteSettlementListingFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByFleetPartnerLabel(query),
      value: {
        type: TaxiRouteSettlementListingFilterType.FLEET_PARTNER,
        value: query,
      },
    },
  ];

  const options: TaxiRouteSettlementListingFilterSelectOption[] = [
    ...stringOptions,
  ] as TaxiRouteSettlementListingFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: TaxiRouteSettlementListingRouteIdFilterSelectOption =
      {
        label: getSearchByRouteIdLabel(query),
        value: {
          type: TaxiRouteSettlementListingFilterType.ROUTE_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSettlementModelOptions = (
  query: string
): TaxiRouteSettlementListingFilterSelectOption[] => {
  const searchFilterTranslations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .filters.search;

  const allSettlementModelSelectOptions: TaxiRouteSettlementListingBillingModelFilterSelectOption[] =
    Object.values(BillingModel).map((billingModel) => {
      const billingModelLabel =
        searchFilterTranslations.searchByBillingModelTemplateText.replace(
          "#{option}",
          billingModel
        );

      return {
        label: billingModelLabel,
        value: {
          type: TaxiRouteSettlementListingFilterType.BILLING_MODEL,
          value: billingModel,
        },
      };
    });

  if (query) {
    return allSettlementModelSelectOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }

  return allSettlementModelSelectOptions;
};

const getSelectOptionsByQuery = (
  query: string
): TaxiRouteSettlementListingFilterSelectOption[] => {
  const settlementModelOptions = getSettlementModelOptions(query);
  const queryOptions = getSearchQueryOptions(query);

  return [...settlementModelOptions, ...queryOptions];
};

const getSelectOptions = (
  query: string,
  selectedFilters: TaxiRouteSettlementListingFilter[]
): TaxiRouteSettlementListingFilterSelectOption[] => {
  const allFilterOptions = getSelectOptionsByQuery(query);

  return allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });
};

const getDefaultFilters = (
  filters: TaxiRouteSettlementListingFilter[]
): TaxiRouteSettlementListingFilter[] => {
  const currentDate = new Date();

  const defaultFilters = [
    {
      type: TaxiRouteSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(currentDate.setDate(1)),
        to: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
      },
    } as TaxiRouteSettlementListingRouteStartDateFilter,
  ];

  defaultFilters.forEach((defaultFilter) => {
    const existingFilter = filters.find(
      (filter) => filter.type === defaultFilter.type
    );

    if (existingFilter) {
      return;
    }

    filters.push(defaultFilter);
  });

  return filters;
};

const taxiRouteSettlementListingFilterHelper = {
  getSelectOptions,
  getDefaultFilters,
};

export default taxiRouteSettlementListingFilterHelper;
