import { FormValidationDefinition } from "../../../../../common/components/form/use-form";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import routesWaypointAddressAddFormValidationService from "./routes-waypoint-address-add-form-validation.service";
import RoutesWaypointAddressAddFormData from "./types/routes-waypoint-address-add-form-data";

const getDefaultFormData = (): RoutesWaypointAddressAddFormData => {
  return {
    addressName: "",
    addressFound: null,
  };
};

const getValidationDefinition =
  (): FormValidationDefinition<RoutesWaypointAddressAddFormData> => {
    return {
      addressName: (values) =>
        routesWaypointAddressAddFormValidationService.validateAddressName(
          values.addressName
        ),

      addressFound: () => formValidationService.defaultValidationResult,
    };
  };

const routesWaypointAddressAddFormHelper = {
  getDefaultFormData,
  getValidationDefinition,
};

export default routesWaypointAddressAddFormHelper;
