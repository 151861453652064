import appTranslationsHelper from "./app-translations.helper";
import RouteActiveListingTranslations from "./types/route/route-active-listing.translations";
import RouteFinishedListingTranslations from "./types/route/route-finished-listing.translations";

const getTranslations = <T>(internalFilePath: string): T => {
  const baseFilePath = `route`;

  const filePath = `${baseFilePath}/${internalFilePath}`;

  return appTranslationsHelper.getTranslations(filePath);
};

const getActiveListingTranslations = (): RouteActiveListingTranslations => {
  const translations = getTranslations<RouteActiveListingTranslations>(
    `route-active-listing.translations.json`
  );

  return translations;
};

const getFinishedListingTranslations = (): RouteFinishedListingTranslations => {
  const translations = getTranslations<RouteFinishedListingTranslations>(
    `route-finished-listing.translations.json`
  );

  return translations;
};

const routeTranslationsHelper = {
  getActiveListingTranslations,
  getFinishedListingTranslations,
};

export default routeTranslationsHelper;
