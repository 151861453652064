import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiOrderSettlementListingByRailyRouteQueryParams from "./common/types/taxi-order-settlement-listing-by-raily-route-query-params";
import TaxiOrderSettlementListingItem from "../common/types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingOrderStartDateFilter,
} from "../common/types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingSortKey from "../common/types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingSortSelectOption from "../common/types/taxi-order-settlement-listing-sort-select-option";
import taxiOrderSettlementListingSortHelper from "../common/taxi-order-settlement-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiOrderSettlementListingByRailyRouteQueryParamsService from "./common/taxi-order-settlement-listing-by-raily-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import TaxiOrderSettlementListingStatusLegendComponent from "../common/status-legend/taxi-order-settlement-listing-status-legend.component";
import TaxiOrderSettlementListingFiltersSelectComponent from "../common/filters/select/taxi-order-settlement-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiOrderSettlementListingFiltersBadgeListComponent from "../common/filters/list/taxi-order-settlement-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiOrderSettlementListingTableComponent from "../common/table/taxi-order-settlement-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiOrderSettlementListingViewBasicProps from "../common/types/taxi-order-settlement-listing-view-basic-props";
import taxiOrderSettlementListingByRailyApiService from "./common/api/taxi-order-settlement-listing-by-raily-api.service";
import taxiOrderSettlementListingByRailyFactory from "./common/taxi-order-settlement-listing-by-raily.factory";
import TaxiOrderSettlementListingFilterType from "../common/types/taxi-order-settlement-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption from "./common/types/taxi-order-settlement-listing-by-raily-taxi-corporation-select-option";
import TaxiOrderSettlementListingByRailyTaxiCorporationsResponse, {
  TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem,
} from "./common/api/taxi-order-settlement-listing-by-raily-taxi-corporations.response";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import TaxiOrderSettlementListingResponse from "../common/api/taxi-order-settlement-listing.response";
import taxiOrderSettlementListingRequestFactory from "../common/taxi-order-settlement-listing-request.factory";
import { useAppContext } from "../../../../../context/app.context";
import TaxiOrderSettlementListingReportResponse, {
  TaxiOrderSettlementListingReportResponseData,
} from "../common/api/taxi-order-settlement-listing-report.response";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../../common/components/button/button.component";
import taxiOrderSettlementListingReportRequestFactory from "../common/taxi-order-settlement-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import TaxiOrderSettlementListingStatsSummaryComponent from "../common/stats-summary/taxi-order-settlement-listing-stats-summary.component";
import TaxiOrderSettlementListingStatsSummary from "../common/types/taxi-order-settlement-listing-stats-summary";

type TaxiOrderSettlementListingByRailyProps =
  TaxiOrderSettlementListingViewBasicProps;

const TaxiOrderSettlementListingByRailyComponent: FC<
  TaxiOrderSettlementListingByRailyProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage } = useAppContext();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiOrderSettlementListingByRailyRouteQueryParams>();

  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations();

  const [filters, setFilters] = useState<TaxiOrderSettlementListingFilter[]>(
    () =>
      taxiOrderSettlementListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiOrderSettlementListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<TaxiOrderSettlementListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiOrderSettlementListingSortKey | null>(
      () =>
        taxiOrderSettlementListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        taxiOrderSettlementListingByRailyRouteQueryParamsService.getTaxiCorporationCompanyUuid(
          routeQueryParams
        ) ?? null
    );

  const onTaxiCorporationChange = (
    selectedOption: TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const [isTaxiCorporationFetching, setIsTaxiCorporationFetching] =
    useState(false);
  const [isTaxiCorporationFetchingError, setIsTaxiCorporationFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<TaxiOrderSettlementListingByRailyTaxiCorporationSelectOption[]>(
      []
    );

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const sortSelectOptions: TaxiOrderSettlementListingSortSelectOption[] =
    useMemo(
      () => taxiOrderSettlementListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiOrderSettlementListingByRailyRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiOrderSettlementListingByRailyRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: TaxiOrderSettlementListingByRailyTaxiCorporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      taxiOrderSettlementListingByRailyFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationFetchingError(true);
  };

  const handleTaxiCorporationsResponse = (
    response: TaxiOrderSettlementListingByRailyTaxiCorporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationFetching(true);
    setIsTaxiCorporationFetchingError(false);

    taxiOrderSettlementListingByRailyApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationsResponse)
      .catch(onTaxiCorporationFetchFailure)
      .finally(() => setIsTaxiCorporationFetching(false));
  }, []);

  const onListingFetchSuccess = (
    response: TaxiOrderSettlementListingResponse
  ) => {
    const listingItems =
      taxiOrderSettlementListingByRailyFactory.createListingItems(
        response.data.data
      );

    const statsSummary =
      taxiOrderSettlementListingByRailyFactory.createStatsSummary(
        response.data.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiOrderSettlementListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = (signal: AbortSignal) => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = taxiOrderSettlementListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    taxiOrderSettlementListingByRailyApiService
      .fetchListing(selectedTaxiCorporationUuid!, request, signal)
      .then((data) => {
        if (!signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchListing(signal);
    return () => {
      abortController.abort();
    };
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiOrderSettlementListingByRailyRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [selectedTaxiCorporationUuid, filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: TaxiOrderSettlementListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiOrderSettlementListingOrderStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiOrderSettlementListingOrderStartDateFilter = {
      type: TaxiOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiOrderSettlementListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: TaxiOrderSettlementListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: TaxiOrderSettlementListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request =
      taxiOrderSettlementListingReportRequestFactory.createRequest(
        filters,
        selectedSortKey
      );

    taxiOrderSettlementListingByRailyApiService
      .fetchReport(selectedTaxiCorporationUuid!, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      data-test-id="taxi-order-settlement-listing-by-raily-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!selectedTaxiCorporationUuid;
  const isListingContentVisible = !!selectedTaxiCorporationUuid;

  return (
    <div className="taxi_order_settlements_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <TaxiOrderSettlementListingStatusLegendComponent
        taxiCorporationUuid={selectedTaxiCorporationUuid}
      />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "taxi_order_settlements_listing_taxi_corporation_select",
          }}
          idForTesting={`taxi-corporation-select`}
          isLoading={isTaxiCorporationFetching}
          isDisabled={isTaxiCorporationFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="taxi_order_settlements_listing_tools">
            <div className="d-flex">
              <TaxiOrderSettlementListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="taxi-order-settlement-listing-by-raily-date-range"
              />
            </div>
            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="taxi-order-settlement-listing-by-raily-sort"
            />
          </div>
          <TaxiOrderSettlementListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          {!!listingItems.length && (
            <TaxiOrderSettlementListingStatsSummaryComponent
              isError={isListingFetchingError}
              isLoading={isListingFetching}
              statsSummary={statsSummary}
            />
          )}
          <CardComponent classNames={{ root: "mt-4" }}>
            <TaxiOrderSettlementListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="taxi_order_settlements_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default TaxiOrderSettlementListingByRailyComponent;
