import ListingFilterBadge from "../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../common/utils/date/date.service";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteFinishedListingByRailyFilter, {
  RouteFinishedListingByRailyDriverFilter,
  RouteFinishedListingByRailyEndDateFilter,
  RouteFinishedListingByRailyPassengersFilter,
  RouteFinishedListingByRailyRouteIntermediateAddressFilter,
  RouteFinishedListingByRailyRouteIdFilter,
  RouteFinishedListingByRailyStartDateFilter,
  RouteFinishedListingByRailyTaxiCorporationFilter,
  RouteFinishedListingByRailyRouteDestinationAddressFilter,
  RouteFinishedListingByRailyRoutePickupAddressFilter,
} from "../../common/types/route-finished-listing-by-raily-filter";
import RouteFinishedListingByRailyFilterType from "../../common/types/route-finished-listing-by-raily-filter-type";

const getBadgeOptions = (filter: RouteFinishedListingByRailyFilter) => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: RouteFinishedListingByRailyFilter;
  }[] = [
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyDriverFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.DRIVER,
        value: filter.value as RouteFinishedListingByRailyDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.endDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyEndDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyEndDateFilter["value"]
              ).to
            )
          ),
        title: translations.endDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyEndDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyEndDateFilter["value"]
              ).to
            )
          ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.END_DATE,
        value:
          filter.value as RouteFinishedListingByRailyEndDateFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyPassengersFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyPassengersFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.PASSENGERS,
        value:
          filter.value as RouteFinishedListingByRailyPassengersFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(
            filter.value as RouteFinishedListingByRailyRouteIdFilter["value"]
          )
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as RouteFinishedListingByRailyRouteIdFilter["value"]
          )
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.ROUTE_ID,
        value:
          filter.value as RouteFinishedListingByRailyRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as RouteFinishedListingByRailyRouteIntermediateAddressFilter["value"],
      },
    },

    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as RouteFinishedListingByRailyRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as RouteFinishedListingByRailyRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.startDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyStartDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyStartDateFilter["value"]
              ).to
            )
          ),
        title: translations.startDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyStartDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByRailyStartDateFilter["value"]
              ).to
            )
          ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.START_DATE,
        value:
          filter.value as RouteFinishedListingByRailyStartDateFilter["value"],
      },
    },
    {
      badge: {
        text: translations.taxiCorporationFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyTaxiCorporationFilter["value"]
        ),
        title: translations.taxiCorporationFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByRailyTaxiCorporationFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByRailyFilterType.TAXI_CORPORATION,
        value:
          filter.value as RouteFinishedListingByRailyTaxiCorporationFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: RouteFinishedListingByRailyFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: RouteFinishedListingByRailyFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const routeFinishedListingByRailyFiltersBadgeListHelper = {
  getBadges,
};

export default routeFinishedListingByRailyFiltersBadgeListHelper;
