enum StatusLegendStatuses {
  PLANNED = "PLANNED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  CANDIDATURE = "CANDIDATURE",
  FINISHED = "FINISHED",
  CANCELLED = "CANCELLED",
  CREATED = "CREATED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REOPENED = "REOPENED",
  REOPEN_REQUEST = "REOPEN_REQUEST",
  REOPEN_REQUEST_SENT = "REOPEN_REQUEST_SENT",
  CURRENT_PLAN = "CURRENT_PLAN",
  ORDER = "ORDER",
  POTENTIAL_PLAN = "POTENTIAL_PLAN",
  PROPOSED_POTENTIAL_PLAN = "PROPOSED_POTENTIAL_PLAN",
  CREATED_TOTAL = "TOTAL",
}

export default StatusLegendStatuses;
