import { RouteActiveListingResponseItem } from "../../../common/api/route-active-listing.response";
import RouteActiveListingByRailyListingItem from "../types/route-active-listing-by-raily-item";

const createListingItem = (
  responseItem: RouteActiveListingResponseItem
): RouteActiveListingByRailyListingItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driver_name,
    endDate: new Date(responseItem.end_time),
    routeAddresses: [
      responseItem.starting_node_label,
      ...responseItem.plan_entry_nodes,
      responseItem.ending_node_label,
    ],
    startDate: new Date(responseItem.start_time),
    passengers: responseItem.involved_cargo_labels,
    routeId: responseItem.human_id,
    taxiCorporationName: responseItem.taxi_name,
  };
};

const createListingItems = (
  responseData: RouteActiveListingResponseItem[]
): RouteActiveListingByRailyListingItem[] => {
  return responseData.map(createListingItem);
};

const routeActiveListingByRailyFactory = {
  createListingItems,
};

export default routeActiveListingByRailyFactory;
