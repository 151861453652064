import { FC } from "react";
import TableComponent from "../../../../../common/components/table/table.component";
import TaxiTaxiContractListingTableColumn from "../common/types/taxi-taxi-contract-listing-table-column";
import TaxiTaxiContractListingTableRow from "../common/types/taxi-taxi-contract-listing-table-row";
import taxiTaxiContractListingTableHelper from "./taxi-taxi-contract-listing-table.helper";
import dateService from "../../../../../common/utils/date/date.service";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import taxiTranslationsHelper from "../../../../../languages/taxi-translations.helper";
import taxiRoutesHelper from "../../../common/routes/taxi-routes.helper";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import TableLinkButtonComponent from "../../../../../common/components/table/button/link/table-link-button.component";
import useTaxiTaxiContractListingUserPermissions from "../common/user-permissions/use-taxi-taxi-contract-listing-user-permissions";
import TaxiTaxiContractListingTablePartnerComponent from "./partner/taxi-taxi-contract-listing-table-partner.component";
import { TaxiTaxiContractListItem } from "../../../../../common/services/taxi-taxi-contract/list/taxi-taxi-contract-list";

type TaxiTaxiContractListingTableProps = {
  isError: boolean;
  isLoading: boolean;
  listingItems: TaxiTaxiContractListItem[];
  taxiCorporationUuid: string | undefined;
};

const TaxiTaxiContractListingTableComponent: FC<
  TaxiTaxiContractListingTableProps
> = (props) => {
  const userPermissions = useTaxiTaxiContractListingUserPermissions();

  const translations =
    taxiTranslationsHelper.getTaxiContractListingTranslations().table;

  const columns: TaxiTaxiContractListingTableColumn[] =
    taxiTaxiContractListingTableHelper.getColumns();

  const createTableRow = (
    listingItem: TaxiTaxiContractListItem
  ): TaxiTaxiContractListingTableRow => {
    const activityStatusText =
      taxiTaxiContractListingTableHelper.getActivityStatusLabel(
        listingItem.isActive
      );

    const periodOfValidityLabel = `${dateService.formatDate(
      listingItem.periodOfValidity.from
    )} - ${dateService.formatDate(listingItem.periodOfValidity.to)}`;

    return {
      id: listingItem.uuid,
      idForTesting: listingItem.uuid,
      value: {
        activityStatus: (
          <div title={activityStatusText}>
            {listingItem.isActive ? (
              <FontAwesomeIcon icon={faCheck} size="sm" />
            ) : (
              <FontAwesomeIcon icon={faTimes} size="sm" />
            )}
          </div>
        ),
        billingModel: (
          <div title={listingItem.billingModel}>{listingItem.billingModel}</div>
        ),
        sourceTaxiCorporation: (
          <div title={listingItem.producerTaxi.name}>
            {listingItem.producerTaxi.name}
          </div>
        ),
        targetTaxiCorporation: (
          <div title={listingItem.consumerTaxi.name}>
            {listingItem.consumerTaxi.name}
          </div>
        ),
        displayName: (
          <div title={listingItem.displayName ?? ""}>
            {listingItem.displayName}
          </div>
        ),
        distanceRate: (
          <div
            title={translations.items.distanceRateValueLabel.replace(
              "#{rate}",
              listingItem.distanceRate.toFixed(2)
            )}
          >
            {translations.items.distanceRateValueLabel.replace(
              "#{rate}",
              listingItem.distanceRate.toFixed(2)
            )}
          </div>
        ),
        freeStoppingPeriodMinutes: (
          <div
            title={translations.items.freeStoppingPeriodValueLabel.replace(
              "#{time}",
              listingItem.freeStoppingPeriodMinutes.toString()
            )}
          >
            {translations.items.freeStoppingPeriodValueLabel.replace(
              "#{time}",
              listingItem.freeStoppingPeriodMinutes.toString()
            )}
          </div>
        ),
        priority: (
          <div title={listingItem.priority.toString()}>
            {listingItem.priority}
          </div>
        ),
        stoppingRate: (
          <div
            title={translations.items.stoppingRateValueLabel.replace(
              "#{rate}",
              listingItem.stoppingRate.toFixed(2)
            )}
          >
            {translations.items.stoppingRateValueLabel.replace(
              "#{rate}",
              listingItem.stoppingRate.toFixed(2)
            )}
          </div>
        ),
        periodOfValidity: (
          <div title={periodOfValidityLabel}>{periodOfValidityLabel}</div>
        ),
        partner: (
          <div title={translations.headers.isPartnerTitle}>
            <TaxiTaxiContractListingTablePartnerComponent
              isPartner={listingItem.isPartner}
            />
          </div>
        ),
        actions: (
          <div className="d-flex">
            {userPermissions.hasAccessToEditTaxiContract && (
              <TableLinkButtonComponent
                icon={faEdit}
                to={taxiRoutesHelper.getTaxiContractEditRoute({
                  contractUuid: listingItem.uuid!,
                  taxiCorporationUuid: props.taxiCorporationUuid!,
                })}
                title={translations.actions.contractEditButtonLabel}
                idForTesting={`taxi-taxi-contract-listing-item-${listingItem.uuid}-edit-button`}
              />
            )}
          </div>
        ),
      },
    };
  };

  const rows: TaxiTaxiContractListingTableRow[] = props.listingItems.map(
    (listingItem) => createTableRow(listingItem)
  );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default TaxiTaxiContractListingTableComponent;
