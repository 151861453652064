import appConfig from "../../../../../../app.config";
import authService from "../../../../../auth/common/auth.service";
import OrderDetailsChangesApprovalDealerApproveResponse from "./order-details-changes-approval-dealer-approve.response";
import OrderDetailsChangesApprovalDispatcherApproveResponse from "./order-details-changes-approval-dispatcher-approve.response";
import OrderDetailsChangesApprovalOperatorApproveResponse from "./order-details-changes-approval-operator-approve.response";

const fetchApproveChangesAsDealer = (
  orderUuid: string
): Promise<OrderDetailsChangesApprovalDealerApproveResponse> => {
  const path = `/orders/${orderUuid}/dealer-approval`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchApproveChangesAsDispatcher = (
  orderUuid: string
): Promise<OrderDetailsChangesApprovalDispatcherApproveResponse> => {
  const path = `/orders/${orderUuid}/dispatch-approval`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const fetchApproveChangesAsOperator = (
  orderUuid: string
): Promise<OrderDetailsChangesApprovalOperatorApproveResponse> => {
  const path = `/orders/${orderUuid}/operational-approval`;
  const url = `${appConfig.baseApiUrl}${path}`;
  const authToken = authService.getAccessToken();
  const init: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  return fetch(url, init).then(async (r) => {
    const responseData = await r.json();
    return {
      status: r.status,
      data: responseData,
    };
  });
};

const orderDetailsChangesApprovalApiService = {
  fetchApproveChangesAsDealer,
  fetchApproveChangesAsDispatcher,
  fetchApproveChangesAsOperator,
};

export default orderDetailsChangesApprovalApiService;
