import { RouteFinishedListingResponseItem } from "../../common/api/route-finished-listing.response";
import RouteFinishedListingByRailyListingItem from "./types/route-finished-listing-by-raily-item";

const createListingItem = (
  responseItem: RouteFinishedListingResponseItem
): RouteFinishedListingByRailyListingItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driver_name,
    endDate: new Date(responseItem.end_time),
    routeAddresses: [
      responseItem.starting_node_label,
      ...responseItem.plan_entry_nodes,
      responseItem.ending_node_label,
    ],
    startDate: new Date(responseItem.start_time),
    passengers: responseItem.involved_cargo_labels,
    routeId: responseItem.human_id,
    taxiCorporationName: responseItem.taxi_name,
  };
};

const createListingItems = (
  responseData: RouteFinishedListingResponseItem[]
): RouteFinishedListingByRailyListingItem[] => {
  return responseData.map(createListingItem);
};

const routeFinishedListingByRailyFactory = {
  createListingItems,
};

export default routeFinishedListingByRailyFactory;
