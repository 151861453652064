import { FC } from "react";
import BillingFormData from "../types/billing-form.data";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import CardComponent from "../../../../../common/components/card/card.component";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import NumericInputComponent from "../../../../../common/components/form/input/numeric-input/numeric-input.component";
import BillingRouteDetailsFormDataValidationResult from "../../../common/route-details/types/billings-route-details-form-data-validation-result";

type AdditionalOptionsProps = {
  billingData: BillingFormData | undefined;
  onDiscountChanged: (discountValue: number) => void;
  onDiscountBlur: () => void;
  formValidationResults: BillingRouteDetailsFormDataValidationResult;
};

const AdditionalOptionsComponent: FC<AdditionalOptionsProps> = (props) => {
  const onDiscountChanged = (discountValue: number | null) => {
    const value = discountValue ?? 0;
    props.onDiscountChanged(value);
  };
  const translations =
    billingsTranslationsHelper.getCargoTaxiEditBillingsTranslations()
      .additionalOptions;

  return (
    <>
      <CardComponent classNames={{ root: "h-100 " }}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              errorMessage={
                props.formValidationResults.discountValue.errorMessage
              }
              label={translations.discountLabel}
              classNames={{ root: `mt-0` }}
            >
              <NumericInputComponent
                value={props.billingData?.discount ?? 0}
                onChange={onDiscountChanged}
                onBlur={props.onDiscountBlur}
                decimalPrecision={2}
                hasError={
                  !!props.formValidationResults.discountValue.errorMessage
                }
              />
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
    </>
  );
};
export default AdditionalOptionsComponent;
