import Joi from "joi";
import TaxiOrderSettlementListingRouteQueryFilterParams from "./types/taxi-order-settlement-listing-route-query-filter-params";
import TaxiOrderSettlementListingRouteQueryParams from "./types/taxi-order-settlement-listing-route-query-params";
import TaxiOrderSettlementListingSortKey from "./types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingInternalOrderIdFilter,
  TaxiOrderSettlementListingOrderStartDateFilter,
  TaxiOrderSettlementListingBillingStatusFilter,
  TaxiOrderSettlementListingBillingModelFilter,
  TaxiOrderSettlementListingBillingTypeFilter,
  TaxiOrderSettlementListingCargoCompanyFilter,
  TaxiOrderSettlementListingDestinationTaxiFilter,
} from "./types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingFilterType from "./types/taxi-order-settlement-listing-filter-type";
import TaxiOrderSettlementListingItemBillingStatus from "./types/taxi-order-settlement-listing-item-billing-status";
import BillingModel from "../../../common/types/billing-model";
import TaxiOrderSettlementListingItemBillingType from "./types/taxi-order-settlement-listing-item-billing-type";
import taxiOrderSettlementListingFilterHelper from "./taxi-order-settlement-listing-filter.helper";

const getFilters = (
  routeQueryFilterParams: TaxiOrderSettlementListingRouteQueryFilterParams
): TaxiOrderSettlementListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiOrderSettlementListingFilter[] = [];

  if (
    routeQueryFilterParams.orderStartDateFrom &&
    routeQueryFilterParams.orderStartDateTo
  ) {
    const startDateFilter: TaxiOrderSettlementListingOrderStartDateFilter = {
      type: TaxiOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.orderStartDateFrom),
        to: new Date(routeQueryFilterParams.orderStartDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: TaxiOrderSettlementListingInternalOrderIdFilter =
      {
        type: TaxiOrderSettlementListingFilterType.INTERNAL_ORDER_ID,
        value: Number(routeQueryFilterParams.internalOrderId),
      };
    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.billingStatus) {
    const billingStatusFilter: TaxiOrderSettlementListingBillingStatusFilter = {
      type: TaxiOrderSettlementListingFilterType.BILLING_STATUS,
      value: routeQueryFilterParams.billingStatus,
    };
    filters.push(billingStatusFilter);
  }

  if (routeQueryFilterParams.billingModel) {
    const billingModelFilter: TaxiOrderSettlementListingBillingModelFilter = {
      type: TaxiOrderSettlementListingFilterType.BILLING_MODEL,
      value: routeQueryFilterParams.billingModel,
    };
    filters.push(billingModelFilter);
  }

  if (routeQueryFilterParams.billingType) {
    const billingTypeFilter: TaxiOrderSettlementListingBillingTypeFilter = {
      type: TaxiOrderSettlementListingFilterType.BILLING_TYPE,
      value: routeQueryFilterParams.billingType,
    };
    filters.push(billingTypeFilter);
  }

  if (routeQueryFilterParams.cargoCompany) {
    const cargoCompanyFilter: TaxiOrderSettlementListingCargoCompanyFilter = {
      type: TaxiOrderSettlementListingFilterType.CARGO_COMPANY,
      value: routeQueryFilterParams.cargoCompany,
    };
    filters.push(cargoCompanyFilter);
  }

  if (routeQueryFilterParams.destinationTaxi) {
    const destinationTaxiFilter: TaxiOrderSettlementListingDestinationTaxiFilter =
      {
        type: TaxiOrderSettlementListingFilterType.DESTINATION_TAXI,
        value: routeQueryFilterParams.destinationTaxi,
      };
    filters.push(destinationTaxiFilter);
  }

  const updatedFilters =
    taxiOrderSettlementListingFilterHelper.getDefaultFilters(filters);

  return updatedFilters;
};

const getSortKey = (
  routeQueryParams: TaxiOrderSettlementListingRouteQueryParams
): TaxiOrderSettlementListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  filters: TaxiOrderSettlementListingFilter[],
  sortKey: TaxiOrderSettlementListingSortKey | null,
  page: number,
  pageSize: number
): TaxiOrderSettlementListingRouteQueryParams => {
  return {
    page,
    pageSize,
    sort: sortKey ?? undefined,
    orderStartDateFrom: (
      filters.find(
        (filter) =>
          filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.from.toJSON(),
    orderStartDateTo: (
      filters.find(
        (filter) =>
          filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      )?.value as
        | TaxiOrderSettlementListingOrderStartDateFilter["value"]
        | undefined
    )?.to.toJSON(),
    internalOrderId: (
      filters.find(
        (filter) =>
          filter.type === TaxiOrderSettlementListingFilterType.INTERNAL_ORDER_ID
      )?.value as
        | TaxiOrderSettlementListingInternalOrderIdFilter["value"]
        | undefined
    )?.toString(),
    billingModel: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_MODEL
    )?.value as
      | TaxiOrderSettlementListingBillingModelFilter["value"]
      | undefined,
    billingType: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_TYPE
    )?.value as
      | TaxiOrderSettlementListingBillingTypeFilter["value"]
      | undefined,
    billingStatus: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.BILLING_STATUS
    )?.value as
      | TaxiOrderSettlementListingBillingStatusFilter["value"]
      | undefined,
    cargoCompany: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.CARGO_COMPANY
    )?.value as
      | TaxiOrderSettlementListingCargoCompanyFilter["value"]
      | undefined,
    destinationTaxi: filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.DESTINATION_TAXI
    )?.value as
      | TaxiOrderSettlementListingDestinationTaxiFilter["value"]
      | undefined,
  };
};

const getPage = (
  routeQueryFilterParams: TaxiOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiOrderSettlementListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiOrderSettlementListingRouteQueryFilterParams
) => {
  const filterParams: TaxiOrderSettlementListingRouteQueryFilterParams = {
    orderStartDateFrom: routeQueryFilterParams.orderStartDateFrom,
    orderStartDateTo: routeQueryFilterParams.orderStartDateTo,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    cargoCompany: routeQueryFilterParams.cargoCompany,
    billingStatus: routeQueryFilterParams.billingStatus,
    billingModel: routeQueryFilterParams.billingModel,
    billingType: routeQueryFilterParams.billingType,
    destinationTaxi: routeQueryFilterParams.destinationTaxi,
  };

  const validationSchema =
    Joi.object<TaxiOrderSettlementListingRouteQueryFilterParams>({
      orderStartDateFrom: Joi.string(),
      orderStartDateTo: Joi.string(),
      internalOrderId: Joi.number(),
      billingStatus: Joi.valid(
        ...Object.values(TaxiOrderSettlementListingItemBillingStatus)
      ),
      billingType: Joi.valid(
        ...Object.values(TaxiOrderSettlementListingItemBillingType)
      ),
      billingModel: Joi.valid(...Object.values(BillingModel)),
      cargoCompany: Joi.string(),
      destinationTaxi: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiOrderSettlementListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiOrderSettlementListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiOrderSettlementListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiOrderSettlementListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiOrderSettlementListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default taxiOrderSettlementListingRouteQueryParamsService;
