export enum CargoOrderFinishedListItemPublicStatus {
  PUBLIC = "PUBLIC",
  PUBLISHED = "PUBLISHED",
  PRIVATE = "PRIVATE",
}

type CargoOrderFinishedListItemClient = {
  id: string;
  name: string;
};

type CargoOrderFinishedListItemConsumer = {
  id: string;
  name: string;
};

type CargoOrderFinishedListItemProducer = {
  id: string;
  name: string;
};

type CargoOrderFinishedListItemAttentions = {
  newSharedPost: boolean;
  cargoOrderEdit: boolean;
  cargoOrderCancel: boolean;
};

export type CargoOrderFinishedListItem = {
  uuid: string;
  estimatedStartDate: Date;
  internalOrderId: number;
  routeAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: CargoOrderFinishedListItemClient | null;
  consumer: CargoOrderFinishedListItemConsumer | null;
  producer: CargoOrderFinishedListItemProducer | null;
  taxiCorporations: string[];
  drivers: string[];
  isMobileAppUser: boolean;
  externalOrderId: string | null;
  publicStatus: CargoOrderFinishedListItemPublicStatus;
  isCancelled: boolean;
  attentions: CargoOrderFinishedListItemAttentions;
  slkStatus: boolean | null;
};

type CargoOrderFinishedList = {
  data: CargoOrderFinishedListItem[];
  totalCount: number;
};

export default CargoOrderFinishedList;
