import DateRange from "../../../../../../common/types/date-range";
import listingFilterService from "../../../../../../common/utils/listing/filters/listing-filter.service";
import RouteActiveListingByTaxiFilter, {
  RouteActiveListingByTaxiDriverFilter,
  RouteActiveListingByTaxiEndDateFilter,
  RouteActiveListingByTaxiPassengersFilter,
  RouteActiveListingByTaxiRouteAddressFilter,
  RouteActiveListingByTaxiRouteIdFilter,
  RouteActiveListingByTaxiStartDateFilter,
} from "../types/route-active-listing-by-taxi-filter";
import RouteActiveListingByTaxiFilterType from "../types/route-active-listing-by-taxi-filter-type";
import RouteActiveListingByTaxiListingItem from "../types/route-active-listing-by-taxi-item";

const filterListingItemsByDriver = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "driverName",
    queries
  );
};

const filterListingItemsByRouteAddresses = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "routeAddresses",
    queries
  );
};

const filterListingItemsByStartDate = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  dateRange: DateRange
) => {
  return listingItems.filter(
    (listingItem) =>
      listingItem.startDate.getTime() >= dateRange.from.getTime() &&
      listingItem.startDate.getTime() <= dateRange.to.getTime()
  );
};

const filterListingItemsByEndDate = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  dateRange: DateRange
) => {
  return listingItems.filter(
    (listingItem) =>
      listingItem.endDate.getTime() >= dateRange.from.getTime() &&
      listingItem.endDate.getTime() <= dateRange.to.getTime()
  );
};

const filterListingItemsByRouteId = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  queries: number[]
) => {
  return listingFilterService.filterByNumberProperty(
    listingItems,
    "routeId",
    queries
  );
};

const filterListingItemsByPassenger = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "passengers",
    queries
  );
};

const filterListingItems = (
  listingItems: RouteActiveListingByTaxiListingItem[],
  filters: RouteActiveListingByTaxiFilter[]
): RouteActiveListingByTaxiListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: RouteActiveListingByTaxiListingItem[] = [
    ...listingItems,
  ];

  const driverFilterQueries = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByTaxiFilterType,
    RouteActiveListingByTaxiDriverFilter["value"]
  >(filters, RouteActiveListingByTaxiFilterType.DRIVER);

  if (driverFilterQueries.length) {
    filteredListingItems = filterListingItemsByDriver(
      filteredListingItems,
      driverFilterQueries
    );
  }

  const passengerFilterQueries =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingByTaxiFilterType,
      RouteActiveListingByTaxiPassengersFilter["value"]
    >(filters, RouteActiveListingByTaxiFilterType.PASSENGERS);

  if (passengerFilterQueries.length) {
    filteredListingItems = filterListingItemsByPassenger(
      filteredListingItems,
      passengerFilterQueries
    );
  }

  const routeAddressFilterQueries =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingByTaxiFilterType,
      RouteActiveListingByTaxiRouteAddressFilter["value"]
    >(filters, RouteActiveListingByTaxiFilterType.ROUTE_ADDRESS);

  if (routeAddressFilterQueries.length) {
    filteredListingItems = filterListingItemsByRouteAddresses(
      filteredListingItems,
      routeAddressFilterQueries
    );
  }

  const routeIdFilterQueries = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByTaxiFilterType,
    RouteActiveListingByTaxiRouteIdFilter["value"]
  >(filters, RouteActiveListingByTaxiFilterType.ROUTE_ID);

  if (routeIdFilterQueries.length) {
    filteredListingItems = filterListingItemsByRouteId(
      filteredListingItems,
      routeIdFilterQueries
    );
  }

  const startDateFilterRanges = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByTaxiFilterType,
    RouteActiveListingByTaxiStartDateFilter["value"]
  >(filters, RouteActiveListingByTaxiFilterType.START_DATE);

  if (startDateFilterRanges.length) {
    filteredListingItems = filterListingItemsByStartDate(
      filteredListingItems,
      startDateFilterRanges[0]
    );
  }

  const endDateFilterRanges = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByTaxiFilterType,
    RouteActiveListingByTaxiEndDateFilter["value"]
  >(filters, RouteActiveListingByTaxiFilterType.END_DATE);

  if (endDateFilterRanges.length) {
    filteredListingItems = filterListingItemsByEndDate(
      filteredListingItems,
      endDateFilterRanges[0]
    );
  }

  return filteredListingItems;
};

const routeActiveListingByTaxiFilterService = {
  filterListingItems,
};

export default routeActiveListingByTaxiFilterService;
