import {
  TaxiOrderSettlementListingResponseDataItem,
  TaxiOrderSettlementListingResponseDataStats,
} from "./api/taxi-order-settlement-listing.response";
import TaxiOrderSettlementListingItem from "./types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingItemBillingStatus from "./types/taxi-order-settlement-listing-item-billing-status";
import TaxiOrderSettlementListingStatsSummary from "./types/taxi-order-settlement-listing-stats-summary";

const createListingItemStatus = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return TaxiOrderSettlementListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return TaxiOrderSettlementListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return TaxiOrderSettlementListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return TaxiOrderSettlementListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return TaxiOrderSettlementListingItemBillingStatus.REOPEN_REQUEST_SENT;
    default:
      return undefined;
  }
};

const createListingItemBonus = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItem["bonus"] => {
  if (responseDataItem.bonus && responseDataItem.bonus_type) {
    return {
      cost: responseDataItem.bonus,
      reason: responseDataItem.bonus_type,
    };
  }

  return null;
};

const createListingItemPenalty = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItem["penalty"] => {
  if (responseDataItem.penalty && responseDataItem.penalty_type) {
    return {
      cost: responseDataItem.penalty,
      reason: responseDataItem.penalty_type,
    };
  }

  return null;
};

const createListingItem = (
  responseDataItem: TaxiOrderSettlementListingResponseDataItem
): TaxiOrderSettlementListingItem => {
  return {
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.human_id,
    externalOrderId: responseDataItem.external_id,
    orderStartDate: new Date(responseDataItem.date),
    cargoCompanyName: responseDataItem.cargo_company,
    billingModel: responseDataItem.billing_model,
    billingType: responseDataItem.billing_type,
    status: createListingItemStatus(responseDataItem),
    distance: responseDataItem.distance,
    distanceCost: responseDataItem.distance_amount,
    distanceRate: responseDataItem.distance_rate,
    stopoverCost: responseDataItem.halting_amount ?? 0,
    tollRoadsCost: responseDataItem.highway_charge ?? 0,
    discountAmountPercent: responseDataItem.discount ?? 0,
    discountCost: responseDataItem.discount_amount ?? 0,
    totalCost: responseDataItem.total_amount,
    bonus: createListingItemBonus(responseDataItem),
    penalty: createListingItemPenalty(responseDataItem),
    discountSavings: responseDataItem.discount_amount ?? 0,
    passengers: responseDataItem.passengers,
    destinationTaxi: responseDataItem.responsible_taxi ?? "",
  };
};

const createListingItems = (
  responseDataItems: TaxiOrderSettlementListingResponseDataItem[]
): TaxiOrderSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: TaxiOrderSettlementListingResponseDataStats
): TaxiOrderSettlementListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    haltingCost: responseStatsSummary.halting_amount,
    highwayCost: responseStatsSummary.highway_charge,
    distanceCost: responseStatsSummary.distance_amount,
    discount: responseStatsSummary.discount_amount,
    bonus: responseStatsSummary.bonus_amount,
    penalty: responseStatsSummary.penalty_amount,
    total: responseStatsSummary.total_amount,
  };
};

const taxiOrderSettlementListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default taxiOrderSettlementListingFactory;
