import googleMapsService from "../../../../../common/services/google-maps/google-maps.service";
import BillingModel from "../../../types/billing-model";
import { BillingData } from "../api/billing-data.response";
import BillingFormData from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((x, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsService.buildRouteUrl(
            { latitute: previousItem.lat, longitude: previousItem.lon },
            { latitute: x.lat, longitude: x.lon }
          )
        : "";

    const result: BillingsNode = {
      id: x.id,
      nodeId: x.node_id,
      lat: x.lat,
      lon: x.lon,
      description: x.description,
      checkoutDate: x.checkout_date ? new Date(x.checkout_date) : null,
      plannedDate: x.planned_date ? new Date(x.planned_date) : null,
      plannedDistance: x.planned_distance,
      orderId: x.order_id,
      distance: x.distance,
      haltingTime: x.halting_time,
      haltingAmount: x.halting_amount,
      highwayCharge: x.highway_charge,
      isHighwayAllowed: x.allow_charge,
      isEditable: x.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingData = (data: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(data),
    contractDetails: {
      companyName: data.contract.cargo_company.company_name,
      discountLimit: data.contract.discount_limit,
      haltingTimeRate: data.contract.halting_time_rate,
      distanceRate: data.contract.distance_rate,
      isHighwayAllowedDuringRide: data.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        data.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        data.contract.allow_charge_while_returning,
      model: data.contract.model as BillingModel,
      haltingTimeAppliedAfter: data.contract.halting_time_after_minutes,
    },
    baseAmount: data.base_amount,
    discountAmount: data.discount_amount,
    haltingCost: data.halting_amount,
    highwayCharge: data.highway_charge,
    distance: data.distance,
    routeDistanceCost: data.distance_amount,
    internalOrderId: data.cargo_order.human_id,
    discount: data.discount,
    total: data.total_amount,
    planEntryId: data.plan_entry_id,
    cargoOrderId: data.cargo_order.id,
  };

  return billingDraft;
};

const billingDataFactory = {
  createBillingData,
};

export default billingDataFactory;
