import { FC, useEffect, useMemo, useState } from "react";
import BillingFormData from "./types/billing-form.data";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingApiService from "./api/billings-taxi-driver-api.service";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingDataResponse from "./api/billing-data.response";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingsApiService from "./api/billings-api.service";
import BillingGpsResponse from "./api/billing-gps-data.response";
import MapRoute from "../../../../common/components/map/types/map-route";
import MapMarker from "../../../../common/components/map/types/map-marker";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MapComponent from "../../../../common/components/map/map.component";
import CardComponent from "../../../../common/components/card/card.component";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import { useAppContext } from "../../../../context/app.context";
import { useParams } from "react-router-dom";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingTaxiDriverDetailsRouteParams from "../../common/routes/types/billing-taxi-driver-details-route-params";
import RelatedBillingsCargoTaxiComponent from "../common/related-billings/cargo-taxi/related-billings-cargo-taxi.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import BillingsRouteDetailsOverviewComponent from "../../common/route-details/billings-route-details-overview.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsTaxiDriverHelper from "./billings-taxi-driver.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";

type BillingComponentProps = {};

const BillingsTaxiDriverViewComponent: FC<BillingComponentProps> = () => {
  const { billingUuid } = useParams<BillingTaxiDriverDetailsRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [routeId, setRouteId] = useState("");
  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [planEntryUuid, setPlanEntryUuid] = useState("");

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithDriverView.replace("#{routeId}", routeId);

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiWithDriverViewBreadcrumbs({
        billingUuid: billingUuid!,
        routeId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, routeId]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  };

  useEffect(() => {
    if (!planEntryUuid) {
      return;
    }

    billingsApiService.fetchGpsData(planEntryUuid).then(handleGpsDataResponse);
  }, [planEntryUuid]);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setPlanEntryUuid(billingData.planEntryId);
    setShouldRetrieveRouteData(true);
    setRouteId(String(billingData.internalOrderId));
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const { user } = useAppContext();

  const translations =
    billingsTranslationsHelper.getTaxiDriverViewBillingsTranslations();

  const messengerChannelsAvailability =
    billingsTaxiDriverHelper.getMessengerChannelAvailability();

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace("#{routeId}", routeId)}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsOverviewComponent
                      billingNodes={billingData?.billingNodes}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent billingData={billingData} />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingDetailsComponent billingData={billingData} />
              </Column>
              <Column withPaddings>
                <RelatedBillingsCargoTaxiComponent
                  planEntryId={planEntryUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent planEntryId={planEntryUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
              {!!planEntryUuid.length && (
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  planEntryUuid={planEntryUuid}
                />
              )}
            </CardComponent>
          </Column>
        </Row>
      </div>
    </>
  );
};

export default BillingsTaxiDriverViewComponent;
