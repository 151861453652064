import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type RouteActiveListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type RouteActiveListingUserPermissionsDefinition =
  UserPermissionsDefinition<RouteActiveListingUserPermissionOption>;

export type RouteActiveListingUserPermissions =
  UserPermissions<RouteActiveListingUserPermissionOption>;

const routeActiveListingUserPermissionDefinition: RouteActiveListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.DEALER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.OPERATOR],
  };

export default routeActiveListingUserPermissionDefinition;
