import classNames from "classnames";
import { FC } from "react";
import { SteppedLineTo } from "react-lineto";
import orderTranslationsHelper from "../../../../languages/order-translations.helper";
import OrderDetailsPassenger from "../common/types/order-details-passenger";
import OrderDetailsRouteWaypoint from "../common/types/order-details-route-waypoint";
import orderDetailsRouteHelper from "./order-details-route.helper";

type OrderDetailsRouteProps = {
  waypoints: OrderDetailsRouteWaypoint[];
  orderUuid: string;
  selectedPassenger: OrderDetailsPassenger | null;
};

const OrderDetailsRouteComponent: FC<OrderDetailsRouteProps> = (props) => {
  const translations = orderTranslationsHelper.getDetailsTranslations().routes;

  const lineConnections =
    orderDetailsRouteHelper.getDefinitionOfLineConnectionsBetweenWaypointsInList(
      props.waypoints,
      props.orderUuid
    );

  return (
    <div className="order_details_routes">
      <div>{translations.headingText}</div>
      <ul className="order_details_routes_list">
        <li className="order_details_route">
          <div className="place">{translations.placeHeaderLabel}</div>
          <div
            className="ordered_date"
            title={translations.orderedDateHeaderTitle}
          >
            {translations.orderedDateHeaderLabel}
          </div>
          <div
            className="estimated_date"
            title={translations.estimatedDateHeaderTitle}
          >
            {translations.estimatedDateHeaderLabel}
          </div>
          <div
            className="halting_date"
            title={translations.haltingDateHeaderTitle}
          >
            {translations.haltingDateHeaderLabel}
          </div>
          <div className="distance" title={translations.distanceHeaderTitle}>
            {translations.distanceHeaderLabel}
          </div>
          <div
            className="completion_date"
            title={translations.completionDateTitle}
          >
            {translations.completionDateLabel}
          </div>
        </li>
        {props.waypoints.map((waypoint, index) => {
          const isHighlightedAsPassengerOnboardPlace =
            props.selectedPassenger &&
            waypoint.onboardingPassengersUuids.includes(
              props.selectedPassenger?.uuid
            );
          const isHighlightedAsPassengerOutboardPlace =
            props.selectedPassenger &&
            waypoint.outboardingPassengersUuids.includes(
              props.selectedPassenger?.uuid
            );
          const isFaded =
            props.selectedPassenger &&
            !isHighlightedAsPassengerOnboardPlace &&
            !isHighlightedAsPassengerOutboardPlace;

          return (
            <li
              key={`waypoint-${waypoint.place.latitude}${waypoint.place.longitude}-index-${index}`}
              className={classNames(
                "order_details_route",
                `order-uuid-${props.orderUuid}`,
                isHighlightedAsPassengerOnboardPlace && "onboard_highlight",
                isHighlightedAsPassengerOutboardPlace && "outboard_highlight",
                isFaded && "faded"
              )}
            >
              <div className="place" title={waypoint.place.displayName}>
                <div className="index">{waypoint.markerPosition}</div>
                <div
                  className={classNames(
                    "dot",
                    orderDetailsRouteHelper.generateWaypointDotClassname(
                      waypoint,
                      props.orderUuid
                    )
                  )}
                ></div>
                <div className="place_name">{waypoint.place.displayName}</div>
              </div>
              <div
                className="ordered_date"
                title={orderDetailsRouteHelper.getOrderedDateTitle(
                  waypoint.orderedDate
                )}
              >
                {orderDetailsRouteHelper.getOrderedDateLabel(
                  waypoint.orderedDate
                )}
              </div>
              <div
                className="estimated_date"
                title={orderDetailsRouteHelper.getEstimatedDateTitle(
                  waypoint.estimatedDate
                )}
              >
                {orderDetailsRouteHelper.getEstimatedDateLabel(
                  waypoint.estimatedDate
                )}
              </div>
              <div className="halting_date">
                {orderDetailsRouteHelper.getHaltingTimeLabel(
                  waypoint.haltingTime
                )}
              </div>
              <div className="distance">
                {orderDetailsRouteHelper.getDistanceLabel(waypoint.distance)}
              </div>
              <div
                className="completion_date"
                title={orderDetailsRouteHelper.getCompletionDateTitle(
                  waypoint.completionDate
                )}
              >
                {orderDetailsRouteHelper.getCompletionDateLabel(
                  waypoint.completionDate
                )}
              </div>
            </li>
          );
        })}
      </ul>
      {lineConnections.map((item, index) => {
        return (
          <SteppedLineTo
            key={`waypoint-list-connection-line-index-${index}`}
            from={item.itemFromClassname}
            to={item.itemToClassname}
            delay={50}
            borderStyle={item.lineType}
            borderWidth={1}
            borderColor={"#000"}
            within={`order_details_route order-uuid-${props.orderUuid}`}
            className="order_details_route__connection_line"
          />
        );
      })}
    </div>
  );
};

export default OrderDetailsRouteComponent;
