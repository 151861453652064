import { SearchRoadRoutingRequestWaypointCoordinate } from "../../../common/utils/search-road-route/search-road-routing.request";
import orderTranslationsHelper from "../../../languages/order-translations.helper";
import {
  OrderJoinPreviewResponseDataRideSeqItem,
  OrderJoinPreviewResponseRideWaypoint,
  OrderJoinOrderDetailsResponseTransportingOrder,
} from "./common/api/order-join-order-details.response";
import OrderJoinOrderDetailsConnectedWaypoints from "./common/types/order-join-order-details-connected-waypoints";
import OrderJoinOrderDetailsDriver from "./common/types/order-join-order-details-driver";
import OrderJoinOrderDetailsRouteWaypoint from "./common/types/order-join-order-details-route-waypoint";

const isOrderSolved = (
  responseOrderTransportingOrders: OrderJoinOrderDetailsResponseTransportingOrder[]
) => {
  if (responseOrderTransportingOrders.length === 0) {
    return false;
  }

  const isOrderSolved = responseOrderTransportingOrders.every(
    (item) => item.solved_order
  );

  return isOrderSolved;
};

const isResponseRideSeqItemAWaypoint = (
  responseRideSeqItem: OrderJoinPreviewResponseDataRideSeqItem
): responseRideSeqItem is OrderJoinPreviewResponseRideWaypoint => {
  if ((responseRideSeqItem as any).lat) {
    return true;
  }

  return false;
};

const isRouteSequenceSolved = (
  waypoints: OrderJoinOrderDetailsRouteWaypoint[]
): boolean => {
  const waypointsStageNoArray = waypoints.map((waypoint) => waypoint.stageNo);

  return !waypointsStageNoArray.some((stageNo, index) => {
    return waypointsStageNoArray.indexOf(stageNo) != index;
  });
};

const getWaypointGroupsCouldBeConnected = (
  waypoints: OrderJoinOrderDetailsRouteWaypoint[]
) => {
  let waypointGroups: OrderJoinOrderDetailsConnectedWaypoints[] = [];

  waypoints.forEach((waypoint, index) => {
    const nextWaypoint = waypoints[index + 1];

    if (!nextWaypoint) {
      return;
    }

    const isWaypointAndNextWaypointSameStageNo =
      waypoint.stageNo === nextWaypoint.stageNo;
    const isWaypointAlone =
      waypoints.filter((w) => w.stageNo === waypoint.stageNo).length === 1;
    const isNextWaypointAlone =
      waypoints.filter((w) => w.stageNo === nextWaypoint.stageNo).length === 1;

    if (
      !isWaypointAndNextWaypointSameStageNo &&
      isWaypointAlone &&
      isNextWaypointAlone
    ) {
      waypointGroups.push({ start: waypoint, end: nextWaypoint });
    }
  });

  return waypointGroups;
};

const getCoordinatesOfWaypointsCouldBeConnected = (
  connectedWaypoints: OrderJoinOrderDetailsConnectedWaypoints[]
): SearchRoadRoutingRequestWaypointCoordinate[][] => {
  let coordinatesOfWaypointsCouldBeConnected: SearchRoadRoutingRequestWaypointCoordinate[][] =
    [];

  connectedWaypoints.forEach((connection) => {
    const start = {
      latitude: connection.start.place.latitude,
      longitude: connection.start.place.longitude,
    };

    const end = {
      latitude: connection.end.place.latitude,
      longitude: connection.end.place.longitude,
    };

    const newGroup: SearchRoadRoutingRequestWaypointCoordinate[] = [start, end];

    coordinatesOfWaypointsCouldBeConnected.push(newGroup);
  });

  return coordinatesOfWaypointsCouldBeConnected;
};

const getDriverButtonTitle = (driver: OrderJoinOrderDetailsDriver): string => {
  const translations = orderTranslationsHelper.getDetailsTranslations();

  return `${translations.drivers.driverButtonTitleTemplateText
    .replace("#{driverDisplayName}", driver.displayName)
    .replace("#{phoneNumber}", driver.phoneNumber)}`;
};

const orderJoinHelper = {
  isOrderSolved,
  isRouteSequenceSolved,
  isResponseRideSeqItemAWaypoint,
  getWaypointGroupsCouldBeConnected,
  getCoordinatesOfWaypointsCouldBeConnected,
  getDriverButtonTitle,
};

export default orderJoinHelper;
