import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type OrderActiveListingUserPermissionOption =
  | "hasVisibleOrderAddLink"
  | "hasVisiblePlannerLink"
  | "hasVisibleOrderJoinButton"
  | "hasVisibileToOrderPlanningButton";

export type OrderActiveListingUserPermissionsDefinition =
  UserPermissionsDefinition<OrderActiveListingUserPermissionOption>;

export type OrderActiveListingUserPermissions =
  UserPermissions<OrderActiveListingUserPermissionOption>;

const orderActiveListingUserPermissionDefinition: OrderActiveListingUserPermissionsDefinition =
  {
    hasVisibleOrderAddLink: [
      UserRole.ADMIN,
      UserRole.DISPATCHER,
      UserRole.OPERATOR,
    ],
    hasVisiblePlannerLink: [],
    hasVisibleOrderJoinButton: [UserRole.ADMIN, UserRole.OPERATOR],
    hasVisibileToOrderPlanningButton: [UserRole.ADMIN],
  };

export default orderActiveListingUserPermissionDefinition;
