export type GoogleMapRoutePoint = {
  latitute: number;
  longitude: number;
};

export type GoogleMapRouteWaypoints = [number, number];

const buildRouteUrl = (
  origin: GoogleMapRoutePoint,
  destination: GoogleMapRoutePoint
): string => {
  return `https://www.google.com/maps/dir/?api=1&origin=${origin.latitute},${origin.longitude}&destination=${destination.latitute},${destination.longitude}&travelmode=driving`;
};

const buildRouteUrlWithManyPoints = (
  points: GoogleMapRouteWaypoints[]
): string => {
  const baseURL = "https://www.google.com/maps/dir/";
  const waypoints = points?.map((point) => point.join(",")).join("/");

  return baseURL + waypoints;
};

const googleMapsService = {
  buildRouteUrl,
  buildRouteUrlWithManyPoints,
};

export default googleMapsService;
