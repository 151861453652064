import appConfig from "../../../../../../app.config";
import urlService from "../../../../../../common/utils/url/url.service";
import authService from "../../../../../auth/common/auth.service";
import CargoOrderSettlementListingReportRequest from "./cargo-order-settlement-listing-report.request";
import CargoOrderSettlementListingReportResponse from "./cargo-order-settlement-listing-report.response";
import CargoOrderSettlementListingStatusCountersRequest from "./cargo-order-settlement-listing-status-counters.request";
import CargoOrderSettlementListingStatusCountersResponse from "./cargo-order-settlement-listing-status-counters.response";
import CargoOrderSettlementListingRequest from "./cargo-order-settlement-listing.request";
import CargoOrderSettlementListingResponse from "./cargo-order-settlement-listing.response";

const fetchListing = async (
  cargoCompanyUuid: string,
  request: CargoOrderSettlementListingRequest,
  signal: AbortSignal
): Promise<CargoOrderSettlementListingResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/order-billings`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    signal,
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const fetchReport = async (
  cargoCompanyUuid: string,
  request: CargoOrderSettlementListingReportRequest
): Promise<CargoOrderSettlementListingReportResponse> => {
  const path = `${appConfig.baseApiUrl}/cargo-companies/${cargoCompanyUuid}/order-billings/csv-dump`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "attachment",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
    mode: "cors",
    keepalive: true,
  };

  const response = await fetch(url, init);

  const filename = response.headers
    ?.get("content-disposition")
    ?.split(";")
    .find((n) => n.includes("filename="))
    ?.replace("filename=", "")
    .replace(/"/g, "")
    .trim()!;

  const responseData = await response.blob();

  return {
    status: response.status,
    data: {
      data: responseData,
      filename,
    },
  };
};

const fetchStatusCounters = async (
  request: CargoOrderSettlementListingStatusCountersRequest
): Promise<CargoOrderSettlementListingStatusCountersResponse> => {
  const path = `/meta/billing-cargo-taxi-status-counters`;

  const url = urlService.buildWithoutEmptyParams(
    `${appConfig.baseApiUrl}${path}`,
    request
  );

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const cargoOrderSettlementListingApiService = {
  fetchListing,
  fetchReport,
  fetchStatusCounters,
};

export default cargoOrderSettlementListingApiService;
