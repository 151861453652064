import { FC, useMemo, useState } from "react";
import RouteFinishedListingByRailyFilter from "../../common/types/route-finished-listing-by-raily-filter";
import routeFinishedListingByRailyFilterHelper from "../../common/filter/route-finished-listing-by-raily-filter.helper";
import RouteFinishedListingByRailyFilterSelectOption from "../../common/types/route-finished-listing-by-raily-filter-select-option";
import ListingFilterSelectComponent from "../../../../../../common/components/listing/filter/select/listing-filter-select.component";
import { useAppContext } from "../../../../../../context/app.context";

type RouteFinishedListingByRailyFiltersSelectProps = {
  filters: RouteFinishedListingByRailyFilter[];
  onAddNewFilter: (filter: RouteFinishedListingByRailyFilter) => void;
};

const RouteFinishedListingByRailyFiltersSelectComponent: FC<
  RouteFinishedListingByRailyFiltersSelectProps
> = (props) => {
  const { selectedAppLanguage } = useAppContext();
  const [searchInputValue, setSearchInputValue] = useState("");

  const searchSelectOptions = useMemo(() => {
    return routeFinishedListingByRailyFilterHelper.getSelectOptions(
      searchInputValue,
      props.filters
    );
  }, [searchInputValue, props.filters, selectedAppLanguage]);

  const onFilterSelect = (
    filter: RouteFinishedListingByRailyFilterSelectOption
  ) => {
    props.onAddNewFilter(filter.value);
  };

  return (
    <ListingFilterSelectComponent
      onChange={(option) =>
        onFilterSelect(option as RouteFinishedListingByRailyFilterSelectOption)
      }
      options={searchSelectOptions}
      inputValue={searchInputValue}
      onInputChange={setSearchInputValue}
      idForTesting="route-finished-listing-by-raily-listing-filter"
    />
  );
};

export default RouteFinishedListingByRailyFiltersSelectComponent;
