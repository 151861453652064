export type OrderRouteEditPassengerAddress = {
  uuid: string;
  displayName: string;
  latitude: number;
  longitude: number;
};

export enum OrderRouteEditPassengerType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
}

export type OrderRouteEditExternalPassenger = {
  uuid: string;
  type: OrderRouteEditPassengerType.EXTERNAL;
  firstName: string;
  lastName: string;
  dispatchName: string;
  externalId: string;
  mobile: string;
};

export type OrderRouteEditInternalPassenger = {
  uuid: string;
  type: OrderRouteEditPassengerType.INTERNAL;
  firstName: string;
  lastName: string;
  addresses: OrderRouteEditPassengerAddress[];
  externalId: string;
};

type OrderRouteEditPassenger =
  | OrderRouteEditExternalPassenger
  | OrderRouteEditInternalPassenger;

export default OrderRouteEditPassenger;
