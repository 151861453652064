import CargoOrderDetailsHandlingLogResponse, {
  CargoOrderDetailsHandlingLogCPECandidatureAcceptedResponseItem,
  CargoOrderDetailsHandlingLogCSOCandidatureAcceptedResponseItem,
  CargoOrderDetailsHandlingLogCancelResponseItem,
  CargoOrderDetailsHandlingLogDSOCandidatureAcceptedResponseItem,
  CargoOrderDetailsHandlingLogResponseData,
  CargoOrderDetailsHandlingLogResponseItem,
  CargoOrderDetailsHandlingLogType,
} from "../../../api/raily/cargo-order/details/handling-log/cargo-order-details-handling-log.response";

import CargoOrderDetailsHandlingLog, {
  CargoOrderDetailsHandlingLogItem,
  CargoOrderDetailsHandlingLogData,
  CargoOrderDetailsHandlingLogCancelItem,
  CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem,
  CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem,
  CargoOrderDetailsHandlingLogAssignmentOrderCancelContentItem,
} from "./cargo-order-details-handling-log";

const createDataHandlingLogItem = (
  item: CargoOrderDetailsHandlingLogResponseItem
): CargoOrderDetailsHandlingLogItem => {
  return {
    type: CargoOrderDetailsHandlingLogType.FORWARD,
    contractDisplayName: item.contract.displayName,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    createDate: item.createdAt,
  };
};

const createDataHandlingLogCancelItem = (
  item: CargoOrderDetailsHandlingLogCancelResponseItem
): CargoOrderDetailsHandlingLogCancelItem => {
  return {
    type: CargoOrderDetailsHandlingLogType.FORWARD_CANCEL,
    producerDisplayName: item.producer.displayName,
    consumerDisplayName: item.consumer.displayName,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    createDate: item.createdAt,
  };
};

const createDataHandlingLogCPECandidatureAcceptedItem = (
  item: CargoOrderDetailsHandlingLogCPECandidatureAcceptedResponseItem
): CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_CPE_CANDIDATURE,
    driverName: item.driver.displayName,
    taxiCorporationName: item.taxiCorporation.displayName,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    createDate: item.createdAt,
  };
};

const createDataHandlingLogCSOCandidatureAcceptedItem = (
  item: CargoOrderDetailsHandlingLogCSOCandidatureAcceptedResponseItem
): CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_CSO_CANDIDATURE,
    driverName: item.driver.displayName,
    taxiCorporationName: item.taxiCorporation.displayName,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    createDate: item.createdAt,
    planEntryUuid: item.planEntryHumanId,
  };
};

const createDataHandlingLogDSOCandidatureAcceptedItem = (
  item: CargoOrderDetailsHandlingLogDSOCandidatureAcceptedResponseItem
): CargoOrderDetailsHandlingLogAssignmentOrderCancelContentItem => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_DSO_CANDIDATURE,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    createDate: item.createdAt,
    planEntryUuid: item.planEntryHumanId,
  };
};

const createHandlingLogEvent = (
  item: CargoOrderDetailsHandlingLogResponseData
): CargoOrderDetailsHandlingLogData => {
  switch (item.logType) {
    case CargoOrderDetailsHandlingLogType.FORWARD:
      return createDataHandlingLogItem(
        item as CargoOrderDetailsHandlingLogResponseItem
      );
    case CargoOrderDetailsHandlingLogType.FORWARD_CANCEL:
      return createDataHandlingLogCancelItem(
        item as CargoOrderDetailsHandlingLogCancelResponseItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_CPE_CANDIDATURE:
      return createDataHandlingLogCPECandidatureAcceptedItem(
        item as CargoOrderDetailsHandlingLogCPECandidatureAcceptedResponseItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_CSO_CANDIDATURE:
      return createDataHandlingLogCSOCandidatureAcceptedItem(
        item as CargoOrderDetailsHandlingLogCSOCandidatureAcceptedResponseItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_DSO_CANDIDATURE:
      return createDataHandlingLogDSOCandidatureAcceptedItem(
        item as CargoOrderDetailsHandlingLogDSOCandidatureAcceptedResponseItem
      );
  }
};

const createDataItem = (
  item: CargoOrderDetailsHandlingLogResponseData
): CargoOrderDetailsHandlingLogData => {
  return createHandlingLogEvent(item);
};

const createData = (
  item: CargoOrderDetailsHandlingLogResponseData[]
): CargoOrderDetailsHandlingLogData[] => {
  return item.map(createDataItem);
};

const create = (
  item: CargoOrderDetailsHandlingLogResponse
): CargoOrderDetailsHandlingLog => {
  return {
    data: createData(item.data),
  };
};

const cargoOrderDetailsHandlingLogFactory = {
  create,
};

export default cargoOrderDetailsHandlingLogFactory;
