import { FC } from "react";
import ListingFilterBadgeListComponent from "../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import RouteFinishedListingByTaxiFilter from "../../common/types/route-finished-listing-by-taxi-filter";
import routeFinishedListingByTaxiFiltersBadgeListHelper from "./route-finished-listing-by-taxi-filters-badge-list.helper";

type RouteFinishedListingByRailyFiltersBadgeListProps = {
  filters: RouteFinishedListingByTaxiFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const RouteFinishedListingByTaxiFiltersBadgeListComponent: FC<
  RouteFinishedListingByRailyFiltersBadgeListProps
> = (props) => {
  const badges = routeFinishedListingByTaxiFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default RouteFinishedListingByTaxiFiltersBadgeListComponent;
