import PhoneNumber from "../../../types/phone-number";

export enum CargoOrderDetailsPublicStatus {
  PRIVATE = "PRIVATE",
  PUBLISHED = "PUBLISHED",
  PUBLIC = "PUBLIC",
}

export enum CargoOrderDetailsExecutionStatus {
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  PLANNED = "PLANNED",
  FINISHED = "FINISHED",
}

export enum CargoOrderDetailsTransportingOrderPassengerType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export type CargoOrderDetailsTransportingOrderPassenger =
  | CargoOrderDetailsTransportingOrderInternalPassenger
  | CargoOrderDetailsTransportingOrderExternalPassenger;

export type CargoOrderDetailsTransportingOrderInternalPassenger = {
  type: CargoOrderDetailsTransportingOrderPassengerType.INTERNAL;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
  uuid: string;
};

export type CargoOrderDetailsTransportingOrderExternalPassenger = {
  type: CargoOrderDetailsTransportingOrderPassengerType.EXTERNAL;
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
};

export type CargoOrderDetailsTransportingOrderWaypointItem = {
  uuid: string;
  latitude: number;
  longitude: number;
  orderedDate: Date | null;
  orderedStopoverTime: number;
  displayName: string;
  boardingPassengersUuids: string[];
  disembarkingPassengerUuids: string[];
  estimatedDate: Date;
  arrivalDate: Date | null;
  stopoverTime: number | null;
  distance: number;
};

export type CargoOrderDetailsTransportingOrderDriver = {
  removalDate: Date | null;
  uuid: string;
  modificationDate: Date | null;
  modifiedBy: string | null;
  creationDate: Date;
  createdBy: string;
  firstName: string;
  lastName: string;
  username: string;
  phoneNumber: PhoneNumber;
  alternativePhoneNumber: PhoneNumber | null;
  email: string;
};

export type CargoOrderDetailsTransportingOrderWaypoint =
  | CargoOrderDetailsTransportingOrderWaypointItem
  | CargoOrderDetailsTransportingOrderWaypointItem[];

export type CargoOrderDetailsTransportingOrder = {
  uuid: string;
  driver: CargoOrderDetailsTransportingOrderDriver | null;
  passengers: CargoOrderDetailsTransportingOrderPassenger[];
  waypoints: CargoOrderDetailsTransportingOrderWaypoint[];
  solvedWaypoints: CargoOrderDetailsTransportingOrderWaypointItem[];
};

type CargoOrderDetails = {
  uuid: string;
  targetTaxiUuid: string | null;
  externalOrderId: string | null;
  internalOrderId: number;
  isCancelled: boolean;
  isCancelRequested: boolean;
  publicStatus: CargoOrderDetailsPublicStatus;
  executionStatus: CargoOrderDetailsExecutionStatus;
  tollRoadsPermissions: {
    isOnApproachAllowed: boolean;
    isOnReturningAllowed: boolean;
    isDuringRideAllowed: boolean;
  };
  dispatcherUuid: string;
  dispatchUuid: string;
  transportingOrders: CargoOrderDetailsTransportingOrder[];
};

export default CargoOrderDetails;
