import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteFinishedListingByTaxiSortKey from "../types/route-finished-listing-by-taxi-sort-key";
import RouteFinishedListingByTaxiSortSelectOption from "../types/route-finished-listing-by-taxi-sort-select-option";

const getSelectOptions = (): RouteFinishedListingByTaxiSortSelectOption[] => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().sort;

  return [
    {
      label: translations.routeIdAscOptionLabel,
      value: RouteFinishedListingByTaxiSortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.routeIdDescOptionLabel,
      value: RouteFinishedListingByTaxiSortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.startDateAscOptionLabel,
      value: RouteFinishedListingByTaxiSortKey.START_DATE_ASC,
    },
    {
      label: translations.startDateDescOptionLabel,
      value: RouteFinishedListingByTaxiSortKey.START_DATE_DESC,
    },
  ];
};

const routeFinishedListingByTaxiSortHelper = {
  getSelectOptions,
};

export default routeFinishedListingByTaxiSortHelper;
