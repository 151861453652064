import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type TaxiTaxiContractListingUserPermissionOption =
  | "hasVisibleAddContractButton"
  | "hasAccessToEditTaxiContract";

export type TaxiTaxiContractListingUserPermissionsDefinition =
  UserPermissionsDefinition<TaxiTaxiContractListingUserPermissionOption>;

export type TaxiTaxiContractListingUserPermissions =
  UserPermissions<TaxiTaxiContractListingUserPermissionOption>;

const taxiTaxiContractListingUserPermissionDefinition: TaxiTaxiContractListingUserPermissionsDefinition =
  {
    hasVisibleAddContractButton: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
    hasAccessToEditTaxiContract: [UserRole.ADMIN],
  };

export default taxiTaxiContractListingUserPermissionDefinition;
