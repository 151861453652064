import CargoCompanyDetailsParams from "./cargo-company-details-params";
import CargoCompanyDetails from "./cargo-company-details";
import cargoCompanyService from "../cargo-company.service";
import useAsyncData from "../../../../hooks/use-async-data";

const useCargoCompanyDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoCompanyDetails | null>(null);

  const load = async (
    params: CargoCompanyDetailsParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoCompany = await cargoCompanyService.getDetails(params, signal);

      setData(cargoCompany);
      setIsLoading(false);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useCargoCompanyDetails;
