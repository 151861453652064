import FormValidationResult from "../../../../../utils/validation/types/form-validation-result";
import Joi from "joi";
import formValidationService from "../../../../../utils/validation/form-validation.service";

const validateFrom = (from: number, to: number): FormValidationResult => {
  const validationSchema = Joi.number().max(to).required();

  return formValidationService.validate(from, validationSchema);
};

const validateTo = (to: number, from: number): FormValidationResult => {
  const validationSchema = Joi.number().min(from).required();

  return formValidationService.validate(to, validationSchema);
};

const numericRangeFormValidationService = {
  validateFrom,
  validateTo,
};

export default numericRangeFormValidationService;
