import {
  CargoOrderDetailsTransportingOrder,
  CargoOrderDetailsTransportingOrderWaypoint,
  CargoOrderDetailsTransportingOrderWaypointItem,
} from "../../../../../common/services/cargo-order/details/cargo-order-details";
import PlanningOrderOrderDetailsRouteItem from "./types/planning-order-order-details-route-item";

const createRouteItemForSolvedWaypoint = (
  solvedWaypoint: CargoOrderDetailsTransportingOrderWaypointItem,
  stageNo: number
): PlanningOrderOrderDetailsRouteItem => {
  return {
    address: solvedWaypoint.displayName,
    distance: solvedWaypoint.distance,
    estimatedDate: solvedWaypoint.estimatedDate,
    orderedDate: solvedWaypoint.orderedDate,
    orderedStopoverTime: solvedWaypoint.orderedStopoverTime,
    stageNo,
  };
};

const createRouteItemForWaypoint = (
  waypoint: CargoOrderDetailsTransportingOrderWaypointItem,
  stageNo: number
): PlanningOrderOrderDetailsRouteItem => {
  return {
    address: waypoint.displayName,
    distance: waypoint.distance,
    estimatedDate: waypoint.estimatedDate,
    orderedDate: waypoint.orderedDate,
    orderedStopoverTime: waypoint.orderedStopoverTime,
    stageNo,
  };
};

const createForDriver = (
  waypoints: CargoOrderDetailsTransportingOrderWaypointItem[],
  stageNo: number
): PlanningOrderOrderDetailsRouteItem[] => {
  return waypoints.map((solvedWaypoint) => {
    stageNo++;
    return createRouteItemForSolvedWaypoint(solvedWaypoint, stageNo);
  });
};

const createForNoDriver = (
  waypoints: CargoOrderDetailsTransportingOrderWaypoint[],
  stageNo: number
): PlanningOrderOrderDetailsRouteItem[] => {
  return waypoints
    .map((waypointItem) => {
      if (Array.isArray(waypointItem)) {
        stageNo++;
        return waypointItem.map((waypoint) =>
          createRouteItemForWaypoint(waypoint, stageNo)
        );
      }
      stageNo++;
      return createRouteItemForWaypoint(waypointItem, stageNo);
    })
    .flat();
};

const create = (
  transportingOrders: CargoOrderDetailsTransportingOrder[]
): PlanningOrderOrderDetailsRouteItem[] => {
  const stageNo = 0;

  return transportingOrders
    .map((transportingOrder) => {
      if (transportingOrder.driver) {
        return createForDriver(transportingOrder.solvedWaypoints, stageNo);
      }
      return createForNoDriver(transportingOrder.waypoints, stageNo);
    })
    .flat();
};

const planningOrderOrderDetailsRouteItemFactory = {
  create,
};

export default planningOrderOrderDetailsRouteItemFactory;
