import { FC, useEffect, useMemo, useState } from "react";
import BillingFormData from "./types/billing-form.data";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingApiService from "./api/billings-cargo-taxi-api.service";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingDataResponse from "./api/billing-data.response";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingsApiService from "./api/billings-api.service";
import BillingGpsResponse from "./api/billing-gps-data.response";
import BillingStatusUpdateResponse from "./api/billing-status-update.response";
import MapRoute from "../../../../common/components/map/types/map-route";
import MapMarker from "../../../../common/components/map/types/map-marker";
import Row from "../../../../common/components/grid/row";
import Column from "../../../../common/components/grid/column";
import MapComponent from "../../../../common/components/map/map.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingsUserPermissionsHelper from "../../common/user-permissions/billings-user-permission.helper";
import { useAppContext } from "../../../../context/app.context";
import { ActionOption } from "./types/action-option";
import BillingsCargoTaxiModal from "./billings-cargo-taxi-modal";
import BillingStatus from "../../types/billing-status";
import billingStatusUpdateRequestFactory from "./factory/billing-status-update.factory";
import billingCargoTaxiApiService from "./api/billings-cargo-taxi-api.service";
import { useParams } from "react-router-dom";
import BillingCargoTaxiDetailsRouteParams from "../../common/routes/types/billing-cargo-taxi-details-route-params";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import RelatedBillingsTaxiDriverComponent from "../common/related-billings/taxi-driver/related-billings-taxi-driver.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import BillingsRouteDetailsOverviewComponent from "../../common/route-details/billings-route-details-overview.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsCargoTaxiHelper from "./billings-cargo-taxi.helper";
import MessengerBillingType from "../../../../common/components/messenger/types/messenger-billing-type";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";

type BillingComponentProps = {};

const BillingsCargoTaxiViewComponent: FC<BillingComponentProps> = () => {
  const { billingUuid } = useParams<BillingCargoTaxiDetailsRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [orderInternalId, setOrderInternalId] = useState("");

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);
  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);

  const [orderUuid, setOrderUuid] = useState("");

  const [isModalVisible, setIsModaVisible] = useState(false);
  const [actionOption, setActionOption] = useState<ActionOption>();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsCargoWithTaxiDetails.replace(
      "#{orderInternalId}",
      orderInternalId
    );

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getCargoWithTaxiDetailsBreadcrumbs({
        billingUuid: billingUuid!,
        orderInternalId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, orderInternalId]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsBillingDataLoading(true);
    billingApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  };

  useEffect(() => {
    if (!orderUuid) {
      return;
    }

    billingsApiService.fetchGpsData(orderUuid).then(handleGpsDataResponse);
  }, [orderUuid]);

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleBillingStatusUpdateResponse = (
    response: BillingStatusUpdateResponse
  ) => {
    if (response.status === 200) {
      fetchData();
    }
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setOrderUuid(response.data.cargo_order.id);
    setOrderInternalId(String(response.data.cargo_order.human_id));
    setShouldRetrieveRouteData(true);
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onSubmitHandle = (comment: string) => {
    const request =
      billingStatusUpdateRequestFactory.createUpdateBillingStatusRequest(
        actionOption!,
        comment
      );

    billingCargoTaxiApiService
      .updateBillingStatus(billingUuid!, request)
      .then(handleBillingStatusUpdateResponse);

    setIsModaVisible(false);
  };

  const onBillingStatusUpdate = (actionOption: ActionOption) => {
    setActionOption(actionOption);
    setIsModaVisible(true);
  };

  const onModalClose = () => {
    setIsModaVisible(false);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const { user } = useAppContext();

  const translations =
    billingsTranslationsHelper.getCargoTaxiViewBillingsTranslations();

  const userPermissions = billingsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const messengerChannelsAvailability =
    billingsCargoTaxiHelper.getMessengerChannelAvailability();

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace(
            "#{orderInternalId}",
            orderInternalId
          )}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsOverviewComponent
                      billingNodes={billingData?.billingNodes}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent billingData={billingData} />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <BillingDetailsComponent billingData={billingData} />
              </Column>
              <Column>
                <div className="billings_submit_buttons_container">
                  {userPermissions.hasAccessToAccept &&
                    billingData?.status === BillingStatus.CREATED && (
                      <ButtonComponent
                        type="success"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.ACCEPT)
                        }
                      >
                        {translations.acceptLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToReject &&
                    billingData?.status === BillingStatus.CREATED && (
                      <ButtonComponent
                        type="danger"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.REJECT)
                        }
                      >
                        {translations.rejectLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToReopen &&
                    billingData?.status === BillingStatus.REOPEN_REQUEST && (
                      <ButtonComponent
                        type="primary"
                        onClick={() =>
                          onBillingStatusUpdate(ActionOption.REOPEN)
                        }
                      >
                        {translations.reopenLabel}
                      </ButtonComponent>
                    )}

                  {userPermissions.hasAccessToRequestToReopen &&
                    billingData?.status === BillingStatus.ACCEPTED && (
                      <ButtonComponent
                        type="primary"
                        onClick={() =>
                          onBillingStatusUpdate(
                            ActionOption.SEND_REQUEST_TO_REOPEN
                          )
                        }
                      >
                        {translations.sendRequestToReopenLabel}
                      </ButtonComponent>
                    )}
                </div>
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiDriverComponent
                  planEntryId={billingData?.planEntryId}
                  orderId={orderUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent orderId={orderUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            {!!billingData && (
              <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  billingType={MessengerBillingType.CARGO}
                  billingUuid={billingUuid!}
                  orderUuid={orderUuid}
                  planEntryUuid={billingData.planEntryId}
                />
              </CardComponent>
            )}
          </Column>
        </Row>

        <BillingsCargoTaxiModal
          isModalVisible={isModalVisible}
          onSubmit={(value) => onSubmitHandle(value)}
          onClose={onModalClose}
          actionOption={actionOption!}
        />
      </div>
    </>
  );
};

export default BillingsCargoTaxiViewComponent;
