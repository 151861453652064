import appTranslationsHelper from "../../../../languages/app-translations.helper";
import UserRole from "../../../types/user-role";
import userPermissionsService from "../../../utils/user/permissions/user-permissions.service";
import MessengerChannel from "../types/messenger-channel";
import MessengerChannelAvailability from "../types/messenger-channel-availability";
import MessengerChannelDefinition from "../types/messenger-channel-definition";
import MessengerChannelOption from "../types/messenger-channel-option";
import { MessengerPostType } from "../types/messenger-post";

const getChannelsDefinitions = (
  channelsAvailability: MessengerChannelAvailability
): MessengerChannelDefinition[] => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger;

  return [
    {
      channelOption: {
        label: translations.channels.billingLabel,
        title: translations.channels.billingTitle,
        channel: MessengerChannel.BILLING,
      },
      isAvailable: channelsAvailability.BILLING,
      userRolesWhiteList: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
        UserRole.TAXI_OFFICER,
        UserRole.DEALER,
      ],
    },
    {
      channelOption: {
        label: translations.channels.cargoLabel,
        title: translations.channels.cargoTitle,
        channel: MessengerChannel.CARGO,
      },
      isAvailable: channelsAvailability.CARGO,
      userRolesWhiteList: [UserRole.CARGO_OFFICER, UserRole.DISPATCHER],
    },
    {
      channelOption: {
        label: translations.channels.driverLabel,
        title: translations.channels.driverTitle,
        channel: MessengerChannel.DRIVER,
      },
      isAvailable: channelsAvailability.DRIVER,
      userRolesWhiteList: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.DEALER,
      ],
    },
    {
      channelOption: {
        label: translations.channels.railyLabel,
        title: translations.channels.railyTitle,
        channel: MessengerChannel.RAILY,
      },
      isAvailable: channelsAvailability.RAILY,
      userRolesWhiteList: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
      ],
    },
    {
      channelOption: {
        label: translations.channels.sharedLabel,
        title: translations.channels.sharedTitle,
        channel: MessengerChannel.SHARED,
      },
      isAvailable: channelsAvailability.SHARED,
      userRolesWhiteList: [
        UserRole.ADMIN,
        UserRole.RAILY_OFFICER,
        UserRole.OPERATOR,
        UserRole.CARGO_OFFICER,
        UserRole.DISPATCHER,
        UserRole.TAXI_OFFICER,
        UserRole.DEALER,
      ],
    },
    {
      channelOption: {
        label: translations.channels.taxiLabel,
        title: translations.channels.taxiTitle,
        channel: MessengerChannel.TAXI,
      },
      isAvailable: channelsAvailability.TAXI,
      userRolesWhiteList: [UserRole.DEALER, UserRole.TAXI_OFFICER],
    },
  ];
};

const getChannelOptions = (
  userRoles: UserRole[],
  channelsAvalibility: MessengerChannelAvailability
): MessengerChannelOption[] => {
  const definitions = getChannelsDefinitions(channelsAvalibility);

  const channelOptions = definitions
    .filter((item) => item.isAvailable)
    .filter((item) =>
      userPermissionsService.solvePermission(userRoles, item.userRolesWhiteList)
    )
    .map((item) => item.channelOption);

  return channelOptions;
};

const getFormattedMessageSentDateTime = (date: Date) => {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const getPostColor = (type: MessengerPostType) => {
  switch (type) {
    case MessengerPostType.ACCEPTED:
      return "accepted";
    case MessengerPostType.REJECTED:
      return "rejected";
    case MessengerPostType.REOPENED:
      return "reopened";
    case MessengerPostType.REOPEN_REQUEST:
      return "reopen_request";
    case MessengerPostType.SETTLED:
      return "settled";
    case MessengerPostType.UPDATED:
      return "updated";
    case MessengerPostType.USER_ENTRY:
      return "user_entry";
  }
};

const getContentTranslation = (content: string) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().messenger.statuses;

  switch (content) {
    case MessengerPostType.ACCEPTED:
      return translations.acceptedStatusLabel;
    case MessengerPostType.REJECTED:
      return translations.rejectedStatusLabel;
    case MessengerPostType.REOPENED:
      return translations.reopenedStatusLabel;
    case MessengerPostType.REOPEN_REQUEST:
      return translations.reopenRequestStatusLabel;
    default:
      return content;
  }
};

const messengerHelper = {
  getChannelOptions,
  getFormattedMessageSentDateTime,
  getPostColor,
  getContentTranslation,
};

export default messengerHelper;
