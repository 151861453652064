import DateRange from "../../../../../../common/types/date-range";
import listingFilterService from "../../../../../../common/utils/listing/filters/listing-filter.service";
import RouteActiveListingByRailyFilter, {
  RouteActiveListingByRailyDriverFilter,
  RouteActiveListingByRailyEndDateFilter,
  RouteActiveListingByRailyPassengersFilter,
  RouteActiveListingByRailyRouteAddressFilter,
  RouteActiveListingByRailyRouteIdFilter,
  RouteActiveListingByRailyStartDateFilter,
  RouteActiveListingByRailyTaxiCorporationFilter,
} from "../types/route-active-listing-by-raily-filter";
import RouteActiveListingByRailyFilterType from "../types/route-active-listing-by-raily-filter-type";
import RouteActiveListingByRailyListingItem from "../types/route-active-listing-by-raily-item";

const filterListingItemsByDriver = (
  listingItems: RouteActiveListingByRailyListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "driverName",
    queries
  );
};

const filterListingItemsByRouteAddresses = (
  listingItems: RouteActiveListingByRailyListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "routeAddresses",
    queries
  );
};

const filterListingItemsByStartDate = (
  listingItems: RouteActiveListingByRailyListingItem[],
  dateRange: DateRange
) => {
  return listingItems.filter(
    (listingItem) =>
      listingItem.startDate.getTime() >= dateRange.from.getTime() &&
      listingItem.startDate.getTime() <= dateRange.to.getTime()
  );
};

const filterListingItemsByEndDate = (
  listingItems: RouteActiveListingByRailyListingItem[],
  dateRange: DateRange
) => {
  return listingItems.filter(
    (listingItem) =>
      listingItem.endDate.getTime() >= dateRange.from.getTime() &&
      listingItem.endDate.getTime() <= dateRange.to.getTime()
  );
};

const filterListingItemsByTaxiCorporation = (
  listingItems: RouteActiveListingByRailyListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByStringProperty(
    listingItems,
    "taxiCorporationName",
    queries
  );
};

const filterListingItemsByRouteId = (
  listingItems: RouteActiveListingByRailyListingItem[],
  queries: number[]
) => {
  return listingFilterService.filterByNumberProperty(
    listingItems,
    "routeId",
    queries
  );
};

const filterListingItemsByPassenger = (
  listingItems: RouteActiveListingByRailyListingItem[],
  queries: string[]
) => {
  return listingFilterService.filterByArrayOfStringsProperty(
    listingItems,
    "passengers",
    queries
  );
};

const filterListingItems = (
  listingItems: RouteActiveListingByRailyListingItem[],
  filters: RouteActiveListingByRailyFilter[]
): RouteActiveListingByRailyListingItem[] => {
  if (!filters.length) {
    return [...listingItems];
  }

  let filteredListingItems: RouteActiveListingByRailyListingItem[] = [
    ...listingItems,
  ];

  const driverFilterQueries = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByRailyFilterType,
    RouteActiveListingByRailyDriverFilter["value"]
  >(filters, RouteActiveListingByRailyFilterType.DRIVER);

  if (driverFilterQueries.length) {
    filteredListingItems = filterListingItemsByDriver(
      filteredListingItems,
      driverFilterQueries
    );
  }

  const passengerFilterQueries =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingByRailyFilterType,
      RouteActiveListingByRailyPassengersFilter["value"]
    >(filters, RouteActiveListingByRailyFilterType.PASSENGERS);

  if (passengerFilterQueries.length) {
    filteredListingItems = filterListingItemsByPassenger(
      filteredListingItems,
      passengerFilterQueries
    );
  }

  const routeAddressFilterQueries =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingByRailyFilterType,
      RouteActiveListingByRailyRouteAddressFilter["value"]
    >(filters, RouteActiveListingByRailyFilterType.ROUTE_ADDRESS);

  if (routeAddressFilterQueries.length) {
    filteredListingItems = filterListingItemsByRouteAddresses(
      filteredListingItems,
      routeAddressFilterQueries
    );
  }

  const routeIdFilterQueries = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByRailyFilterType,
    RouteActiveListingByRailyRouteIdFilter["value"]
  >(filters, RouteActiveListingByRailyFilterType.ROUTE_ID);

  if (routeIdFilterQueries.length) {
    filteredListingItems = filterListingItemsByRouteId(
      filteredListingItems,
      routeIdFilterQueries
    );
  }

  const taxiCorporationFilterQueries =
    listingFilterService.getValuesOfGivenFilterType<
      RouteActiveListingByRailyFilterType,
      RouteActiveListingByRailyTaxiCorporationFilter["value"]
    >(filters, RouteActiveListingByRailyFilterType.TAXI_CORPORATION);

  if (taxiCorporationFilterQueries.length) {
    filteredListingItems = filterListingItemsByTaxiCorporation(
      filteredListingItems,
      taxiCorporationFilterQueries
    );
  }

  const startDateFilterRanges = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByRailyFilterType,
    RouteActiveListingByRailyStartDateFilter["value"]
  >(filters, RouteActiveListingByRailyFilterType.START_DATE);

  if (startDateFilterRanges.length) {
    filteredListingItems = filterListingItemsByStartDate(
      filteredListingItems,
      startDateFilterRanges[0]
    );
  }

  const endDateFilterRanges = listingFilterService.getValuesOfGivenFilterType<
    RouteActiveListingByRailyFilterType,
    RouteActiveListingByRailyEndDateFilter["value"]
  >(filters, RouteActiveListingByRailyFilterType.END_DATE);

  if (endDateFilterRanges.length) {
    filteredListingItems = filterListingItemsByEndDate(
      filteredListingItems,
      endDateFilterRanges[0]
    );
  }

  return filteredListingItems;
};

const routeActiveListingByRailyFilterService = {
  filterListingItems,
};

export default routeActiveListingByRailyFilterService;
