import Joi from "joi";
import TaxiUnsettledPrivateOrderListingFilter, {
  TaxiUnsettledPrivateOrderListingClientFilter,
  TaxiUnsettledPrivateOrderListingDispatchFilter,
  TaxiUnsettledPrivateOrderListingDriverFilter,
  TaxiUnsettledPrivateOrderListingExternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingInternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingPassengerFilter,
  TaxiUnsettledPrivateOrderListingRouteAddressFilter,
  TaxiUnsettledPrivateOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter,
  TaxiUnsettledPrivateOrderListingStartDateFilter,
  TaxiUnsettledPrivateOrderListingStatusFilter,
} from "./types/taxi-unsettled-private-order-listing-filter";
import TaxiUnsettledPrivateOrderListingRouteQueryParams from "./types/taxi-unsettled-private-order-listing-route-query-params";
import TaxiUnsettledPrivateOrderListingSortKey from "./types/taxi-unsettled-private-order-listing-sort-key";
import TaxiUnsettledPrivateOrderListingFilterType from "./types/taxi-unsettled-private-order-listing-filter-type";
import TaxiUnsettledPrivateOrderListingRouteQueryFilterParams from "./types/taxi-unsettled-private-order-listing-route-query-filter-params";
import TaxiUnsettledPrivateOrderListingTaxiCorporation from "./types/taxi-unsettled-private-order-listing-taxi-corporation";

const getFilters = (
  routeQueryFilterParams: TaxiUnsettledPrivateOrderListingRouteQueryFilterParams
): TaxiUnsettledPrivateOrderListingFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: TaxiUnsettledPrivateOrderListingFilter[] = [];

  if (routeQueryFilterParams.client) {
    const clientFilter: TaxiUnsettledPrivateOrderListingClientFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.CLIENT,
      value: routeQueryFilterParams.client,
    };

    filters.push(clientFilter);
  }

  if (routeQueryFilterParams.dispatch) {
    const dispatchFilter: TaxiUnsettledPrivateOrderListingDispatchFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.DISPATCH,
      value: routeQueryFilterParams.dispatch,
    };

    filters.push(dispatchFilter);
  }

  if (routeQueryFilterParams.driver) {
    const driverFilter: TaxiUnsettledPrivateOrderListingDriverFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.externalOrderId) {
    const externalOrderIdFilter: TaxiUnsettledPrivateOrderListingExternalOrderIdFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value: routeQueryFilterParams.externalOrderId,
      };

    filters.push(externalOrderIdFilter);
  }

  if (routeQueryFilterParams.internalOrderId) {
    const internalOrderIdFilter: TaxiUnsettledPrivateOrderListingInternalOrderIdFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
        value: routeQueryFilterParams.internalOrderId,
      };

    filters.push(internalOrderIdFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: TaxiUnsettledPrivateOrderListingPassengerFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.PASSENGER,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: TaxiUnsettledPrivateOrderListingRouteAddressFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value: routeQueryFilterParams.routeAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationFilter: TaxiUnsettledPrivateOrderListingRouteDestinationAddressFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeIntermediateAddressFilter: TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeIntermediateAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter =
      {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: TaxiUnsettledPrivateOrderListingStartDateFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.status) {
    const statusFilter: TaxiUnsettledPrivateOrderListingStatusFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.STATUS,
      value: routeQueryFilterParams.status,
    };

    filters.push(statusFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: TaxiUnsettledPrivateOrderListingRouteQueryParams
): TaxiUnsettledPrivateOrderListingSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const createRouteQueryParams = (
  taxiCorporationUuid:
    | TaxiUnsettledPrivateOrderListingTaxiCorporation["uuid"]
    | null,
  filters: TaxiUnsettledPrivateOrderListingFilter[],
  sortKey: TaxiUnsettledPrivateOrderListingSortKey | null,
  page: number,
  pageSize: number
): TaxiUnsettledPrivateOrderListingRouteQueryParams => {
  const routeQueryParams: TaxiUnsettledPrivateOrderListingRouteQueryParams = {
    client: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.CLIENT
    )?.value as
      | TaxiUnsettledPrivateOrderListingClientFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.DISPATCH
    )?.value as
      | TaxiUnsettledPrivateOrderListingDispatchFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.DRIVER
    )?.value as
      | TaxiUnsettledPrivateOrderListingDriverFilter["value"]
      | undefined,
    externalOrderId: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledPrivateOrderListingExternalOrderIdFilter["value"]
      | undefined,
    internalOrderId: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledPrivateOrderListingInternalOrderIdFilter["value"]
      | undefined,
    passenger: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.PASSENGER
    )?.value as
      | TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
      | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
    status: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.STATUS
    )?.value as
      | TaxiUnsettledPrivateOrderListingStatusFilter["value"]
      | undefined,
    sort: sortKey ?? undefined,
    page,
    pageSize,
    taxiCorporation: taxiCorporationUuid ?? undefined,
  };

  return routeQueryParams;
};

const getTaxiCorporationUuid = (
  routeQueryParams: TaxiUnsettledPrivateOrderListingRouteQueryFilterParams
): string | undefined => {
  if (!validateTaxiCorporationUuid(routeQueryParams.taxiCorporation)) {
    return undefined;
  }

  return routeQueryParams.taxiCorporation;
};

const validateTaxiCorporationUuid = (
  routeQueryTaxiCorporationParam: TaxiUnsettledPrivateOrderListingRouteQueryFilterParams["taxiCorporation"]
) => {
  const validationSchema = Joi.string();

  return !validationSchema.validate(routeQueryTaxiCorporationParam).error
    ?.message;
};

const getPage = (
  routeQueryFilterParams: TaxiUnsettledPrivateOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryFilterParams.page)) {
    return undefined;
  }

  return routeQueryFilterParams.page
    ? Number(routeQueryFilterParams.page)
    : undefined;
};

const getPageSize = (
  routeQueryFilterParams: TaxiUnsettledPrivateOrderListingRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryFilterParams.pageSize)) {
    return undefined;
  }
  return routeQueryFilterParams.pageSize
    ? Number(routeQueryFilterParams.pageSize)
    : undefined;
};

const validateFilters = (
  routeQueryFilterParams: TaxiUnsettledPrivateOrderListingRouteQueryParams
) => {
  const filterParams: TaxiUnsettledPrivateOrderListingRouteQueryParams = {
    client: routeQueryFilterParams.client,
    dispatch: routeQueryFilterParams.dispatch,
    driver: routeQueryFilterParams.driver,
    externalOrderId: routeQueryFilterParams.externalOrderId,
    internalOrderId: routeQueryFilterParams.internalOrderId,
    passenger: routeQueryFilterParams.passenger,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
  };

  const validationSchema =
    Joi.object<TaxiUnsettledPrivateOrderListingRouteQueryParams>({
      client: Joi.string(),
      dispatch: Joi.string(),
      driver: Joi.string(),
      externalOrderId: Joi.string(),
      internalOrderId: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      startDateFrom: Joi.date(),
      startDateTo: Joi.date(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: TaxiUnsettledPrivateOrderListingRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(TaxiUnsettledPrivateOrderListingSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: TaxiUnsettledPrivateOrderListingRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: TaxiUnsettledPrivateOrderListingRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const taxiUnsettledPrivateOrderListingRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
  getTaxiCorporationUuid,
};

export default taxiUnsettledPrivateOrderListingRouteQueryParamsService;
