import messengerApiService from "../../../api/raily/messenger/messenger-api.service";
import {
  MessengerBillingSendPostRequest,
  MessengerCargoSendPostRequest,
  MessengerDriverSendPostRequest,
  MessengerRailySendPostRequest,
  MessengerSharedSendPostRequest,
  MessengerSolvedDriverSendPostRequest,
  MessengerTaxiSendPostRequest,
} from "../../../api/raily/messenger/send-post/messenger-send-post.request";
import MessengerSendPostResponse from "../../../api/raily/messenger/send-post/messenger-send-post.response";
import { MessengerPostChannel } from "../post/messenger-post";
import MessengerSendPostLoadParams, {
  MessengerSendPostLoadParamsBillingType,
} from "./messenger-send-post-load-params";

const create = (
  params: MessengerSendPostLoadParams,
  abortSignal: AbortSignal
): Promise<MessengerSendPostResponse> | undefined => {
  switch (params.channel) {
    case MessengerPostChannel.BILLING: {
      const request: MessengerBillingSendPostRequest = {
        billingUuid: params.billingUuid ?? "",
        content: params.content,
      };

      if (params.billingType === MessengerSendPostLoadParamsBillingType.TAXI) {
        return messengerApiService().sendTaxiBillingPost(request, abortSignal);
      } else {
        return messengerApiService().sendCargoBillingPost(request, abortSignal);
      }
    }
    case MessengerPostChannel.CARGO: {
      const request: MessengerCargoSendPostRequest = {
        content: params.content,
        orderUuid: params.orderUuid ?? "",
      };

      return messengerApiService().sendCargoPost(request, abortSignal);
    }
    case MessengerPostChannel.DRIVER: {
      if (params.planEntryUuid) {
        const request: MessengerDriverSendPostRequest = {
          content: params.content,
          planEntryUuid: params.planEntryUuid,
        };

        return messengerApiService().sendDriverPost(request, abortSignal);
      }

      if (params.solvedOrderUuid) {
        const request: MessengerSolvedDriverSendPostRequest = {
          content: params.content,
          solvedOrderUuid: params.solvedOrderUuid,
        };

        return messengerApiService().sendSolvedDriverPost(request, abortSignal);
      }

      return Promise.reject();
    }
    case MessengerPostChannel.RAILY: {
      const request: MessengerRailySendPostRequest = {
        content: params.content,
        orderUuid: params.orderUuid ?? "",
      };

      return messengerApiService().sendRailyPost(request, abortSignal);
    }
    case MessengerPostChannel.SHARED: {
      const request: MessengerSharedSendPostRequest = {
        content: params.content,
        orderUuid: params.orderUuid ?? "",
      };

      return messengerApiService().sendSharedPost(request, abortSignal);
    }
    case MessengerPostChannel.TAXI: {
      const request: MessengerTaxiSendPostRequest = {
        content: params.content,
        orderUuid: params.orderUuid ?? "",
        taxiCorporationUuid: params.taxiCorporationUuid ?? "",
      };

      return messengerApiService().sendTaxiPost(request, abortSignal);
    }
  }
};

const messengerSendPostRequestFactory = { create };

export default messengerSendPostRequestFactory;
