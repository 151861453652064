import CargoOrderDetailsHandlingLogRequest from "../../../api/raily/cargo-order/details/handling-log/cargo-order-details-handling-log.request";
import CargoOrderDetailsHandlingLogLoadParams from "./cargo-order-details-handling-log-load-params";

const create = (
  params: CargoOrderDetailsHandlingLogLoadParams
): CargoOrderDetailsHandlingLogRequest => {
  return {
    orderId: params.orderId,
    creationStart: params.creationStart,
    creationEnd: params.creationEnd,
  };
};

const cargoOrderDetailsHandlingLogRequestFactory = {
  create,
};

export default cargoOrderDetailsHandlingLogRequestFactory;
