import { FC } from "react";
import ListingFilterBadgeListComponent from "../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import RouteFinishedListingByRailyFilter from "../../common/types/route-finished-listing-by-raily-filter";
import routeFinishedListingByRailyFiltersBadgeListHelper from "./route-finished-listing-by-raily-filters-badge-list.helper";

type RouteFinishedListingByRailyFiltersBadgeListProps = {
  filters: RouteFinishedListingByRailyFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const RouteFinishedListingByRailyFiltersBadgeListComponent: FC<
  RouteFinishedListingByRailyFiltersBadgeListProps
> = (props) => {
  const badges = routeFinishedListingByRailyFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default RouteFinishedListingByRailyFiltersBadgeListComponent;
