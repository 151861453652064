import dateService from "../../../../../common/utils/date/date.service";
import routeTranslationsHelper from "../../../../../languages/route-translations.helper";
import RouteFinishedListingByRailyListingItem from "../common/types/route-finished-listing-by-raily-item";
import RouteFinishedListingByRailyTableColumn from "../common/types/route-finished-listing-by-raily-table-column";
import RouteFinishedListingByRailyTableRow from "../common/types/route-finished-listing-by-raily-table-row";

const getColumns = (): RouteFinishedListingByRailyTableColumn[] => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().table.headers;

  const columns: RouteFinishedListingByRailyTableColumn[] = [
    {
      header: translations.routeIdLabel,
      title: translations.routeIdTitle,
      accessor: "routeId",
      colSpan: 5,
    },
    {
      header: translations.routeLabel,
      title: translations.routeTitle,
      accessor: "route",
      colSpan: 15,
    },
    {
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      accessor: "startDate",
      colSpan: 10,
    },
    {
      header: translations.endDateLabel,
      title: translations.endDateTitle,
      accessor: "endDate",
      colSpan: 10,
    },
    {
      header: translations.driverLabel,
      title: translations.driverTitle,
      accessor: "driver",
      colSpan: 10,
    },
    {
      header: translations.taxiCorporationLabel,
      title: translations.taxiCorporationTitle,
      accessor: "taxiCorporation",
      colSpan: 10,
    },
    {
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      accessor: "passengers",
      colSpan: 10,
    },
  ];

  return columns;
};

const getRow = (
  listingItem: RouteFinishedListingByRailyListingItem
): RouteFinishedListingByRailyTableRow => {
  return {
    id: listingItem.uuid,
    value: {
      driver: (
        <div title={listingItem.driverName}>{listingItem.driverName}</div>
      ),
      endDate: (
        <div title={dateService.formatDateTime(listingItem.endDate)}>
          {dateService.formatDateTime(listingItem.endDate)}
        </div>
      ),
      taxiCorporation: (
        <div title={listingItem.taxiCorporationName}>
          {listingItem.taxiCorporationName}
        </div>
      ),
      passengers: (
        <div>
          {listingItem.passengers.map((passenger, index) => (
            <div title={passenger} key={index}>
              {passenger}
            </div>
          ))}
        </div>
      ),
      routeId: (
        <div title={String(listingItem.routeId)}>
          {String(listingItem.routeId)}
        </div>
      ),
      startDate: (
        <div title={dateService.formatDateTime(listingItem.startDate)}>
          {dateService.formatDateTime(listingItem.startDate)}
        </div>
      ),
      route: (
        <div>
          {listingItem.routeAddresses.map((routeAddress, index) => {
            const stageNo = index + 1;
            return (
              <div
                title={routeAddress}
                key={index}
              >{`${stageNo}. ${routeAddress}`}</div>
            );
          })}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: RouteFinishedListingByRailyListingItem[]
): RouteFinishedListingByRailyTableRow[] => {
  return listingItems.map(getRow);
};

const routeFinishedListingByRailyTableHelper = {
  getColumns,
  getRows,
};

export default routeFinishedListingByRailyTableHelper;
