import { FC } from "react";
import BillingFormData from "../types/billing-form.data";
import CardComponent from "../../../../../common/components/card/card.component";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import Column from "../../../../../common/components/grid/column";
import Row from "../../../../../common/components/grid/row";
import billingsTranslationsHelper from "../../../../../languages/billings-translations.helper";
import additionalOptionsFormHelper from "./additional-options-form.helper";

type AdditionalOptionsProps = {
  billingData: BillingFormData | undefined;
};

const AdditionalOptionsComponent: FC<AdditionalOptionsProps> = (props) => {
  const translations =
    billingsTranslationsHelper.getTaxiDriverViewBillingsTranslations();

  const penaltyTypeTranslations =
    additionalOptionsFormHelper.getPenaltyTypeTranslations();

  const bonusTypeTranslations =
    additionalOptionsFormHelper.getBonusTypeTranslations();

  const getPenaltyType = (): string => {
    if (props.billingData && props.billingData.penaltyType) {
      return penaltyTypeTranslations.get(props.billingData.penaltyType)!;
    }

    return "-";
  };

  const getBonusType = (): string => {
    if (props.billingData && props.billingData.bonusType) {
      return bonusTypeTranslations.get(props.billingData.bonusType)!;
    }

    return "-";
  };

  return (
    <>
      <CardComponent classNames={{ root: "h-100 " }}>
        <Row>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.additionalOptions.penaltyLabel}
              classNames={{ root: `mt-0` }}
            >
              {props.billingData?.penalty}
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.additionalOptions.penaltyReasonLabel}
              classNames={{ root: `mt-0` }}
            >
              {getPenaltyType()}
            </FormFieldComponent>
          </Column>
          <Column lg={6}>
            <FormFieldComponent
              label={translations.additionalOptions.bonusLabel}
              classNames={{ root: `mt-0` }}
            >
              {props.billingData?.bonus}
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.additionalOptions.bonusReasonLabel}
              classNames={{ root: `mt-0` }}
            >
              {getBonusType()}
            </FormFieldComponent>
          </Column>
        </Row>
      </CardComponent>
    </>
  );
};
export default AdditionalOptionsComponent;
