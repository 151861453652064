import appConfig from "../../../../../app.config";
import urlService from "../../../../../common/utils/url/url.service";
import authService from "../../../../auth/common/auth.service";
import RouteFinishedListingRequest from "./route-finished-listing.request";
import RouteFinishedListingResponse from "./route-finished-listing.response";

const fetchListing = async (
  request: RouteFinishedListingRequest
): Promise<RouteFinishedListingResponse> => {
  const path = `${appConfig.baseApiUrl}/plan-entries/finished`;
  const url = urlService.buildWithoutEmptyParams(path, request);

  const authToken = authService.getAccessToken();

  const init: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "include",
  };

  const r = await fetch(url, init);
  const responseData = await r.json();

  return {
    status: r.status,
    data: responseData,
  };
};

const routeFinishedListingApiService = {
  fetchListing,
};

export default routeFinishedListingApiService;
