import apiService from "../api.service";
import ApiConfig from "../types/api-config";

import railyApiHelper from "./raily-api.helper";

const railyApiService = () => {
  const config: ApiConfig = railyApiHelper.getConfig();

  return apiService(config);
};

export default railyApiService;
