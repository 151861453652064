import { FC } from "react";
import cargoOrderSettlementListingTableHelper from "./cargo-order-settlement-listing-table.helper";
import TableComponent, {
  TableProps,
} from "../../../../../../common/components/table/table.component";
import CargoOrderSettlementListingItem from "../types/cargo-order-settlement-listing-item";
import CargoOrderSettlementListingTableColumn from "../types/cargo-order-settlement-listing-table-column";
import CargoOrderSettlementListingTableRow from "../types/cargo-order-settlement-listing-table-row";
import cargoOrderSettlementListingUserPermissionsHelper from "../user-permissions/cargo-order-settlement-listing-user-permission.helper";
import { useAppContext } from "../../../../../../context/app.context";

type CargoOrderSettlementListingTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: CargoOrderSettlementListingItem[];
};

const CargoOrderSettlementListingTableComponent: FC<
  CargoOrderSettlementListingTableProps
> = (props) => {
  const { user } = useAppContext();
  const userPermissions =
    cargoOrderSettlementListingUserPermissionsHelper.getPermissions(
      user?.roles!
    );

  const columns: CargoOrderSettlementListingTableColumn[] =
    cargoOrderSettlementListingTableHelper.getColumns();

  const rows: CargoOrderSettlementListingTableRow[] =
    cargoOrderSettlementListingTableHelper.getRows(
      props.listingItems,
      userPermissions
    );

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default CargoOrderSettlementListingTableComponent;
