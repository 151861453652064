import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiTransferedOrderSettlementListingSortKey from "../types/taxi-transfered-order-settlement-listing-sort-key";
import TaxiTransferedOrderSettlementListingSortSelectOption from "../types/taxi-transfered-order-settlement-listing-sort-select-option";

const getSelectOptions =
  (): TaxiTransferedOrderSettlementListingSortSelectOption[] => {
    const translations =
      settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
        .sort;

    return [
      {
        label: translations.orderStartDateAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_ASC,
      },
      {
        label: translations.orderStartDateDescOptionLabel,
        value:
          TaxiTransferedOrderSettlementListingSortKey.ORDER_START_DATE_DESC,
      },
      {
        label: translations.internalOrderIdAscOptionLabel,
        value:
          TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
      },
      {
        label: translations.internalOrderIdDescOptionLabel,
        value:
          TaxiTransferedOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
      },
      {
        label: translations.producerAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_ASC,
      },
      {
        label: translations.producerDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.PRODUCER_DESC,
      },
      {
        label: translations.consumerAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_ASC,
      },
      {
        label: translations.consumerDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.CONSUMER_DESC,
      },
      {
        label: translations.billingModelAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_ASC,
      },
      {
        label: translations.billingModelDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.BILLING_MODEL_DESC,
      },
      {
        label: translations.distanceAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_ASC,
      },
      {
        label: translations.distanceDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_DESC,
      },
      {
        label: translations.distanceRateAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
      },
      {
        label: translations.distanceRateDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
      },
      {
        label: translations.distanceCostAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_ASC,
      },
      {
        label: translations.distanceCostDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.DISTANCE_COST_DESC,
      },
      {
        label: translations.stopoverCostAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_ASC,
      },
      {
        label: translations.stopoverCostDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.STOPOVER_COST_DESC,
      },
      {
        label: translations.tollRoadsCostAscOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
      },
      {
        label: translations.tollRoadsCostDescOptionLabel,
        value: TaxiTransferedOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
      },
    ];
  };

const taxiTransferedOrderSettlementListingSortHelper = {
  getSelectOptions,
};

export default taxiTransferedOrderSettlementListingSortHelper;
