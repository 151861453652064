import UserRole from "../../../../../common/types/user-role";
import UserPermissions from "../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../common/utils/user/permissions/user-permissions-definition";

export type RouteFinishedListingUserPermissionOption =
  | "hasAccessToTaxiView"
  | "hasAccessToRailyView";

export type RouteFinishedListingUserPermissionsDefinition =
  UserPermissionsDefinition<RouteFinishedListingUserPermissionOption>;

export type RouteFinishedListingUserPermissions =
  UserPermissions<RouteFinishedListingUserPermissionOption>;

const routeFinishedListingUserPermissionDefinition: RouteFinishedListingUserPermissionsDefinition =
  {
    hasAccessToTaxiView: [UserRole.DEALER],
    hasAccessToRailyView: [UserRole.ADMIN, UserRole.OPERATOR],
  };

export default routeFinishedListingUserPermissionDefinition;
