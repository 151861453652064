import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiRouteSettlementListingItem from "../types/taxi-route-settlement-listing-item";
import TaxiRouteSettlementListingTableColumn from "../types/taxi-route-settlement-listing-table-column";
import TaxiRouteSettlementListingTableRow from "../types/taxi-route-settlement-listing-table-row";
import dateService from "../../../../../../common/utils/date/date.service";
import TaxiRouteSettlementListingBonusReason from "../types/taxi-route-settlement-listing-bonus-reason";
import TaxiRouteSettlementListingPenaltyReason from "../types/taxi-route-settlement-listing-penalty-reason";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";

const getColumns = (): TaxiRouteSettlementListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .table.headers;

  return [
    {
      accessor: "routeStartDate",
      header: translations.routeStartDateLabel,
      title: translations.routeStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "routeId",
      header: translations.routeIdLabel,
      title: translations.routeIdTitle,
      colSpan: 4,
    },
    {
      accessor: "driver",
      header: translations.driverLabel,
      title: translations.driverTitle,
      colSpan: 10,
    },
    {
      accessor: "fleetPartner",
      header: translations.fleetPartnerLabel,
      title: translations.fleetPartnerTitle,
      colSpan: 10,
    },
    {
      accessor: "billingModel",
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      colSpan: 6,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceRate",
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceCost",
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      colSpan: 10,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 9,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "addresses",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 12,
    },
    {
      accessor: "bonus",
      header: translations.bonusLabel,
      title: translations.bonusTitle,
      colSpan: 9,
    },
    {
      accessor: "penalty",
      header: translations.penaltyLabel,
      title: translations.penaltyTitle,
      colSpan: 9,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 10,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 6,
    },
  ];
};

const getDistanceLabel = (
  distance: TaxiRouteSettlementListingItem["distance"]
): string => {
  return `${distance} km`;
};

const getDistanceTitle = (
  distance: TaxiRouteSettlementListingItem["distance"]
): string => {
  return `${distance} km`;
};

const getDistanceRateLabel = (
  distanceRate: TaxiRouteSettlementListingItem["distanceRate"]
): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDistanceRateTitle = (
  distanceRate: TaxiRouteSettlementListingItem["distanceRate"]
): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getStopoverCostLabel = (
  stopoverCost: TaxiRouteSettlementListingItem["stopoverCost"]
): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (
  stopoverCost: TaxiRouteSettlementListingItem["stopoverCost"]
): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (
  tollRoadsCost: TaxiRouteSettlementListingItem["tollRoadsCost"]
): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (
  tollRoadsCost: TaxiRouteSettlementListingItem["tollRoadsCost"]
): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getDistanceCostLabel = (
  distanceCost: TaxiRouteSettlementListingItem["distanceCost"]
): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getDistanceCostTitle = (
  distanceCost: TaxiRouteSettlementListingItem["distanceCost"]
): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getTotalCostLabel = (
  totalCost: TaxiRouteSettlementListingItem["totalCost"]
): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (
  totalCost: TaxiRouteSettlementListingItem["totalCost"]
): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getBonusReasonText = (
  bonusReason: TaxiRouteSettlementListingBonusReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .bonusReason;

  switch (bonusReason) {
    case TaxiRouteSettlementListingBonusReason.DISTANCE_RATE_CHANGED:
      return translations.DISTANCE_RATE_CHANGED;
    case TaxiRouteSettlementListingBonusReason.HALTING_RATE_CHANGED:
      return translations.HALTING_RATE_CHANGED;
    case TaxiRouteSettlementListingBonusReason.OTHER:
      return translations.OTHER;
    case TaxiRouteSettlementListingBonusReason.SHORT_ROUTE:
      return translations.SHORT_ROUTE;
  }
};

const getPenaltyReasonText = (
  penaltyReason: TaxiRouteSettlementListingPenaltyReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .penaltyReason;

  switch (penaltyReason) {
    case TaxiRouteSettlementListingPenaltyReason.BEING_LATE:
      return translations.BEING_LATE;
    case TaxiRouteSettlementListingPenaltyReason.GUIDELINE_IGNORED:
      return translations.GUIDELINE_IGNORED;
    case TaxiRouteSettlementListingPenaltyReason.HIGHWAY_NOT_ALLOWED:
      return translations.HIGHWAY_NOT_ALLOWED;
    case TaxiRouteSettlementListingPenaltyReason.INCOMPATIBLE_CAR:
      return translations.INCOMPATIBLE_CAR;
    case TaxiRouteSettlementListingPenaltyReason.OPTIMIZATION_REFUSED:
      return translations.OPTIMIZATION_REFUSED;
    case TaxiRouteSettlementListingPenaltyReason.OTHER:
      return translations.OTHER;
    case TaxiRouteSettlementListingPenaltyReason.SUBOPTIMAL_RIDE:
      return translations.SUBOPTIMAL_RIDE;
  }
};

const getBonusLabel = (
  bonus: TaxiRouteSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
        .table.bonusLabelTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getBonusTitle = (
  bonus: TaxiRouteSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
        .table.bonusTitleTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getPenaltyLabel = (
  penalty: TaxiRouteSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
        .table.penaltyLabelTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getPenaltyTitle = (
  penalty: TaxiRouteSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
        .table.penaltyTitleTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getRow = (
  listingItem: TaxiRouteSettlementListingItem
): TaxiRouteSettlementListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiRouteSettlementListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.routeStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.routeStartDate,
    "dd.mm.yyyy"
  );

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const distanceRateLabel = getDistanceRateLabel(listingItem.distanceRate);
  const distanceRateTitle = getDistanceRateTitle(listingItem.distanceRate);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const distanceCostLabel = getDistanceCostLabel(listingItem.distanceCost);
  const distanceCostTitle = getDistanceCostTitle(listingItem.distanceCost);

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const bonusLabel = getBonusLabel(listingItem.bonus);
  const bonusTitle = getBonusTitle(listingItem.bonus);

  const penaltyLabel = getPenaltyLabel(listingItem.penalty);
  const penaltyTitle = getPenaltyTitle(listingItem.penalty);

  return {
    id: listingItem.uuid,
    value: {
      routeStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      routeId: (
        <div title={String(listingItem.routeId)}>{listingItem.routeId}</div>
      ),
      driver: (
        <div title={listingItem.driverName}>{listingItem.driverName}</div>
      ),
      fleetPartner: (
        <div title={listingItem.fleetPartner ?? ""}>
          {listingItem.fleetPartner}
        </div>
      ),
      billingModel: (
        <div title={listingItem.billingModel}>{listingItem.billingModel}</div>
      ),
      bonus: <div title={bonusTitle}>{bonusLabel}</div>,
      penalty: <div title={penaltyTitle}>{penaltyLabel}</div>,
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
      distanceCost: <div title={distanceCostTitle}>{distanceCostLabel}</div>,
      addresses: (
        <div title={listingItem.addresses}>{listingItem.addresses}</div>
      ),
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          <TableLinkButtonComponent
            icon={faEdit}
            to={billingRoutesHelper.getTaxiWithDriverEditRoute({
              billingUuid: listingItem.uuid,
            })}
            title={translations.taxiWithDriverSettlementEditButtonTitle}
            idForTesting={`taxi-route-settlement-listing-table-item-${listingItem.uuid}-edit-button`}
          />
          <TableLinkButtonComponent
            icon={faCircleInfo}
            to={billingRoutesHelper.getTaxiWithDriverDetailsRoute({
              billingUuid: listingItem.uuid,
            })}
            title={translations.taxiWithDriverSettlementViewButtonTitle}
            idForTesting={`taxi-route-settlement-listing-table-item-${listingItem.uuid}-details-button`}
          />
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiRouteSettlementListingItem[]
): TaxiRouteSettlementListingTableRow[] => {
  return listingItems.map(getRow);
};

const taxiRouteSettlementListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiRouteSettlementListingTableHelper;
