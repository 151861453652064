import { FC, useEffect, useMemo, useState } from "react";
import billingDataRouteRequestsFactory from "./factory/billing-data-route-requests.factory";
import billingMapRoutesFactory from "./factory/billing-map-routes.factory";
import BillingsNode from "../../common/types/billings-node";
import billingRecalculateRequestFactory from "./factory/billing-recalculate-request.factory";
import billingDataFactory from "./factory/billing-data.factory";
import BillingDetailsComponent from "./billing-details/billing-details.component";
import ContractDetailsComponent from "./contract-details/contract-details.component";
import AdditionalOptionsComponent from "./additional-options/additional-options.component";
import billingSaveRequestFactory from "./factory/billing-save-request.factory";
import BonusType from "./types/bonus-type";
import PenaltyType from "./types/penalty-type";
import RelatedBillingsCargoTaxiComponent from "../common/related-billings/cargo-taxi/related-billings-cargo-taxi.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import CardComponent from "../../../../common/components/card/card.component";
import Column from "../../../../common/components/grid/column";
import Row from "../../../../common/components/grid/row";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import BillingFormData from "./types/billing-form.data";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import billingTaxiDriverApiService from "./api/billings-taxi-driver-api.service";
import billingsApiService from "./api/billing-api.service";
import BillingDataResponse from "./api/billing-data.response";
import BillingGpsResponse from "./api/billing-gps-data.response";
import BillingSaveResponse from "./api/billing-save.response";
import BillingTaxiDriverEditRouteParams from "../../common/routes/types/billing-taxi-driver-edit-route-params";
import { useNavigate, useParams } from "react-router-dom";
import formValidationService from "../../../../common/utils/validation/form-validation.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import settlementRoutesHelper from "../../../settlement/common/routes/settlement-routes.helper";
import { useAppContext } from "../../../../context/app.context";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import billingBreadcrumbsHelper from "../../common/breadcrumbs/billings-breadcrumbs.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import BillingsSummaryConfirmationComponent from "./billings-summary-confirmation.component";
import RelatedBillingsTaxiTaxiComponent from "../common/related-billings/taxi-taxi/related-billings-taxi-taxi.component";
import billingRouteDetailsHelper from "../../common/route-details/common/billings-route-details.helper";
import { NodeValidationResult } from "../../common/route-details/types/billings-route-details-form-data-validation-result";
import BillingFormDataValidationResult from "./types/billing-form-data-validation-result";
import billingRouteDetailsDataValidationService from "../../common/route-details/common/billing-route-details-form-data-validation.service";
import BillingsRouteDetailsFormComponent from "../../common/route-details/billings-route-details-form.component";
import MessengerComponent from "../../../../common/components/messenger/messenger.component";
import billingsTaxiDriverHelper from "./billings-taxi-driver.helper";
import billingsMapMarkersFactory from "../../common/map-markers/billings-map-markers.factory";

type BillingsTaxiDriverAddProps = {};

const BillingsTaxiDriverEditComponent: FC<BillingsTaxiDriverAddProps> = () => {
  const { billingUuid } = useParams<BillingTaxiDriverEditRouteParams>();

  const { setBreadcrumbs, selectedAppLanguage } = useAppContext();

  const [routeId, setRouteId] = useState("");

  const [isBillingSavePending, setIsBillingSavePending] = useState(false);

  const [billingData, setBillingData] = useState<BillingFormData>();
  const [billingFormData, setBillingFormData] = useState<BillingFormData>();

  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();
  const [plannedMapRoute, setPlannedMapRoute] = useState<MapRoute | null>(null);
  const [completedMapRoute, setCompletedMapRoute] = useState<MapRoute | null>(
    null
  );
  const [isSummaryConfirmationVisible, setIsSummaryConfirmationVisible] =
    useState(false);

  const [planEntryUuid, setPlanEntryUuid] = useState("");

  const [shouldRetrieveRouteData, setShouldRetrieveRouteData] = useState(false);

  const [isBillingDataLoading, setIsBillingDataLoading] = useState(false);
  const [formValidationResults, setFormValidationResults] =
    useState<BillingFormDataValidationResult>({
      discountValue: formValidationService.defaultValidationResult,
      nodeValidations: [],
      penaltyValue: formValidationService.defaultValidationResult,
    });

  useEffect(() => {
    setIsBillingDataLoading(true);
    billingTaxiDriverApiService
      .fetchBillingData(billingUuid!)
      .then(handleBillingDataResponse)
      .finally(() => {
        setIsBillingDataLoading(false);
      });
  }, [billingUuid]);

  const navigate = useNavigate();

  const documentTitle = appTranslationsHelper
    .getDocumentTitleTranslations()
    .billingsTaxiWithDriverEdit.replace("#{routeId}", routeId);

  useDocumentTitle(documentTitle);

  useEffect(() => {
    const breadcrumbs =
      billingBreadcrumbsHelper.getTaxiWithDriverEditBreadcrumbs({
        billingUuid: billingUuid!,
        routeId,
      });

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage, routeId]);

  useEffect(() => {
    if (!planEntryUuid) {
      return;
    }

    billingsApiService.fetchGpsData(planEntryUuid).then(handleGpsDataResponse);
  }, [planEntryUuid]);

  const onModalClose = () => {
    setIsSummaryConfirmationVisible(false);
  };

  const openModal = () => {
    setIsSummaryConfirmationVisible(true);
  };

  useEffect(() => {
    if (!billingData || !shouldRetrieveRouteData) {
      return;
    }

    const mapMarkers = billingsMapMarkersFactory.createMapMarkers(
      billingData.billingNodes
    );

    const routeRequests =
      billingDataRouteRequestsFactory.createBillingDataRouteRequests(
        billingData.billingNodes
      );

    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    routeRequests.forEach((routeRequest) => {
      const fetch = billingTaxiDriverApiService.fetchRoute(routeRequest);

      fetchPromises.push(fetch);
    });

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response, index) => {
        const mapRoute = response.routes[0]
          ? billingMapRoutesFactory.createMapRoute(
              response.routes[0].geometry.coordinates
            )
          : null;

        if (mapRoute?.waypoints) {
          mapRouteWaypointGroups.push(mapRoute.waypoints);
        }
      });

      const newMapRouteWaypoints: MapRoute["waypoints"] = [];

      mapRouteWaypointGroups.forEach((waypoint) => {
        newMapRouteWaypoints.push(...waypoint);
      });

      const newMapRoute: MapRoute = {
        waypoints: newMapRouteWaypoints,
        options: { color: "red" },
      };

      setPlannedMapRoute(newMapRoute);
      setMapMarkers(mapMarkers);
    });
  }, [shouldRetrieveRouteData]);

  const recalculateBilling = (formData: BillingFormData) => {
    const recalculateRequest =
      billingRecalculateRequestFactory.createRecalculateRequest(formData);

    billingTaxiDriverApiService
      .recalculateBilling(planEntryUuid, recalculateRequest)
      .then(handleRecalculateResponse);
  };

  const handleBillingDataResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onBillingDataFetchSuccess(response);
    }
  };

  const handleGpsDataResponse = (response: BillingGpsResponse) => {
    if (response.status === 200) {
      onGpsDataFetchSuccess(response);
    }
  };

  const handleRecalculateResponse = (response: BillingDataResponse) => {
    if (response.status === 200) {
      onRecalculateSuccess(response);
    }
  };

  const navigateToListing = () => {
    navigate(settlementRoutesHelper.getTaxiListingOfSettledRoutesRoute());
  };

  const handleBillingSaveResponse = (response: BillingSaveResponse) => {
    if (response.status === 200) {
      notificationService.success(translations.successNotificationText);
      navigateToListing();

      return;
    }
    notificationService.error(translations.failureNotificationText);
  };

  const onBillingDataFetchSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);

    setBillingData(billingData);
    setBillingFormData(billingData);
    setPlanEntryUuid(response.data.plan_entry.id);
    setRouteId(String(response.data.plan_entry.human_id));
    setShouldRetrieveRouteData(true);

    setFormValidationResults((current) => ({
      discountValue: current.discountValue,
      nodeValidations: billingData.billingNodes.map((x) => {
        return {
          distance: formValidationService.defaultValidationResult,
          highwayCharge: formValidationService.defaultValidationResult,
          haltingTime: formValidationService.defaultValidationResult,
          position: x.position,
        };
      }),
      penaltyValue: current.penaltyValue,
    }));
  };

  const onGpsDataFetchSuccess = (response: BillingGpsResponse) => {
    const gpsData: MapRoute = {
      waypoints: response.data.map((x) => {
        return { latitude: x.lat, longitude: x.lon };
      }),
      options: { color: "blue" },
    };

    setCompletedMapRoute(gpsData);
  };

  const onBillingDataSave = () => {
    const areNodesValid =
      billingFormData?.billingNodes
        .map((node) => {
          return billingRouteDetailsHelper.haltingTimeValidation(
            formValidationResults,
            node.haltingTime,
            node.position
          );
        })
        .every((x) => x) ?? false;

    const isFormValid = areNodesValid && validatePenalty();

    if (!isFormValid) return;

    if (billingFormData) {
      setIsBillingSavePending(true);
      const data =
        billingSaveRequestFactory.createSaveBillingRequest(billingFormData);

      billingTaxiDriverApiService
        .saveBillingData(billingUuid!, data)
        .then(handleBillingSaveResponse)
        .finally(() => setIsBillingSavePending(false));
    }
  };

  const onPenaltyBlur = () => {
    const shouldRecalculate = validatePenalty();

    if (shouldRecalculate) {
      recalculateBilling(billingFormData!);
    }
  };

  const onCopyFromPlannedDistance = () => {
    const newBillingNodes = billingFormData?.billingNodes.map((node) => {
      node.distance = node.plannedDistance;

      return node;
    });

    const newBillingFormData: BillingFormData = {
      ...billingFormData!,
      billingNodes: newBillingNodes!,
    };

    setBillingFormData(newBillingFormData);

    const result = billingRouteDetailsHelper.validateAllFieldsByType(
      formValidationResults,
      newBillingFormData.billingNodes,
      "distance"
    );

    nodeValidationResults(result.nodeResult!);

    if (result.isAllFormValid) recalculateBilling(newBillingFormData);
  };

  const validatePenalty = () => {
    const maximumPenaltyValue = billingData?.total ?? 0;

    const validationResult =
      billingRouteDetailsDataValidationService.validatePenalty(
        billingFormData?.penalty ?? 0,
        maximumPenaltyValue
      );

    setFormValidationResults((curr) => ({
      ...curr,
      penaltyValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const onBonusBlur = () => {
    const shouldRecalculate = validateBonus();

    if (shouldRecalculate) recalculateBilling(billingFormData!);
  };

  const validateBonus = () => {
    const validationResult =
      billingRouteDetailsDataValidationService.validateBonus(
        billingFormData?.bonus!
      );

    setFormValidationResults((curr) => ({
      ...curr,
      discountValue: validationResult,
    }));

    return validationResult.isValid;
  };

  const validationOnBlur = (position: number, fieldName: string) => {
    type Node = keyof typeof node;

    const node = billingFormData?.billingNodes.find(
      (x) => x.position === position
    );

    const fieldToValidate = node && node[fieldName as Node];

    const results = billingRouteDetailsHelper.validationOnBlur(
      fieldToValidate,
      formValidationResults,
      position,
      fieldName
    );

    nodeValidationResults(results.nodeResult!);

    if (results.isAllFormValid) recalculateBilling(billingFormData!);
  };

  const nodeValidationResults = (node: NodeValidationResult[]) => {
    setFormValidationResults((curr) => ({
      ...curr,
      nodeValidations: node,
    }));
  };

  const onBonusChanged = (bonusValue: number) => {
    setBillingFormData((current) => ({ ...current!, bonus: bonusValue }));
  };

  const onBonusTypeChanged = (bonusType: BonusType) => {
    setBillingFormData((current) => ({
      ...current!,
      bonusType: bonusType,
    }));
  };

  const onPenaltyChanged = (penaltyValue: number) => {
    setBillingFormData((current) => ({ ...current!, penalty: penaltyValue }));
  };

  const onPenaltyTypeChanged = (penaltyType: PenaltyType) => {
    setBillingFormData((current) => ({
      ...current!,
      penaltyType: penaltyType,
    }));
  };

  const onBillingNodeChanged = (node: BillingsNode) => {
    const nodes = billingFormData?.billingNodes.filter(
      (x) => x.position !== node.position
    )!;

    nodes.push(node);

    const sortedArray = nodes.sort((a, b) =>
      a.position < b.position ? -1 : 1
    );

    setBillingFormData((current) => ({
      ...current!,
      billingNodes: sortedArray,
    }));
  };

  const onInputBlur = () => {
    recalculateBilling(billingFormData!);
  };

  const onRecalculateSuccess = (response: BillingDataResponse) => {
    const billingData = billingDataFactory.createBillingData(response.data);
    setBillingData(billingData);
    setBillingFormData(billingData);
  };

  const mapRoutes: MapRoute[] = useMemo(() => {
    const finalMapRoutes: MapRoute[] = [];
    if (plannedMapRoute) {
      finalMapRoutes.push(plannedMapRoute);
    }
    if (completedMapRoute) {
      finalMapRoutes.push(completedMapRoute);
    }
    return finalMapRoutes;
  }, [plannedMapRoute, completedMapRoute]);

  const translations =
    billingsTranslationsHelper.getTaxiDriverEditBillingsTranslations();

  const messengerChannelsAvailability =
    billingsTaxiDriverHelper.getMessengerChannelAvailability();

  return (
    <>
      <div className="billings">
        <HeadingComponent
          title={translations.header.headingText.replace("#{routeId}", routeId)}
        />
        <Row>
          <Column lg={8}>
            <Row>
              <Column withPaddings>
                <div className="billings_map_wrapper">
                  <MapComponent
                    markers={mapMarkers}
                    autoFit
                    autoFitOnUpdate
                    routes={mapRoutes}
                  />
                </div>
              </Column>
              <Column withPaddings>
                <ContractDetailsComponent
                  isLoading={isBillingDataLoading}
                  contractDetails={billingData?.contractDetails ?? null}
                />
              </Column>
              <Column withPaddings>
                <Row>
                  <Column lg={9}>
                    <BillingsRouteDetailsFormComponent
                      billingNodes={billingFormData?.billingNodes}
                      formValidationResults={
                        formValidationResults.nodeValidations
                      }
                      onBillingNodeChanged={onBillingNodeChanged}
                      onInputBlur={onInputBlur}
                      onCopyFromPlannedDistance={onCopyFromPlannedDistance}
                      validationOnBlur={validationOnBlur}
                    />
                  </Column>
                  <Column lg={3}>
                    <AdditionalOptionsComponent
                      billingData={billingFormData}
                      onBonusChanged={onBonusChanged}
                      onBonusTypeChanged={onBonusTypeChanged}
                      onPenaltyChanged={onPenaltyChanged}
                      onPenaltyTypeChanged={onPenaltyTypeChanged}
                      onPenaltyBlur={onPenaltyBlur}
                      onBonusBlur={onBonusBlur}
                      formValidationResults={formValidationResults}
                    />
                  </Column>
                </Row>
              </Column>
              <Column withPaddings>
                <CardComponent
                  classNames={{ root: "billing_details" }}
                  header={{ title: translations.billingSummary.summaryLabel }}
                >
                  <BillingDetailsComponent billingData={billingData} />
                </CardComponent>
              </Column>
              <Column>
                <ButtonComponent type="primary" onClick={openModal}>
                  {translations.submitLabel}
                </ButtonComponent>
              </Column>
              <Column withPaddings>
                <RelatedBillingsCargoTaxiComponent
                  planEntryId={planEntryUuid}
                />
              </Column>
              <Column withPaddings>
                <RelatedBillingsTaxiTaxiComponent planEntryId={planEntryUuid} />
              </Column>
            </Row>
          </Column>
          <Column lg={4} withPaddings>
            <CardComponent classNames={{ root: "h-50", content: "h-100" }}>
              {!!planEntryUuid.length && (
                <MessengerComponent
                  channelsAvailability={messengerChannelsAvailability}
                  planEntryUuid={planEntryUuid}
                />
              )}
            </CardComponent>
          </Column>
        </Row>
        <BillingsSummaryConfirmationComponent
          isVisible={isSummaryConfirmationVisible}
          onSubmit={onBillingDataSave}
          onClose={onModalClose}
          billingData={billingData!}
          isLoading={isBillingSavePending}
        />
      </div>
    </>
  );
};

export default BillingsTaxiDriverEditComponent;
