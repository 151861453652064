import Joi from "joi";
import RouteActiveListingByRailyFilter, {
  RouteActiveListingByRailyDriverFilter,
  RouteActiveListingByRailyEndDateFilter,
  RouteActiveListingByRailyPassengersFilter,
  RouteActiveListingByRailyRouteAddressFilter,
  RouteActiveListingByRailyRouteIdFilter,
  RouteActiveListingByRailyStartDateFilter,
  RouteActiveListingByRailyTaxiCorporationFilter,
} from "./types/route-active-listing-by-raily-filter";
import RouteActiveListingByRailyFilterType from "./types/route-active-listing-by-raily-filter-type";
import RouteActiveListingByRailyRouteQueryFilterParams from "./types/route-active-listing-by-raily-route-query-filter-params";
import RouteActiveListingByRailyRouteQueryParams from "./types/route-active-listing-by-raily-route-query-params";
import RouteActiveListingByRailySortKey from "./types/route-active-listing-by-raily-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteActiveListingByRailyRouteQueryFilterParams
): RouteActiveListingByRailyFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: RouteActiveListingByRailyFilter[] = [];

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteActiveListingByRailyDriverFilter = {
      type: RouteActiveListingByRailyFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.endDateFrom && routeQueryFilterParams.endDateTo) {
    const endDateFilter: RouteActiveListingByRailyEndDateFilter = {
      type: RouteActiveListingByRailyFilterType.END_DATE,
      value: {
        from: new Date(routeQueryFilterParams.endDateFrom),
        to: new Date(routeQueryFilterParams.endDateTo),
      },
    };

    filters.push(endDateFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: RouteActiveListingByRailyStartDateFilter = {
      type: RouteActiveListingByRailyFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteActiveListingByRailyPassengersFilter = {
      type: RouteActiveListingByRailyFilterType.PASSENGERS,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.taxiCorporation) {
    const taxiCorporationFilter: RouteActiveListingByRailyTaxiCorporationFilter =
      {
        type: RouteActiveListingByRailyFilterType.TAXI_CORPORATION,
        value: routeQueryFilterParams.taxiCorporation,
      };

    filters.push(taxiCorporationFilter);
  }

  if (routeQueryFilterParams.routeAddress) {
    const routeAddressFilter: RouteActiveListingByRailyRouteAddressFilter = {
      type: RouteActiveListingByRailyFilterType.ROUTE_ADDRESS,
      value: routeQueryFilterParams.routeAddress,
    };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: RouteActiveListingByRailyRouteIdFilter = {
      type: RouteActiveListingByRailyFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };

    filters.push(routeIdFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteActiveListingByRailyRouteQueryParams
): RouteActiveListingByRailySortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: RouteActiveListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: RouteActiveListingByRailyRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: RouteActiveListingByRailyFilter[],
  sortKey: RouteActiveListingByRailySortKey | null,
  page: number,
  pageSize: number
): RouteActiveListingByRailyRouteQueryParams => {
  const routeQueryParams: RouteActiveListingByRailyRouteQueryParams = {
    driver: filters.find(
      (filter) => filter.type === RouteActiveListingByRailyFilterType.DRIVER
    )?.value as RouteActiveListingByRailyDriverFilter["value"] | undefined,
    endDateFrom: (
      filters.find(
        (filter) => filter.type === RouteActiveListingByRailyFilterType.END_DATE
      )?.value as RouteActiveListingByRailyEndDateFilter["value"] | undefined
    )?.from.toJSON(),
    endDateTo: (
      filters.find(
        (filter) => filter.type === RouteActiveListingByRailyFilterType.END_DATE
      )?.value as RouteActiveListingByRailyEndDateFilter["value"] | undefined
    )?.to.toJSON(),
    passenger: filters.find(
      (filter) => filter.type === RouteActiveListingByRailyFilterType.PASSENGERS
    )?.value as RouteActiveListingByRailyPassengersFilter["value"] | undefined,
    routeAddress: filters.find(
      (filter) =>
        filter.type === RouteActiveListingByRailyFilterType.ROUTE_ADDRESS
    )?.value as
      | RouteActiveListingByRailyRouteAddressFilter["value"]
      | undefined,
    routeId: filters.find(
      (filter) => filter.type === RouteActiveListingByRailyFilterType.ROUTE_ID
    )?.value as RouteActiveListingByRailyRouteIdFilter["value"] | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteActiveListingByRailyFilterType.START_DATE
      )?.value as RouteActiveListingByRailyStartDateFilter["value"] | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteActiveListingByRailyFilterType.START_DATE
      )?.value as RouteActiveListingByRailyStartDateFilter["value"] | undefined
    )?.to.toJSON(),
    taxiCorporation: filters.find(
      (filter) =>
        filter.type === RouteActiveListingByRailyFilterType.TAXI_CORPORATION
    )?.value as
      | RouteActiveListingByRailyTaxiCorporationFilter["value"]
      | undefined,

    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: RouteActiveListingByRailyRouteQueryFilterParams
) => {
  const filterParams: RouteActiveListingByRailyRouteQueryFilterParams = {
    driver: routeQueryFilterParams.driver,
    endDateFrom: routeQueryFilterParams.endDateFrom,
    endDateTo: routeQueryFilterParams.endDateTo,
    passenger: routeQueryFilterParams.passenger,
    routeAddress: routeQueryFilterParams.routeAddress,
    routeId: routeQueryFilterParams.routeId,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
    taxiCorporation: routeQueryFilterParams.taxiCorporation,
  };

  const validationSchema =
    Joi.object<RouteActiveListingByRailyRouteQueryFilterParams>({
      driver: Joi.string(),
      endDateFrom: Joi.string(),
      endDateTo: Joi.string(),
      passenger: Joi.string(),
      routeAddress: Joi.string(),
      routeId: Joi.number(),
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
      taxiCorporation: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: RouteActiveListingByRailyRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(RouteActiveListingByRailySortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: RouteActiveListingByRailyRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: RouteActiveListingByRailyRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const routeActiveListingByRailyRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeActiveListingByRailyRouteQueryParamsService;
