import googleMapsService from "../../../../../common/services/google-maps/google-maps.service";
import BillingModel from "../../../types/billing-model";
import { BillingData } from "../api/billing-data.response";
import BillingFormData from "../types/billing-form.data";
import BillingsNode from "../../../common/types/billings-node";

const createBillingNodes = (data: BillingData): BillingsNode[] => {
  return data.billing_nodes.map((x, index) => {
    const previousItem = index - 1 >= 0 ? data.billing_nodes[index - 1] : null;

    const url =
      previousItem !== null
        ? googleMapsService.buildRouteUrl(
            { latitute: previousItem.lat, longitude: previousItem.lon },
            { latitute: x.lat, longitude: x.lon }
          )
        : "";

    const result: BillingsNode = {
      id: x.id,
      nodeId: x.node_id,
      lat: x.lat,
      lon: x.lon,
      description: x.description,
      checkoutDate: x.checkout_date ? new Date(x.checkout_date) : null,
      plannedDate: x.planned_date ? new Date(x.planned_date) : null,
      plannedDistance: x.planned_distance,
      orderId: x.order_id,
      distance: x.distance,
      haltingTime: x.halting_time,
      haltingAmount: x.halting_amount,
      highwayCharge: x.highway_charge,
      isHighwayAllowed: x.allow_charge,
      isEditable: x.editable_coordinates,
      googleMapsUrl: url,
      position: index + 1,
    };

    return result;
  });
};

const createBillingData = (billingData: BillingData) => {
  const billingDraft: BillingFormData = {
    billingNodes: createBillingNodes(billingData),
    contractDetails: {
      companyName: billingData.contract.cargo_company.company_name,
      discountLimit: billingData.contract.discount_limit,
      haltingTimeRate: billingData.contract.halting_time_rate,
      distanceRate: billingData.contract.distance_rate,
      isHighwayAllowedDuringRide: billingData.contract.allow_charge_during_ride,
      isHighwayAllowedWhenApproaching:
        billingData.contract.allow_charge_while_approaching,
      isHighwayAllowedWhileReturning:
        billingData.contract.allow_charge_while_returning,
      model: billingData.contract.model as BillingModel,
      haltingTimeAppliedAfter: billingData.contract.halting_time_after_minutes,
    },
    baseAmount: billingData.base_amount,
    discountAmount: billingData.discount_amount,
    haltingCost: billingData.halting_amount,
    highwayCharge: billingData.highway_charge,
    distance: billingData.distance,
    routeDistanceCost: billingData.distance_amount,
    internalOrderId: billingData.cargo_order.human_id,
    discount: billingData.discount,
    total: billingData.total_amount,
    planEntryId: billingData.plan_entry_id,
    cargoOrderId: billingData.cargo_order.id,
    date: new Date(billingData.date),
  };

  return billingDraft;
};

const billingDataFactory = {
  createBillingData,
};

export default billingDataFactory;
