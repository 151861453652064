import RouteActiveListingByRailyListingItem from "../types/route-active-listing-by-raily-item";
import RouteActiveListingByRailySortKey from "../types/route-active-listing-by-raily-sort-key";

const sortListingItemsByDriverNameAsc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.driverName.localeCompare(itemB.driverName)
  );
};

const sortListingItemsByDriverNameDesc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.driverName.localeCompare(itemA.driverName)
  );
};

const sortListingItemsByEndDateAsc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.endDate.getTime() - itemB.endDate.getTime()
  );
};

const sortListingItemsByEndDateDesc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.endDate.getTime() - itemA.endDate.getTime()
  );
};

const sortListingItemsByRouteIdAsc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) => itemA.routeId - itemB.routeId);
};

const sortListingItemsByRouteIdDesc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) => itemB.routeId - itemA.routeId);
};

const sortListingItemsByStartDateAsc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemA.startDate.getTime() - itemB.startDate.getTime()
  );
};

const sortListingItemsByStartDateDesc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort(
    (itemA, itemB) => itemB.startDate.getTime() - itemA.startDate.getTime()
  );
};

const sortListingItemsByTaxiCorporationAsc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemA.taxiCorporationName.localeCompare(itemB.taxiCorporationName)
  );
};

const sortListingItemsByTaxiCorporationDesc = (
  listingItems: RouteActiveListingByRailyListingItem[]
): RouteActiveListingByRailyListingItem[] => {
  return listingItems.sort((itemA, itemB) =>
    itemB.taxiCorporationName.localeCompare(itemA.taxiCorporationName)
  );
};

const sortListingItems = (
  listingItems: RouteActiveListingByRailyListingItem[],
  sortKey: RouteActiveListingByRailySortKey | null
): RouteActiveListingByRailyListingItem[] => {
  switch (sortKey) {
    case RouteActiveListingByRailySortKey.DRIVER_NAME_ASC:
      return sortListingItemsByDriverNameAsc(listingItems);
    case RouteActiveListingByRailySortKey.DRIVER_NAME_DESC:
      return sortListingItemsByDriverNameDesc(listingItems);
    case RouteActiveListingByRailySortKey.END_DATE_ASC:
      return sortListingItemsByEndDateAsc(listingItems);
    case RouteActiveListingByRailySortKey.END_DATE_DESC:
      return sortListingItemsByEndDateDesc(listingItems);
    case RouteActiveListingByRailySortKey.ROUTE_ID_ASC:
      return sortListingItemsByRouteIdAsc(listingItems);
    case RouteActiveListingByRailySortKey.ROUTE_ID_DESC:
      return sortListingItemsByRouteIdDesc(listingItems);
    case RouteActiveListingByRailySortKey.START_DATE_ASC:
      return sortListingItemsByStartDateAsc(listingItems);
    case RouteActiveListingByRailySortKey.START_DATE_DESC:
      return sortListingItemsByStartDateDesc(listingItems);
    case RouteActiveListingByRailySortKey.TAXI_CORPORATION_ASC:
      return sortListingItemsByTaxiCorporationAsc(listingItems);
    case RouteActiveListingByRailySortKey.TAXI_CORPORATION_DESC:
      return sortListingItemsByTaxiCorporationDesc(listingItems);
    default:
      return listingItems;
  }
};

const routeActiveListingByRailySortService = {
  sortListingItems,
};

export default routeActiveListingByRailySortService;
