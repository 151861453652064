import isEqual from "lodash/isEqual";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteActiveListingByTaxiFilter from "../types/route-active-listing-by-taxi-filter";
import RouteActiveListingByTaxiFilterSelectOption, {
  RouteActiveListingByTaxiRouteIdFilterSelectOption,
} from "../types/route-active-listing-by-taxi-filter-select-option";
import RouteActiveListingByTaxiFilterType from "../types/route-active-listing-by-taxi-filter-type";

const getSearchQueryOptions = (
  query: string
): RouteActiveListingByTaxiFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    routeTranslationsHelper.getActiveListingTranslations().filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByDriverQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengersQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengersTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIdQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByDriverQueryLabel(query),
      value: {
        type: RouteActiveListingByTaxiFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByPassengersQueryLabel(query),
      value: {
        type: RouteActiveListingByTaxiFilterType.PASSENGERS,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressQueryLabel(query),
      value: {
        type: RouteActiveListingByTaxiFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
  ];

  const options: RouteActiveListingByTaxiFilterSelectOption[] = [
    ...stringOptions,
  ] as RouteActiveListingByTaxiFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: RouteActiveListingByTaxiRouteIdFilterSelectOption =
      {
        label: getSearchByRouteIdQueryLabel(query),
        value: {
          type: RouteActiveListingByTaxiFilterType.ROUTE_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSelectOptions = (
  query: string,
  selectedFilters: RouteActiveListingByTaxiFilter[]
): RouteActiveListingByTaxiFilterSelectOption[] => {
  const allFilterOptions = getSearchQueryOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const routeActiveListingByTaxiFilterHelper = {
  getSelectOptions,
};

export default routeActiveListingByTaxiFilterHelper;
