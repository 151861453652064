import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import OrderAddCargoAddress from "../../types/order-add-cargo-address";
import OrderAddPassenger, {
  OrderAddPassengerAddress,
} from "../../types/order-add-passenger";
import OrderAddRouteAddress from "../../types/order-add-route-address";
import OrderAddRouteAddressSelectOption from "../../types/order-add-route-address-select-option";
import {
  OrderAddRouteWaypointGroupWaypoint,
  OrderAddRouteWaypoint,
} from "../../types/order-add-route-waypoint";
import orderAddRoutesHelper from "./order-add-routes.helper";

const createWaypointFromGroupWaypoint = (
  waypoint: OrderAddRouteWaypointGroupWaypoint
): OrderAddRouteWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    date: null,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createGroupWaypointFromWaypoint = (
  waypoint: OrderAddRouteWaypoint
): OrderAddRouteWaypointGroupWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createRouteAddressSelectOptionFromPassengerAddress = (
  passenger: OrderAddPassenger,
  passengerAddress: OrderAddPassengerAddress
): OrderAddRouteAddressSelectOption => {
  const translations =
    orderTranslationsHelper.getAddTranslations().routes.waypoint;

  return {
    label: passengerAddress.displayName,
    subText: `${translations.passengerAddressHomeType}: ${passenger.firstName} ${passenger.lastName}`,
    value: passengerAddress,
  };
};

const createRouteAddressFromCargoAddress = (
  cargoAddress: OrderAddCargoAddress
): OrderAddRouteAddress => {
  return {
    uuid: cargoAddress.uuid,
    displayName: cargoAddress.displayName,
    latitude: cargoAddress.latitude,
    longitude: cargoAddress.longitude,
  };
};

const createRouteAddressSelectOptionFromCargoAddress = (
  cargoAddress: OrderAddCargoAddress
): OrderAddRouteAddressSelectOption => {
  const subText =
    orderAddRoutesHelper.getRouteAddressSelectOptionSubTextForCargoAddress(
      cargoAddress.type
    );
  const value = createRouteAddressFromCargoAddress(cargoAddress);

  return {
    label: cargoAddress.displayName,
    subText: subText,
    value,
  };
};

const orderAddRoutesFactory = {
  createWaypointFromGroupWaypoint,
  createGroupWaypointFromWaypoint,
  createRouteAddressSelectOptionFromPassengerAddress,
  createRouteAddressSelectOptionFromCargoAddress,
};

export default orderAddRoutesFactory;
