import { FC, useEffect, useMemo, useState } from "react";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import { useAppContext } from "../../../../context/app.context";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import TaxiTransferedOrderSettlementListingRouteQueryParams from "./common/types/taxi-transfered-order-settlement-listing-route-query-params";
import settlementTranslationsHelper from "../../../../languages/settlement-translations.helper";
import TaxiTransferedOrderSettlementListingFilter, {
  TaxiTransferedOrderSettlementListingOrderStartDateFilter,
} from "./common/types/taxi-transfered-order-settlement-listing-filter";
import taxiTransferedOrderSettlementListingRouteQueryParamsService from "./common/taxi-order-settlement-listing-route-query-params.service";
import TaxiTransferedOrderSettlementListingItem from "./common/types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingSortKey from "./common/types/taxi-transfered-order-settlement-listing-sort-key";
import TaxiTransferedOrderSettlementListingSortSelectOption from "./common/types/taxi-transfered-order-settlement-listing-sort-select-option";
import taxiTransferedOrderSettlementListingSortHelper from "./common/helper/taxi-transfered-order-settlement-listing-sort.helper";
import usePagination from "../../../../common/hooks/use-pagination";
import TaxiTransferedOrderSettlementListingResponse, {
  TaxiTransferedOrderSettlementListingResponseData,
} from "./common/api/taxi-transfered-order-settlement-listing.response";
import taxiTransferedOrderSettlementListingFactory from "./common/factory/taxi-transfered-order-settlement-listing.factory";
import taxiTransferedOrderSettlementListingRequestFactory from "./common/factory/taxi-transfered-order-settlement-listing-request.factory";
import taxiTransferedOrderSettlementListingApiService from "./common/api/taxi-transfered-order-settlement-listing-api.service";
import TaxiTransferedOrderSettlementListingFilterType from "./common/types/taxi-transfered-order-settlement-listing-filter-type";
import TaxiTransferedOrderSettlementListingReportResponse, {
  TaxiTransferedOrderSettlementListingReportResponseData,
} from "./common/api/taxi-transfered-order-settlement-listing-report.response";
import fileDownloadService from "../../../../common/utils/file-download/file-download.service";
import notificationService from "../../../../common/utils/notification/notification.service";
import ButtonComponent from "../../../../common/components/button/button.component";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import TaxiTransferedOrderSettlementListingFiltersSelectComponent from "./common/filters/select/taxi-transfered-order-settlement-listing-filters-select.component";
import TaxiTransferedOrderSettlementListingFiltersBadgeListComponent from "./common/filters/list/taxi-transfered-order-settlement-listing-filters-badge-list.component";
import CardComponent from "../../../../common/components/card/card.component";
import TaxiTransferedOrderSettlementListingTableComponent from "./common/table/taxi-transfered-order-settlement-listing-table.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import taxiTransferedOrderSettlementListingReportRequestFactory from "./common/factory/taxi-transfered-order-settlement-listing-report-request.factory";
import settlementBreadcrumbsHelper from "../../common/breadcrumbs/settlement-breadcrumbs.helper";
import DateRange from "../../../../common/types/date-range";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import { useTaxiTransferedOrderSettlementStatuses } from "./common/status-legend/hooks/use-taxi-transfered-order-settlement-statuses.hook";
import StatusLegendComponent from "../../../../common/components/status-legend/status-legend.component";

type TaxiTransferedOrderSettlementListingProps = {};

const TaxiTransferedOrderSettlementListingComponent: FC<
  TaxiTransferedOrderSettlementListingProps
> = () => {
  const isComponentMounted = useIsComponentMounted();
  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  const { user } = useAppContext();
  const { statusOptions } = useTaxiTransferedOrderSettlementStatuses();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiTransferedOrderSettlementListingRouteQueryParams>();

  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.settlementsTaxiSettledTransferedOrderListing
  );

  const [filters, setFilters] = useState<
    TaxiTransferedOrderSettlementListingFilter[]
  >(() =>
    taxiTransferedOrderSettlementListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiTransferedOrderSettlementListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiTransferedOrderSettlementListingSortKey | null>(
      () =>
        taxiTransferedOrderSettlementListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: TaxiTransferedOrderSettlementListingSortSelectOption[] =
    useMemo(
      () => taxiTransferedOrderSettlementListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiTransferedOrderSettlementListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiTransferedOrderSettlementListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    response: TaxiTransferedOrderSettlementListingResponseData
  ) => {
    const listingItems =
      taxiTransferedOrderSettlementListingFactory.createListingItems(
        response.data
      );

    setListingItems(listingItems);
    setTotalResults(response.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiTransferedOrderSettlementListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const taxiCorporationId = user?.aspects.taxiOfficer?.taxiCorporationUuid;

    const request =
      taxiTransferedOrderSettlementListingRequestFactory.createRequest(
        taxiCorporationId,
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    taxiTransferedOrderSettlementListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    const breadcrumbs =
      settlementBreadcrumbsHelper.getTaxiSettledTransferedOrderListingBreadcrumbs();
    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [filters, selectedSortKey, page, pageSize]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiTransferedOrderSettlementListingRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (
    newFilter: TaxiTransferedOrderSettlementListingFilter
  ) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type ===
      TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiTransferedOrderSettlementListingOrderStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !==
          TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type ===
        TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiTransferedOrderSettlementListingOrderStartDateFilter =
      {
        type: TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE,
        value: {
          from: dateRange.from!,
          to: dateRange.to!,
        },
      };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiTransferedOrderSettlementListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: TaxiTransferedOrderSettlementListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: TaxiTransferedOrderSettlementListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const taxiCorporationId = user?.aspects.taxiOfficer?.taxiCorporationUuid;

    const request =
      taxiTransferedOrderSettlementListingReportRequestFactory.createRequest(
        taxiCorporationId,
        filters,
        selectedSortKey
      );

    taxiTransferedOrderSettlementListingApiService
      .fetchReport(request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting={`taxi-transfered-order-settlement-listing-report-download-button`}
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  return (
    <div className="taxi_transfered_order_settlements_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[ReportDownloadButton]}
      />
      <StatusLegendComponent statusData={statusOptions} />
      <>
        <div className="taxi_transfered_order_settlements_listing_tools">
          <div className="d-flex">
            <TaxiTransferedOrderSettlementListingFiltersSelectComponent
              filters={filters}
              onAddNewFilter={addNewFilter}
            />
            <DateRangeInputComponent
              date={startDateFilterValue ?? null}
              onChange={onOrderStartDateFilterValueChange}
              classNames={{ root: "ml-2" }}
              placeholder={
                translations.filters.searchByStartDateSelectInputPlaceholder
              }
              idForTesting={`taxi-transfered-order-settlement-listing-date-range`}
            />
          </div>
          <ListingSortSelectComponent
            onChange={(option) =>
              setSelectedSortKey(
                (
                  option as TaxiTransferedOrderSettlementListingSortSelectOption | null
                )?.value ?? null
              )
            }
            options={sortSelectOptions}
            value={selectedSortSelectOption}
            idForTesting={`taxi-transfered-order-settlement-listing-sort`}
          />
        </div>
        <TaxiTransferedOrderSettlementListingFiltersBadgeListComponent
          filters={filters}
          onDeleteFilterClick={deleteFilter}
          onDeleteAllFiltersButtonClick={deleteAllFilters}
        />
        <CardComponent classNames={{ root: "mt-4" }}>
          <TaxiTransferedOrderSettlementListingTableComponent
            listingItems={listingItems}
            isError={isListingFetchingError}
            isLoading={isListingFetching}
          />
          <div className="taxi_transfered_order_settlements_listing__pagination_wrapper">
            <PaginationComponent
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              page={page}
              pageSize={pageSize}
              totalResults={totalResults}
            />
          </div>
        </CardComponent>
      </>
    </div>
  );
};

export default TaxiTransferedOrderSettlementListingComponent;
