import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiOrderSettlementListingItem from "../types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingTableColumn from "../types/taxi-order-settlement-listing-table-column";
import TaxiOrderSettlementListingTableRow from "../types/taxi-order-settlement-listing-table-row";
import dateService from "../../../../../../common/utils/date/date.service";
import TaxiOrderSettlementListingTableStatusComponent from "./status/taxi-order-settlement-listing-table-status.component";
import TaxiOrderSettlementListingBonusReason from "../types/taxi-order-settlement-listing-bonus-reason";
import TaxiOrderSettlementListingPenaltyReason from "../types/taxi-order-settlement-listing-penalty-reason";
import taxiOrderSettlementListingHelper from "../taxi-order-settlement-listing.helper";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faCircleInfo, faEdit } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";
import TaxiOrderSettlementListingItemBillingType from "../types/taxi-order-settlement-listing-item-billing-type";
import TaxiOrderSettlementListingItemBillingStatus from "../types/taxi-order-settlement-listing-item-billing-status";
import { TaxiOrderSettlementListingUserPermissions } from "../user-permissions/taxi-order-settlement-listing-user-permission";

const getColumns = (): TaxiOrderSettlementListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .table.headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "externalOrderId",
      header: translations.externalOrderIdLabel,
      title: translations.externalOrderIdTitle,
      colSpan: 8,
    },
    {
      accessor: "cargoCompany",
      header: translations.cargoCompanyLabel,
      title: translations.cargoCompanyTitle,
      colSpan: 14,
    },
    {
      accessor: "destinationTaxi",
      header: translations.destinationTaxiLabel,
      title: translations.destinationTaxiTitle,
      colSpan: 14,
    },
    {
      accessor: "passengers",
      header: translations.passengersLabel,
      title: translations.passengersTitle,
      colSpan: 15,
    },
    {
      accessor: "billingModel",
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      colSpan: 6,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceRate",
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceCost",
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      colSpan: 10,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 10,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "billingType",
      header: translations.billingTypeLabel,
      title: translations.billingTypeTitle,
      colSpan: 10,
    },
    {
      accessor: "discountAmount",
      header: translations.discountAmountLabel,
      title: translations.discountAmountTitle,
      colSpan: 6,
    },
    {
      accessor: "discountSavings",
      header: translations.discountSavingsLabel,
      title: translations.discountSavingsTitle,
      colSpan: 9,
    },
    {
      accessor: "bonus",
      header: translations.bonusLabel,
      title: translations.bonusTitle,
      colSpan: 9,
    },
    {
      accessor: "penalty",
      header: translations.penaltyLabel,
      title: translations.penaltyTitle,
      colSpan: 9,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 10,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceRateLabel = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDistanceRateTitle = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getDiscountAmountLabel = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getDiscountAmountTitle = (discountAmountPercent: number): string => {
  return `${discountAmountPercent}%`;
};

const getDiscountCostLabel = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getDiscountCostTitle = (discountCost: number): string => {
  return `${String(discountCost.toFixed(2))} PLN`;
};

const getDistanceCostLabel = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getDistanceCostTitle = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getBonusReasonText = (
  bonusReason: TaxiOrderSettlementListingBonusReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .bonusReason;

  switch (bonusReason) {
    case TaxiOrderSettlementListingBonusReason.DISTANCE_RATE_CHANGED:
      return translations.DISTANCE_RATE_CHANGED;
    case TaxiOrderSettlementListingBonusReason.HALTING_RATE_CHANGED:
      return translations.HALTING_RATE_CHANGED;
    case TaxiOrderSettlementListingBonusReason.OTHER:
      return translations.OTHER;
    case TaxiOrderSettlementListingBonusReason.SHORT_ROUTE:
      return translations.SHORT_ROUTE;
  }
};

const getPenaltyReasonText = (
  penaltyReason: TaxiOrderSettlementListingPenaltyReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .penaltyReason;

  switch (penaltyReason) {
    case TaxiOrderSettlementListingPenaltyReason.BEING_LATE:
      return translations.BEING_LATE;
    case TaxiOrderSettlementListingPenaltyReason.GUIDELINE_IGNORED:
      return translations.GUIDELINE_IGNORED;
    case TaxiOrderSettlementListingPenaltyReason.HIGHWAY_NOT_ALLOWED:
      return translations.HIGHWAY_NOT_ALLOWED;
    case TaxiOrderSettlementListingPenaltyReason.INCOMPATIBLE_CAR:
      return translations.INCOMPATIBLE_CAR;
    case TaxiOrderSettlementListingPenaltyReason.OPTIMIZATION_REFUSED:
      return translations.OPTIMIZATION_REFUSED;
    case TaxiOrderSettlementListingPenaltyReason.OTHER:
      return translations.OTHER;
    case TaxiOrderSettlementListingPenaltyReason.SUBOPTIMAL_RIDE:
      return translations.SUBOPTIMAL_RIDE;
  }
};

const getBonusLabel = (
  bonus: TaxiOrderSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
        .table.bonusLabelTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getBonusTitle = (
  bonus: TaxiOrderSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
        .table.bonusTitleTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getPenaltyLabel = (
  penalty: TaxiOrderSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
        .table.penaltyLabelTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getPenaltyTitle = (
  penalty: TaxiOrderSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
        .table.penaltyTitleTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getRow = (
  listingItem: TaxiOrderSettlementListingItem,
  userPermissions: TaxiOrderSettlementListingUserPermissions
): TaxiOrderSettlementListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const distanceRateLabel = getDistanceRateLabel(listingItem.distanceRate);
  const distanceRateTitle = getDistanceRateTitle(listingItem.distanceRate);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const discountAmountLabel = getDiscountAmountLabel(
    listingItem.discountAmountPercent
  );
  const discountAmountTitle = getDiscountAmountTitle(
    listingItem.discountAmountPercent
  );

  const discountCostLabel = getDiscountCostLabel(listingItem.discountCost);
  const discountCostTitle = getDiscountCostTitle(listingItem.discountCost);

  const distanceCostLabel = getDistanceCostLabel(listingItem.distanceCost);
  const distanceCostTitle = getDistanceCostTitle(listingItem.distanceCost);

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const bonusLabel = getBonusLabel(listingItem.bonus);
  const bonusTitle = getBonusTitle(listingItem.bonus);

  const penaltyLabel = getPenaltyLabel(listingItem.penalty);
  const penaltyTitle = getPenaltyTitle(listingItem.penalty);

  const billingModelLabel =
    taxiOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );
  const billingModelTitle =
    taxiOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );

  const billingTypeLabel = taxiOrderSettlementListingHelper.getBillingTypeText(
    listingItem.billingType
  );
  const billingTypeTitle = taxiOrderSettlementListingHelper.getBillingTypeText(
    listingItem.billingType
  );

  const isCargoWithTaxiSettlementEditButtonVisible =
    userPermissions.hasAccessToEditCargoWithTaxiSettlement &&
    listingItem.billingType ===
      TaxiOrderSettlementListingItemBillingType.PRIV_CARGO_2_TAXI &&
    listingItem.status &&
    [
      TaxiOrderSettlementListingItemBillingStatus.CREATED,
      TaxiOrderSettlementListingItemBillingStatus.REJECTED,
      TaxiOrderSettlementListingItemBillingStatus.REOPENED,
    ].includes(listingItem.status);

  const isCargoWithTaxiSettlementDetailsButtonVisible =
    userPermissions.hasAccessToDetailsCargoWithTaxiSettlement &&
    listingItem.billingType ===
      TaxiOrderSettlementListingItemBillingType.PRIV_CARGO_2_TAXI;

  return {
    id: listingItem.uuid,
    value: {
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      internalOrderId: (
        <div title={listingItem.internalOrderId}>
          {listingItem.internalOrderId}
        </div>
      ),
      externalOrderId: (
        <div title={String(listingItem.externalOrderId)}>
          {listingItem.externalOrderId}
        </div>
      ),
      cargoCompany: (
        <div title={listingItem.cargoCompanyName ?? "Raily"}>
          {listingItem.cargoCompanyName ?? "Raily"}
        </div>
      ),
      destinationTaxi: (
        <div title={listingItem.destinationTaxi}>
          {listingItem.destinationTaxi}
        </div>
      ),
      passengers: (
        <div title={listingItem.passengers.join("\r")}>
          {listingItem.passengers.map((passenger, index) => {
            return <div key={index}>{passenger}</div>;
          })}
        </div>
      ),
      billingModel: <div title={billingModelTitle}>{billingModelLabel}</div>,
      billingType: <div title={billingTypeTitle}>{billingTypeLabel}</div>,
      billingStatus: (
        <TaxiOrderSettlementListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      bonus: <div title={bonusTitle}>{bonusLabel}</div>,
      penalty: <div title={penaltyTitle}>{penaltyLabel}</div>,
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
      distanceCost: <div title={distanceCostTitle}>{distanceCostLabel}</div>,
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      discountAmount: (
        <div title={discountAmountTitle}>{discountAmountLabel}</div>
      ),
      discountSavings: <div title={discountCostTitle}>{discountCostLabel}</div>,
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isCargoWithTaxiSettlementEditButtonVisible && (
            <TableLinkButtonComponent
              icon={faEdit}
              to={billingRoutesHelper.getCargoWithTaxiEditRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiSettlementEditButtonTitle}
              idForTesting={`taxi-order-settlement-listing-table-item-${listingItem.uuid}-edit-button`}
            />
          )}
          {isCargoWithTaxiSettlementDetailsButtonVisible && (
            <TableLinkButtonComponent
              icon={faCircleInfo}
              to={billingRoutesHelper.getCargoWithTaxiDetailsRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.cargoWithTaxiSettlementDetailsButtonTitle}
              idForTesting={`taxi-order-settlement-listing-table-item-${listingItem.uuid}-details-button`}
            />
          )}
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiOrderSettlementListingItem[],
  userPermissions: TaxiOrderSettlementListingUserPermissions
): TaxiOrderSettlementListingTableRow[] => {
  return listingItems.map((listingItem) =>
    getRow(listingItem, userPermissions)
  );
};

const taxiOrderSettlementListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiOrderSettlementListingTableHelper;
