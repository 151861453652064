import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import DateRange from "../../../../common/types/date-range";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import usePagination from "../../../../common/hooks/use-pagination";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import routeFinishedListingApiService from "../common/api/route-finished-listing-api.service";
import RouteFinishedListingResponse from "../common/api/route-finished-listing.response";
import RouteFinishedListingViewBasicProps from "../common/types/route-finished-listing-view-basic-props";
import routeFinishedListingByTaxiFactory from "./common/route-finished-listing-by-taxi.factory";
import routeFinishedListingByTaxiRouteQueryParamsService from "./common/route-finished-listing-by-taxi-route-query-params.service";
import routeFinishedListingByTaxiSortHelper from "./common/sort/route-finished-listing-by-taxi-sort.helper";
import RouteFinishedListingByTaxiFilter, {
  RouteFinishedListingByTaxiEndDateFilter,
  RouteFinishedListingByTaxiStartDateFilter,
} from "./common/types/route-finished-listing-by-taxi-filter";
import RouteFinishedListingByTaxiFilterType from "./common/types/route-finished-listing-by-taxi-filter-type";
import RouteFinishedListingByTaxiListingItem from "./common/types/route-finished-listing-by-taxi-item";
import RouteFinishedListingByTaxiRouteQueryParams from "./common/types/route-finished-listing-by-taxi-route-query-params";
import RouteFinishedListingByTaxiSortKey from "./common/types/route-finished-listing-by-taxi-sort-key";
import RouteFinishedListingByTaxiSortSelectOption from "./common/types/route-finished-listing-by-taxi-sort-select-option";
import RouteFinishedListingByTaxiFiltersBadgeListComponent from "./filter/list/route-finished-listing-by-taxi-filters-badge-list.component";
import RouteFinishedListingByTaxiFiltersSelectComponent from "./filter/select/route-finished-listing-by-taxi-listing-filters-select.component";
import RouteFinishedListingByTaxiTableComponent from "./table/route-finished-listing-by-taxi-table.component";
import routeFinishedListingByTaxiRequestFactory from "./common/route-finished-listing-by-taxi-request.factory";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";

type RouteFinishedListingByTaxiProps = RouteFinishedListingViewBasicProps;

const RouteFinishedListingByTaxiComponent: FC<
  RouteFinishedListingByTaxiProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<RouteFinishedListingByTaxiRouteQueryParams>();

  const translations = routeTranslationsHelper.getFinishedListingTranslations();

  const [filters, setFilters] = useState<RouteFinishedListingByTaxiFilter[]>(
    () =>
      routeFinishedListingByTaxiRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<RouteFinishedListingByTaxiSortKey | null>(
      () =>
        routeFinishedListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    RouteFinishedListingByTaxiListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults,
    defaultPageSize:
      routeFinishedListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      routeFinishedListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      routeFinishedListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: RouteFinishedListingByTaxiFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const onListingFetchSuccess = (response: RouteFinishedListingResponse) => {
    const listingItems = routeFinishedListingByTaxiFactory.createListingItems(
      response.data.data
    );

    setListingItems(listingItems);
    setTotalResults(response.data.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: RouteFinishedListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }
    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request = routeFinishedListingByTaxiRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    routeFinishedListingApiService
      .fetchListing(request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }

    fetchListing();
  }, [page, pageSize, filters, selectedSortKey]);

  const sortSelectOptions: RouteFinishedListingByTaxiSortSelectOption[] =
    useMemo(() => routeFinishedListingByTaxiSortHelper.getSelectOptions(), []);

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) => filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
  )?.value as RouteFinishedListingByTaxiStartDateFilter["value"] | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== RouteFinishedListingByTaxiFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
    );

    const newFilter: RouteFinishedListingByTaxiStartDateFilter = {
      type: RouteFinishedListingByTaxiFilterType.START_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteFinishedListingByTaxiFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const endDateFilterValue = filters.find(
    (filter) => filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
  )?.value as RouteFinishedListingByTaxiEndDateFilter["value"] | undefined;

  const onEndDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== RouteFinishedListingByTaxiFilterType.END_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) => filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
    );

    const newFilter: RouteFinishedListingByTaxiEndDateFilter = {
      type: RouteFinishedListingByTaxiFilterType.END_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteFinishedListingByTaxiFilterType.END_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
  };

  return (
    <div className="route_finished_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <div className="route_finished_listing_tools">
        <div className="d-flex">
          <RouteFinishedListingByTaxiFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="route-finished-listing-by-taxi-date-range-start"
          />
          <DateRangeInputComponent
            date={endDateFilterValue ?? null}
            onChange={onEndDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByEndDateSelectInputPlaceholder
            }
            idForTesting="route-finished-listing-by-taxi-date-range-end"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="route-finished-listing-by-taxi-select"
        />
      </div>
      <RouteFinishedListingByTaxiFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <RouteFinishedListingByTaxiTableComponent
          listingItems={listingItems}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="route_finished_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default RouteFinishedListingByTaxiComponent;
