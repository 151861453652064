import { RouteActiveListingResponseItem } from "../../../common/api/route-active-listing.response";
import RouteActiveListingByTaxiListingItem from "../types/route-active-listing-by-taxi-item";

const createListingItem = (
  responseItem: RouteActiveListingResponseItem
): RouteActiveListingByTaxiListingItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driver_name,
    endDate: new Date(responseItem.end_time),
    routeAddresses: [
      responseItem.starting_node_label,
      ...responseItem.plan_entry_nodes,
      responseItem.ending_node_label,
    ],
    startDate: new Date(responseItem.start_time),
    passengers: responseItem.involved_cargo_labels,
    routeId: responseItem.human_id,
  };
};

const createListingItems = (
  responseData: RouteActiveListingResponseItem[]
): RouteActiveListingByTaxiListingItem[] => {
  return responseData.map(createListingItem);
};

const routeActiveListingByTaxiFactory = {
  createListingItems,
};

export default routeActiveListingByTaxiFactory;
