import ListingFilterBadge from "../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../common/utils/date/date.service";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteActiveListingByTaxiFilter, {
  RouteActiveListingByTaxiDriverFilter,
  RouteActiveListingByTaxiEndDateFilter,
  RouteActiveListingByTaxiPassengersFilter,
  RouteActiveListingByTaxiRouteAddressFilter,
  RouteActiveListingByTaxiRouteIdFilter,
  RouteActiveListingByTaxiStartDateFilter,
} from "../../common/types/route-active-listing-by-taxi-filter";
import RouteActiveListingByTaxiFilterType from "../../common/types/route-active-listing-by-taxi-filter-type";

const getBadgeOptions = (filter: RouteActiveListingByTaxiFilter) => {
  const translations =
    routeTranslationsHelper.getActiveListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: RouteActiveListingByTaxiFilter;
  }[] = [
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiDriverFilter["value"]
        ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.DRIVER,
        value: filter.value as RouteActiveListingByTaxiDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.endDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiEndDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiEndDateFilter["value"])
                .to
            )
          ),
        title: translations.endDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiEndDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiEndDateFilter["value"])
                .to
            )
          ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.END_DATE,
        value: filter.value as RouteActiveListingByTaxiEndDateFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiPassengersFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiPassengersFilter["value"]
        ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.PASSENGERS,
        value:
          filter.value as RouteActiveListingByTaxiPassengersFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(filter.value as RouteActiveListingByTaxiRouteIdFilter["value"])
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(filter.value as RouteActiveListingByTaxiRouteIdFilter["value"])
        ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.ROUTE_ID,
        value: filter.value as RouteActiveListingByTaxiRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeAddressFilterText.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiRouteAddressFilter["value"]
        ),
        title: translations.routeAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteActiveListingByTaxiRouteAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.ROUTE_ADDRESS,
        value:
          filter.value as RouteActiveListingByTaxiRouteAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.startDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiStartDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiStartDateFilter["value"])
                .to
            )
          ),
        title: translations.startDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiStartDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteActiveListingByTaxiStartDateFilter["value"])
                .to
            )
          ),
      },
      filter: {
        type: RouteActiveListingByTaxiFilterType.START_DATE,
        value: filter.value as RouteActiveListingByTaxiStartDateFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: RouteActiveListingByTaxiFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: RouteActiveListingByTaxiFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const routeActiveListingByTaxiFiltersBadgeListHelper = {
  getBadges,
};

export default routeActiveListingByTaxiFiltersBadgeListHelper;
