import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import TaxiOrderSettlementListingSortKey from "./types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingSortSelectOption from "./types/taxi-order-settlement-listing-sort-select-option";

const getSelectOptions = (): TaxiOrderSettlementListingSortSelectOption[] => {
  const translations =
    settlementTranslationsHelper.getTaxiOrderSettlementListingTranslations()
      .sort;

  return [
    {
      label: translations.distanceAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_ASC,
    },
    {
      label: translations.distanceDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_DESC,
    },
    {
      label: translations.tollRoadsCostAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.TOLL_ROADS_COST_ASC,
    },
    {
      label: translations.tollRoadsCostDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.TOLL_ROADS_COST_DESC,
    },
    {
      label: translations.stopoverCostAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.STOPOVER_COST_ASC,
    },
    {
      label: translations.stopoverCostDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.STOPOVER_COST_DESC,
    },
    {
      label: translations.internalOrderIdAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      label: translations.internalOrderIdDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      label: translations.billingModelAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.BILLING_MODEL_ASC,
    },
    {
      label: translations.billingModelDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.BILLING_MODEL_DESC,
    },
    {
      label: translations.distanceRateAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_RATE_ASC,
    },
    {
      label: translations.distanceRateDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_RATE_DESC,
    },
    {
      label: translations.distanceCostAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_COST_ASC,
    },
    {
      label: translations.distanceCostDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.DISTANCE_COST_DESC,
    },
    {
      label: translations.totalCostAscOptionLabel,
      value: TaxiOrderSettlementListingSortKey.TOTAL_COST_ASC,
    },
    {
      label: translations.totalCostDescOptionLabel,
      value: TaxiOrderSettlementListingSortKey.TOTAL_COST_DESC,
    },
  ];
};

const taxiOrderSettlementListingSortHelper = {
  getSelectOptions,
};

export default taxiOrderSettlementListingSortHelper;
