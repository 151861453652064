enum RouteFinishedListingByRailyFilterType {
  TAXI_CORPORATION = "TAXI_CORPORATION",
  DRIVER = "DRIVER",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  ROUTE_INTERMEDIATE_ADDRESS = "ROUTE_INTERMEDIATE_ADDRESS",
  ROUTE_PICKUP_ADDRESS = "ROUTE_PICKUP_ADDRESS",
  ROUTE_DESTINATION_ADDRESS = "ROUTE_DESTINATION_ADDRESS",
  ROUTE_ID = "ROUTE_ID",
  PASSENGERS = "PASSENGERS",
}

export default RouteFinishedListingByRailyFilterType;
