enum RouteActiveListingByRailyFilterType {
  TAXI_CORPORATION = "TAXI_CORPORATION",
  DRIVER = "DRIVER",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  ROUTE_ADDRESS = "ROUTE_ADDRESS",
  ROUTE_ID = "ROUTE_ID",
  PASSENGERS = "PASSENGERS",
}

export default RouteActiveListingByRailyFilterType;
