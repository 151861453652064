import CargoOrderDetailsRequest from "../../../api/raily/cargo-order/details/cargo-order-details.request";
import CargoOrderDetailsLoadParams from "./cargo-order-details-load-params";

const create = (
  params: CargoOrderDetailsLoadParams
): CargoOrderDetailsRequest => {
  return { orderId: params.orderUuid };
};

const cargoOrderDetailsRequestFactory = {
  create,
};

export default cargoOrderDetailsRequestFactory;
