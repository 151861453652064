import MessengerPostsMap, {
  MessengerPostChannel,
} from "../../../services/messenger/post/messenger-post";
import MessengerChannel from "../types/messenger-channel";
import MessengerChannelData from "../types/messenger-channel-data";

const createPostsChannel = (
  postsChannel: MessengerPostChannel
): MessengerChannel => {
  switch (postsChannel) {
    case MessengerPostChannel.BILLING:
      return MessengerChannel.BILLING;
    case MessengerPostChannel.CARGO:
      return MessengerChannel.CARGO;
    case MessengerPostChannel.DRIVER:
      return MessengerChannel.DRIVER;
    case MessengerPostChannel.RAILY:
      return MessengerChannel.RAILY;
    case MessengerPostChannel.SHARED:
      return MessengerChannel.SHARED;
    case MessengerPostChannel.TAXI:
      return MessengerChannel.TAXI;
  }
};

const createPosts = (
  messengerServicePostsMap: MessengerPostsMap
): Map<MessengerChannel, MessengerChannelData> => {
  const messengerPostsMap: Map<MessengerChannel, MessengerChannelData> =
    new Map();

  messengerServicePostsMap.forEach((value, key) => {
    const messengerChannel = createPostsChannel(key);

    messengerPostsMap.set(messengerChannel, value);
  });

  return messengerPostsMap;
};

const messengerFactory = {
  createPosts,
};

export default messengerFactory;
