import AppLanguage from "./languages/types/app-language";

type AppConfig = {
  appName: string;
  baseApiUrl: string;
  searchAddressBaseApiUrl: string;
  routingServiceBaseApiUrl: string;
  defaultAppLanguage: AppLanguage;
  checkTokenExpirationIntervalTime: number;
  silentRenewBeforeExpirationTime: number;
  marketplaceUrl: string;
};

const appConfig: AppConfig = {
  appName: "Raily Taxi",
  baseApiUrl: process.env.BASE_API_URL,
  searchAddressBaseApiUrl: process.env.ADDRESS_SEARCH_SERVICE_BASE_API_URL,
  routingServiceBaseApiUrl: process.env.ROUTING_SERVICE_BASE_API_URL,
  marketplaceUrl: process.env.MARKETPLACE_URL,
  defaultAppLanguage: AppLanguage.PL,
  checkTokenExpirationIntervalTime: 10000, // ms
  silentRenewBeforeExpirationTime: 60000, // ms
};

export default appConfig;
