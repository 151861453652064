import Joi from "joi";
import formValidationService from "../../../../../common/utils/validation/form-validation.service";
import FormValidationResult from "../../../../../common/utils/validation/types/form-validation-result";

const validateAddressName = (addressName: string): FormValidationResult => {
  const validationSchema = Joi.string().min(1).max(150).trim().required();

  return formValidationService.validate(addressName, validationSchema);
};

const routesWaypointAddressAddFormValidationService = {
  validateAddressName,
};

export default routesWaypointAddressAddFormValidationService;
