import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../../languages/settlement-translations.helper";
import useRouteQueryParams from "../../../../../common/hooks/use-route-query-params";
import TaxiOrderSettlementListingByTaxiRouteQueryParams from "./common/types/taxi-order-settlement-listing-by-taxi-route-query-params";
import TaxiOrderSettlementListingItem from "../common/types/taxi-order-settlement-listing-item";
import TaxiOrderSettlementListingFilter, {
  TaxiOrderSettlementListingOrderStartDateFilter,
} from "../common/types/taxi-order-settlement-listing-filter";
import TaxiOrderSettlementListingSortKey from "../common/types/taxi-order-settlement-listing-sort-key";
import TaxiOrderSettlementListingSortSelectOption from "../common/types/taxi-order-settlement-listing-sort-select-option";
import taxiOrderSettlementListingSortHelper from "../common/taxi-order-settlement-listing-sort.helper";
import usePagination from "../../../../../common/hooks/use-pagination";
import taxiOrderSettlementListingByTaxiRouteQueryParamsService from "./common/taxi-order-settlement-listing-by-taxi-route-query-params.service";
import HeadingComponent from "../../../../../common/components/heading/heading.component";
import CargoSettledOrderListingStatusLegendComponent from "../common/status-legend/taxi-order-settlement-listing-status-legend.component";
import TaxiOrderSettlementListingFiltersSelectComponent from "../common/filters/select/taxi-order-settlement-listing-filters-select.component";
import ListingSortSelectComponent from "../../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiOrderSettlementListingFiltersBadgeListComponent from "../common/filters/list/taxi-order-settlement-listing-filters-badge-list.component";
import CardComponent from "../../../../../common/components/card/card.component";
import TaxiOrderSettlementListingTableComponent from "../common/table/taxi-order-settlement-listing-table.component";
import PaginationComponent from "../../../../../common/components/pagination/pagination.component";
import TaxiOrderSettlementListingViewBasicProps from "../common/types/taxi-order-settlement-listing-view-basic-props";
import taxiOrderSettlementListingByTaxiFactory from "./common/taxi-order-settlement-listing-by-taxi.factory";
import { useAppContext } from "../../../../../context/app.context";
import taxiOrderSettlementListingByTaxiApiService from "./common/api/taxi-order-settlement-listing-by-taxi-api.service";
import TaxiOrderSettlementListingFilterType from "../common/types/taxi-order-settlement-listing-filter-type";
import DateRange from "../../../../../common/types/date-range";
import TaxiOrderSettlementListingResponse, {
  TaxiOrderSettlementListingResponseData,
} from "../common/api/taxi-order-settlement-listing.response";
import taxiOrderSettlementListingRequestFactory from "../common/taxi-order-settlement-listing-request.factory";
import useIsComponentMounted from "../../../../../common/hooks/use-is-component-mounted";
import ButtonComponent from "../../../../../common/components/button/button.component";
import fileDownloadService from "../../../../../common/utils/file-download/file-download.service";
import TaxiOrderSettlementListingReportResponse, {
  TaxiOrderSettlementListingReportResponseData,
} from "../common/api/taxi-order-settlement-listing-report.response";
import notificationService from "../../../../../common/utils/notification/notification.service";
import taxiOrderSettlementListingReportRequestFactory from "../common/taxi-order-settlement-listing-report-request.factory";
import DateRangeInputComponent from "../../../../../common/components/form/input/date-range/date-range-input.component";
import TaxiOrderSettlementListingStatsSummary from "../common/types/taxi-order-settlement-listing-stats-summary";
import TaxiOrderSettlementListingStatsSummaryComponent from "../common/stats-summary/taxi-order-settlement-listing-stats-summary.component";

type TaxiOrderSettlementListingByTaxiProps =
  TaxiOrderSettlementListingViewBasicProps;

const TaxiOrderSettlementListingByTaxiComponent: FC<
  TaxiOrderSettlementListingByTaxiProps
> = (props) => {
  const isComponentMounted = useIsComponentMounted();

  const { user, selectedAppLanguage } = useAppContext();

  const taxiCorporationUuid: string =
    user?.aspects.taxiOfficer?.taxiCorporationUuid!;

  const translations =
    settlementTranslationsHelper.getCargoOrderSettlementListingTranslations();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiOrderSettlementListingByTaxiRouteQueryParams>();

  const [areListingItemsFetching, setAreListingItemsFetching] = useState(false);

  const [isListingItemsFetchingError, setIsListingItemsFetchingError] =
    useState(false);

  const [listingItems, setListingItems] = useState<
    TaxiOrderSettlementListingItem[]
  >([]);
  const [statsSummary, setStatsSummary] =
    useState<TaxiOrderSettlementListingStatsSummary | null>(null);
  const [totalResults, setTotalResults] = useState(0);

  const [isReportFetching, setIsReportFetching] = useState(false);

  const [filters, setFilters] = useState<TaxiOrderSettlementListingFilter[]>(
    () =>
      taxiOrderSettlementListingByTaxiRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiOrderSettlementListingSortKey | null>(
      () =>
        taxiOrderSettlementListingByTaxiRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const sortSelectOptions: TaxiOrderSettlementListingSortSelectOption[] =
    useMemo(
      () => taxiOrderSettlementListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiOrderSettlementListingByTaxiRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiOrderSettlementListingByTaxiRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  const onListingFetchSuccess = (
    responseData: TaxiOrderSettlementListingResponseData
  ) => {
    const listingItems =
      taxiOrderSettlementListingByTaxiFactory.createListingItems(
        responseData.data
      );

    const statsSummary =
      taxiOrderSettlementListingByTaxiFactory.createStatsSummary(
        responseData.stats
      );

    setListingItems(listingItems);
    setStatsSummary(statsSummary);
    setTotalResults(responseData.total_count);
  };

  const onListingFetchFailure = () => {
    setIsListingItemsFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiOrderSettlementListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = (signal: AbortSignal) => {
    setAreListingItemsFetching(true);
    setIsListingItemsFetchingError(false);

    const request = taxiOrderSettlementListingRequestFactory.createRequest(
      page,
      pageSize,
      filters,
      selectedSortKey
    );

    taxiOrderSettlementListingByTaxiApiService
      .fetchListing(taxiCorporationUuid, request, signal)
      .then((data) => {
        if (!signal.aborted) {
          handleListingResponse(data);
        }
      })
      .catch(() => {
        if (!signal.aborted) {
          onListingFetchFailure();
        }
      })
      .finally(() => setAreListingItemsFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize) {
      return;
    }
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchListing(signal);

    return () => {
      abortController.abort();
    };
  }, [page, pageSize, filters, selectedSortKey]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiOrderSettlementListingByTaxiRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: TaxiOrderSettlementListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
  )?.value as
    | TaxiOrderSettlementListingOrderStartDateFilter["value"]
    | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiOrderSettlementListingFilterType.ORDER_START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiOrderSettlementListingFilterType.ORDER_START_DATE
    );

    const newFilter: TaxiOrderSettlementListingOrderStartDateFilter = {
      type: TaxiOrderSettlementListingFilterType.ORDER_START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiOrderSettlementListingFilterType.ORDER_START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const downloadReportFile = (
    responseData: TaxiOrderSettlementListingReportResponseData
  ) => {
    fileDownloadService.downloadFromBlob(
      responseData.data,
      responseData.filename
    );
  };

  const onReportFetchSuccess = async (
    response: TaxiOrderSettlementListingReportResponse
  ) => {
    if (response.status === 200) {
      downloadReportFile(response.data);
      return;
    }

    onReportFetchFailure();
  };

  const onReportFetchFailure = () => {
    notificationService.error(
      translations.report.failureDownloadingNotificationText
    );
  };

  const fetchReport = () => {
    setIsReportFetching(true);

    const request =
      taxiOrderSettlementListingReportRequestFactory.createRequest(
        filters,
        selectedSortKey
      );

    taxiOrderSettlementListingByTaxiApiService
      .fetchReport(taxiCorporationUuid, request)
      .then(onReportFetchSuccess)
      .catch(onReportFetchFailure)
      .finally(() => setIsReportFetching(false));
  };

  const onReportDownloadButtonClick = () => {
    fetchReport();
  };

  const ReportDownloadButton = (
    <ButtonComponent
      onClick={onReportDownloadButtonClick}
      type="primary"
      isLoading={isReportFetching}
      title={translations.report.downloadButtonTitle}
      idForTesting="taxi-order-settlement-listing-by-taxi-report-download-button"
    >
      {translations.report.downloadButtonText}
    </ButtonComponent>
  );

  const isReportDownloadButtonVisible = !!taxiCorporationUuid;

  return (
    <div className="taxi_order_settlements_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={[
          ...props.actionButtons,
          isReportDownloadButtonVisible && ReportDownloadButton,
        ]}
      />
      <CargoSettledOrderListingStatusLegendComponent
        taxiCorporationUuid={taxiCorporationUuid}
      />
      <div className="taxi_order_settlements_listing_tools">
        <div className="d-flex">
          <TaxiOrderSettlementListingFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="taxi-order-settlement-listing-by-taxi-date-range"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="taxi-order-settlement-listing-by-taxi-sort"
        />
      </div>
      <TaxiOrderSettlementListingFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      {!!listingItems.length && (
        <TaxiOrderSettlementListingStatsSummaryComponent
          isError={isListingItemsFetchingError}
          isLoading={areListingItemsFetching}
          statsSummary={statsSummary}
        />
      )}
      <CardComponent classNames={{ root: "mt-4" }}>
        <TaxiOrderSettlementListingTableComponent
          listingItems={listingItems}
          isError={isListingItemsFetchingError}
          isLoading={areListingItemsFetching}
        />
        <div className="taxi_order_settlements_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default TaxiOrderSettlementListingByTaxiComponent;
