import { FC, useMemo } from "react";
import routeActiveListingByTaxiTableHelper from "./route-active-listing-by-raily-table.helper";
import RouteActiveListingByTaxiListingItem from "../common/types/route-active-listing-by-taxi-item";
import TableComponent, {
  TableProps,
} from "../../../../../common/components/table/table.component";
import RouteActiveListingByTaxiTableColumn from "../common/types/route-active-listing-by-taxi-table-column";
import RouteActiveListingByTaxiTableRow from "../common/types/route-active-listing-by-taxi-table-row";

type RouteActiveListingByTaxiTableProps = Pick<
  TableProps,
  "isError" | "isLoading"
> & {
  listingItems: RouteActiveListingByTaxiListingItem[];
};

const RouteActiveListingByTaxiTableComponent: FC<
  RouteActiveListingByTaxiTableProps
> = (props) => {
  const columns: RouteActiveListingByTaxiTableColumn[] =
    routeActiveListingByTaxiTableHelper.getColumns();

  const rows: RouteActiveListingByTaxiTableRow[] = useMemo(() => {
    return routeActiveListingByTaxiTableHelper.getRows(props.listingItems);
  }, [props.listingItems]);

  return (
    <TableComponent
      columns={columns}
      rows={rows}
      isError={props.isError}
      isLoading={props.isLoading}
    />
  );
};

export default RouteActiveListingByTaxiTableComponent;
