import { FC } from "react";
import appTranslationsHelper from "../../../../../languages/app-translations.helper";
import SingleSelectComponent, {
  SingleSelectProps,
} from "../../../form/select/single-select/single-select.component";

type ListingFilterSelectProps = Pick<
  SingleSelectProps,
  "onChange" | "options" | "inputValue" | "onInputChange" | "idForTesting"
>;

const ListingFilterSelectComponent: FC<ListingFilterSelectProps> = (props) => {
  const translations =
    appTranslationsHelper.getComponentTranslations().listing.filter.select;

  const getNoOptionsMessage = (inputValue: string) => {
    if (inputValue) {
      return translations.noOptionsMessageForQuery;
    }
    return translations.noOptionsMessage;
  };

  return (
    <SingleSelectComponent
      onChange={(option) => props.onChange(option)}
      options={props.options}
      classNames={{ root: "listing_filter_select" }}
      value={null}
      placeholder={translations.inputPlaceholder}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      isSearchable
      menuPlacement="bottom"
      maxMenuHeight={400}
      noOptionsMessage={getNoOptionsMessage}
      idForTesting={props.idForTesting}
    />
  );
};

export default ListingFilterSelectComponent;
