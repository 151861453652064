import MessengerPostResponse, {
  MessengerPostResponsePost,
  MessengerPostResponsePostType,
} from "../../../api/raily/messenger/post/messenger-post.response";
import { MessengerPost, MessengerPostType } from "./messenger-post";

const createPostType = (
  responsePostType: MessengerPostResponsePostType
): MessengerPostType => {
  switch (responsePostType) {
    case MessengerPostResponsePostType.ACCEPTED:
      return MessengerPostType.ACCEPTED;
    case MessengerPostResponsePostType.REJECTED:
      return MessengerPostType.REJECTED;
    case MessengerPostResponsePostType.REOPEN_REQUEST:
      return MessengerPostType.REOPEN_REQUEST;
    case MessengerPostResponsePostType.REOPENED:
      return MessengerPostType.REOPENED;
    case MessengerPostResponsePostType.SETTLED:
      return MessengerPostType.SETTLED;
    case MessengerPostResponsePostType.UPDATED:
      return MessengerPostType.UPDATED;
    case MessengerPostResponsePostType.USER_ENTRY:
      return MessengerPostType.USER_ENTRY;
  }
};

const createItem = (responseItem: MessengerPostResponsePost): MessengerPost => {
  return {
    authorUuid: responseItem.id,
    authorFullName: `${responseItem.authorFirstName} ${responseItem.authorLastName}`,
    authorCompany: responseItem.authorCompany,
    content: responseItem.content,
    date: new Date(responseItem.createdAt),
    postType: createPostType(responseItem.postType),
  };
};

const create = (response: MessengerPostResponse) => {
  return response.data.map((item) => createItem(item));
};

const messengerPostFactory = {
  create,
};

export default messengerPostFactory;
