import paginationService from "../../../../../common/utils/pagination/pagination.service";
import RouteFinishedListingRequest, {
  RouteFinishedListingRequestOrder,
} from "../../common/api/route-finished-listing.request";
import RouteFinishedListingByRailyFilter, {
  RouteFinishedListingByRailyDriverFilter,
  RouteFinishedListingByRailyEndDateFilter,
  RouteFinishedListingByRailyPassengersFilter,
  RouteFinishedListingByRailyRouteIntermediateAddressFilter,
  RouteFinishedListingByRailyRouteDestinationAddressFilter,
  RouteFinishedListingByRailyRouteIdFilter,
  RouteFinishedListingByRailyRoutePickupAddressFilter,
  RouteFinishedListingByRailyStartDateFilter,
  RouteFinishedListingByRailyTaxiCorporationFilter,
} from "./types/route-finished-listing-by-raily-filter";
import RouteFinishedListingByRailyFilterType from "./types/route-finished-listing-by-raily-filter-type";
import RouteFinishedListingByRailySortKey from "./types/route-finished-listing-by-raily-sort-key";

const createRequestOrder = (
  sortKey: RouteFinishedListingByRailySortKey | null
): RouteFinishedListingRequest["order"] => {
  const options: {
    sortKey: RouteFinishedListingByRailySortKey;
    requestOrder: RouteFinishedListingRequest["order"];
  }[] = [
    {
      requestOrder: RouteFinishedListingRequestOrder.HUMAN_ID_ASC,
      sortKey: RouteFinishedListingByRailySortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.HUMAN_ID_DESC,
      sortKey: RouteFinishedListingByRailySortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.START_TIME_ASC,
      sortKey: RouteFinishedListingByRailySortKey.START_DATE_ASC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.START_TIME_DESC,
      sortKey: RouteFinishedListingByRailySortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: RouteFinishedListingByRailyFilter[],
  sortKey: RouteFinishedListingByRailySortKey | null
): RouteFinishedListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    limit: pageSize,
    offset,
    human_id: filters.find(
      (filter) => filter.type === RouteFinishedListingByRailyFilterType.ROUTE_ID
    )?.value as RouteFinishedListingByRailyRouteIdFilter["value"] | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.START_DATE
      )?.value as
        | RouteFinishedListingByRailyStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.START_DATE
      )?.value as
        | RouteFinishedListingByRailyStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
    end_time_since: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.END_DATE
      )?.value as RouteFinishedListingByRailyEndDateFilter["value"] | undefined
    )?.from?.toJSON(),
    end_time_to: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByRailyFilterType.END_DATE
      )?.value as RouteFinishedListingByRailyEndDateFilter["value"] | undefined
    )?.to?.toJSON(),
    driver_name: filters.find(
      (filter) => filter.type === RouteFinishedListingByRailyFilterType.DRIVER
    )?.value as RouteFinishedListingByRailyDriverFilter["value"] | undefined,
    taxi_name: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByRailyFilterType.TAXI_CORPORATION
    )?.value as
      | RouteFinishedListingByRailyTaxiCorporationFilter["value"]
      | undefined,
    involved_cargo_labels: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByRailyFilterType.PASSENGERS
    )?.value as
      | RouteFinishedListingByRailyPassengersFilter["value"]
      | undefined,
    starting_node_label: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRoutePickupAddressFilter["value"]
      | undefined,
    ending_node_label: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRouteDestinationAddressFilter["value"]
      | undefined,
    plan_entry_nodes: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByRailyFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | RouteFinishedListingByRailyRouteIntermediateAddressFilter["value"]
      | undefined,
  };
};

const routeFinishedListingByRailyRequestFactory = {
  createRequest,
};

export default routeFinishedListingByRailyRequestFactory;
