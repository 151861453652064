export enum OrderRouteEditCargoAddressType {
  HOTEL = "HOTEL",
  STATION = "STATION",
  MEETING_POINT = "MEETING_POINT",
}

type OrderRouteEditCargoAddress = {
  uuid: string;
  displayName: string;
  latitude: number;
  longitude: number;
  type: OrderRouteEditCargoAddressType;
};

export default OrderRouteEditCargoAddress;
