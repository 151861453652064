import { FC, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import ModalComponent from "../../../../../common/components/modal/modal.component";
import ButtonComponent from "../../../../../common/components/button/button.component";
import orderTranslationsHelper from "../../../../../languages/order-translations.helper";
import Row from "../../../../../common/components/grid/row";
import Column from "../../../../../common/components/grid/column";
import FormFieldComponent from "../../../../../common/components/form/field/form-field.component";
import InputComponent from "../../../../../common/components/form/input/input.component";
import useForm from "../../../../../common/components/form/use-form";
import routesWaypointAddressAddFormHelper from "./routes-waypoint-address-add-form.helper";
import SingleSelectComponent from "../../../../../common/components/form/select/single-select/single-select.component";
import SearchAddressResponse from "../../../../../common/utils/search-address/search-address.response";
import searchAddressApiService from "../../../../../common/utils/search-address/search-address.service";
import RoutesWaypointAddressAddFormSearchSelectOption from "./types/routes-waypoint-address-add-form-search-select-option";
import routesWaypointAddressAddFormFactory from "./routes-waypoint-address-add-form.factory";
import MapAddressSelectorComponent from "../../../../../common/components/map-address-selector/map-address-selector-component";
import MapAddressSelectorAddressSelected from "../../../../../common/components/map-address-selector/types/map-address-selector-address-selected";
import SelectOption from "../../../../../common/components/form/select/common/option/select-option";
import RoutesWaypointAddressAddFormAddressData from "./types/routes-waypoint-address-add-form-adress-data";

type RoutesWaypointAddressAddFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (address: RoutesWaypointAddressAddFormAddressData | null) => void;
  selectedValue?: RoutesWaypointAddressAddFormAddressData | null;
};

const RoutesWaypointAddressAddFormComponent: FC<
  RoutesWaypointAddressAddFormProps
> = (props) => {
  const translations =
    orderTranslationsHelper.getAddTranslations().routes.temporaryAddressAdd;

  const [addressData, setAddressData] =
    useState<RoutesWaypointAddressAddFormAddressData | null>(null);
  const [isAddressFetching, setIsAddressFetching] = useState(false);
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [addressSelectOptions, setAddressSelectOptions] = useState<
    RoutesWaypointAddressAddFormSearchSelectOption[]
  >([]);

  const form = useForm({
    emptyValues: routesWaypointAddressAddFormHelper.getDefaultFormData(),
    validationDefinition:
      routesWaypointAddressAddFormHelper.getValidationDefinition(),
  });

  useEffect(() => {
    if (!props.isOpen || !props.selectedValue) {
      setAddressData(null);
      form.restore();
      return;
    }

    const address = {
      value: props.selectedValue,
      label: props.selectedValue.displayName,
    };

    form.setValues({
      addressFound: address,
      addressName:
        props.selectedValue.subText || props.selectedValue.displayName,
    });
  }, [props.selectedValue, props.isOpen]);

  useEffect(() => {
    if (!addressSearchQuery) return;

    getDebounceAddressSelectOptions(addressSearchQuery);
  }, [addressSearchQuery]);

  useEffect(() => {
    if (!form.values.addressFound && !addressData && !props.selectedValue) {
      clearAddressName();
      return;
    }

    if (props.selectedValue) return;

    setAddressName(
      form.values.addressFound?.subText! || form.values.addressName
    );
  }, [form.values.addressFound, addressData, props.selectedValue]);

  useEffect(() => {
    if (!form.values.addressFound && !addressData) clearAddressName();
  }, [form.values.addressFound, addressData]);

  const getDebounceAddressSelectOptions = useCallback(
    debounce((addressSearchQuery: string) => {
      getAddressSelectOptions(addressSearchQuery);
    }, 300),
    []
  );

  const searchAddress = (
    searchAddressQuery: string
  ): Promise<SearchAddressResponse> => {
    return searchAddressApiService.searchByQuery(searchAddressQuery);
  };

  const getAddressSelectOptions = (searchAddressQuery: string) => {
    setIsAddressFetching(true);

    searchAddress(searchAddressQuery)
      .then((response) => {
        const selectOptions =
          routesWaypointAddressAddFormFactory.createAddressSelectOptions(
            response
          );

        setAddressSelectOptions(selectOptions);
      })
      .finally(() => {
        setIsAddressFetching(false);
      });
  };

  const setAddressNameFieldName = (
    value: MapAddressSelectorAddressSelected
  ) => {
    const addressName =
      value.town && value.street
        ? `${value.town}, ${value.street}`
        : value.name;

    const address: RoutesWaypointAddressAddFormAddressData = {
      displayName: addressName,
      latitude: value.latitude,
      longitude: value.longitude,
    };

    setAddressData(address);
    setAddressName(addressName);
    clearAddressFound();
  };

  const handleOnConfirm = async () => {
    const isFormValid: boolean = await form.validateAll();

    if (!isFormValid) return;

    const lat = form.values.addressFound
      ? form.values.addressFound?.value.latitude
      : addressData?.latitude;

    const long = form.values.addressFound
      ? form.values.addressFound?.value.longitude
      : addressData?.longitude;

    const address: RoutesWaypointAddressAddFormAddressData = {
      displayName: form.values.addressName || form.values.addressFound?.label!,
      latitude: lat!,
      longitude: long!,
      subText: form.values.addressName,
    };

    if (!address) return;

    props.onConfirm(address);
    props.onClose();
  };

  const clearAddressFound = () => {
    form.setValue("addressFound", "");
  };

  const clearAddressName = () => {
    form.setValue("addressName", "");
  };

  const setAddressFound = (value: SelectOption | null) => {
    form.setValue("addressFound", value);
  };

  const setAddressName = (value: string) => {
    form.setValue("addressName", value);
  };

  return (
    <ModalComponent
      header={{ title: translations.headingLabel }}
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        <ButtonComponent
          type="danger"
          onClick={handleOnConfirm}
          title={translations.confirmationButtonTitle}
          idForTesting="routes-waypoint-address-add-form-confirm-button"
        >
          {translations.confirmationButtonLabel}
        </ButtonComponent>,
        <ButtonComponent
          type="brand"
          onClick={props.onClose}
          title={translations.rejectButtonTitle}
          idForTesting="routes-waypoint-address-add-form-close-button"
        >
          {translations.rejectButtonLabel}
        </ButtonComponent>,
      ]}
    >
      <>
        <div className={"routes-waypoint-address-add-form"}>
          {translations.tipLabel}
        </div>
        <Row>
          <Column xl={6}>
            <FormFieldComponent
              label={translations.form.searchAddressLabel}
              classNames={{ root: "mt-0" }}
            >
              <SingleSelectComponent
                placeholder={translations.form.searchAddressTitle}
                options={addressSelectOptions}
                value={form.values.addressFound}
                isLoading={isAddressFetching}
                inputValue={addressSearchQuery}
                onInputChange={setAddressSearchQuery}
                onChange={setAddressFound}
                isClearable
                filterFunction={() => true}
                idForTesting="routes-waypoint-address-add-form-address-search-select"
              />
            </FormFieldComponent>
            <FormFieldComponent
              label={translations.form.addressNameLabel}
              classNames={{ root: "mt-0" }}
              isRequired
              errorMessage={form.validationResults.addressName.errorMessage}
            >
              <InputComponent
                placeholder={translations.form.addressNameTitle}
                value={form.values.addressName}
                onChange={setAddressName}
                onBlur={() => form.validate("addressName")}
                hasError={!!form.validationResults.addressName.errorMessage}
                isDisabled={
                  !form.values.addressFound?.value && !addressData?.displayName
                }
                idForTesting="routes-waypoint-address-add-form-address-name-input"
              />
            </FormFieldComponent>
          </Column>
          <Column xl={6}>
            <FormFieldComponent
              label={translations.mapLabel}
              classNames={{
                root: "routes-waypoint-address-add-form-map-wrapper",
              }}
            >
              <div className="routes-waypoint-address-add-form-map">
                <MapAddressSelectorComponent
                  onAddressChange={setAddressNameFieldName}
                  address={form.values.addressFound?.value}
                />
              </div>
            </FormFieldComponent>
          </Column>
        </Row>
      </>
    </ModalComponent>
  );
};

export default RoutesWaypointAddressAddFormComponent;
