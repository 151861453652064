import paginationService from "../../../../../common/utils/pagination/pagination.service";
import RouteFinishedListingRequest, {
  RouteFinishedListingRequestOrder,
} from "../../common/api/route-finished-listing.request";
import RouteFinishedListingByTaxiFilter, {
  RouteFinishedListingByTaxiDriverFilter,
  RouteFinishedListingByTaxiEndDateFilter,
  RouteFinishedListingByTaxiPassengersFilter,
  RouteFinishedListingByTaxiRouteDestinationAddressFilter,
  RouteFinishedListingByTaxiRouteIdFilter,
  RouteFinishedListingByTaxiRouteIntermediateAddressFilter,
  RouteFinishedListingByTaxiRoutePickupAddressFilter,
  RouteFinishedListingByTaxiStartDateFilter,
} from "./types/route-finished-listing-by-taxi-filter";
import RouteFinishedListingByTaxiFilterType from "./types/route-finished-listing-by-taxi-filter-type";
import RouteFinishedListingByTaxiSortKey from "./types/route-finished-listing-by-taxi-sort-key";

const createRequestOrder = (
  sortKey: RouteFinishedListingByTaxiSortKey | null
): RouteFinishedListingRequest["order"] => {
  const options: {
    sortKey: RouteFinishedListingByTaxiSortKey;
    requestOrder: RouteFinishedListingRequest["order"];
  }[] = [
    {
      requestOrder: RouteFinishedListingRequestOrder.HUMAN_ID_ASC,
      sortKey: RouteFinishedListingByTaxiSortKey.ROUTE_ID_ASC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.HUMAN_ID_DESC,
      sortKey: RouteFinishedListingByTaxiSortKey.ROUTE_ID_DESC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.START_TIME_ASC,
      sortKey: RouteFinishedListingByTaxiSortKey.START_DATE_ASC,
    },
    {
      requestOrder: RouteFinishedListingRequestOrder.START_TIME_DESC,
      sortKey: RouteFinishedListingByTaxiSortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: RouteFinishedListingByTaxiFilter[],
  sortKey: RouteFinishedListingByTaxiSortKey | null
): RouteFinishedListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    limit: pageSize,
    offset,
    human_id: filters.find(
      (filter) => filter.type === RouteFinishedListingByTaxiFilterType.ROUTE_ID
    )?.value as RouteFinishedListingByTaxiRouteIdFilter["value"] | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
      )?.value as RouteFinishedListingByTaxiStartDateFilter["value"] | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
      )?.value as RouteFinishedListingByTaxiStartDateFilter["value"] | undefined
    )?.to?.toJSON(),
    end_time_since: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
      )?.value as RouteFinishedListingByTaxiEndDateFilter["value"] | undefined
    )?.from?.toJSON(),
    end_time_to: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
      )?.value as RouteFinishedListingByTaxiEndDateFilter["value"] | undefined
    )?.to?.toJSON(),
    driver_name: filters.find(
      (filter) => filter.type === RouteFinishedListingByTaxiFilterType.DRIVER
    )?.value as RouteFinishedListingByTaxiDriverFilter["value"] | undefined,
    involved_cargo_labels: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByTaxiFilterType.PASSENGERS
    )?.value as RouteFinishedListingByTaxiPassengersFilter["value"] | undefined,
    starting_node_label: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRoutePickupAddressFilter["value"]
      | undefined,
    ending_node_label: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRouteDestinationAddressFilter["value"]
      | undefined,
    plan_entry_nodes: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRouteIntermediateAddressFilter["value"]
      | undefined,
  };
};

const routeFinishedListingByTaxiRequestFactory = {
  createRequest,
};

export default routeFinishedListingByTaxiRequestFactory;
