import isEqual from "lodash/isEqual";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteActiveListingByRailyFilter from "../types/route-active-listing-by-raily-filter";
import RouteActiveListingByRailyFilterSelectOption, {
  RouteActiveListingByRailyRouteIdFilterSelectOption,
} from "../types/route-active-listing-by-raily-filter-select-option";
import RouteActiveListingByRailyFilterType from "../types/route-active-listing-by-raily-filter-type";

const getSearchQueryOptions = (
  query: string
): RouteActiveListingByRailyFilterSelectOption[] => {
  if (!query) {
    return [];
  }

  const searchFilterTranslations =
    routeTranslationsHelper.getActiveListingTranslations().filters.search;

  const parseStringToNumber = (string: string): number | undefined => {
    const stringAsNumber = Number(string);

    if (Number.isNaN(stringAsNumber)) {
      return undefined;
    }

    return stringAsNumber;
  };

  const getSearchByDriverQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByDriverTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByPassengersQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByPassengersTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteAddressQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteAddressTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByRouteIdQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByRouteIdTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const getSearchByTaxiCorporationQueryLabel = (query: string) => {
    return searchFilterTranslations.searchByTaxiCorporationTemplateText.replace(
      `#{query}`,
      query
    );
  };

  const stringOptions = [
    {
      label: getSearchByDriverQueryLabel(query),
      value: {
        type: RouteActiveListingByRailyFilterType.DRIVER,
        value: query,
      },
    },
    {
      label: getSearchByPassengersQueryLabel(query),
      value: {
        type: RouteActiveListingByRailyFilterType.PASSENGERS,
        value: query,
      },
    },
    {
      label: getSearchByRouteAddressQueryLabel(query),
      value: {
        type: RouteActiveListingByRailyFilterType.ROUTE_ADDRESS,
        value: query,
      },
    },
    {
      label: getSearchByTaxiCorporationQueryLabel(query),
      value: {
        type: RouteActiveListingByRailyFilterType.TAXI_CORPORATION,
        value: query,
      },
    },
  ];

  const options: RouteActiveListingByRailyFilterSelectOption[] = [
    ...stringOptions,
  ] as RouteActiveListingByRailyFilterSelectOption[];

  if (parseStringToNumber(query) !== undefined) {
    const routeIdFilterOption: RouteActiveListingByRailyRouteIdFilterSelectOption =
      {
        label: getSearchByRouteIdQueryLabel(query),
        value: {
          type: RouteActiveListingByRailyFilterType.ROUTE_ID,
          value: Number(query),
        },
      };

    options.push(routeIdFilterOption);
  }

  return options;
};

const getSelectOptions = (
  query: string,
  selectedFilters: RouteActiveListingByRailyFilter[]
): RouteActiveListingByRailyFilterSelectOption[] => {
  const allFilterOptions = getSearchQueryOptions(query);

  const filterOptionsWithoutSelected = allFilterOptions.filter((option) => {
    return !selectedFilters.find((selectedFilter) =>
      isEqual(selectedFilter.type, option.value.type)
    );
  });

  return filterOptionsWithoutSelected;
};

const routeActiveListingByRailyFilterHelper = {
  getSelectOptions,
};

export default routeActiveListingByRailyFilterHelper;
