import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import routeFinishedListingUserPermissionDefinition, {
  RouteFinishedListingUserPermissions,
} from "./route-finished-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): RouteFinishedListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    routeFinishedListingUserPermissionDefinition
  );

  return permissions;
};

const routeFinishedListingUserPermissionsHelper = {
  getPermissions,
};

export default routeFinishedListingUserPermissionsHelper;
