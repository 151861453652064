import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteActiveListingByTaxiSortKey from "../types/route-active-listing-by-taxi-sort-key";
import RouteActiveListingByTaxiSortSelectOption from "../types/route-active-listing-by-taxi-sort-select-option";

const getSelectOptions = (): RouteActiveListingByTaxiSortSelectOption[] => {
  const translations =
    routeTranslationsHelper.getActiveListingTranslations().sort;

  return [
    {
      label: translations.driverAscOptionLabel,
      value: RouteActiveListingByTaxiSortKey.DRIVER_NAME_ASC,
    },
    {
      label: translations.driverDescOptionLabel,
      value: RouteActiveListingByTaxiSortKey.DRIVER_NAME_DESC,
    },
    {
      label: translations.endDateAscOptionLabel,
      value: RouteActiveListingByTaxiSortKey.END_DATE_ASC,
    },
    {
      label: translations.endDateDescOptionLabel,
      value: RouteActiveListingByTaxiSortKey.END_DATE_DESC,
    },
    {
      label: translations.routeIdAscOptionLabel,
      value: RouteActiveListingByTaxiSortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.routeIdDescOptionLabel,
      value: RouteActiveListingByTaxiSortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.startDateAscOptionLabel,
      value: RouteActiveListingByTaxiSortKey.START_DATE_ASC,
    },
    {
      label: translations.startDateDescOptionLabel,
      value: RouteActiveListingByTaxiSortKey.START_DATE_DESC,
    },
  ];
};

const routeActiveListingByTaxiSortHelper = {
  getSelectOptions,
};

export default routeActiveListingByTaxiSortHelper;
