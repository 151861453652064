import Joi from "joi";
import RouteFinishedListingByTaxiFilter, {
  RouteFinishedListingByTaxiDriverFilter,
  RouteFinishedListingByTaxiEndDateFilter,
  RouteFinishedListingByTaxiPassengersFilter,
  RouteFinishedListingByTaxiRouteIntermediateAddressFilter,
  RouteFinishedListingByTaxiRouteIdFilter,
  RouteFinishedListingByTaxiStartDateFilter,
  RouteFinishedListingByTaxiRoutePickupAddressFilter,
  RouteFinishedListingByTaxiRouteDestinationAddressFilter,
} from "./types/route-finished-listing-by-taxi-filter";
import RouteFinishedListingByTaxiFilterType from "./types/route-finished-listing-by-taxi-filter-type";
import RouteFinishedListingByTaxiRouteQueryFilterParams from "./types/route-finished-listing-by-taxi-route-query-filter-params";
import RouteFinishedListingByTaxiRouteQueryParams from "./types/route-finished-listing-by-taxi-route-query-params";
import RouteFinishedListingByTaxiSortKey from "./types/route-finished-listing-by-taxi-sort-key";

const getFilters = (
  routeQueryFilterParams: RouteFinishedListingByTaxiRouteQueryFilterParams
): RouteFinishedListingByTaxiFilter[] => {
  if (!validateFilters(routeQueryFilterParams)) {
    return [];
  }

  const filters: RouteFinishedListingByTaxiFilter[] = [];

  if (routeQueryFilterParams.driver) {
    const driverFilter: RouteFinishedListingByTaxiDriverFilter = {
      type: RouteFinishedListingByTaxiFilterType.DRIVER,
      value: routeQueryFilterParams.driver,
    };

    filters.push(driverFilter);
  }

  if (routeQueryFilterParams.endDateFrom && routeQueryFilterParams.endDateTo) {
    const endDateFilter: RouteFinishedListingByTaxiEndDateFilter = {
      type: RouteFinishedListingByTaxiFilterType.END_DATE,
      value: {
        from: new Date(routeQueryFilterParams.endDateFrom),
        to: new Date(routeQueryFilterParams.endDateTo),
      },
    };

    filters.push(endDateFilter);
  }

  if (
    routeQueryFilterParams.startDateFrom &&
    routeQueryFilterParams.startDateTo
  ) {
    const startDateFilter: RouteFinishedListingByTaxiStartDateFilter = {
      type: RouteFinishedListingByTaxiFilterType.START_DATE,
      value: {
        from: new Date(routeQueryFilterParams.startDateFrom),
        to: new Date(routeQueryFilterParams.startDateTo),
      },
    };

    filters.push(startDateFilter);
  }

  if (routeQueryFilterParams.passenger) {
    const passengerFilter: RouteFinishedListingByTaxiPassengersFilter = {
      type: RouteFinishedListingByTaxiFilterType.PASSENGERS,
      value: routeQueryFilterParams.passenger,
    };

    filters.push(passengerFilter);
  }

  if (routeQueryFilterParams.routeIntermediateAddress) {
    const routeAddressFilter: RouteFinishedListingByTaxiRouteIntermediateAddressFilter =
      {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value: routeQueryFilterParams.routeIntermediateAddress,
      };

    filters.push(routeAddressFilter);
  }

  if (routeQueryFilterParams.routeDestinationAddress) {
    const routeDestinationAddressFilter: RouteFinishedListingByTaxiRouteDestinationAddressFilter =
      {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_DESTINATION_ADDRESS,
        value: routeQueryFilterParams.routeDestinationAddress,
      };

    filters.push(routeDestinationAddressFilter);
  }

  if (routeQueryFilterParams.routePickupAddress) {
    const routePickupAddressFilter: RouteFinishedListingByTaxiRoutePickupAddressFilter =
      {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_PICKUP_ADDRESS,
        value: routeQueryFilterParams.routePickupAddress,
      };

    filters.push(routePickupAddressFilter);
  }

  if (routeQueryFilterParams.routeId) {
    const routeIdFilter: RouteFinishedListingByTaxiRouteIdFilter = {
      type: RouteFinishedListingByTaxiFilterType.ROUTE_ID,
      value: Number(routeQueryFilterParams.routeId),
    };

    filters.push(routeIdFilter);
  }

  return filters;
};

const getSortKey = (
  routeQueryParams: RouteFinishedListingByTaxiRouteQueryParams
): RouteFinishedListingByTaxiSortKey | undefined => {
  if (!validateSortKey(routeQueryParams.sort)) {
    return undefined;
  }

  return routeQueryParams.sort;
};

const getPage = (
  routeQueryParams: RouteFinishedListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePage(routeQueryParams.page)) {
    return undefined;
  }

  return routeQueryParams.page ? Number(routeQueryParams.page) : undefined;
};

const getPageSize = (
  routeQueryParams: RouteFinishedListingByTaxiRouteQueryParams
): number | undefined => {
  if (!validatePageSize(routeQueryParams.pageSize)) {
    return undefined;
  }
  return routeQueryParams.pageSize
    ? Number(routeQueryParams.pageSize)
    : undefined;
};

const createRouteQueryParams = (
  filters: RouteFinishedListingByTaxiFilter[],
  sortKey: RouteFinishedListingByTaxiSortKey | null,
  page: number,
  pageSize: number
): RouteFinishedListingByTaxiRouteQueryParams => {
  const routeQueryParams: RouteFinishedListingByTaxiRouteQueryParams = {
    driver: filters.find(
      (filter) => filter.type === RouteFinishedListingByTaxiFilterType.DRIVER
    )?.value as RouteFinishedListingByTaxiDriverFilter["value"] | undefined,
    endDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
      )?.value as RouteFinishedListingByTaxiEndDateFilter["value"] | undefined
    )?.from.toJSON(),
    endDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.END_DATE
      )?.value as RouteFinishedListingByTaxiEndDateFilter["value"] | undefined
    )?.to.toJSON(),
    passenger: filters.find(
      (filter) =>
        filter.type === RouteFinishedListingByTaxiFilterType.PASSENGERS
    )?.value as RouteFinishedListingByTaxiPassengersFilter["value"] | undefined,
    routeIntermediateAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRouteIntermediateAddressFilter["value"]
      | undefined,
    routePickupAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRoutePickupAddressFilter["value"]
      | undefined,
    routeDestinationAddress: filters.find(
      (filter) =>
        filter.type ===
        RouteFinishedListingByTaxiFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | RouteFinishedListingByTaxiRouteDestinationAddressFilter["value"]
      | undefined,
    routeId: filters.find(
      (filter) => filter.type === RouteFinishedListingByTaxiFilterType.ROUTE_ID
    )?.value as RouteFinishedListingByTaxiRouteIdFilter["value"] | undefined,
    startDateFrom: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
      )?.value as RouteFinishedListingByTaxiStartDateFilter["value"] | undefined
    )?.from.toJSON(),
    startDateTo: (
      filters.find(
        (filter) =>
          filter.type === RouteFinishedListingByTaxiFilterType.START_DATE
      )?.value as RouteFinishedListingByTaxiStartDateFilter["value"] | undefined
    )?.to.toJSON(),
    sort: sortKey ?? undefined,
    page,
    pageSize,
  };

  return routeQueryParams;
};

const validateFilters = (
  routeQueryFilterParams: RouteFinishedListingByTaxiRouteQueryFilterParams
) => {
  const filterParams: RouteFinishedListingByTaxiRouteQueryFilterParams = {
    driver: routeQueryFilterParams.driver,
    endDateFrom: routeQueryFilterParams.endDateFrom,
    endDateTo: routeQueryFilterParams.endDateTo,
    passenger: routeQueryFilterParams.passenger,
    routeIntermediateAddress: routeQueryFilterParams.routeIntermediateAddress,
    routeDestinationAddress: routeQueryFilterParams.routeDestinationAddress,
    routePickupAddress: routeQueryFilterParams.routePickupAddress,
    routeId: routeQueryFilterParams.routeId,
    startDateFrom: routeQueryFilterParams.startDateFrom,
    startDateTo: routeQueryFilterParams.startDateTo,
  };

  const validationSchema =
    Joi.object<RouteFinishedListingByTaxiRouteQueryFilterParams>({
      driver: Joi.string(),
      endDateFrom: Joi.string(),
      endDateTo: Joi.string(),
      passenger: Joi.string(),
      routeIntermediateAddress: Joi.string(),
      routeDestinationAddress: Joi.string(),
      routePickupAddress: Joi.string(),
      routeId: Joi.number(),
      startDateFrom: Joi.string(),
      startDateTo: Joi.string(),
    });

  return !validationSchema.validate(filterParams).error?.message;
};

const validateSortKey = (
  routeQuerySortParam: RouteFinishedListingByTaxiRouteQueryParams["sort"]
): boolean => {
  const validationSchema = Joi.valid(
    ...Object.values(RouteFinishedListingByTaxiSortKey)
  );

  return !validationSchema.validate(routeQuerySortParam).error?.message;
};

const validatePage = (
  routeQueryPageParam: RouteFinishedListingByTaxiRouteQueryParams["page"]
) => {
  const validationSchema = Joi.number().min(1);

  return !validationSchema.validate(routeQueryPageParam).error?.message;
};

const validatePageSize = (
  routeQueryPageSizeParam: RouteFinishedListingByTaxiRouteQueryParams["pageSize"]
) => {
  const validationSchema = Joi.number().valid(...[50, 100, 200]);

  return !validationSchema.validate(routeQueryPageSizeParam).error?.message;
};

const routeFinishedListingByTaxiRouteQueryParamsService = {
  getFilters,
  getSortKey,
  getPage,
  getPageSize,
  createRouteQueryParams,
};

export default routeFinishedListingByTaxiRouteQueryParamsService;
