import { FC, useEffect, useMemo, useState } from "react";
import settlementTranslationsHelper from "../../../../languages/settlement-translations.helper";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import FormFieldComponent from "../../../../common/components/form/field/form-field.component";
import TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption from "./common/types/taxi-unsettled-private-order-listing-taxi-corporation-select-option";
import usePagination from "../../../../common/hooks/use-pagination";
import TaxiUnsettledPrivateOrderListingItem from "./common/types/taxi-unsettled-private-order-listing-item";
import taxiUnsettledPrivateOrderListingRequestFactory from "./common/taxi-unsettled-private-order-listing-request.factory";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import taxiUnsettledPrivateOrderListingRouteQueryParamsService from "./common/taxi-unsettled-private-order-listing-route-query-params.service";
import TaxiUnsettledPrivateOrderListingFilter, {
  TaxiUnsettledPrivateOrderListingStartDateFilter,
} from "./common/types/taxi-unsettled-private-order-listing-filter";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import TaxiUnsettledPrivateOrderListingRouteQueryParams from "./common/types/taxi-unsettled-private-order-listing-route-query-params";
import TaxiUnsettledPrivateOrderListingSortKey from "./common/types/taxi-unsettled-private-order-listing-sort-key";
import taxiUnsettledPrivateOrderListingApiService from "./common/api/taxi-unsettled-private-order-listing-api.service";
import TaxiUnsettledPrivateOrderListingResponse, {
  TaxiUnsettledPrivateOrderListingResponseData,
} from "./common/api/taxi-unsettled-private-order-listing.response";
import taxiUnsettledPrivateOrderListingFactory from "./common/taxi-unsettled-private-order-listing.factory";
import TaxiUnsettledPrivateOrderListingTaxiCoporationsResponse, {
  TaxiUnsettledPrivateOrderListingTaxiCoporationsResponseDataItem,
} from "./common/api/taxi-unsettled-private-order-listing-taxi-corporations.response";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import TaxiUnsettledPrivateOrderListingSortSelectOption from "./common/types/taxi-unsettled-private-order-listing-sort-select-option";
import taxiUnsettledPrivateOrderListingSortHelper from "./common/taxi-unsettled-private-order-listing-sort.helper";
import { useAppContext } from "../../../../context/app.context";
import CardComponent from "../../../../common/components/card/card.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import TaxiUnsettledPrivateOrderListingTableComponent from "./table/taxi-unsettled-private-order-listing-table.component";
import TaxiUnsettledPrivateOrderListingFilterType from "./common/types/taxi-unsettled-private-order-listing-filter-type";
import DateRange from "../../../../common/types/date-range";
import TaxiUnsettledPrivateOrderListingFiltersSelectComponent from "./common/filters/select/taxi-unsettled-private-order-listing-filters-select.component";
import TaxiUnsettledPrivateOrderListingFiltersBadgeListComponent from "./common/filters/list/taxi-unsettled-private-order-listing-filters-badge-list.component";
import useDocumentTitle from "../../../../common/hooks/use-document-title";
import appTranslationsHelper from "../../../../languages/app-translations.helper";
import settlementBreadcrumbsHelper from "../../common/breadcrumbs/settlement-breadcrumbs.helper";
import TaxiUnsettledPrivateOrderListingRequest from "./common/api/taxi-unsettled-private-order-listing.request";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import StatusLegendComponent from "../../../../common/components/status-legend/status-legend.component";
import taxiUnsettledPrivateOrderListingHelper from "./common/taxi-unsettled-private-order-listing.helper";

type TaxiUnsettledPrivateOrderListingProps = {};

const TaxiUnsettledPrivateOrderListingComponent: FC<
  TaxiUnsettledPrivateOrderListingProps
> = () => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations();

  const documentTitleTranslations =
    appTranslationsHelper.getDocumentTitleTranslations();

  useDocumentTitle(
    documentTitleTranslations.settlementsTaxiUnsettledPrivateOrderListing
  );

  const { selectedAppLanguage, setBreadcrumbs } = useAppContext();

  useEffect(() => {
    const breadcrumbs =
      settlementBreadcrumbsHelper.getTaxiUnsettledPrivateOrderListingBreadcrumbs();

    setBreadcrumbs(breadcrumbs);
  }, [selectedAppLanguage]);

  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<TaxiUnsettledPrivateOrderListingRouteQueryParams>();

  const [isTaxiCorporationsFetching, setIsTaxiCorporationsFetching] =
    useState(false);
  const [isTaxiCorporationsFetchingError, setIsTaxiCorporationsFetchingError] =
    useState(false);

  const [taxiCorporationSelectOptions, setTaxiCorporationSelectOptions] =
    useState<TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption[]>([]);

  const [selectedTaxiCorporationUuid, setSelectedTaxiCorporationUuid] =
    useState<string | null>(
      () =>
        taxiUnsettledPrivateOrderListingRouteQueryParamsService.getTaxiCorporationUuid(
          routeQueryParams
        ) ?? null
    );

  const selectedTaxiCorporationSelectOption = taxiCorporationSelectOptions.find(
    (option) => option.value.uuid === selectedTaxiCorporationUuid
  );

  const onTaxiCorporationFetchSuccess = (
    responseDataItems: TaxiUnsettledPrivateOrderListingTaxiCoporationsResponseDataItem[]
  ) => {
    const taxiCorporationsSelectOptions =
      taxiUnsettledPrivateOrderListingFactory.createTaxiCorporationSelectOptions(
        responseDataItems
      );

    setTaxiCorporationSelectOptions(taxiCorporationsSelectOptions);
  };

  const onTaxiCorporationFetchFailure = () => {
    setIsTaxiCorporationsFetchingError(true);
  };

  const handleTaxiCorporationResponse = (
    response: TaxiUnsettledPrivateOrderListingTaxiCoporationsResponse
  ) => {
    if (response.status === 200) {
      onTaxiCorporationFetchSuccess(response.data.data);
      return;
    }

    onTaxiCorporationFetchFailure();
  };

  useEffect(() => {
    setIsTaxiCorporationsFetching(true);
    setIsTaxiCorporationsFetchingError(false);

    taxiUnsettledPrivateOrderListingApiService
      .fetchTaxiCorporations()
      .then(handleTaxiCorporationResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsTaxiCorporationsFetching(false));
  }, []);

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    TaxiUnsettledPrivateOrderListingItem[]
  >([]);
  const [totalResults, setTotalResults] = useState(0);

  const [filters, setFilters] = useState<
    TaxiUnsettledPrivateOrderListingFilter[]
  >(() =>
    taxiUnsettledPrivateOrderListingRouteQueryParamsService.getFilters(
      routeQueryParams
    )
  );

  const sortSelectOptions: TaxiUnsettledPrivateOrderListingSortSelectOption[] =
    useMemo(
      () => taxiUnsettledPrivateOrderListingSortHelper.getSelectOptions(),
      [selectedAppLanguage]
    );

  const [selectedSortKey, setSelectedSortKey] =
    useState<TaxiUnsettledPrivateOrderListingSortKey | null>(
      () =>
        taxiUnsettledPrivateOrderListingRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
  )?.value as
    | TaxiUnsettledPrivateOrderListingStartDateFilter["value"]
    | undefined;

  const onOrderStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== TaxiUnsettledPrivateOrderListingFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
    );

    const newFilter: TaxiUnsettledPrivateOrderListingStartDateFilter = {
      type: TaxiUnsettledPrivateOrderListingFilterType.START_DATE,
      value: {
        from: dateRange.from!,
        to: dateRange.to!,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !==
            TaxiUnsettledPrivateOrderListingFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const { page, pageSize, setPage, setPageSize } = usePagination({
    totalResults: totalResults,
    defaultPageSize:
      taxiUnsettledPrivateOrderListingRouteQueryParamsService.getPageSize(
        routeQueryParams
      ),
    defaultPage:
      taxiUnsettledPrivateOrderListingRouteQueryParamsService.getPage(
        routeQueryParams
      ),
  });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      taxiUnsettledPrivateOrderListingRouteQueryParamsService.createRouteQueryParams(
        selectedTaxiCorporationUuid,
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize, selectedTaxiCorporationUuid]);

  const onTaxiCorporationChange = (
    selectedOption: TaxiUnsettledPrivateOrderListingTaxiCorporationSelectOption
  ) => {
    setSelectedTaxiCorporationUuid(selectedOption.value.uuid);
    setPage(1);
  };

  const onListingFetchSuccess = (
    responseData: TaxiUnsettledPrivateOrderListingResponseData
  ) => {
    setTotalResults(responseData.total_count);

    const listingItems =
      taxiUnsettledPrivateOrderListingFactory.createListingItems(
        responseData.data
      );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (
    response: TaxiUnsettledPrivateOrderListingResponse
  ) => {
    if (response.status === 200) {
      onListingFetchSuccess(response.data);
      return;
    }

    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    const request: TaxiUnsettledPrivateOrderListingRequest =
      taxiUnsettledPrivateOrderListingRequestFactory.createRequest(
        page,
        pageSize,
        filters,
        selectedSortKey
      );

    taxiUnsettledPrivateOrderListingApiService
      .fetchListing(selectedTaxiCorporationUuid!, request)
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    if (!page || !pageSize || !selectedTaxiCorporationUuid) {
      return;
    }

    fetchListing();
  }, [filters, selectedTaxiCorporationUuid, selectedSortKey, page, pageSize]);

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filter, _index) => _index !== index);

    setFilters(newFilters);
    setPage(1);
  };

  const deleteAllFilters = () => {
    setFilters([]);
    setPage(1);
  };

  const addNewFilter = (newFilter: TaxiUnsettledPrivateOrderListingFilter) => {
    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const isListingContentVisible = !!selectedTaxiCorporationUuid;
  const statusOptions =
    taxiUnsettledPrivateOrderListingHelper.getStatusLegendOptions();

  return (
    <div className="taxi_unsettled_private_order_listing">
      <HeadingComponent title={translations.header.headingText} />
      <StatusLegendComponent statusData={statusOptions} />
      <FormFieldComponent label={translations.taxiCorporationLabel} isRequired>
        <SingleSelectComponent
          placeholder={translations.taxiCorporationPlaceholder}
          value={selectedTaxiCorporationSelectOption ?? null}
          options={taxiCorporationSelectOptions}
          onChange={(selectedOption) =>
            onTaxiCorporationChange(selectedOption!)
          }
          classNames={{
            root: "taxi_unsettled_private_order_listing_taxi_corporation_select",
          }}
          idForTesting={`taxi-corporation-select`}
          isLoading={isTaxiCorporationsFetching}
          isDisabled={isTaxiCorporationsFetchingError}
          isSearchable
        />
      </FormFieldComponent>
      {isListingContentVisible && (
        <>
          <div className="taxi_unsettled_private_order_listing_tools">
            <div className="d-flex">
              <TaxiUnsettledPrivateOrderListingFiltersSelectComponent
                filters={filters}
                onAddNewFilter={addNewFilter}
              />
              <DateRangeInputComponent
                date={startDateFilterValue ?? null}
                onChange={onOrderStartDateFilterValueChange}
                classNames={{ root: "ml-2" }}
                placeholder={
                  translations.filters.searchByStartDateSelectInputPlaceholder
                }
                idForTesting="taxi-unsettled-private-order-listing-date-range"
              />
            </div>

            <ListingSortSelectComponent
              onChange={(option) => setSelectedSortKey(option?.value!)}
              options={sortSelectOptions}
              value={selectedSortSelectOption}
              idForTesting="taxi-unsettled-private-order-listing-sort"
            />
          </div>
          <TaxiUnsettledPrivateOrderListingFiltersBadgeListComponent
            filters={filters}
            onDeleteFilterClick={deleteFilter}
            onDeleteAllFiltersButtonClick={deleteAllFilters}
          />
          <CardComponent classNames={{ root: "mt-4" }}>
            <TaxiUnsettledPrivateOrderListingTableComponent
              listingItems={listingItems}
              isError={isListingFetchingError}
              isLoading={isListingFetching}
            />
            <div className="taxi_unsettled_private_order_listing__pagination_wrapper">
              <PaginationComponent
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                page={page}
                pageSize={pageSize}
                totalResults={totalResults}
              />
            </div>
          </CardComponent>
        </>
      )}
    </div>
  );
};

export default TaxiUnsettledPrivateOrderListingComponent;
