import messengerApiService from "../../../api/raily/messenger/messenger-api.service";
import {
  MessengerBillingPostRequest,
  MessengerCargoPostRequest,
  MessengerDriverPostRequest,
  MessengerRailyPostRequest,
  MessengerSharedPostRequest,
  MessengerSolvedDriverPostRequest,
  MessengerTaxiPostRequest,
} from "../../../api/raily/messenger/post/messenger-post.request";
import MessengerPostResponse from "../../../api/raily/messenger/post/messenger-post.response";
import { MessengerPostChannel } from "./messenger-post";
import MessengerPostLoadParams, {
  MessengerPostLoadParamsBillingType,
} from "./messenger-post-load-params";

const create = (
  params: MessengerPostLoadParams,
  abortSignal: AbortSignal
): Promise<MessengerPostResponse>[] => {
  const promises = params.availableChannels.map((channel) => {
    switch (channel) {
      case MessengerPostChannel.BILLING: {
        const request: MessengerBillingPostRequest = {
          billingUuid: params.billingUuid ?? "",
        };

        if (params.billingType === MessengerPostLoadParamsBillingType.TAXI) {
          return messengerApiService().getTaxiBillingPosts(
            request,
            abortSignal
          );
        } else {
          return messengerApiService().getCargoBillingPosts(
            request,
            abortSignal
          );
        }
      }
      case MessengerPostChannel.CARGO: {
        const request: MessengerCargoPostRequest = {
          orderUuid: params.orderUuid ?? "",
        };

        return messengerApiService().getCargoPosts(request, abortSignal);
      }
      case MessengerPostChannel.DRIVER: {
        if (params.planEntryUuid) {
          const request: MessengerDriverPostRequest = {
            planEntryUuid: params.planEntryUuid,
          };

          return messengerApiService().getDriverPosts(request, abortSignal);
        }

        if (params.solvedOrderUuid) {
          const request: MessengerSolvedDriverPostRequest = {
            solvedOrderUuid: params.solvedOrderUuid,
          };

          return messengerApiService().getSolvedDriverPosts(
            request,
            abortSignal
          );
        }

        return Promise.reject();
      }
      case MessengerPostChannel.RAILY: {
        const request: MessengerRailyPostRequest = {
          orderUuid: params.orderUuid ?? "",
        };

        return messengerApiService().getRailyPosts(request, abortSignal);
      }
      case MessengerPostChannel.SHARED: {
        const request: MessengerSharedPostRequest = {
          orderUuid: params.orderUuid ?? "",
        };

        return messengerApiService().getSharedPosts(request, abortSignal);
      }
      case MessengerPostChannel.TAXI: {
        const request: MessengerTaxiPostRequest = {
          orderUuid: params.orderUuid ?? "",
          taxiCorporationUuid: params.taxiCorporationUuid ?? "",
        };

        return messengerApiService().getTaxiPosts(request, abortSignal);
      }
    }
  });

  return promises;
};

const messengerPostRequestFactory = { create };

export default messengerPostRequestFactory;
