import BillingModel from "../../../common/types/billing-model";
import {
  TaxiRouteSettlementListingResponseDataItem,
  TaxiRouteSettlementListingResponseDataItemBillingNodes,
  TaxiRouteSettlementListingResponseDataStats,
} from "./api/taxi-route-settlement-listing.response";
import TaxiRouteSettlementListingItem from "./types/taxi-route-settlement-listing-item";
import TaxiRouteSettlementListingStatsSummary from "./types/taxi-route-settlement-listing-stats-summary";

const createListingItemBonus = (
  responseDataItem: TaxiRouteSettlementListingResponseDataItem
): TaxiRouteSettlementListingItem["bonus"] => {
  if (responseDataItem.bonus && responseDataItem.bonus_type) {
    return {
      cost: responseDataItem.bonus,
      reason: responseDataItem.bonus_type,
    };
  }

  return null;
};

const createListingItemPenalty = (
  responseDataItem: TaxiRouteSettlementListingResponseDataItem
): TaxiRouteSettlementListingItem["penalty"] => {
  if (responseDataItem.penalty && responseDataItem.penalty_type) {
    return {
      cost: responseDataItem.penalty,
      reason: responseDataItem.penalty_type,
    };
  }

  return null;
};

const createListingItemRouteAddress = (
  nodes: TaxiRouteSettlementListingResponseDataItemBillingNodes[]
): string => {
  return nodes.map((item) => item.description).join(", ");
};

const createListingItem = (
  responseDataItem: TaxiRouteSettlementListingResponseDataItem
): TaxiRouteSettlementListingItem => {
  return {
    uuid: responseDataItem.id,
    routeId: responseDataItem.human_id,
    routeStartDate: new Date(responseDataItem.date),
    billingModel: responseDataItem.billing_model as BillingModel,
    bonus: createListingItemBonus(responseDataItem),
    penalty: createListingItemPenalty(responseDataItem),
    distance: responseDataItem.distance,
    distanceCost: responseDataItem.distance_amount,
    distanceRate: responseDataItem.distance_rate,
    tollRoadsCost: responseDataItem.highway_charge ?? 0,
    totalCost: responseDataItem.total_amount,
    driverName: responseDataItem.driver,
    fleetPartner: responseDataItem.fleet_partner,
    stopoverCost: responseDataItem.halting_amount ?? 0,
    addresses: createListingItemRouteAddress(responseDataItem.billing_nodes),
  };
};

const createListingItems = (
  responseDataItems: TaxiRouteSettlementListingResponseDataItem[]
): TaxiRouteSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const createStatsSummary = (
  responseStatsSummary: TaxiRouteSettlementListingResponseDataStats
): TaxiRouteSettlementListingStatsSummary => {
  return {
    distance: responseStatsSummary.distance,
    haltingCost: responseStatsSummary.halting_amount,
    highwayCost: responseStatsSummary.highway_charge,
    total: responseStatsSummary.total_amount,
  };
};

const taxiRouteSettlementListingFactory = {
  createListingItems,
  createStatsSummary,
};

export default taxiRouteSettlementListingFactory;
