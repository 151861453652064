import { FC, useMemo } from "react";
import BillingsNode from "../types/billings-node";
import billingRouteDetailsHelper from "./common/billings-route-details.helper";
import BillingsRouteDetailsTableRow from "./types/billings-route-details-table-row";
import dateService from "../../../../common/utils/date/date.service";
import TableComponent from "../../../../common/components/table/table.component";
import CardComponent from "../../../../common/components/card/card.component";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import BillingsRouteDetailsMapPoints from "./types/billings-route-details-map-points";
import googleMapsService from "../../../../common/services/google-maps/google-maps.service";

type BillingsRouteDetailsProps = {
  billingNodes: BillingsNode[] | undefined;
};

const BillingsRouteDetailsOverviewComponent: FC<BillingsRouteDetailsProps> = (
  props
) => {
  const translations = billingsTranslationsHelper.getRouteDetailsTranslations();

  const createTableRow = (
    listingItem: BillingsNode
  ): BillingsRouteDetailsTableRow => {
    const isFirstNode = listingItem.position === 1;

    return {
      id: listingItem.position.toString(),
      value: {
        routeNode: (
          <div title={`${listingItem.position} - ${listingItem.description}`}>
            <div>{`${listingItem.position} - ${listingItem.description}`}</div>
          </div>
        ),
        time: (
          <div
            title={dateService.format(
              listingItem.checkoutDate!,
              "dd/mm/yyyy HH:MM"
            )}
          >
            {dateService.format(listingItem.checkoutDate!, "HH:MM")}
          </div>
        ),
        plannedTime: (
          <div
            title={dateService.format(
              listingItem.plannedDate!,
              "dd/mm/yyyy HH:MM"
            )}
          >
            {dateService.format(listingItem.plannedDate!, "HH:MM")}
          </div>
        ),
        haltingTime: (
          <div title={Math.ceil(listingItem.haltingTime / 60).toString()}>
            {Math.ceil(listingItem.haltingTime / 60).toString()}
          </div>
        ),
        distance: (
          <div title={listingItem.distance?.toString()}>
            {listingItem?.distance}
          </div>
        ),
        plannedDistance: (
          <div title={listingItem.plannedDistance?.toString()}>
            {listingItem.plannedDistance}
          </div>
        ),
        highwayCost: (
          <div title={listingItem.highwayCharge?.toString()}>
            {listingItem.highwayCharge?.toString()}
          </div>
        ),
        actions: !isFirstNode && (
          <TableLinkButtonComponent
            icon={faMapLocation}
            openInNewTab
            to={listingItem.googleMapsUrl}
          />
        ),
      },
    };
  };

  const tableRows: BillingsRouteDetailsTableRow[] = useMemo(() => {
    if (props.billingNodes) {
      return props.billingNodes.map((item) => createTableRow(item));
    }

    return [];
  }, [props.billingNodes]);

  const tableColumns = billingRouteDetailsHelper.getTableColumns();

  const allRoutePoints: BillingsRouteDetailsMapPoints[] | undefined =
    props.billingNodes?.map((item) => [item.lat, item.lon]);
  const googleMapsRouteUrl = googleMapsService.buildRouteUrlWithManyPoints(
    allRoutePoints!
  );

  return (
    <>
      <CardComponent
        classNames={{ root: "route_details" }}
        header={{ title: translations.headingText }}
      >
        <TableComponent columns={tableColumns} rows={tableRows} />
        <div className="route_details_button_wrapper">
          <LinkButtonComponent
            type="primary"
            title={translations.opentRouteInGoogleMapsTitle}
            to={googleMapsRouteUrl}
            openInNewTab
          >
            {translations.opentRouteInGoogleMapsLabel}
          </LinkButtonComponent>
        </div>
      </CardComponent>
    </>
  );
};

export default BillingsRouteDetailsOverviewComponent;
