import { camelizeKeys, decamelizeKeys } from "humps";
import appConfig from "../../../app.config";
import authService from "../../../modules/auth/common/auth.service";
import ApiConfig from "../types/api-config";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import dateService from "../../utils/date/date.service";
import ApiHeaders from "../types/api-headers";

const getConfigDefaultHeaders = (): ApiHeaders => {
  const authToken = authService.getAccessToken();

  const headers: ApiHeaders = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  return headers;
};

const getConfigRequestInterceptors = (config: InternalAxiosRequestConfig) => {
  const newConfig = { ...config };
  if (newConfig.headers["Content-Type"] === "multipart/form-data") {
    return newConfig;
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  return newConfig;
};

const transformDates = (data: any): any => {
  if (dateService.isValidDateTime(data)) {
    return new Date(data);
  }
  if (Array.isArray(data)) {
    return data.map(transformDates);
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, transformDates(value)])
    );
  }
  return data;
};

const getConfigResponseInterceptors = (response: AxiosResponse) => {
  if (
    response.data &&
    response.headers["content-type"] === "application/json"
  ) {
    response.data = camelizeKeys(response.data);
    response.data = transformDates(response.data);
  }

  return response;
};

const getConfig = (): ApiConfig => {
  const defaultHeaders = getConfigDefaultHeaders();

  const config: ApiConfig = {
    baseUrl: appConfig.baseApiUrl,
    defaultHeaders,
    interceptors: {
      request: getConfigRequestInterceptors,
      response: getConfigResponseInterceptors,
    },
    withCredentials: true,
    paramSerializerConfig: { indexes: null },
  };

  return config;
};

const railyApiHelper = {
  getConfig,
};

export default railyApiHelper;
