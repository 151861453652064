import MessengerChannel from "../../../../common/components/messenger/types/messenger-channel";
import MessengerChannelAvailability from "../../../../common/components/messenger/types/messenger-channel-availability";

const getMessengerChannelAvailability = (): MessengerChannelAvailability => {
  return {
    [MessengerChannel.BILLING]: false,
    [MessengerChannel.CARGO]: false,
    [MessengerChannel.DRIVER]: true,
    [MessengerChannel.RAILY]: false,
    [MessengerChannel.SHARED]: false,
    [MessengerChannel.TAXI]: false,
  };
};

const billingsTaxiDriverHelper = { getMessengerChannelAvailability };

export default billingsTaxiDriverHelper;
