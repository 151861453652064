import HttpResponse from "../../../../utils/http/http.response";

export enum CargoOrderActiveListResponseItemStatus {
  CANDIDATURE = "CANDIDATURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  PLANNED = "PLANNED",
}

export enum CargoOrderActiveListResponseItemPublicStatus {
  PUBLIC = "PUBLIC",
  PUBLISHED = "PUBLISHED",
  PRIVATE = "PRIVATE",
}

type CargoOrderActiveListResponseItemProducer = {
  id: string | null;
  name: string;
};

type CargoOrderActiveListResponseItemConsumer = {
  id: string | null;
  name: string;
};

type CargoOrderActiveListResponseItemClient = {
  id: string | null;
  name: string;
};

type CargoOrderActiveListingResponseItemAttentions = {
  newSharedPost: boolean;
  cargoOrderEdit: boolean;
  cargoOrderCancel: boolean;
};

export type CargoOrderActiveListResponseItem = {
  id: string;
  startTime: Date;
  humanId: number;
  rideAddresses: (string | string[])[];
  passengers: string[];
  dispatch: string;
  client: CargoOrderActiveListResponseItemClient;
  taxiCorporations: string[];
  consumer: CargoOrderActiveListResponseItemConsumer | null;
  producer: CargoOrderActiveListResponseItemProducer;
  drivers: string[];
  push: boolean;
  status: CargoOrderActiveListResponseItemStatus;
  externalId: string | null;
  publicStatus: CargoOrderActiveListResponseItemPublicStatus;
  isCancelled: boolean;
  isCancelRequested: boolean;
  attentions: CargoOrderActiveListingResponseItemAttentions;
  slkStatus: boolean | null;
};

type CargoOrderActiveListResponse = HttpResponse<
  CargoOrderActiveListResponseItem[]
>;

export default CargoOrderActiveListResponse;
