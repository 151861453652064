import UserRole from "../../../../../common/types/user-role";
import userPermissionsService from "../../../../../common/utils/user/permissions/user-permissions.service";
import routeActiveListingUserPermissionDefinition, {
  RouteActiveListingUserPermissions,
} from "./route-active-listing-user-permission";

const getPermissions = (
  userRoles: UserRole[]
): RouteActiveListingUserPermissions => {
  const permissions = userPermissionsService.solvePermissions(
    userRoles,
    routeActiveListingUserPermissionDefinition
  );

  return permissions;
};

const routeActiveListingUserPermissionsHelper = {
  getPermissions,
};

export default routeActiveListingUserPermissionsHelper;
