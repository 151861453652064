import DataLoadError, {
  DataLoadDefaultErrors,
} from "../../../utils/data-load-error/data-load-error";

export enum DriverPlanCandidatureAddByOrderIdErrorType {
  DRIVER_HAS_CANDIDATURE = "DRIVER_HAS_CANDIDATURE",
  PLAN_ENTRY_CANDIDATE_COLLISION = "PLAN_ENTRY_CANDIDATE_COLLISION",
  TAXI_DRIVER_ASSOCIATION_NOT_EXISTS = "TAXI_DRIVER_ASSOCIATION_NOT_EXISTS",
  TAXI_DRIVER_CONTRACT_NOT_VALID = "TAXI_DRIVER_CONTRACT_NOT_VALID",
  TAXI_RAILY_CONTRACT_NOT_VALID = "TAXI_RAILY_CONTRACT_NOT_VALID",
  ORDER_NOT_EXISTS = "ORDER_NOT_EXISTS",
  ORDER_IN_PROGRESS = "ORDER_IN_PROGRESS",
  PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR = "PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR",
}

export type DriverHasCandidatureError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.DRIVER_HAS_CANDIDATURE,
  {}
>;

export type DriverPlanEntryCandidateCollisionError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_COLLISION,
  {}
>;

export type DriverPlanTaxiDriverAssociationNotExistsError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.TAXI_DRIVER_ASSOCIATION_NOT_EXISTS,
  {}
>;

export type DriverPlanTaxiDriverContractNotValidError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.TAXI_DRIVER_CONTRACT_NOT_VALID,
  {}
>;

export type DriverPlanTaxiRailyContractNotExistsError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.TAXI_RAILY_CONTRACT_NOT_VALID,
  {}
>;

export type DriverPlanOrderNotExistsError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.ORDER_NOT_EXISTS,
  {}
>;

export type DriverPlanOrderInProgressError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.ORDER_IN_PROGRESS,
  {}
>;

export type DriverPlanPlanEntryCandidateStartTimeExpiredError = DataLoadError<
  DriverPlanCandidatureAddByOrderIdErrorType.PLAN_ENTRY_CANDIDATE_START_TIME_EXPIRED_ERROR,
  {}
>;

type DriverPlanCandidatureAddByOrderIdError =
  | DataLoadDefaultErrors
  | DriverHasCandidatureError
  | DriverPlanEntryCandidateCollisionError
  | DriverPlanTaxiDriverAssociationNotExistsError
  | DriverPlanTaxiDriverContractNotValidError
  | DriverPlanTaxiRailyContractNotExistsError
  | DriverPlanOrderNotExistsError
  | DriverPlanOrderInProgressError
  | DriverPlanPlanEntryCandidateStartTimeExpiredError;

export default DriverPlanCandidatureAddByOrderIdError;
