import {
  CargoOrderDetailsResponseItem,
  CargoOrderDetailsResponseItemPublicStatus,
  CargoOrderDetailsResponseItemStatus,
  CargoOrderDetailsResponseItemTransportingOrder,
  CargoOrderDetailsResponseItemTransportingOrderDriver,
  CargoOrderDetailsResponseItemTransportingOrderPassenger,
  CargoOrderDetailsResponseItemSolveWaypoint,
  CargoOrderDetailsResponseItemPassengerType,
  CargoOrderDetailsResponseItemTransportingOrderInternalPassenger,
  CargoOrderDetailsResponseItemTransportingOrderExternalPassenger,
  CargoOrderDetailsResopnseItemSolve,
} from "../../../api/raily/cargo-order/details/cargo-order-details.response";
import CargoOrderDetails, {
  CargoOrderDetailsExecutionStatus,
  CargoOrderDetailsPublicStatus,
  CargoOrderDetailsTransportingOrder,
  CargoOrderDetailsTransportingOrderDriver,
  CargoOrderDetailsTransportingOrderPassengerType,
  CargoOrderDetailsTransportingOrderPassenger,
  CargoOrderDetailsTransportingOrderWaypointItem,
  CargoOrderDetailsTransportingOrderInternalPassenger,
  CargoOrderDetailsTransportingOrderExternalPassenger,
  CargoOrderDetailsTransportingOrderWaypoint,
} from "./cargo-order-details";
CargoOrderDetailsResponseItemPublicStatus;

const createPublicStatus = (
  responsePublicStatus: CargoOrderDetailsResponseItemPublicStatus
): CargoOrderDetailsPublicStatus => {
  switch (responsePublicStatus) {
    case CargoOrderDetailsResponseItemPublicStatus.PRIVATE:
      return CargoOrderDetailsPublicStatus.PRIVATE;
    case CargoOrderDetailsResponseItemPublicStatus.PUBLIC:
      return CargoOrderDetailsPublicStatus.PUBLIC;
    case CargoOrderDetailsResponseItemPublicStatus.PUBLISHED:
      return CargoOrderDetailsPublicStatus.PUBLISHED;
  }
};

const createExecutionStatus = (
  responseStatus: CargoOrderDetailsResponseItemStatus
): CargoOrderDetailsExecutionStatus => {
  switch (responseStatus) {
    case CargoOrderDetailsResponseItemStatus.CANDIDATURE:
      return CargoOrderDetailsExecutionStatus.CANDIDATURE;
    case CargoOrderDetailsResponseItemStatus.IN_PROGRESS:
      return CargoOrderDetailsExecutionStatus.IN_PROGRESS;
    case CargoOrderDetailsResponseItemStatus.PENDING:
      return CargoOrderDetailsExecutionStatus.PENDING;
    case CargoOrderDetailsResponseItemStatus.PLANNED:
      return CargoOrderDetailsExecutionStatus.PLANNED;
    case CargoOrderDetailsResponseItemStatus.FINISHED:
      return CargoOrderDetailsExecutionStatus.FINISHED;
  }
};

const createAlternativePhoneNumber = (
  responseItem: CargoOrderDetailsResponseItemTransportingOrderDriver
): CargoOrderDetailsTransportingOrderDriver["alternativePhoneNumber"] => {
  if (!responseItem.mobileSecondary || !responseItem.mobileSecondaryPrefix)
    return null;

  return {
    dialingCode: responseItem.mobileSecondaryPrefix,
    number: responseItem.mobileSecondary,
  };
};

const createTransportingOrderDriver = (
  driverItem: CargoOrderDetailsResponseItemTransportingOrderDriver
): CargoOrderDetailsTransportingOrderDriver => {
  return {
    removalDate: driverItem.removedAt,
    uuid: driverItem.id,
    modificationDate: driverItem.modifiedAt,
    modifiedBy: driverItem.modifiedBy,
    creationDate: driverItem.createdAt,
    createdBy: driverItem.createdBy,
    firstName: driverItem.firstName,
    lastName: driverItem.lastName,
    username: driverItem.username,
    phoneNumber: {
      dialingCode: driverItem.mobilePrimaryPrefix,
      number: driverItem.mobilePrimary,
    },
    alternativePhoneNumber: createAlternativePhoneNumber(driverItem),
    email: driverItem.email,
  };
};

const createTransportingOrderInternalPassenger = (
  passengerItem: CargoOrderDetailsResponseItemTransportingOrderInternalPassenger
): CargoOrderDetailsTransportingOrderInternalPassenger => {
  return {
    type: CargoOrderDetailsTransportingOrderPassengerType.INTERNAL,
    firstName: passengerItem.firstName,
    lastName: passengerItem.lastName,
    phoneNumber: {
      dialingCode: passengerItem.phonePrefix,
      number: passengerItem.phoneNo,
    },
    uuid: passengerItem.passengerId,
  };
};

const createTransportingOrderExternalPassenger = (
  passengerItem: CargoOrderDetailsResponseItemTransportingOrderExternalPassenger
): CargoOrderDetailsTransportingOrderExternalPassenger => {
  return {
    type: CargoOrderDetailsTransportingOrderPassengerType.EXTERNAL,
    firstName: passengerItem.firstName,
    lastName: passengerItem.lastName,
    phoneNumber: {
      dialingCode: passengerItem.phonePrefix,
      number: passengerItem.phoneNo,
    },
  };
};

const createTransportingOrderPassenger = (
  passengerItem: CargoOrderDetailsResponseItemTransportingOrderPassenger
): CargoOrderDetailsTransportingOrderPassenger => {
  if (
    passengerItem.itemType ===
    CargoOrderDetailsResponseItemPassengerType.INTERNAL
  )
    return createTransportingOrderInternalPassenger(passengerItem);

  return createTransportingOrderExternalPassenger(passengerItem);
};

const createTransportingOrderPassengers = (
  passengerItem: CargoOrderDetailsResponseItemTransportingOrderPassenger[]
): CargoOrderDetailsTransportingOrderPassenger[] =>
  passengerItem.map(createTransportingOrderPassenger);

const createTransportingOrderWaypoint = (
  waypointItem: CargoOrderDetailsResponseItemSolveWaypoint
): CargoOrderDetailsTransportingOrderWaypointItem => {
  return {
    uuid: waypointItem.nodeId,
    latitude: waypointItem.lat,
    longitude: waypointItem.lon,
    orderedDate: waypointItem.requestedTime,
    orderedStopoverTime: waypointItem.requestedHaltingTime,
    displayName: waypointItem.displayName,
    boardingPassengersUuids: waypointItem.cargoEnter,
    disembarkingPassengerUuids: waypointItem.cargoExit,
    estimatedDate: waypointItem.estimatedTime,
    arrivalDate: waypointItem.checkoutEvent?.achievedAt!,
    stopoverTime: waypointItem.checkoutEvent?.haltingTime!,
    distance: waypointItem.distance,
  };
};

const checkIsSingleWaypoint = (
  item: CargoOrderDetailsResopnseItemSolve
): item is CargoOrderDetailsResponseItemSolveWaypoint =>
  !!(item as CargoOrderDetailsResponseItemSolveWaypoint).nodeId;

const createTransportingOrderWaypoints = (
  transportingOrderItemSolve: CargoOrderDetailsResopnseItemSolve[]
): CargoOrderDetailsTransportingOrderWaypoint[] =>
  transportingOrderItemSolve.map((itemSolve) => {
    const isSingleWaypoint = checkIsSingleWaypoint(itemSolve);
    if (isSingleWaypoint) return createTransportingOrderWaypoint(itemSolve);

    return itemSolve.map(createTransportingOrderWaypoint);
  });

const createTransportingOrderSolvedWaypoints = (
  transportingOrderItemSolve: CargoOrderDetailsResponseItemSolveWaypoint[]
): CargoOrderDetailsTransportingOrderWaypointItem[] => {
  return transportingOrderItemSolve.map(createTransportingOrderWaypoint);
};

const createTransportingOrder = (
  transportingOrder: CargoOrderDetailsResponseItemTransportingOrder
): CargoOrderDetailsTransportingOrder => {
  return {
    uuid: transportingOrder.id,
    driver: transportingOrder.driver
      ? createTransportingOrderDriver(transportingOrder.driver)
      : null,
    passengers: createTransportingOrderPassengers(transportingOrder.passengers),
    waypoints: createTransportingOrderWaypoints(transportingOrder.solve),
    solvedWaypoints: transportingOrder.driverSolve
      ? createTransportingOrderSolvedWaypoints(transportingOrder.driverSolve)
      : [],
  };
};

const createTransportingOrderDetails = (
  transportingOrder: CargoOrderDetailsResponseItemTransportingOrder[]
): CargoOrderDetailsTransportingOrder[] =>
  transportingOrder.map(createTransportingOrder);

const create = (
  responseItem: CargoOrderDetailsResponseItem
): CargoOrderDetails => {
  return {
    uuid: responseItem.id,
    targetTaxiUuid: responseItem.targetTaxiId,
    externalOrderId: responseItem.externalId,
    internalOrderId: responseItem.humanId,
    isCancelled: responseItem.isCancelled,
    isCancelRequested: responseItem.isCancelRequested,
    publicStatus: createPublicStatus(responseItem.orderType),
    executionStatus: createExecutionStatus(responseItem.status),
    tollRoadsPermissions: {
      isOnApproachAllowed: responseItem.nodeExclusions.excludeApproaching,
      isOnReturningAllowed: responseItem.nodeExclusions.excludeReturning,
      isDuringRideAllowed: responseItem.nodeExclusions.excludeInside,
    },
    dispatcherUuid: responseItem.dispatcher.dispatchId,
    dispatchUuid: responseItem.dispatcher.dispatch?.id!,
    transportingOrders: createTransportingOrderDetails(
      responseItem.transportingOrders
    ),
  };
};

const cargoOrderDetailsFactory = { create };

export default cargoOrderDetailsFactory;
