import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import dateService from "../../../../../../common/utils/date/date.service";
import TaxiTransferedOrderSettlementListingTableColumn from "../types/taxi-transfered-order-settlement-listing-table-column";
import TaxiTransferedOrderSettlementListingBonusReason from "../types/taxi-transfered-order-settlement-listing-bonus-reason";
import TaxiTransferedOrderSettlementListingPenaltyReason from "../types/taxi-transfered-order-settlement-listing-penalty-reason";
import TaxiTransferedOrderSettlementListingItem from "../types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingTableRow from "../types/taxi-transfered-order-settlement-listing-table-row";
import taxiTransferedOrderSettlementListingHelper from "../helper/taxi-transfered-order-settlement-listing.helper";
import TaxiTransferedOrderSettlementListingTableStatusComponent from "./status/taxi-transfered-order-settlement-listing-table-status.component";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faCircleInfo, faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";

const getColumns = (): TaxiTransferedOrderSettlementListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table.headers;

  return [
    {
      accessor: "billingStatus",
      header: "",
      title: "",
      colSpan: 2,
    },
    {
      accessor: "orderStartDate",
      header: translations.orderStartDateLabel,
      title: translations.orderStartDateTitle,
      colSpan: 10,
    },
    {
      accessor: "orderTransferDate",
      header: translations.orderTransferDateLabel,
      title: translations.orderTransferDateTitle,
      colSpan: 10,
    },
    {
      accessor: "internalOrderId",
      header: translations.internalOrderIdLabel,
      title: translations.internalOrderIdTitle,
      colSpan: 5,
    },
    {
      accessor: "producerTaxi",
      header: translations.producerTaxiLabel,
      title: translations.producerTaxiTitle,
      colSpan: 10,
    },
    {
      accessor: "consumerTaxi",
      header: translations.consumerTaxiLabel,
      title: translations.consumerTaxiTitle,
      colSpan: 10,
    },
    {
      accessor: "contractName",
      header: translations.contractNameLabel,
      title: translations.contractNameTitle,
      colSpan: 10,
    },
    {
      accessor: "billingModel",
      header: translations.billingModelLabel,
      title: translations.billingModelTitle,
      colSpan: 6,
    },
    {
      accessor: "distance",
      header: translations.distanceLabel,
      title: translations.distanceTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceRate",
      header: translations.distanceRateLabel,
      title: translations.distanceRateTitle,
      colSpan: 10,
    },
    {
      accessor: "distanceCost",
      header: translations.distanceCostLabel,
      title: translations.distanceCostTitle,
      colSpan: 10,
    },
    {
      accessor: "stopCost",
      header: translations.stopoverCostLabel,
      title: translations.stopoverCostTitle,
      colSpan: 10,
    },
    {
      accessor: "tollRoadsCost",
      header: translations.tollRoadsCostLabel,
      title: translations.tollRoadsCostTitle,
      colSpan: 10,
    },
    {
      accessor: "bonus",
      header: translations.bonusLabel,
      title: translations.bonusTitle,
      colSpan: 5,
    },
    {
      accessor: "penalty",
      header: translations.penaltyLabel,
      title: translations.penaltyTitle,
      colSpan: 5,
    },
    {
      accessor: "totalCost",
      header: translations.totalCostLabel,
      title: translations.totalCostTitle,
      colSpan: 9,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 9,
    },
  ];
};

const getInternalOrderIdLabel = (internalOrderId: number): string => {
  return String(internalOrderId);
};

const getInternalOrderIdTitle = (internalOrderId: number): string => {
  return String(internalOrderId);
};

const getDistanceLabel = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceTitle = (distance: number): string => {
  return `${distance} km`;
};

const getDistanceRateLabel = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getDistanceRateTitle = (distanceRate: number): string => {
  return `${String(distanceRate.toFixed(2))} PLN/km`;
};

const getStopoverCostLabel = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getStopoverCostTitle = (stopoverCost: number): string => {
  return `${String(stopoverCost.toFixed(2))} PLN`;
};

const getTollRoadsCostLabel = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getTollRoadsCostTitle = (tollRoadsCost: number): string => {
  return `${String(tollRoadsCost.toFixed(2))} PLN`;
};

const getDistanceCostLabel = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getDistanceCostTitle = (distanceCost: number): string => {
  return `${String(distanceCost.toFixed(2))} PLN`;
};

const getTotalCostLabel = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getTotalCostTitle = (totalCost: number): string => {
  return `${String(totalCost.toFixed(2))} PLN`;
};

const getBonusReasonText = (
  bonusReason: TaxiTransferedOrderSettlementListingBonusReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .bonusReason;

  switch (bonusReason) {
    case TaxiTransferedOrderSettlementListingBonusReason.DISTANCE_RATE_CHANGED:
      return translations.DISTANCE_RATE_CHANGED;
    case TaxiTransferedOrderSettlementListingBonusReason.HALTING_RATE_CHANGED:
      return translations.HALTING_RATE_CHANGED;
    case TaxiTransferedOrderSettlementListingBonusReason.OTHER:
      return translations.OTHER;
    case TaxiTransferedOrderSettlementListingBonusReason.SHORT_ROUTE:
      return translations.SHORT_ROUTE;
  }
};

const getPenaltyReasonText = (
  penaltyReason: TaxiTransferedOrderSettlementListingPenaltyReason
): string => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .penaltyReason;

  switch (penaltyReason) {
    case TaxiTransferedOrderSettlementListingPenaltyReason.BEING_LATE:
      return translations.BEING_LATE;
    case TaxiTransferedOrderSettlementListingPenaltyReason.GUIDELINE_IGNORED:
      return translations.GUIDELINE_IGNORED;
    case TaxiTransferedOrderSettlementListingPenaltyReason.HIGHWAY_NOT_ALLOWED:
      return translations.HIGHWAY_NOT_ALLOWED;
    case TaxiTransferedOrderSettlementListingPenaltyReason.INCOMPATIBLE_CAR:
      return translations.INCOMPATIBLE_CAR;
    case TaxiTransferedOrderSettlementListingPenaltyReason.OPTIMIZATION_REFUSED:
      return translations.OPTIMIZATION_REFUSED;
    case TaxiTransferedOrderSettlementListingPenaltyReason.OTHER:
      return translations.OTHER;
    case TaxiTransferedOrderSettlementListingPenaltyReason.SUBOPTIMAL_RIDE:
      return translations.SUBOPTIMAL_RIDE;
  }
};

const getBonusLabel = (
  bonus: TaxiTransferedOrderSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
        .table.bonusLabelTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getBonusTitle = (
  bonus: TaxiTransferedOrderSettlementListingItem["bonus"]
): string => {
  if (bonus?.cost && bonus.reason) {
    const template =
      settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
        .table.bonusTitleTemplateText;

    return template
      .replace("#{cost}", String(bonus.cost))
      .replace("#{reason}", getBonusReasonText(bonus.reason));
  }

  return "";
};

const getPenaltyLabel = (
  penalty: TaxiTransferedOrderSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
        .table.penaltyLabelTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getPenaltyTitle = (
  penalty: TaxiTransferedOrderSettlementListingItem["penalty"]
): string => {
  if (penalty?.cost && penalty.reason) {
    const template =
      settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
        .table.penaltyTitleTemplateText;

    return template
      .replace("#{cost}", String(penalty.cost))
      .replace("#{reason}", getPenaltyReasonText(penalty.reason));
  }

  return "";
};

const getRow = (
  listingItem: TaxiTransferedOrderSettlementListingItem
): TaxiTransferedOrderSettlementListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiTransferedOrderSettlementListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.orderStartDate,
    "dd.mm.yyyy"
  );

  const transferDateLabel = dateService.format(
    listingItem.orderTransferDate,
    "dd.mm.yyyy"
  );

  const transferDateTitle = dateService.format(
    listingItem.orderTransferDate,
    "dd.mm.yyyy"
  );

  const internalOrderIdLabel = getInternalOrderIdLabel(
    listingItem.internalOrderId
  );
  const internalOrderIdTitle = getInternalOrderIdTitle(
    listingItem.internalOrderId
  );

  const distanceLabel = getDistanceLabel(listingItem.distance);
  const distanceTitle = getDistanceTitle(listingItem.distance);

  const distanceRateLabel = getDistanceRateLabel(listingItem.distanceRate);
  const distanceRateTitle = getDistanceRateTitle(listingItem.distanceRate);

  const stopoverCostLabel = getStopoverCostLabel(listingItem.stopoverCost);
  const stopoverCostTitle = getStopoverCostTitle(listingItem.stopoverCost);

  const tollRoadsCostLabel = getTollRoadsCostLabel(listingItem.tollRoadsCost);
  const tollRoadsCostTitle = getTollRoadsCostTitle(listingItem.tollRoadsCost);

  const distanceCostLabel = getDistanceCostLabel(listingItem.distanceCost);
  const distanceCostTitle = getDistanceCostTitle(listingItem.distanceCost);

  const totalCostLabel = getTotalCostLabel(listingItem.totalCost);
  const totalCostTitle = getTotalCostTitle(listingItem.totalCost);

  const bonusLabel = getBonusLabel(listingItem.bonus);
  const bonusTitle = getBonusTitle(listingItem.bonus);

  const penaltyLabel = getPenaltyLabel(listingItem.penalty);
  const penaltyTitle = getPenaltyTitle(listingItem.penalty);

  const billingModelLabel =
    taxiTransferedOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );
  const billingModelTitle =
    taxiTransferedOrderSettlementListingHelper.getBillingModelText(
      listingItem.billingModel
    );

  const isEditButtonVisible = listingItem.canBeModified;

  return {
    id: listingItem.uuid,
    value: {
      billingStatus: (
        <TaxiTransferedOrderSettlementListingTableStatusComponent
          status={listingItem.status}
        />
      ),
      orderStartDate: <div title={startDateLabel}>{startDateTitle}</div>,
      orderTransferDate: (
        <div title={transferDateLabel}>{transferDateTitle}</div>
      ),
      internalOrderId: (
        <div title={internalOrderIdLabel}>{internalOrderIdTitle}</div>
      ),
      producerTaxi: (
        <div title={listingItem.producerTaxi}>{listingItem.producerTaxi}</div>
      ),
      consumerTaxi: (
        <div title={listingItem.consumerTaxi}>{listingItem.consumerTaxi}</div>
      ),
      contractName: (
        <div title={listingItem.contractName}>{listingItem.contractName}</div>
      ),
      billingModel: <div title={billingModelTitle}>{billingModelLabel}</div>,
      bonus: <div title={bonusTitle}>{bonusLabel}</div>,
      penalty: <div title={penaltyTitle}>{penaltyLabel}</div>,
      distance: <div title={distanceTitle}>{distanceLabel}</div>,
      distanceRate: <div title={distanceRateTitle}>{distanceRateLabel}</div>,
      distanceCost: <div title={distanceCostTitle}>{distanceCostLabel}</div>,
      stopCost: <div title={stopoverCostTitle}>{stopoverCostLabel}</div>,
      tollRoadsCost: <div title={tollRoadsCostTitle}>{tollRoadsCostLabel}</div>,
      totalCost: <div title={totalCostTitle}>{totalCostLabel}</div>,
      actions: (
        <div className="d-flex">
          {isEditButtonVisible && (
            <TableLinkButtonComponent
              icon={faEdit}
              to={billingRoutesHelper.getTaxiWithTaxiEditRoute({
                billingUuid: listingItem.uuid,
              })}
              title={translations.taxiWithTaxiSettlementEditButtonTitle}
              idForTesting={`taxi-transfered-order-settlement-listing-table-item-${listingItem.uuid}-edit-button`}
            />
          )}
          <TableLinkButtonComponent
            icon={faCircleInfo}
            to={billingRoutesHelper.getTaxiWithTaxiDetailsRoute({
              billingUuid: listingItem.uuid,
            })}
            title={translations.taxiWithTaxiSettlementViewButtonTitle}
            idForTesting={`taxi-transfered-order-settlement-listing-table-item-${listingItem.uuid}-details-button`}
          />
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiTransferedOrderSettlementListingItem[]
): TaxiTransferedOrderSettlementListingTableRow[] => {
  return listingItems.map((listingItem) => getRow(listingItem));
};

const taxiTransferedOrderSettlementListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiTransferedOrderSettlementListingTableHelper;
