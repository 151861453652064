import { FC, useEffect, useMemo, useState } from "react";
import CardComponent from "../../../../common/components/card/card.component";
import DateRange from "../../../../common/types/date-range";
import HeadingComponent from "../../../../common/components/heading/heading.component";
import ListingSortSelectComponent from "../../../../common/components/listing/filter/sort/select/listing-sort-select.component";
import PaginationComponent from "../../../../common/components/pagination/pagination.component";
import useClientSidePagination from "../../../../common/hooks/use-clientside-pagination";
import useIsComponentMounted from "../../../../common/hooks/use-is-component-mounted";
import useRouteQueryParams from "../../../../common/hooks/use-route-query-params";
import routeTranslationsHelper from "../../../../languages/route-translations.helper";
import routeActiveListingApiService from "../common/api/route-active-listing-api.service";
import RouteActiveListingResponse from "../common/api/route-active-listing.response";
import RouteActiveListingViewBasicProps from "../common/types/route-active-listing-view-basic-props";
import routeActiveListingByRailyFactory from "./common/factory/route-active-listing-by-raily.factory";
import routeActiveListingByRailyFilterService from "./common/filter/route-active-listing-by-raily-filter.service";
import routeActiveListingByRailyRouteQueryParamsService from "./common/route-active-listing-by-raily-route-query-params.service";
import routeActiveListingByRailySortHelper from "./common/sort/route-active-listing-by-raily-sort.helper";
import routeActiveListingByRailySortService from "./common/sort/route-active-listing-by-raily-sort.service";
import RouteActiveListingByRailyFilter, {
  RouteActiveListingByRailyEndDateFilter,
  RouteActiveListingByRailyStartDateFilter,
} from "./common/types/route-active-listing-by-raily-filter";
import RouteActiveListingByRailyFilterType from "./common/types/route-active-listing-by-raily-filter-type";
import RouteActiveListingByRailyListingItem from "./common/types/route-active-listing-by-raily-item";
import RouteActiveListingByRailyRouteQueryParams from "./common/types/route-active-listing-by-raily-route-query-params";
import RouteActiveListingByRailySortKey from "./common/types/route-active-listing-by-raily-sort-key";
import RouteActiveListingByRailySortSelectOption from "./common/types/route-active-listing-by-raily-sort-select-option";
import RouteActiveListingByRailyFiltersBadgeListComponent from "./filter/list/route-active-listing-by-raily-filters-badge-list.component";
import RouteActiveListingByRailyFiltersSelectComponent from "./filter/select/route-active-listing-by-raily-listing-filters-select.component";
import RouteActiveListingByRailyTableComponent from "./table/route-active-listing-by-raily-table.component";
import DateRangeInputComponent from "../../../../common/components/form/input/date-range/date-range-input.component";

type RouteActiveListingByRailyProps = RouteActiveListingViewBasicProps;

const RouteActiveListingByRailyComponent: FC<RouteActiveListingByRailyProps> = (
  props
) => {
  const isComponentMounted = useIsComponentMounted();

  const [routeQueryParams, setRouteQueryParams] =
    useRouteQueryParams<RouteActiveListingByRailyRouteQueryParams>();

  const translations = routeTranslationsHelper.getActiveListingTranslations();

  const [filters, setFilters] = useState<RouteActiveListingByRailyFilter[]>(
    () =>
      routeActiveListingByRailyRouteQueryParamsService.getFilters(
        routeQueryParams
      )
  );

  const [selectedSortKey, setSelectedSortKey] =
    useState<RouteActiveListingByRailySortKey | null>(
      () =>
        routeActiveListingByRailyRouteQueryParamsService.getSortKey(
          routeQueryParams
        ) ?? null
    );

  const [isListingFetching, setIsListingFetching] = useState(false);
  const [isListingFetchingError, setIsListingFetchingError] = useState(false);
  const [listingItems, setListingItems] = useState<
    RouteActiveListingByRailyListingItem[]
  >([]);
  const [filteredListingItems, setFilteredListingItems] = useState<
    RouteActiveListingByRailyListingItem[]
  >([]);

  const { pageData, page, pageSize, setPage, setPageSize, totalResults } =
    useClientSidePagination({
      data: filteredListingItems,
      totalResults: filteredListingItems.length,
      defaultPageSize:
        routeActiveListingByRailyRouteQueryParamsService.getPageSize(
          routeQueryParams
        ),
      defaultPage:
        routeActiveListingByRailyRouteQueryParamsService.getPage(
          routeQueryParams
        ),
    });

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const queryParams =
      routeActiveListingByRailyRouteQueryParamsService.createRouteQueryParams(
        filters,
        selectedSortKey,
        page,
        pageSize
      );

    setRouteQueryParams(queryParams);
  }, [filters, selectedSortKey, page, pageSize]);

  const addNewFilter = (newFilter: RouteActiveListingByRailyFilter) => {
    setFilters((curr) => [...curr, newFilter]);
  };

  const deleteFilter = (index: number) => {
    const newFilters = filters.filter((filterItem) => {
      return filters.indexOf(filterItem) !== index;
    });

    setFilters(newFilters);
  };

  const deleteAllFilters = () => {
    setFilters([]);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    const filteredListingItems =
      routeActiveListingByRailyFilterService.filterListingItems(
        listingItems,
        filters
      );

    const sortedListingItems =
      routeActiveListingByRailySortService.sortListingItems(
        filteredListingItems,
        selectedSortKey
      );

    setFilteredListingItems(sortedListingItems);
  }, [selectedSortKey, JSON.stringify(filters), JSON.stringify(listingItems)]);

  useEffect(() => {
    if (!isComponentMounted) {
      return;
    }

    setPage(1);
  }, [pageSize, filters]);

  const onListingFetchSuccess = (response: RouteActiveListingResponse) => {
    const listingItems = routeActiveListingByRailyFactory.createListingItems(
      response.data
    );

    setListingItems(listingItems);
  };

  const onListingFetchFailure = () => {
    setIsListingFetchingError(true);
  };

  const handleListingResponse = (response: RouteActiveListingResponse) => {
    if (response.status === 200) {
      onListingFetchSuccess(response);
      return;
    }
    onListingFetchFailure();
  };

  const fetchListing = () => {
    setIsListingFetching(true);
    setIsListingFetchingError(false);

    routeActiveListingApiService
      .fetchListing()
      .then(handleListingResponse)
      .catch(onListingFetchFailure)
      .finally(() => setIsListingFetching(false));
  };

  useEffect(() => {
    fetchListing();
  }, []);

  const sortSelectOptions: RouteActiveListingByRailySortSelectOption[] =
    useMemo(() => routeActiveListingByRailySortHelper.getSelectOptions(), []);

  const selectedSortSelectOption = useMemo(() => {
    return (
      sortSelectOptions.find((item) => item.value === selectedSortKey) ?? null
    );
  }, [selectedSortKey]);

  const startDateFilterValue = filters.find(
    (filter) => filter.type === RouteActiveListingByRailyFilterType.START_DATE
  )?.value as RouteActiveListingByRailyStartDateFilter["value"] | undefined;

  const onStartDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) =>
          filter.type !== RouteActiveListingByRailyFilterType.START_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) => filter.type === RouteActiveListingByRailyFilterType.START_DATE
    );

    const newFilter: RouteActiveListingByRailyStartDateFilter = {
      type: RouteActiveListingByRailyFilterType.START_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteActiveListingByRailyFilterType.START_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      setPage(1);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
    setPage(1);
  };

  const endDateFilterValue = filters.find(
    (filter) => filter.type === RouteActiveListingByRailyFilterType.END_DATE
  )?.value as RouteActiveListingByRailyEndDateFilter["value"] | undefined;

  const onEndDateFilterValueChange = (dateRange: DateRange | null) => {
    if (!dateRange) {
      const newFilters = filters.filter(
        (filter) => filter.type !== RouteActiveListingByRailyFilterType.END_DATE
      );

      setFilters(newFilters);
      return;
    }

    const isFilterExists = !!filters.find(
      (filter) => filter.type === RouteActiveListingByRailyFilterType.END_DATE
    );

    const newFilter: RouteActiveListingByRailyEndDateFilter = {
      type: RouteActiveListingByRailyFilterType.END_DATE,
      value: {
        from: dateRange.from,
        to: dateRange.to,
      },
    };

    if (isFilterExists) {
      const newFilters = [
        ...filters.filter(
          (filter) =>
            filter.type !== RouteActiveListingByRailyFilterType.END_DATE
        ),
        newFilter,
      ];

      setFilters(newFilters);
      return;
    }

    setFilters((curr) => [...curr, newFilter]);
  };

  return (
    <div className="route_active_listing">
      <HeadingComponent
        title={translations.header.headingText}
        actions={props.changeViewButtons}
      />
      <div className="route_active_listing_tools">
        <div className="d-flex">
          <RouteActiveListingByRailyFiltersSelectComponent
            filters={filters}
            onAddNewFilter={addNewFilter}
          />
          <DateRangeInputComponent
            date={startDateFilterValue ?? null}
            onChange={onStartDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByStartDateSelectInputPlaceholder
            }
            idForTesting="route-active-listing-by-raily-date-range-start"
          />
          <DateRangeInputComponent
            date={endDateFilterValue ?? null}
            onChange={onEndDateFilterValueChange}
            classNames={{ root: "ml-2" }}
            placeholder={
              translations.filters.searchByEndDateSelectInputPlaceholder
            }
            idForTesting="route-active-listing-by-raily-date-range-end"
          />
        </div>
        <ListingSortSelectComponent
          onChange={(option) => setSelectedSortKey(option?.value!)}
          options={sortSelectOptions}
          value={selectedSortSelectOption}
          idForTesting="route-active-listing-by-raily-select"
        />
      </div>
      <RouteActiveListingByRailyFiltersBadgeListComponent
        filters={filters}
        onDeleteFilterClick={deleteFilter}
        onDeleteAllFiltersButtonClick={deleteAllFilters}
      />
      <CardComponent classNames={{ root: "mt-4" }}>
        <RouteActiveListingByRailyTableComponent
          listingItems={pageData}
          isError={isListingFetchingError}
          isLoading={isListingFetching}
        />
        <div className="route_active_listing__pagination_wrapper">
          <PaginationComponent
            onPageChange={onPageChange}
            onPageSizeChange={setPageSize}
            page={page}
            pageSize={pageSize}
            totalResults={totalResults}
          />
        </div>
      </CardComponent>
    </div>
  );
};

export default RouteActiveListingByRailyComponent;
