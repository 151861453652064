import DashboardComponent from "../modules/dashboard/dashboard.component";
import orderRoutesDefinition from "../modules/order/common/routes/order-routes";
import cargoRoutesDefinition from "../modules/cargo/common/routes/cargo-routes";
import appRoutesUrls from "./app-routes-urls";
import RouteItemDefinition from "./types/route-item-definition";
import userRoutesDefinition from "../modules/user/common/routes/user-routes";
import taxiRoutesDefinition from "../modules/taxi/common/routes/taxi-routes";
import settlementsRoutesDefinition from "../modules/settlement/common/routes/settlement-routes";
import billingRoutesDefinition from "../modules/billings/common/routes/billing-routes";
import routeRoutesDefinition from "../modules/route/common/routes/route-routes";
import planningRoutesDefinition from "../modules/planning/common/routes/planning-routes";

const appRoutesDefinition: RouteItemDefinition[] = [
  ...cargoRoutesDefinition,
  ...orderRoutesDefinition,
  ...settlementsRoutesDefinition,
  ...taxiRoutesDefinition,
  ...userRoutesDefinition,
  ...billingRoutesDefinition,
  ...routeRoutesDefinition,
  ...planningRoutesDefinition,
  {
    path: appRoutesUrls.main,
    component: <DashboardComponent />,
  },
];

export default appRoutesDefinition;
