import orderTranslationsHelper from "../../../../../../languages/order-translations.helper";
import OrderRouteEditCargoAddress from "../../types/order-route-edit-cargo-address";
import OrderRouteEditPassenger, {
  OrderRouteEditPassengerAddress,
} from "../../types/order-route-edit-passenger";
import OrderRouteEditRouteAddress from "../../types/order-route-edit-route-address";
import OrderRouteEditRouteAddressSelectOption from "../../types/order-route-edit-route-address-select-option";
import {
  OrderRouteEditRouteWaypointGroupWaypoint,
  OrderRouteEditRouteWaypoint,
} from "../../types/order-route-edit-route-waypoint";
import orderRouteEditRoutesHelper from "./order-route-edit-routes.helper";

const createWaypointFromGroupWaypoint = (
  waypoint: OrderRouteEditRouteWaypointGroupWaypoint
): OrderRouteEditRouteWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    date: null,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createGroupWaypointFromWaypoint = (
  waypoint: OrderRouteEditRouteWaypoint
): OrderRouteEditRouteWaypointGroupWaypoint => {
  return {
    uuid: waypoint.uuid,
    address: waypoint.address,
    haltingTimeMinutes: waypoint.haltingTimeMinutes,
    onboardingPassengerListItems: waypoint.onboardingPassengerListItems,
    outboardingPassengerListItems: waypoint.outboardingPassengerListItems,
  };
};

const createRouteAddressSelectOptionFromPassengerAddress = (
  passenger: OrderRouteEditPassenger,
  passengerAddress: OrderRouteEditPassengerAddress
): OrderRouteEditRouteAddressSelectOption => {
  const translations =
    orderTranslationsHelper.getEditTranslations().routes.waypoint;

  return {
    label: passengerAddress.displayName,
    subText: `${translations.passengerAddressHomeType}: ${passenger.firstName} ${passenger.lastName}`,
    value: passengerAddress,
  };
};

const createRouteAddressFromCargoAddress = (
  cargoAddress: OrderRouteEditCargoAddress
): OrderRouteEditRouteAddress => {
  return {
    uuid: cargoAddress.uuid,
    displayName: cargoAddress.displayName,
    latitude: cargoAddress.latitude,
    longitude: cargoAddress.longitude,
  };
};

const createRouteAddressSelectOptionFromCargoAddress = (
  cargoAddress: OrderRouteEditCargoAddress
): OrderRouteEditRouteAddressSelectOption => {
  const subText =
    orderRouteEditRoutesHelper.getRouteAddressSelectOptionSubTextForCargoAddress(
      cargoAddress.type
    );
  const value = createRouteAddressFromCargoAddress(cargoAddress);

  return {
    label: cargoAddress.displayName,
    subText: subText,
    value,
  };
};

const orderRouteEditRoutesFactory = {
  createWaypointFromGroupWaypoint,
  createGroupWaypointFromWaypoint,
  createRouteAddressSelectOptionFromPassengerAddress,
  createRouteAddressSelectOptionFromCargoAddress,
};

export default orderRouteEditRoutesFactory;
