import ListingFilter from "../../../types/listing-filter";

const checkIsString = (value: any): value is string => {
  return typeof value === "string";
};

const filterByArrayOfStringsProperty = <T>(
  listingItems: T[],
  filteredProperty: keyof T,
  queries: string[]
): T[] => {
  let filteredListingItems: T[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) =>
      (item[filteredProperty] as string[]).some((itemProperty) =>
        itemProperty.trim().toLowerCase().includes(query.trim().toLowerCase())
      )
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByStringProperty = <T>(
  listingItems: T[],
  filteredProperty: keyof T,
  queries: string[]
): T[] => {
  const isEachPropertyString = listingItems.every((item) =>
    checkIsString(item[filteredProperty])
  );

  if (!isEachPropertyString) {
    console.error(
      `Not every list item has string value for given property ${
        filteredProperty as string
      }`
    );

    return listingItems;
  }

  let filteredListingItems: T[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      return (item[filteredProperty] as string)
        .trim()
        .toLowerCase()
        .includes(query.trim().toLowerCase());
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByEnumProperty = <T, K>(
  listingItems: T[],
  filteredProperty: keyof T,
  options: K[]
) => {
  let filteredListingItems: T[] = [];

  options.forEach((query) => {
    const newFilteredItems = listingItems.filter(
      (item) => item[filteredProperty] === query
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByBooleanProperty = <T>(
  listingItems: T[],
  filteredProperty: keyof T,
  queries: boolean[]
): T[] => {
  let filteredListingItems: T[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter((item) => {
      return item[filteredProperty] === query;
    });

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const filterByNumberProperty = <T>(
  listingItems: T[],
  filteredProperty: keyof T,
  queries: number[]
) => {
  let filteredListingItems: T[] = [];

  queries.forEach((query) => {
    const newFilteredItems = listingItems.filter(
      (item) => item[filteredProperty] === query
    );

    filteredListingItems = [...filteredListingItems, ...newFilteredItems];
  });

  return filteredListingItems;
};

const getValuesOfGivenFilterType = <Type = any, Value = any>(
  filters: ListingFilter<Type, any>[],
  type: Type
): Value[] => {
  return filters.filter((item) => item.type === type).map((item) => item.value);
};

const getFilterValues = <F extends ListingFilter>(
  filters: F[],
  type: F["type"]
): F["value"][] => {
  return filters.filter((item) => item.type === type).map((item) => item.value);
};

const listingFilterService = {
  filterByArrayOfStringsProperty,
  filterByStringProperty,
  filterByEnumProperty,
  filterByNumberProperty,
  filterByBooleanProperty,
  getValuesOfGivenFilterType,
  getFilterValues,
};

export default listingFilterService;
