import BillingsOrderTaxiTaxiResponse, {
  BillingsOrderTaxiTaxiResponseItem,
} from "../../../../api/raily/billings/order/taxi-taxi/billings-order-taxi-taxi.response";
import BillingModel from "../../../../types/billing-model";
import BillingStatus from "../../../../types/billing-status";
import BillingsOrderTaxiTaxi, {
  BillingsOrderTaxiTaxiItem,
} from "./billings-order-taxi-taxi";

const createDataItem = (
  data: BillingsOrderTaxiTaxiResponseItem
): BillingsOrderTaxiTaxiItem => {
  return {
    billingUuid: data.id,
    forwardingUuid: data.orderForwardingAction.id,
    contractDetails: {
      companyName: data.orderForwardingAction.contract.consumerTaxi.displayName,

      distanceRate: data.orderForwardingAction.contract.distanceRate,
      model: data.orderForwardingAction.contract.model as BillingModel,
    },
    baseAmount: data.baseAmount,
    haltingCost: data.haltingAmount,
    highwayCharge: data.highwayCharge,
    distance: data.distance,
    routeDistanceCost: data.distanceAmount,
    internalOrderId:
      data.orderForwardingAction.transportingOrder.cargoOrder.humanId,
    total: data.totalAmount,
    isDraft: data.draft,
    date: data.date,
    bonus: data.bonus,
    penalty: data.penalty,
    status: data.status as BillingStatus,
    readOnly: data.readOnly,
  };
};

const createData = (
  responseData: BillingsOrderTaxiTaxiResponseItem[]
): BillingsOrderTaxiTaxiItem[] => {
  return responseData.map(createDataItem);
};

const create = (
  response: BillingsOrderTaxiTaxiResponse
): BillingsOrderTaxiTaxi => {
  return {
    data: createData(response.data),
  };
};

const billingsOrderTaxiTaxiFactory = {
  create,
};

export default billingsOrderTaxiTaxiFactory;
