import useAsyncData from "../../../hooks/use-async-data";
import CargoOrderDetails from "./cargo-order-details";
import CargoOrderDetailsLoadParams from "./cargo-order-details-load-params";
import cargoOrderService from "../cargo-order.service";

const useCargoOrderDetails = () => {
  const { isLoading, setIsLoading, isError, setIsError, data, setData } =
    useAsyncData<CargoOrderDetails | null>(null);

  const load = async (
    params: CargoOrderDetailsLoadParams,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const cargoOrderDetails = await cargoOrderService.getDetails(
        params,
        signal
      );

      setData(cargoOrderDetails);
      setIsLoading(false);
    } catch (_error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data,
    load,
  };
};

export default useCargoOrderDetails;
