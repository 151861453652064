import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteActiveListingByRailySortKey from "../types/route-active-listing-by-raily-sort-key";
import RouteActiveListingByRailySortSelectOption from "../types/route-active-listing-by-raily-sort-select-option";

const getSelectOptions = (): RouteActiveListingByRailySortSelectOption[] => {
  const translations =
    routeTranslationsHelper.getActiveListingTranslations().sort;

  return [
    {
      label: translations.driverAscOptionLabel,
      value: RouteActiveListingByRailySortKey.DRIVER_NAME_ASC,
    },
    {
      label: translations.driverDescOptionLabel,
      value: RouteActiveListingByRailySortKey.DRIVER_NAME_DESC,
    },
    {
      label: translations.endDateAscOptionLabel,
      value: RouteActiveListingByRailySortKey.END_DATE_ASC,
    },
    {
      label: translations.endDateDescOptionLabel,
      value: RouteActiveListingByRailySortKey.END_DATE_DESC,
    },
    {
      label: translations.routeIdAscOptionLabel,
      value: RouteActiveListingByRailySortKey.ROUTE_ID_ASC,
    },
    {
      label: translations.routeIdDescOptionLabel,
      value: RouteActiveListingByRailySortKey.ROUTE_ID_DESC,
    },
    {
      label: translations.startDateAscOptionLabel,
      value: RouteActiveListingByRailySortKey.START_DATE_ASC,
    },
    {
      label: translations.startDateDescOptionLabel,
      value: RouteActiveListingByRailySortKey.START_DATE_DESC,
    },
    {
      label: translations.taxiCorporationAscOptionLabel,
      value: RouteActiveListingByRailySortKey.TAXI_CORPORATION_ASC,
    },
    {
      label: translations.taxiCorporationDescOptionLabel,
      value: RouteActiveListingByRailySortKey.TAXI_CORPORATION_DESC,
    },
  ];
};

const routeActiveListingByRailySortHelper = {
  getSelectOptions,
};

export default routeActiveListingByRailySortHelper;
