import railyApiService from "../raily-api.service";
import {
  MessengerBillingPostRequest,
  MessengerCargoPostRequest,
  MessengerDriverPostRequest,
  MessengerSolvedDriverPostRequest,
  MessengerRailyPostRequest,
  MessengerSharedPostRequest,
  MessengerTaxiPostRequest,
} from "./post/messenger-post.request";
import MessengerPostResponse from "./post/messenger-post.response";
import messengerSendPostRequestFactory from "./send-post/messenger-send-post-request.factory";
import {
  MessengerBillingSendPostRequest,
  MessengerCargoSendPostRequest,
  MessengerDriverSendPostRequest,
  MessengerRailySendPostRequest,
  MessengerSendPostRequestBody,
  MessengerSharedSendPostRequest,
  MessengerSolvedDriverSendPostRequest,
  MessengerTaxiSendPostRequest,
} from "./send-post/messenger-send-post.request";
import MessengerSendPostResponse from "./send-post/messenger-send-post.response";

const messengerApiService = () => {
  const getCargoBillingPosts = async (
    request: MessengerBillingPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/billings/${request.billingUuid}/posts`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getTaxiBillingPosts = async (
    request: MessengerBillingPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/billings/${request.billingUuid}/taxi-taxi/posts`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getCargoPosts = async (
    request: MessengerCargoPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/cargo`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getDriverPosts = async (
    request: MessengerDriverPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    let url = `/plan-entries/${request.planEntryUuid}/posts`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getSolvedDriverPosts = (
    request: MessengerSolvedDriverPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/orders/solved/${request.solvedOrderUuid}/posts/plan-entry`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getRailyPosts = async (
    request: MessengerRailyPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/raily`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getSharedPosts = async (
    request: MessengerSharedPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/shared`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const getTaxiPosts = async (
    request: MessengerTaxiPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/taxi/${request.taxiCorporationUuid}`;

    return railyApiService().get<MessengerPostResponse>({
      url,
      abortSignal,
    });
  };

  const sendCargoBillingPost = (
    request: MessengerBillingSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/billings/${request.billingUuid}/order/posts`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendTaxiBillingPost = (
    request: MessengerBillingSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/billings/${request.billingUuid}/taxi-taxi/posts`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendCargoPost = async (
    request: MessengerCargoSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/cargo`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendDriverPost = async (
    request: MessengerDriverSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/plan-entries/${request.planEntryUuid}/posts`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendSolvedDriverPost = async (
    request: MessengerSolvedDriverSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/orders/solved/${request.solvedOrderUuid}/posts/plan-entry`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendRailyPost = async (
    request: MessengerRailySendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/raily`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendSharedPost = async (
    request: MessengerSharedSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/shared`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  const sendTaxiPost = async (
    request: MessengerTaxiSendPostRequest,
    abortSignal: AbortSignal
  ): Promise<MessengerSendPostResponse> => {
    const url = `/orders/cargo-orders/${request.orderUuid}/posts/taxi/${request.taxiCorporationUuid}`;

    const body: MessengerSendPostRequestBody =
      messengerSendPostRequestFactory.createBody(request.content);

    return railyApiService().post<MessengerSendPostResponse>({
      url,
      abortSignal,
      body,
    });
  };

  return {
    getCargoBillingPosts,
    getTaxiBillingPosts,
    getCargoPosts,
    getDriverPosts,
    getSolvedDriverPosts,
    getRailyPosts,
    getSharedPosts,
    getTaxiPosts,
    sendCargoBillingPost,
    sendTaxiBillingPost,
    sendCargoPost,
    sendDriverPost,
    sendSolvedDriverPost,
    sendRailyPost,
    sendSharedPost,
    sendTaxiPost,
  };
};

export default messengerApiService;
