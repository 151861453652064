enum MessengerChannel {
  BILLING = "BILLING",
  CARGO = "CARGO",
  RAILY = "RAILY",
  SHARED = "SHARED",
  TAXI = "TAXI",
  DRIVER = "DRIVER",
}

export default MessengerChannel;
