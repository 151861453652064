enum TaxiOrderSettlementListingPenaltyReason {
  OPTIMIZATION_REFUSED = "OPTIMIZATION_REFUSED",
  BEING_LATE = "BEING_LATE",
  INCOMPATIBLE_CAR = "INCOMPATIBLE_CAR",
  HIGHWAY_NOT_ALLOWED = "HIGHWAY_NOT_ALLOWED",
  GUIDELINE_IGNORED = "GUIDELINE_IGNORED",
  SUBOPTIMAL_RIDE = "SUBOPTIMAL_RIDE",
  OTHER = "OTHER",
}

export default TaxiOrderSettlementListingPenaltyReason;
