import { RouteFinishedListingResponseItem } from "../../common/api/route-finished-listing.response";
import RouteFinishedListingByTaxiListingItem from "./types/route-finished-listing-by-taxi-item";

const createListingItem = (
  responseItem: RouteFinishedListingResponseItem
): RouteFinishedListingByTaxiListingItem => {
  return {
    uuid: responseItem.id,
    driverName: responseItem.driver_name,
    endDate: new Date(responseItem.end_time),
    routeAddresses: [
      responseItem.starting_node_label,
      ...responseItem.plan_entry_nodes,
      responseItem.ending_node_label,
    ],
    startDate: new Date(responseItem.start_time),
    passengers: responseItem.involved_cargo_labels,
    routeId: responseItem.human_id,
  };
};

const createListingItems = (
  responseData: RouteFinishedListingResponseItem[]
): RouteFinishedListingByTaxiListingItem[] => {
  return responseData.map(createListingItem);
};

const routeFinishedListingByTaxiFactory = {
  createListingItems,
};

export default routeFinishedListingByTaxiFactory;
