import { TaxiTransferedOrderSettlementListingResponseDataItem } from "../api/taxi-transfered-order-settlement-listing.response";
import TaxiTransferedOrderSettlementListingItem from "../types/taxi-transfered-order-settlement-listing-item";
import TaxiTransferedOrderSettlementListingItemBillingStatus from "../types/taxi-transfered-order-settlement-listing-item-billing-status";

const createListingItemStatus = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItemBillingStatus | undefined => {
  switch (responseDataItem.status) {
    case "CREATED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.CREATED;
    case "ACCEPTED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.ACCEPTED;
    case "REJECTED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REJECTED;
    case "REOPENED":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REOPENED;
    case "REOPEN_REQUEST":
      return TaxiTransferedOrderSettlementListingItemBillingStatus.REOPEN_REQUEST;
    default:
      return undefined;
  }
};

const createListingItemBonus = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItem["bonus"] => {
  if (responseDataItem.bonus && responseDataItem.bonus_type) {
    return {
      cost: responseDataItem.bonus,
      reason: responseDataItem.bonus_type,
    };
  }

  return null;
};

const createListingItemPenalty = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItem["penalty"] => {
  if (responseDataItem.penalty && responseDataItem.penalty_type) {
    return {
      cost: responseDataItem.penalty,
      reason: responseDataItem.penalty_type,
    };
  }

  return null;
};

const createListingItem = (
  responseDataItem: TaxiTransferedOrderSettlementListingResponseDataItem
): TaxiTransferedOrderSettlementListingItem => {
  return {
    status: createListingItemStatus(responseDataItem),
    uuid: responseDataItem.id,
    internalOrderId: responseDataItem.cargo_order.human_id,
    orderStartDate: new Date(responseDataItem.start_time),
    orderTransferDate: new Date(responseDataItem.forwarding_time),
    producerTaxi: responseDataItem.producer.name,
    consumerTaxi: responseDataItem.consumer.name,
    billingModel: responseDataItem.billing_model,
    distance: responseDataItem.distance,
    distanceCost: responseDataItem.distance_amount,
    distanceRate: responseDataItem.distance_rate,
    stopoverCost: responseDataItem.halting_amount ?? 0,
    tollRoadsCost: responseDataItem.highway_charge ?? 0,
    totalCost: responseDataItem.total_amount,
    bonus: createListingItemBonus(responseDataItem),
    penalty: createListingItemPenalty(responseDataItem),
    canBeModified: responseDataItem.can_be_modified,
    contractName: responseDataItem.contract_name,
  };
};

const createListingItems = (
  responseDataItems: TaxiTransferedOrderSettlementListingResponseDataItem[]
): TaxiTransferedOrderSettlementListingItem[] => {
  return responseDataItems.map(createListingItem);
};

const taxiTransferedOrderSettlementListingFactory = {
  createListingItems,
};

export default taxiTransferedOrderSettlementListingFactory;
