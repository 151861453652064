export enum MessengerPostChannel {
  BILLING = "BILLING",
  CARGO = "CARGO",
  RAILY = "RAILY",
  SHARED = "SHARED",
  TAXI = "TAXI",
  DRIVER = "DRIVER",
}

export enum MessengerPostType {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REOPEN_REQUEST = "REOPEN_REQUEST",
  REOPENED = "REOPENED",
  SETTLED = "SETTLED",
  UPDATED = "UPDATED ",
  USER_ENTRY = "USER_ENTRY",
}

export type MessengerPost = {
  authorUuid: string;
  authorFullName: string;
  authorCompany: string;
  content: string;
  date: Date;
  postType: MessengerPostType;
};

export type MessengerPostChannelData = {
  isError: boolean;
  posts: MessengerPost[];
};

type MessengerPostsMap = Map<MessengerPostChannel, MessengerPostChannelData>;

export default MessengerPostsMap;
