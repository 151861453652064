import UserRole from "../../../../../../common/types/user-role";
import UserPermissions from "../../../../../../common/utils/user/permissions/user-permissions";
import UserPermissionsDefinition from "../../../../../../common/utils/user/permissions/user-permissions-definition";

export type PassengerListingUserPermissionOption =
  | "hasAccessToAddPassenger"
  | "hasAccessToEditUser"
  | "hasAccessToDeleteUser";

export type PassengerListingUserPermissionsDefinition =
  UserPermissionsDefinition<PassengerListingUserPermissionOption>;

export type PassengerListingUserPermissions =
  UserPermissions<PassengerListingUserPermissionOption>;

const passengerListingUserPermissionDefinition: PassengerListingUserPermissionsDefinition =
  {
    hasAccessToAddPassenger: [
      UserRole.ADMIN,
      UserRole.CARGO_OFFICER,
      UserRole.RAILY_OFFICER,
      UserRole.DISPATCHER,
    ],
    hasAccessToEditUser: [
      UserRole.ADMIN,
      UserRole.RAILY_OFFICER,
      UserRole.CARGO_OFFICER,
    ],
    hasAccessToDeleteUser: [UserRole.ADMIN, UserRole.RAILY_OFFICER],
  };

export default passengerListingUserPermissionDefinition;
