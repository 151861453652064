import BillingSaveRequest from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingFormData from "../types/billing-form.data";

const createSaveBillingRequest = (
  data: BillingFormData
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: data.billingNodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
        planned_date: specificNode.plannedDate?.toJSON() ?? null,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    bonus: data.bonus,
    bonus_type: data.bonusType!,
    penalty: data.penalty,
    penalty_type: data.penaltyType!,
  };
  return result;
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
