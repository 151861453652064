import ListingFilterBadge from "../../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../../common/utils/date/date.service";
import settlementTranslationsHelper from "../../../../../../../languages/settlement-translations.helper";
import taxiUnsettledPrivateOrderListingHelper from "../../taxi-unsettled-private-order-listing.helper";
import TaxiUnsettledPrivateOrderListingFilter, {
  TaxiUnsettledPrivateOrderListingClientFilter,
  TaxiUnsettledPrivateOrderListingDispatchFilter,
  TaxiUnsettledPrivateOrderListingDriverFilter,
  TaxiUnsettledPrivateOrderListingExternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingInternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingPassengerFilter,
  TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter,
  TaxiUnsettledPrivateOrderListingStartDateFilter,
  TaxiUnsettledPrivateOrderListingStatusFilter,
} from "../../types/taxi-unsettled-private-order-listing-filter";
import TaxiUnsettledPrivateOrderListingFilterType from "../../types/taxi-unsettled-private-order-listing-filter-type";
import TaxiUnsettledPrivateOrderListingItemStatus from "../../types/taxi-unsettled-private-order-listing-item-status";

const getStartDateBadgeOption = (
  filter: TaxiUnsettledPrivateOrderListingStartDateFilter
): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledPrivateOrderListingStartDateFilter;
} => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .filters;

  const formattedFromDate = dateService.formatDate(filter.value.from);

  const formattedToDate = dateService.formatDate(filter.value.to);

  return {
    badge: {
      text: translations.startDateFilterText
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
      title: translations.startDateFilterTitle
        .replace("#{fromDate}", formattedFromDate)
        .replace("#{toDate}", formattedToDate),
    },
    filter: {
      type: TaxiUnsettledPrivateOrderListingFilterType.START_DATE,
      value: filter.value,
    },
  };
};

const getStatusBadgeOptions = (): {
  badge: ListingFilterBadge;
  filter: TaxiUnsettledPrivateOrderListingStatusFilter;
}[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledPrivateOrderListingTranslations()
      .filters;

  return Object.values(TaxiUnsettledPrivateOrderListingItemStatus).map(
    (statusFilterValue) => {
      return {
        badge: {
          text: translations.statusFilterText.replace(
            "#{option}",
            taxiUnsettledPrivateOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
          title: translations.statusFilterTitle.replace(
            "#{option}",
            taxiUnsettledPrivateOrderListingHelper.getStatusText(
              statusFilterValue
            )
          ),
        },
        filter: {
          type: TaxiUnsettledPrivateOrderListingFilterType.STATUS,
          value: statusFilterValue,
        },
      };
    }
  );
};

const getBadgeOptions = (filter: TaxiUnsettledPrivateOrderListingFilter) => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledOrderListingTranslations()
      .filters;

  const options: {
    badge: ListingFilterBadge;
    filter: TaxiUnsettledPrivateOrderListingFilter;
  }[] = [
    {
      badge: {
        text: translations.clientNameFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingClientFilter["value"]
        ),
        title: translations.clientNameFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingClientFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.CLIENT,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingClientFilter["value"],
      },
    },
    {
      badge: {
        text: translations.dispatchNameFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingDispatchFilter["value"]
        ),
        title: translations.dispatchNameFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingDispatchFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.DISPATCH,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingDispatchFilter["value"],
      },
    },
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingDriverFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.DRIVER,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.internalOrderIdFilterText.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledPrivateOrderListingInternalOrderIdFilter["value"]
          )
        ),
        title: translations.internalOrderIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as TaxiUnsettledPrivateOrderListingInternalOrderIdFilter["value"]
          )
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.INTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingInternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.externalOrderIdFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingExternalOrderIdFilter["value"]
        ),
        title: translations.externalOrderIdFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingExternalOrderIdFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingExternalOrderIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.PASSENGER,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_ADDRESS,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingPassengerFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: TaxiUnsettledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter["value"],
      },
    },
    getStartDateBadgeOption(
      filter as TaxiUnsettledPrivateOrderListingStartDateFilter
    ),
    ...getStatusBadgeOptions(),
  ];

  return options;
};

const getBadge = (filter: TaxiUnsettledPrivateOrderListingFilter) => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: TaxiUnsettledPrivateOrderListingFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const taxiUnsettledPrivateOrderListingFiltersBadgeListHelper = {
  getBadges,
};

export default taxiUnsettledPrivateOrderListingFiltersBadgeListHelper;
