import { FC } from "react";
import ListingFilterBadgeListComponent from "../../../../../../common/components/listing/filter/badge-list/listing-filter-badge-list.component";
import RouteActiveListingByRailyFilter from "../../common/types/route-active-listing-by-raily-filter";
import routeActiveListingByRailyFiltersBadgeListHelper from "./route-active-listing-by-raily-filters-badge-list.helper";

type RouteActiveListingByRailyFiltersBadgeListProps = {
  filters: RouteActiveListingByRailyFilter[];
  onDeleteFilterClick: (filterIndex: number) => void;
  onDeleteAllFiltersButtonClick: () => void;
};

const RouteActiveListingByRailyFiltersBadgeListComponent: FC<
  RouteActiveListingByRailyFiltersBadgeListProps
> = (props) => {
  const badges = routeActiveListingByRailyFiltersBadgeListHelper.getBadges(
    props.filters
  );

  return (
    <ListingFilterBadgeListComponent
      badges={badges}
      onDeleteAll={props.onDeleteAllFiltersButtonClick}
      onDelete={props.onDeleteFilterClick}
    />
  );
};

export default RouteActiveListingByRailyFiltersBadgeListComponent;
