import paginationService from "../../../../../common/utils/pagination/pagination.service";
import TaxiUnsettledPrivateOrderListingRequest, {
  TaxiUnsettledPrivateOrderListingRequestOrder,
} from "./api/taxi-unsettled-private-order-listing.request";
import TaxiUnsettledPrivateOrderListingFilter, {
  TaxiUnsettledPrivateOrderListingClientFilter,
  TaxiUnsettledPrivateOrderListingDispatchFilter,
  TaxiUnsettledPrivateOrderListingDriverFilter,
  TaxiUnsettledPrivateOrderListingExternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingInternalOrderIdFilter,
  TaxiUnsettledPrivateOrderListingPassengerFilter,
  TaxiUnsettledPrivateOrderListingRouteAddressFilter,
  TaxiUnsettledPrivateOrderListingRouteDestinationAddressFilter,
  TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter,
  TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter,
  TaxiUnsettledPrivateOrderListingStartDateFilter,
  TaxiUnsettledPrivateOrderListingStatusFilter,
} from "./types/taxi-unsettled-private-order-listing-filter";
import TaxiUnsettledPrivateOrderListingFilterType from "./types/taxi-unsettled-private-order-listing-filter-type";
import TaxiUnsettledPrivateOrderListingItemStatus from "./types/taxi-unsettled-private-order-listing-item-status";
import TaxiUnsettledPrivateOrderListingSortKey from "./types/taxi-unsettled-private-order-listing-sort-key";

const createRequestIsCancelled = (
  filters: TaxiUnsettledPrivateOrderListingFilter[]
): TaxiUnsettledPrivateOrderListingRequest["is_cancelled"] => {
  const filterValue = filters.find(
    (filter) =>
      filter.type === TaxiUnsettledPrivateOrderListingFilterType.STATUS
  )?.value as TaxiUnsettledPrivateOrderListingStatusFilter["value"] | undefined;

  if (filterValue === undefined) {
    return undefined;
  }

  if (filterValue === TaxiUnsettledPrivateOrderListingItemStatus.CANCELLED) {
    return true;
  }

  return false;
};

const createRequestOrder = (
  sortKey: TaxiUnsettledPrivateOrderListingSortKey | null
): TaxiUnsettledPrivateOrderListingRequest["order"] => {
  const options: {
    sortKey: TaxiUnsettledPrivateOrderListingSortKey;
    requestOrder: TaxiUnsettledPrivateOrderListingRequestOrder;
  }[] = [
    {
      requestOrder: TaxiUnsettledPrivateOrderListingRequestOrder.HUMAN_ID_ASC,
      sortKey: TaxiUnsettledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_ASC,
    },
    {
      requestOrder: TaxiUnsettledPrivateOrderListingRequestOrder.HUMAN_ID_DESC,
      sortKey: TaxiUnsettledPrivateOrderListingSortKey.INTERNAL_ORDER_ID_DESC,
    },
    {
      requestOrder: TaxiUnsettledPrivateOrderListingRequestOrder.START_TIME_ASC,
      sortKey: TaxiUnsettledPrivateOrderListingSortKey.START_DATE_ASC,
    },
    {
      requestOrder:
        TaxiUnsettledPrivateOrderListingRequestOrder.START_TIME_DESC,
      sortKey: TaxiUnsettledPrivateOrderListingSortKey.START_DATE_DESC,
    },
  ];

  return options.find((option) => option.sortKey === sortKey)?.requestOrder;
};

const createRequest = (
  page: number,
  pageSize: number,
  filters: TaxiUnsettledPrivateOrderListingFilter[],
  sortKey: TaxiUnsettledPrivateOrderListingSortKey | null
): TaxiUnsettledPrivateOrderListingRequest => {
  const offset = paginationService.calculateOffset(page, pageSize);

  return {
    order: createRequestOrder(sortKey),
    address: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.ROUTE_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteAddressFilter["value"]
      | undefined,
    limit: pageSize,
    client: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.CLIENT
    )?.value as
      | TaxiUnsettledPrivateOrderListingClientFilter["value"]
      | undefined,
    dispatch: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.DISPATCH
    )?.value as
      | TaxiUnsettledPrivateOrderListingDispatchFilter["value"]
      | undefined,
    driver: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.DRIVER
    )?.value as
      | TaxiUnsettledPrivateOrderListingDriverFilter["value"]
      | undefined,
    end_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_DESTINATION_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteDestinationAddressFilter["value"]
      | undefined,
    external_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.EXTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledPrivateOrderListingExternalOrderIdFilter["value"]
      | undefined,
    human_id: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.INTERNAL_ORDER_ID
    )?.value as
      | TaxiUnsettledPrivateOrderListingInternalOrderIdFilter["value"]
      | undefined,
    is_cancelled: createRequestIsCancelled(filters),
    mid_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_INTERMEDIATE_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRouteIntermediateAddressFilter["value"]
      | undefined,
    offset,
    passenger: filters.find(
      (filter) =>
        filter.type === TaxiUnsettledPrivateOrderListingFilterType.PASSENGER
    )?.value as
      | TaxiUnsettledPrivateOrderListingPassengerFilter["value"]
      | undefined,
    start_address: filters.find(
      (filter) =>
        filter.type ===
        TaxiUnsettledPrivateOrderListingFilterType.ROUTE_PICKUP_ADDRESS
    )?.value as
      | TaxiUnsettledPrivateOrderListingRoutePickupAddressFilter["value"]
      | undefined,
    start_time_since: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.from?.toJSON(),
    start_time_to: (
      filters.find(
        (filter) =>
          filter.type === TaxiUnsettledPrivateOrderListingFilterType.START_DATE
      )?.value as
        | TaxiUnsettledPrivateOrderListingStartDateFilter["value"]
        | undefined
    )?.to?.toJSON(),
  };
};

const taxiUnsettledPrivateOrderListingRequestFactory = {
  createRequest,
};

export default taxiUnsettledPrivateOrderListingRequestFactory;
