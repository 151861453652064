import { FC } from "react";
import PassengerListingFilter from "../common/types/passenger-listing-filter";
import PassengerListingSortKey from "../common/types/passenger-listing-sort-key";
import ListingFilterDefinition from "../../../../../common/components/listing/filter/types/listing-filter-definition";
import passengerListingFilterHelper from "./passenger-listing-filter.helper";
import ListingSortDefinition from "../../../../../common/components/listing/filter/types/listing-sort-definition";
import ListingFilterComponent from "../../../../../common/components/listing/filter/listing-filter.component";

type PassengerListingFilterProps = {
  filters: PassengerListingFilter[];
  onFiltersChange: (filters: PassengerListingFilter[]) => void;
  sortKey: PassengerListingSortKey;
  onSortKeyChange: (sortKey: PassengerListingSortKey) => void;
};

const PassengerListingFilterComponent: FC<PassengerListingFilterProps> = (
  props
) => {
  const filterDefinition: ListingFilterDefinition<PassengerListingFilter> =
    passengerListingFilterHelper().getFilterDefinition();

  const sortDefinition: ListingSortDefinition =
    passengerListingFilterHelper().getSortDefinition();

  return (
    <ListingFilterComponent
      appliedFilters={props.filters}
      filterDefinition={filterDefinition}
      onFiltersChange={(appliedFilters) => {
        props.onFiltersChange(appliedFilters as PassengerListingFilter[]);
      }}
      appliedSortKey={props.sortKey}
      onSortKeyChange={(value) =>
        props.onSortKeyChange(value as PassengerListingSortKey)
      }
      sortDefinition={sortDefinition}
      idForTestingFilter="passenger-listing-filter"
      idForTestingSort="passenger-listing-sort"
    />
  );
};

export default PassengerListingFilterComponent;
