import {
  OrderDetailsHistoryResponseItem,
  OrderDetailsHistoryResponseItemCancelOrderCommand,
  OrderDetailsHistoryResponseItemRequestCancelOrderCommand,
  OrderDetailsHistoryResponseItemChangeRideCommand,
  OrderDetailsHistoryResponseItemDealerApprovalCommand,
  OrderDetailsHistoryResponseItemDispatchApprovalCommand,
  OrderDetailsHistoryResponseItemInitializeCommand,
  OrderDetailsHistoryResponseItemInitializeCommandRide,
  OrderDetailsHistoryResponseItemMergeOrderCommand,
  OrderDetailsHistoryResponseItemOperatorApprovalCommand,
  OrderDetailsHistoryResponseItemChangeTargetTaxiCommand,
  OrderDetailsHistoryResponseItemBindPassengerCommand,
  OrderDetailsHistoryResponseItemExternalIdUpdateCommand,
  OrderDetailsHistoryResponseItemShuffleMergeCommand,
  OrderDetailsHistoryResponseData,
  OrderDetailsHistoryResponseBillingModel,
} from "./api/order-details-history.response";
import orderDetailsHistoryChangeRideEntryFactory from "./order-details-history-change-ride-entry.factory";
import orderDetailsHistoryOrderRouteHelper from "./order-details-history-order-route.helper";
import OrderDetailsHistoryEntry from "./types/order-details-history-entry";
import OrderDetailsHistoryEntryEvent, {
  OrderDetailsHistoryEntryCancelOrderEvent,
  OrderDetailsHistoryEntryChangeRideEvent,
  OrderDetailsHistoryEntryDealerApprovalEvent,
  OrderDetailsHistoryEntryDispatchApprovalEvent,
  OrderDetailsHistoryEntryInitializeEvent,
  OrderDetailsHistoryEntryMergeOrdersEvent,
  OrderDetailsHistoryEntryOperationalApprovalEvent,
  OrderDetailsHistoryEntryRequestCancelOrderEvent,
  OrderDetailsHistoryEntryInitializeEventRouteWaypoint,
  OrderDetailsHistoryEntryChangeTargetTaxiEvent,
  OrderDetailsHistoryEntryBindPassengerEvent,
  OrderDetailsHistoryEntryExternalIdUpdateEvent,
  OrderDetailsHistoryEntryShuffleMergeEvent,
} from "./types/order-details-history-entry-event";
import {
  OrderDetailsTHandlingLogEntryForwardEvent,
  OrderDetailsTHandlingLogEntryForwardCancelEvent,
  OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent,
  OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent,
  OrderDetailsTHandlingLogAssignmentOrderCancelEvent,
} from "./types/order-details-handling-log-entry-event";
import OrderDetailsHistoryEventType from "./types/order-details-history-event-type";
import OrderDetailsHandlingLogEntryEvent from "./types/order-details-handling-log-entry-event";
import { CargoOrderDetailsHandlingLogType } from "../../../../../common/api/raily/cargo-order/details/handling-log/cargo-order-details-handling-log.response";
import {
  CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem as CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem,
  CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem as CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem,
  CargoOrderDetailsHandlingLogCancelItem,
  CargoOrderDetailsHandlingLogAssignmentOrderCancelContentItem as CargoOrderDetailsHandlingLogAssignmentOrderCancelItem,
  CargoOrderDetailsHandlingLogData,
  CargoOrderDetailsHandlingLogItem,
} from "../../../../../common/services/cargo-order/handling-log/cargo-order-details-handling-log";
import BillingModel from "../../../../../common/types/billing-model";

const createBillingModel = (
  responseModel: OrderDetailsHistoryResponseBillingModel
) => {
  switch (responseModel) {
    case OrderDetailsHistoryResponseBillingModel.AB:
      return BillingModel.AB;
    case OrderDetailsHistoryResponseBillingModel.ABA:
      return BillingModel.ABA;
    case OrderDetailsHistoryResponseBillingModel.OTHER:
      return BillingModel.OTHER;
    case OrderDetailsHistoryResponseBillingModel.SABS:
      return BillingModel.SABS;
  }
};

const createBindPassengerEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemBindPassengerCommand
): OrderDetailsHistoryEntryBindPassengerEvent => {
  return {
    type: OrderDetailsHistoryEventType.BIND_PASSENGER,
  };
};

const createCancelOrderEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemCancelOrderCommand
): OrderDetailsHistoryEntryCancelOrderEvent => {
  return {
    type: OrderDetailsHistoryEventType.CANCEL_ORDER,
  };
};

const createChangeRideEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemChangeRideCommand
): OrderDetailsHistoryEntryChangeRideEvent => {
  const events = orderDetailsHistoryChangeRideEntryFactory.createEvents(
    responsePerformedCommand.update_ride_command_sequence
  );

  return {
    type: OrderDetailsHistoryEventType.CHANGE_RIDE,
    events,
  };
};

const createChangeTargetTaxiEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemChangeTargetTaxiCommand
): OrderDetailsHistoryEntryChangeTargetTaxiEvent => {
  return {
    type: OrderDetailsHistoryEventType.CHANGE_TARGET_TAXI,
  };
};

const createDealerApprovalEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemDealerApprovalCommand
): OrderDetailsHistoryEntryDealerApprovalEvent => {
  return {
    type: OrderDetailsHistoryEventType.DEALER_APPROVAL,
    approvingDealerUuid: responsePerformedCommand.approving_dealer_id,
  };
};

const createDispatchApprovalEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemDispatchApprovalCommand
): OrderDetailsHistoryEntryDispatchApprovalEvent => {
  return {
    type: OrderDetailsHistoryEventType.DISPATCH_APPROVAL,
    approvingDispatcherUuid: responsePerformedCommand.approving_dispatcher_id,
  };
};

const createExternalIdUpdateEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemExternalIdUpdateCommand
): OrderDetailsHistoryEntryExternalIdUpdateEvent => {
  return {
    type: OrderDetailsHistoryEventType.EXTERNAL_ID_UPDATE,
    newCargoExternalOrderId: responsePerformedCommand.new_external_id,
  };
};

const createInitializeEventRoute = (
  ride: OrderDetailsHistoryResponseItemInitializeCommandRide,
  currentStageNo?: number
): OrderDetailsHistoryEntryInitializeEventRouteWaypoint[] => {
  const route: OrderDetailsHistoryEntryInitializeEventRouteWaypoint[] = [];

  let stageNo = currentStageNo ?? 1;

  ride.seq.forEach((item) => {
    const isRideWaypoint =
      orderDetailsHistoryOrderRouteHelper.checkIsResponseRideSeqItemAWaypoint(
        item
      );
    if (isRideWaypoint) {
      route.push({
        addressDisplayName: item.display_name,
        haltingTime: item.halting_time,
        plannedDate: item.time ? new Date(item.time) : null,
        stageNo,
      });

      stageNo++;
      return;
    }

    const groupRouteWaypoints = createInitializeEventRoute(item, stageNo);

    route.push(...groupRouteWaypoints);

    stageNo++;
  });

  return route;
};

const createInitializeEvent = (
  responseItem: OrderDetailsHistoryResponseItem
): OrderDetailsHistoryEntryInitializeEvent => {
  const responsePerformedCommand =
    responseItem.performed_command as OrderDetailsHistoryResponseItemInitializeCommand;

  const route = createInitializeEventRoute(responsePerformedCommand.ride);
  return {
    type: OrderDetailsHistoryEventType.INITIALIZE,
    cargoExternalOrderId: responsePerformedCommand.external_id,
    contractModel:
      responseItem.cargo_taxi_billing_model &&
      createBillingModel(responseItem.cargo_taxi_billing_model),
    taxiCorporationName: responseItem.taxi_corporation_display_name,
    route,
  };
};

const createMergeOrderEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemMergeOrderCommand
): OrderDetailsHistoryEntryMergeOrdersEvent => {
  return {
    type: OrderDetailsHistoryEventType.MERGE_ORDERS,
  };
};

const createOperatorApprovalEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemOperatorApprovalCommand
): OrderDetailsHistoryEntryOperationalApprovalEvent => {
  return {
    type: OrderDetailsHistoryEventType.OPERATIONAL_APPROVAL,
    operatorUuid: responsePerformedCommand.approving_operator_id,
  };
};

const createRequestCancelOrderEvent = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemRequestCancelOrderCommand
): OrderDetailsHistoryEntryRequestCancelOrderEvent => {
  return {
    type: OrderDetailsHistoryEventType.REQUEST_CANCEL_ORDER,
  };
};

const createShuffleMergeCommand = (
  responsePerformedCommand: OrderDetailsHistoryResponseItemShuffleMergeCommand
): OrderDetailsHistoryEntryShuffleMergeEvent => {
  return {
    type: OrderDetailsHistoryEventType.SHUFFLE_MERGE,
    sourceInternalOrderIds: responsePerformedCommand.representation.sources.map(
      (data) => data.human_id
    ),
    targetInternalOrderId:
      responsePerformedCommand.representation.target.human_id,
  };
};

const createHistoryEvent = (
  responseItem: OrderDetailsHistoryResponseItem
): OrderDetailsHistoryEntryEvent => {
  switch (responseItem.performed_command.command_type) {
    case OrderDetailsHistoryEventType.BIND_PASSENGER:
      return createBindPassengerEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemBindPassengerCommand
      );
    case OrderDetailsHistoryEventType.CANCEL_ORDER:
      return createCancelOrderEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemCancelOrderCommand
      );
    case OrderDetailsHistoryEventType.CHANGE_RIDE:
      return createChangeRideEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemChangeRideCommand
      );
    case OrderDetailsHistoryEventType.CHANGE_TARGET_TAXI:
      return createChangeTargetTaxiEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemChangeTargetTaxiCommand
      );
    case OrderDetailsHistoryEventType.DEALER_APPROVAL:
      return createDealerApprovalEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemDealerApprovalCommand
      );
    case OrderDetailsHistoryEventType.DISPATCH_APPROVAL:
      return createDispatchApprovalEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemDispatchApprovalCommand
      );
    case OrderDetailsHistoryEventType.EXTERNAL_ID_UPDATE:
      return createExternalIdUpdateEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemExternalIdUpdateCommand
      );
    case OrderDetailsHistoryEventType.INITIALIZE:
      return createInitializeEvent(responseItem);
    case OrderDetailsHistoryEventType.MERGE_ORDERS:
      return createMergeOrderEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemMergeOrderCommand
      );
    case OrderDetailsHistoryEventType.OPERATIONAL_APPROVAL:
      return createOperatorApprovalEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemOperatorApprovalCommand
      );
    case OrderDetailsHistoryEventType.REQUEST_CANCEL_ORDER:
      return createRequestCancelOrderEvent(
        responseItem.performed_command as OrderDetailsHistoryResponseItemRequestCancelOrderCommand
      );
    case OrderDetailsHistoryEventType.SHUFFLE_MERGE:
      return createShuffleMergeCommand(
        responseItem.performed_command as OrderDetailsHistoryResponseItemShuffleMergeCommand
      );
    default:
      return undefined;
  }
};

const createForwardingOrderEvent = (
  item: CargoOrderDetailsHandlingLogItem
): OrderDetailsTHandlingLogEntryForwardEvent => {
  return {
    type: CargoOrderDetailsHandlingLogType.FORWARD,
    contractDisplayName: item.contractDisplayName,
  };
};

const createForwardingCancelOrderEvent = (
  item: CargoOrderDetailsHandlingLogCancelItem
): OrderDetailsTHandlingLogEntryForwardCancelEvent => {
  return {
    type: CargoOrderDetailsHandlingLogType.FORWARD_CANCEL,
    producerDisplayName: item.producerDisplayName,
    consumerDisplayName: item.consumerDisplayName,
  };
};

const createAssignmentOrderNewPlanEvent = (
  item: CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem
): OrderDetailsTHandlingLogAssignmentOrderNewPlanEvent => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_CPE_CANDIDATURE,
    driverName: item.driverName,
    taxiCorporationName: item.taxiCorporationName,
    createDate: item.createDate,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
  };
};

const createAssignmentOrderExistingPlanEvent = (
  item: CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem
): OrderDetailsTHandlingLogAssignmentOrderExistingPlanEvent => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_CSO_CANDIDATURE,
    driverName: item.driverName,
    taxiCorporationName: item.taxiCorporationName,
    createDate: item.createDate,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    planEntryUuid: item.planEntryUuid,
  };
};

const createAssignmentOrderCancelEvent = (
  item: CargoOrderDetailsHandlingLogAssignmentOrderCancelItem
): OrderDetailsTHandlingLogAssignmentOrderCancelEvent => {
  return {
    type: CargoOrderDetailsHandlingLogType.ACCEPTED_DSO_CANDIDATURE,
    createDate: item.createDate,
    author: {
      firstName: item.author.firstName,
      lastName: item.author.lastName,
    },
    planEntryUuid: item.planEntryUuid,
  };
};

const createHandlingLogEvent = (
  item: CargoOrderDetailsHandlingLogData
): OrderDetailsHandlingLogEntryEvent => {
  switch (item.type) {
    case CargoOrderDetailsHandlingLogType.FORWARD:
      return createForwardingOrderEvent(
        item as CargoOrderDetailsHandlingLogItem
      );
    case CargoOrderDetailsHandlingLogType.FORWARD_CANCEL:
      return createForwardingCancelOrderEvent(
        item as CargoOrderDetailsHandlingLogCancelItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_CPE_CANDIDATURE:
      return createAssignmentOrderNewPlanEvent(
        item as CargoOrderDetailsHandlingLogAssignmentOrderNewPlanItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_CSO_CANDIDATURE:
      return createAssignmentOrderExistingPlanEvent(
        item as CargoOrderDetailsHandlingLogAssignmentOrderExistingPlanItem
      );
    case CargoOrderDetailsHandlingLogType.ACCEPTED_DSO_CANDIDATURE:
      return createAssignmentOrderCancelEvent(
        item as CargoOrderDetailsHandlingLogAssignmentOrderCancelItem
      );
    default:
      return undefined;
  }
};

const createHistoryEntry = (
  responseItem: OrderDetailsHistoryResponseItem
): OrderDetailsHistoryEntry => {
  const event = createHistoryEvent(responseItem);
  return {
    authorDisplayName: `${responseItem.author.first_name} ${responseItem.author.last_name}`,
    date: new Date(responseItem.created_at),
    event,
  };
};

const createHandlingLogDataEntries = (
  item: CargoOrderDetailsHandlingLogData
): OrderDetailsHistoryEntry => {
  const event = createHandlingLogEvent(item);
  return {
    authorDisplayName: `${item.author.firstName} ${item.author.lastName}`,
    date: item.createDate,
    event,
  };
};

const createHistoryEntries = (
  historyData: OrderDetailsHistoryResponseData,
  handlingLogData: CargoOrderDetailsHandlingLogData[]
): OrderDetailsHistoryEntry[] => {
  const historyEntries = historyData.map(createHistoryEntry);
  const handlingLogEntries = handlingLogData
    ? handlingLogData.map(createHandlingLogDataEntries)
    : [];

  return [...historyEntries, ...handlingLogEntries].sort(
    (entryA, entryB) => entryB.date.getTime() - entryA.date.getTime()
  );
};

const orderDetailsHistoryEntryFactory = {
  createHistoryEntries,
};

export default orderDetailsHistoryEntryFactory;
