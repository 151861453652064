import { FC, useEffect, useState } from "react";
import MapComponent from "../../../../common/components/map/map.component";
import MapMarker from "../../../../common/components/map/types/map-marker";
import useAbort from "../../../../common/hooks/use-abort";
import useCargoOrderDetails from "../../../../common/services/cargo-order/details/use-cargo-order-details";
import planningOrderOrderMapFactory from "./planning-order-order-map.factory";
import LoaderComponent from "../../../../common/components/loader/loader.component";
import MapRoute from "../../../../common/components/map/types/map-route";
import SearchRoadRoutingResponse from "../../../../common/utils/search-road-route/search-road-routing.response";
import PlanningOrderOrderMapWaypoints from "./types/planning-order-order-map";
import searchRoadRoutingService from "../../../../common/utils/search-road-route/search-road-routing.service";

type PlanningOrderOrderMapProps = { orderUuid: string | undefined };

const PlanningOrderOrderMapComponent: FC<PlanningOrderOrderMapProps> = (
  props
) => {
  const [mapRoutes, setMapRoutes] = useState<MapRoute[]>([]);
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>();

  const cargoOrderDetailsAbort = useAbort();
  const cargoOrderDetails = useCargoOrderDetails();

  useEffect(() => {
    if (!props.orderUuid) return;

    cargoOrderDetails.load(
      {
        orderUuid: props.orderUuid,
      },
      cargoOrderDetailsAbort.signal
    );
  }, []);

  useEffect(() => {
    if (!cargoOrderDetails.data?.transportingOrders) return;

    const waypoints = planningOrderOrderMapFactory.createWaypoints(
      cargoOrderDetails.data.transportingOrders
    );

    const mapMarkers: MapMarker[] =
      planningOrderOrderMapFactory.createMapMarkers(waypoints);

    setMapMarkers(mapMarkers);

    fetchRoute(waypoints);
  }, [cargoOrderDetails.data?.transportingOrders]);

  const fetchRoute = (waypoints: PlanningOrderOrderMapWaypoints[]) => {
    const fetchPromises: Promise<SearchRoadRoutingResponse>[] = [];

    const isHighwayAllowed =
      cargoOrderDetails.data?.tollRoadsPermissions.isDuringRideAllowed;

    const waypointsCoordinates =
      planningOrderOrderMapFactory.createWaypointsCoordinates(
        waypoints,
        isHighwayAllowed
      );
    const fetch = searchRoadRoutingService.search(waypointsCoordinates);
    fetchPromises.push(fetch);

    Promise.all(fetchPromises).then((responses) => {
      const mapRouteWaypointGroups: MapRoute["waypoints"][] = [];

      responses.forEach((response) => {
        const mapRoute = planningOrderOrderMapFactory.createMapRoutes(
          response.routes[0].geometry.coordinates
        );

        if (mapRoute?.waypoints)
          mapRouteWaypointGroups.push(mapRoute.waypoints);
      });

      const newMapRoutes: MapRoute[] = mapRouteWaypointGroups.map(
        (routeWaypointGroup) => ({
          waypoints: routeWaypointGroup,
        })
      );

      setMapRoutes(newMapRoutes);
    });
  };

  const LoaderContent = (
    <div className="planning_order_order_loader">
      <LoaderComponent type="primary" />
    </div>
  );

  const MapContent = (
    <div className={"planning_order_order_map"}>
      <MapComponent
        markers={mapMarkers}
        autoFit
        autoFitOnUpdate
        routes={mapRoutes}
      />
    </div>
  );

  return (
    <div className="planning_order_order_details">
      {cargoOrderDetails.isLoading ? LoaderContent : MapContent}
    </div>
  );
};

export default PlanningOrderOrderMapComponent;
