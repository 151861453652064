import settlementTranslationsHelper from "../../../../../../languages/settlement-translations.helper";
import TaxiUnsettledRouteListingItem from "../types/taxi-unsettled-route-listing-item";
import TaxiUnsettledRouteListingTableColumn from "../types/taxi-unsettled-route-listing-table-column";
import TaxiUnsettledRouteListingTableRow from "../types/taxi-unsettled-route-listing-table-row";
import dateService from "../../../../../../common/utils/date/date.service";
import TableLinkButtonComponent from "../../../../../../common/components/table/button/link/table-link-button.component";
import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import billingRoutesHelper from "../../../../../billings/common/routes/billing-routes.helper";

const getColumns = (): TaxiUnsettledRouteListingTableColumn[] => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations()
      .table.headers;

  return [
    {
      accessor: "startDate",
      header: translations.startDateLabel,
      title: translations.startDateTitle,
      colSpan: 5,
    },
    {
      accessor: "orderIds",
      header: translations.orderIdsLabel,
      title: translations.orderIdsTitle,
      colSpan: 4,
    },
    {
      accessor: "routeId",
      header: translations.routeIdTitle,
      title: translations.routeIdTitle,
      colSpan: 4,
    },
    {
      accessor: "driver",
      header: translations.driverLabel,
      title: translations.driverTitle,
      colSpan: 7,
    },
    {
      accessor: "fleetPartner",
      header: translations.fleetPartnerLabel,
      title: translations.fleetPartnerTitle,
      colSpan: 7,
    },
    {
      accessor: "clients",
      header: translations.clientsLabel,
      title: translations.clientsTitle,
      colSpan: 10,
    },
    {
      accessor: "addresses",
      header: translations.routeLabel,
      title: translations.routeTitle,
      colSpan: 14,
    },
    {
      accessor: "actions",
      header: "",
      title: "",
      colSpan: 4,
    },
  ];
};

const getRow = (
  listingItem: TaxiUnsettledRouteListingItem
): TaxiUnsettledRouteListingTableRow => {
  const translations =
    settlementTranslationsHelper.getTaxiUnsettledRouteListingTranslations()
      .table;

  const startDateLabel = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  const startDateTitle = dateService.format(
    listingItem.startDate,
    "dd.mm.yyyy"
  );

  return {
    id: listingItem.uuid,
    value: {
      startDate: <div title={startDateLabel}>{startDateTitle}</div>,
      routeId: (
        <div title={String(listingItem.routeId)}>{listingItem.routeId}</div>
      ),
      driver: (
        <div title={listingItem.driverName}>{listingItem.driverName}</div>
      ),
      fleetPartner: (
        <div title={listingItem.fleetPartner ?? ""}>
          {listingItem.fleetPartner}
        </div>
      ),
      clients: <div title={listingItem.clients}>{listingItem.clients}</div>,
      addresses: (
        <div title={listingItem.addresses}>{listingItem.addresses}</div>
      ),
      orderIds: (
        <div title={listingItem.orderIds.join(",")}>
          {listingItem.orderIds.join(",")}
        </div>
      ),
      actions: (
        <div className="d-flex">
          <TableLinkButtonComponent
            icon={faAdd}
            to={billingRoutesHelper.getTaxiWithDriverAddRoute({
              planEntryUuid: listingItem.uuid,
            })}
            title={translations.taxiWithDriverSettlementAddButtonTitle}
            idForTesting={`taxi-unsettled-route-listing-table-item-${listingItem.uuid}-add-button`}
          />
        </div>
      ),
    },
  };
};

const getRows = (
  listingItems: TaxiUnsettledRouteListingItem[]
): TaxiUnsettledRouteListingTableRow[] => {
  return listingItems.map(getRow);
};

const taxiUnsettledRouteListingTableHelper = {
  getColumns,
  getRows,
};

export default taxiUnsettledRouteListingTableHelper;
