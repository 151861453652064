import { debounce } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import BillingsNode from "../types/billings-node";
import BillingsRouteDetailsTableRow from "./types/billings-route-details-table-row";
import dateService from "../../../../common/utils/date/date.service";
import NumericInputComponent from "../../../../common/components/form/input/numeric-input/numeric-input.component";
import TableComponent from "../../../../common/components/table/table.component";
import CardComponent from "../../../../common/components/card/card.component";
import billingsTranslationsHelper from "../../../../languages/billings-translations.helper";
import BillingAddressAddComponent from "../billing-address-add/billing-address-add.component";
import { useAppContext } from "../../../../context/app.context";
import billingsUserPermissionsHelper from "../user-permissions/billings-user-permission.helper";
import SingleSelectComponent from "../../../../common/components/form/select/single-select/single-select.component";
import ButtonComponent from "../../../../common/components/button/button.component";
import TableLinkButtonComponent from "../../../../common/components/table/button/link/table-link-button.component";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import billingRouteDetailsHelper from "./common/billings-route-details.helper";
import BillingsRouteDetailsAddress from "./types/billings-route-details-address";
import BillingsRouteDetailsAddressSelectOption from "./types/billings-route-details-address-select-option";
import billingsRouteDetailsFactory from "./common/billings-route-details.factory";
import useOpen from "../../../../common/hooks/use-open";
import useBillingsOrderAddresses from "../../../../common/services/billings/order/addresses/use-billings-order-addresses";
import useAbort from "../../../../common/hooks/use-abort";
import { NodeValidationResult } from "./types/billings-route-details-form-data-validation-result";
import LinkButtonComponent from "../../../../common/components/button/link/link-button.component";
import googleMapsService from "../../../../common/services/google-maps/google-maps.service";
import BillingsRouteDetailsMapPoints from "./types/billings-route-details-map-points";

type BillingRouteDetailsProps = {
  billingNodes: BillingsNode[] | undefined;
  formValidationResults: NodeValidationResult[];
  onBillingNodeChanged: (node: BillingsNode) => void;
  onCopyFromPlannedDistance: () => void;
  onInputBlur: () => void;
  validationOnBlur: (position: number, fieldName: string) => void;
  orderId?: string;
};

const BillingsRouteDetailsFormComponent: FC<BillingRouteDetailsProps> = (
  props
) => {
  const translations = billingsTranslationsHelper.getRouteDetailsTranslations();

  const { user } = useAppContext();

  const userPermissions = billingsUserPermissionsHelper.getPermissions(
    user?.roles!
  );

  const billingsOrderAddresses = useBillingsOrderAddresses();
  const addressesAbort = useAbort();

  const { isOpen, open, close } = useOpen();

  const [editingNodeAddressNodePosition, setEditingNodeAddressNodePosition] =
    useState(-1);

  const [addressSelectOptions, setAddressSelectionOptions] = useState<
    BillingsRouteDetailsAddressSelectOption[]
  >([]);

  useEffect(() => {
    const newBillingNodeAddressesSelectOptions =
      billingsRouteDetailsFactory.createBillingAddressesSelectOptions(
        billingsOrderAddresses.data
      );

    setAddressSelectionOptions(newBillingNodeAddressesSelectOptions);
  }, [billingsOrderAddresses.data]);

  const loadAddresses = useCallback(
    debounce((query: string, orderUuid: string) => {
      billingsOrderAddresses.load(
        { orderUuid: orderUuid, searchQuery: query },
        addressesAbort.signal
      );
    }, 500),
    []
  );

  const onDistanceChange = (value: number | null, nodePosition: number) => {
    const node = props.billingNodes!.find((x) => x.position === nodePosition)!;

    node.distance = value ?? 0;

    props.onBillingNodeChanged(node);
  };

  const onHaltingTimeChange = (value: number | null, nodePosition: number) => {
    const node = props.billingNodes!.find((x) => x.position === nodePosition)!;

    node.haltingTime = value ? value * 60 : 0;

    props.onBillingNodeChanged(node);
  };

  const onHighwayChargeChange = (
    value: number | null,
    nodePosition: number
  ) => {
    const node = props.billingNodes!.find((x) => x.position === nodePosition)!;

    node.highwayCharge = value ?? 0;

    props.onBillingNodeChanged(node);
  };

  const onNodeWaypointsChange = (
    address: BillingsRouteDetailsAddress | null,
    position: number
  ) => {
    if (!address) {
      return;
    }

    const node = props.billingNodes!.find((x) => x.position === position)!;

    node.lat = address.latitude;
    node.lon = address.longitude;
    node.description = address.displayName;

    props.onBillingNodeChanged(node);
    props.onInputBlur();
  };

  const onAddressSearchQueryChange = useCallback(
    (query: string) => {
      if (!query || !props.orderId) {
        return;
      }

      loadAddresses(query, props.orderId);
    },
    [loadAddresses, props.orderId]
  );

  const createTableRow = (
    listingItem: BillingsNode
  ): BillingsRouteDetailsTableRow => {
    const address =
      billingsRouteDetailsFactory.createBillingAddress(listingItem);

    const addressSelectOption =
      billingsRouteDetailsFactory.createAddressSelectOption(address);

    const addCustomAddressSelectOption: BillingsRouteDetailsAddressSelectOption =
      {
        label: translations.addressSelectSearchAddNewAddress,
        value: null,
        onClick: () => {
          open();
          setEditingNodeAddressNodePosition(listingItem.position);
        },
      };

    const selectOptions = [
      addCustomAddressSelectOption,
      ...addressSelectOptions,
    ];

    const isFirstNode = listingItem.position === 1;

    const nodeValidationResult = props.formValidationResults.find(
      (x) => x.position === listingItem.position
    );

    return {
      id: `${listingItem.position}`,
      value: {
        routeNode: listingItem.isEditable ? (
          <div
            title={`${listingItem.position} - ${listingItem.description}`}
            className="d-flex align-items-center"
          >
            <div className="mr-2">{listingItem.position}</div>
            <SingleSelectComponent
              onChange={(value) =>
                onNodeWaypointsChange(value?.value, listingItem.position)
              }
              options={selectOptions}
              defaultValue={addressSelectOption}
              value={addressSelectOption}
              isLoading={billingsOrderAddresses.isLoading}
              maxMenuHeight={500}
              isSearchable={userPermissions.hasAccessToSearchAddress}
              filterFunction={() => true}
              onInputChange={(query) => {
                onAddressSearchQueryChange(query);
              }}
              noOptionsMessage={(inputValue) => {
                if (inputValue) {
                  return translations.addressSelectSearchNoOptionsMessage;
                }
                return translations.addressSelectSearchTipMessage;
              }}
            />
          </div>
        ) : (
          <div title={`${listingItem.position} - ${listingItem.description}`}>
            <div>{`${listingItem.position} - ${listingItem.description}`}</div>
          </div>
        ),
        time: (
          <div
            title={dateService.format(
              listingItem.checkoutDate!,
              "dd/mm/yyyy HH:MM"
            )}
          >
            {dateService.format(listingItem.checkoutDate!, "HH:MM")}
          </div>
        ),
        plannedTime: (
          <div
            title={dateService.format(
              listingItem.plannedDate!,
              "dd/mm/yyyy HH:MM"
            )}
          >
            {dateService.format(listingItem.plannedDate!, "HH:MM")}
          </div>
        ),
        haltingTime: (
          <div title={Math.ceil(listingItem.haltingTime / 60).toString()}>
            <NumericInputComponent
              value={Math.ceil(listingItem.haltingTime / 60)}
              onChange={(value) =>
                onHaltingTimeChange(value, listingItem.position)
              }
              isIntegerOnly
              onBlur={() =>
                props.validationOnBlur(listingItem.position, "haltingTime")
              }
              hasError={!!nodeValidationResult?.haltingTime.errorMessage}
            />
            {!!nodeValidationResult?.haltingTime.errorMessage && (
              <span className={"route_details_error_message"}>
                {nodeValidationResult.haltingTime.errorMessage}
              </span>
            )}
          </div>
        ),
        distance: (
          <div title={listingItem.distance?.toString()}>
            <NumericInputComponent
              value={listingItem?.distance}
              onChange={(value) =>
                onDistanceChange(value, listingItem.position)
              }
              isIntegerOnly
              onBlur={() =>
                props.validationOnBlur(listingItem.position, "distance")
              }
              hasError={!!nodeValidationResult?.distance.errorMessage}
            />
            {!!nodeValidationResult?.distance.errorMessage && (
              <span className={"route_details_error_message"}>
                {nodeValidationResult.distance.errorMessage}
              </span>
            )}
          </div>
        ),
        plannedDistance: (
          <div title={listingItem.plannedDistance?.toString()}>
            {listingItem.plannedDistance}
          </div>
        ),
        highwayCost: (
          <div>
            <NumericInputComponent
              value={listingItem?.highwayCharge}
              onChange={(value) =>
                onHighwayChargeChange(value, listingItem.position)
              }
              decimalPrecision={2}
              onBlur={() =>
                props.validationOnBlur(listingItem.position, "highwayCharge")
              }
              hasError={!!nodeValidationResult?.highwayCharge.errorMessage}
            />
            {!!nodeValidationResult?.highwayCharge?.errorMessage && (
              <span className={"route_details_error_message"}>
                {nodeValidationResult.highwayCharge.errorMessage}
              </span>
            )}
          </div>
        ),
        actions: !isFirstNode && (
          <TableLinkButtonComponent
            icon={faMapLocation}
            openInNewTab
            to={listingItem.googleMapsUrl}
          />
        ),
      },
    };
  };

  const tableRows: BillingsRouteDetailsTableRow[] =
    props.billingNodes?.map((item) => createTableRow(item)) ?? [];

  const tableColumns = billingRouteDetailsHelper.getTableColumns();

  const allRoutePoints: BillingsRouteDetailsMapPoints[] | undefined =
    props.billingNodes?.map((item) => [item.lat, item.lon]);
  const googleMapsRouteUrl = googleMapsService.buildRouteUrlWithManyPoints(
    allRoutePoints!
  );

  return (
    <>
      <CardComponent
        classNames={{ root: "route_details" }}
        header={{ title: translations.headingText }}
      >
        <TableComponent columns={tableColumns} rows={tableRows} />
        <div className="route_details_button_wrapper">
          <ButtonComponent
            type="primary"
            onClick={props.onCopyFromPlannedDistance}
          >
            {translations.copyDistanceLabel}
          </ButtonComponent>
          <LinkButtonComponent
            type="primary"
            title={translations.opentRouteInGoogleMapsTitle}
            to={googleMapsRouteUrl}
            openInNewTab
          >
            {translations.opentRouteInGoogleMapsLabel}
          </LinkButtonComponent>
        </div>
      </CardComponent>

      {isOpen && (
        <BillingAddressAddComponent
          addAddressIsOpen={isOpen}
          closeModal={() => {
            close();
            setEditingNodeAddressNodePosition(-1);
          }}
          onSave={(address: BillingsRouteDetailsAddress) => {
            close();
            onNodeWaypointsChange(address, editingNodeAddressNodePosition);
          }}
        />
      )}
    </>
  );
};

export default BillingsRouteDetailsFormComponent;
