import BillingSaveRequest from "../api/billing-save.request";
import BillingNodeApi from "../api/types/billing-node";
import BillingsNode from "../../../common/types/billings-node";

const createSaveBillingRequest = (
  nodes: BillingsNode[],
  discountValue: number
): BillingSaveRequest => {
  const result: BillingSaveRequest = {
    billing_nodes: nodes.map((specificNode) => {
      const node: BillingNodeApi = {
        id: specificNode.id,
        node_id: specificNode.nodeId,
        order_id: specificNode.orderId,
        lat: specificNode.lat,
        lon: specificNode.lon,
        distance: specificNode.distance,
        description: specificNode.description,
        checkout_date: specificNode.checkoutDate?.toJSON() ?? null,
        planned_date: specificNode.plannedDate?.toJSON() ?? null,
        halting_amount: specificNode.haltingAmount,
        halting_time: specificNode.haltingTime,
        allow_charge: specificNode.isHighwayAllowed,
        highway_charge: specificNode.highwayCharge,
        planned_distance: specificNode.plannedDistance,
        editable_coordinates: specificNode.isEditable,
      };
      return node;
    }),
    discount: discountValue,
  };
  return result;
};

const billingSaveRequestFactory = {
  createSaveBillingRequest,
};

export default billingSaveRequestFactory;
