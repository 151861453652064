import ListingFilterBadge from "../../../../../../common/components/listing/filter/badge/listing-filter-badge";
import dateService from "../../../../../../common/utils/date/date.service";
import routeTranslationsHelper from "../../../../../../languages/route-translations.helper";
import RouteFinishedListingByTaxiFilter, {
  RouteFinishedListingByTaxiDriverFilter,
  RouteFinishedListingByTaxiEndDateFilter,
  RouteFinishedListingByTaxiPassengersFilter,
  RouteFinishedListingByTaxiRouteIntermediateAddressFilter,
  RouteFinishedListingByTaxiRouteIdFilter,
  RouteFinishedListingByTaxiStartDateFilter,
  RouteFinishedListingByTaxiRouteDestinationAddressFilter,
  RouteFinishedListingByTaxiRoutePickupAddressFilter,
} from "../../common/types/route-finished-listing-by-taxi-filter";
import RouteFinishedListingByTaxiFilterType from "../../common/types/route-finished-listing-by-taxi-filter-type";

const getBadgeOptions = (filter: RouteFinishedListingByTaxiFilter) => {
  const translations =
    routeTranslationsHelper.getFinishedListingTranslations().filters;

  const options: {
    badge: ListingFilterBadge;
    filter: RouteFinishedListingByTaxiFilter;
  }[] = [
    {
      badge: {
        text: translations.driverFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiDriverFilter["value"]
        ),
        title: translations.driverFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiDriverFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.DRIVER,
        value: filter.value as RouteFinishedListingByTaxiDriverFilter["value"],
      },
    },
    {
      badge: {
        text: translations.endDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteFinishedListingByTaxiEndDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteFinishedListingByTaxiEndDateFilter["value"])
                .to
            )
          ),
        title: translations.endDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (filter.value as RouteFinishedListingByTaxiEndDateFilter["value"])
                .from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (filter.value as RouteFinishedListingByTaxiEndDateFilter["value"])
                .to
            )
          ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.END_DATE,
        value: filter.value as RouteFinishedListingByTaxiEndDateFilter["value"],
      },
    },
    {
      badge: {
        text: translations.passengerFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiPassengersFilter["value"]
        ),
        title: translations.passengerFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiPassengersFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.PASSENGERS,
        value:
          filter.value as RouteFinishedListingByTaxiPassengersFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIdFilterText.replace(
          "#{query}",
          String(
            filter.value as RouteFinishedListingByTaxiRouteIdFilter["value"]
          )
        ),
        title: translations.routeIdFilterTitle.replace(
          "#{query}",
          String(
            filter.value as RouteFinishedListingByTaxiRouteIdFilter["value"]
          )
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_ID,
        value: filter.value as RouteFinishedListingByTaxiRouteIdFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeIntermediateAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRouteIntermediateAddressFilter["value"]
        ),
        title: translations.routeIntermediateAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRouteIntermediateAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_INTERMEDIATE_ADDRESS,
        value:
          filter.value as RouteFinishedListingByTaxiRouteIntermediateAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routeDestinationAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRouteDestinationAddressFilter["value"]
        ),
        title: translations.routeDestinationAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRouteDestinationAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_DESTINATION_ADDRESS,
        value:
          filter.value as RouteFinishedListingByTaxiRouteDestinationAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.routePickupAddressFilterText.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRoutePickupAddressFilter["value"]
        ),
        title: translations.routePickupAddressFilterTitle.replace(
          "#{query}",
          filter.value as RouteFinishedListingByTaxiRoutePickupAddressFilter["value"]
        ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.ROUTE_PICKUP_ADDRESS,
        value:
          filter.value as RouteFinishedListingByTaxiRoutePickupAddressFilter["value"],
      },
    },
    {
      badge: {
        text: translations.startDateFilterText
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByTaxiStartDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByTaxiStartDateFilter["value"]
              ).to
            )
          ),
        title: translations.startDateFilterTitle
          .replace(
            "#{fromDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByTaxiStartDateFilter["value"]
              ).from
            )
          )
          .replace(
            "#{toDate}",
            dateService.formatDate(
              (
                filter.value as RouteFinishedListingByTaxiStartDateFilter["value"]
              ).to
            )
          ),
      },
      filter: {
        type: RouteFinishedListingByTaxiFilterType.START_DATE,
        value:
          filter.value as RouteFinishedListingByTaxiStartDateFilter["value"],
      },
    },
  ];

  return options;
};

const getBadge = (
  filter: RouteFinishedListingByTaxiFilter
): ListingFilterBadge | undefined => {
  const options = getBadgeOptions(filter);

  return options.find(
    (item) =>
      item.filter.type === filter.type && item.filter.value === filter.value
  )?.badge;
};

const getBadges = (
  filters: RouteFinishedListingByTaxiFilter[]
): ListingFilterBadge[] => {
  const badges: ListingFilterBadge[] = [];

  filters.forEach((filter) => {
    const badge = getBadge(filter);

    if (!badge) {
      return;
    }

    badges.push(badge);
  });

  return badges;
};

const routeFinishedListingByTaxiFiltersBadgeListHelper = {
  getBadges,
};

export default routeFinishedListingByTaxiFiltersBadgeListHelper;
